import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An ISO 8601-encoded date */
  ISO8601Date: any
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any
  /** Represents untyped JSON */
  JSON: any
  Upload: any
}

export type IAccountRequest = {
  __typename?: 'AccountRequest'
  createdAt: Scalars['ISO8601DateTime']
  errorMessage?: Maybe<Scalars['String']>
  escalatedAt?: Maybe<Scalars['ISO8601DateTime']>
  escalatedBy?: Maybe<IUser>
  failedAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  kind: OpsAccountRequestKind
  notes?: Maybe<Scalars['String']>
  payload: IAccountRequestPayloadUnionPresenterType
  priority: OpsAccountRequestPriority
  processedAt?: Maybe<Scalars['ISO8601DateTime']>
  processedBy?: Maybe<IUser>
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']>
  rejectedBy?: Maybe<IUser>
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>
  reviewedBy?: Maybe<IUser>
  status: OpsAccountRequestStatus
  user: IUser
}

export type IAccountRequestCollection = {
  __typename?: 'AccountRequestCollection'
  accountRequests: Array<IAccountRequest>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IAccountRequestDynamicFilter = {
  field: AccountRequestDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum AccountRequestDynamicFilterField {
  EscalatedAt = 'escalated_at',
  Kind = 'kind',
  Priority = 'priority',
  Status = 'status',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserId = 'user_id'
}

export type IAccountRequestDynamicFilterItem = {
  items: Array<IAccountRequestDynamicFilter>
  operator: DynamicFilterOperator
}

export type IAccountRequestPayloadUnionPresenterType =
  | ICancelSubscriptionResponsePayload
  | IChangeSubscriptionBillingCycleResponsePayload
  | ICreditResponsePayload
  | IDeleteDuplicateAccountResponsePayload
  | IMiscRequestResponsePayload
  | IOneOffAddonResponsePayload
  | IPauseSubscriptionResponsePayload
  | IRefundResponsePayload
  | IUncancelSubscriptionResponsePayload
  | IUnpauseSubscriptionResponsePayload
  | IUpdateSubscriptionResponsePayload
  | IUpsertLimitedSupportResponsePayload

export type IAccountRequestPayloadUnionSubmissionType = {
  cancelSubscription?: InputMaybe<ICancelSubscriptionRequestPayload>
  changeSubscriptionBillingCycle?: InputMaybe<IChangeSubscriptionBillingCycleRequestPayload>
  credit?: InputMaybe<ICreditRequestPayload>
  deleteDuplicateAccount?: InputMaybe<IDeleteDuplicateAccountRequestPayload>
  miscRequest?: InputMaybe<IMiscRequestPayload>
  oneOffAddon?: InputMaybe<IOneOffAddonRequestPayload>
  pauseSubscription?: InputMaybe<IPauseSubscriptionRequestPayload>
  refund?: InputMaybe<IRefundRequestPayload>
  type: OpsAccountRequestKind
  uncancelSubscription?: InputMaybe<IUncancelSubscriptionRequestPayload>
  unpauseSubscription?: InputMaybe<IUnpauseSubscriptionRequestPayload>
  updateSubscription?: InputMaybe<IUpgradeSubscriptionRequestPayload>
}

export type IAccountRequestSort = {
  direction: SortDirection
  field: AccountRequestSortField
}

export enum AccountRequestSortField {
  EscalatedAt = 'escalated_at',
  Kind = 'kind',
  Priority = 'priority',
  ProcessedAt = 'processed_at',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name'
}

export type IAccountRequestsConfig = {
  __typename?: 'AccountRequestsConfig'
  pauseReasons: Array<IOpsAccountRequestKindsPauseSubscriptionReasonDef>
  refundReasons: Array<IOpsAccountRequestKindsRefundReasonDef>
}

export type IActivity = {
  __typename?: 'Activity'
  allDay: Scalars['Boolean']
  autoGenerated: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  description: Scalars['String']
  endedAt: Scalars['ISO8601DateTime']
  externalSource?: Maybe<Scalars['String']>
  favorite: Scalars['Boolean']
  glucose: IEntryGlucose
  id: Scalars['ID']
  length: Scalars['Int']
  occurredAt: Scalars['ISO8601DateTime']
  startedAt: Scalars['ISO8601DateTime']
  statistics: IEntryStatistics
  status?: Maybe<Scalars['String']>
  title: Scalars['String']
  type: Scalars['String']
}

export type IAddonLimit = {
  __typename?: 'AddonLimit'
  description?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  productId: Scalars['ID']
  purchasedAmount: Scalars['Int']
}

export type IAddress = {
  __typename?: 'Address'
  city: Scalars['String']
  country?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  state?: Maybe<AddressState>
  street: Scalars['String']
  street2: Scalars['String']
  zipCode: Scalars['String']
}

export enum AddressCountry {
  Ca = 'CA',
  Gb = 'GB',
  Us = 'US'
}

export enum AddressState {
  Aa = 'AA',
  Ab = 'AB',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Bc = 'BC',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Mb = 'MB',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nb = 'NB',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nl = 'NL',
  Nm = 'NM',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  On = 'ON',
  Or = 'OR',
  Other = 'Other',
  Pa = 'PA',
  Pe = 'PE',
  Pr = 'PR',
  Pw = 'PW',
  Qc = 'QC',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Sk = 'SK',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
  Yt = 'YT'
}

export type IAllowEarlyExitBehavior = {
  __typename?: 'AllowEarlyExitBehavior'
  followupSurvey?: Maybe<SurveysConfigKind>
}

export type IAnswer = {
  __typename?: 'Answer'
  /** @deprecated Use followups instead */
  followup?: Maybe<IFollowup>
  followups: Array<IFollowup>
  inlineAlert?: Maybe<IInlineAlert>
  label: Scalars['String']
  value: Scalars['String']
}

export type IAnswersGroup = {
  __typename?: 'AnswersGroup'
  answers: Array<IAnswer>
  answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
}

export type IAppointment = {
  __typename?: 'Appointment'
  actualDuration?: Maybe<Scalars['Int']>
  addToGoogleCalendarLink?: Maybe<Scalars['String']>
  agenda?: Maybe<Scalars['String']>
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  date?: Maybe<Scalars['String']>
  externalVideochatUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  insurancePolicy?: Maybe<IInsurancePolicy>
  meetingAt?: Maybe<Scalars['ISO8601DateTime']>
  meetingStatus?: Maybe<InsuranceBerryStreetAppointmentMeetingStatus>
  nutritionistName?: Maybe<Scalars['String']>
  recurrent: Scalars['Boolean']
  status: InsuranceBerryStreetAppointmentStatus
  title?: Maybe<Scalars['String']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']>
  user: IUser
}

export type IAppointmentCollection = {
  __typename?: 'AppointmentCollection'
  appointments: Array<IHealthPortalAppointment>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IAppointmentDynamicFilter = {
  field: AppointmentDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum AppointmentDynamicFilterField {
  MeetingAt = 'meeting_at',
  NutritionistEmail = 'nutritionist_email',
  UserId = 'user_id'
}

export type IAppointmentDynamicFilterItem = {
  items: Array<IAppointmentDynamicFilter>
  operator: DynamicFilterOperator
}

export type IAppointmentSort = {
  direction: SortDirection
  field: AppointmentSortField
}

export enum AppointmentSortField {
  MeetingAt = 'meeting_at'
}

export type IAppointmentType = {
  __typename?: 'AppointmentType'
  duration: Scalars['String']
  key: InsuranceBerryStreetAppointmentType
  title: Scalars['String']
}

export type IApprovalRequest = {
  __typename?: 'ApprovalRequest'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  kind: UserApprovalRequestKind
  state: UserApprovalRequestState
  user: IUser
}

export type IApprovalRequestCollection = {
  __typename?: 'ApprovalRequestCollection'
  approvalRequests: Array<IApprovalRequest>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IApprovalRequestDynamicFilter = {
  field: ApprovalRequestsDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export type IApprovalRequestDynamicFilterItem = {
  items: Array<IApprovalRequestDynamicFilter>
  operator: DynamicFilterOperator
}

export type IApprovalRequestSort = {
  direction: SortDirection
  field: ApprovalRequestSortField
}

export enum ApprovalRequestSortField {
  CreatedAt = 'created_at',
  Kind = 'kind',
  State = 'state',
  UserAddressCity = 'user_address_city',
  UserAddressState = 'user_address_state',
  UserAddressStreet = 'user_address_street',
  UserAddressStreet2 = 'user_address_street2',
  UserAddressZipCode = 'user_address_zip_code',
  UserDateOfBirth = 'user_date_of_birth',
  UserDexcomSensorPromptUiState = 'user_dexcom_sensor_prompt_ui_state',
  UserEmail = 'user_email',
  UserFulfillmentConfigurationSensorKind = 'user_fulfillment_configuration_sensor_kind',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserLastCoreSubscriptionLatestInvoiceLatestChargeCreated = 'user_last_core_subscription_latest_invoice_latest_charge_created',
  UserLastCoreSubscriptionLatestInvoicePrimaryInvoiceLinePlanNickname = 'user_last_core_subscription_latest_invoice_primary_invoice_line_plan_nickname',
  UserLastCoreSubscriptionPrimaryProduct = 'user_last_core_subscription_primary_product',
  UserPhoneNumber = 'user_phone_number'
}

export enum ApprovalRequestsDynamicFilterField {
  CreatedAt = 'created_at',
  Kind = 'kind',
  State = 'state',
  UserAddressCity = 'user_address_city',
  UserAddressState = 'user_address_state',
  UserAddressStreet = 'user_address_street',
  UserAddressStreet2 = 'user_address_street2',
  UserAddressZipCode = 'user_address_zip_code',
  UserDateOfBirth = 'user_date_of_birth',
  UserDexcomSensorPromptUiState = 'user_dexcom_sensor_prompt_ui_state',
  UserEmail = 'user_email',
  UserFulfillmentConfigurationSensorKind = 'user_fulfillment_configuration_sensor_kind',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserLastCoreSubscriptionLatestInvoiceLatestChargeCreated = 'user_last_core_subscription_latest_invoice_latest_charge_created',
  UserLastCoreSubscriptionLatestInvoicePrimaryInvoiceLinePlanNickname = 'user_last_core_subscription_latest_invoice_primary_invoice_line_plan_nickname',
  UserLastCoreSubscriptionPrimaryProduct = 'user_last_core_subscription_primary_product',
  UserPhoneNumber = 'user_phone_number'
}

export type IBehavior = {
  __typename?: 'Behavior'
  allowEarlyExit: Scalars['Boolean']
  allowMultipleSubmissions: Scalars['Boolean']
  redirectOnOutcome: Scalars['Boolean']
  showAlertOnClose: Scalars['Boolean']
  showProgressIndicator: Scalars['Boolean']
  trackResponses: Scalars['Boolean']
  warnBeforeStopping: Scalars['Boolean']
}

export enum BillingProduct {
  AutoCancelNutrisenseMonthly = 'auto_cancel_nutrisense_monthly',
  BundledFixedMember = 'bundled_fixed_member',
  BundledFixedMemberBuyNowPayLater = 'bundled_fixed_member_buy_now_pay_later',
  CancellationFee = 'cancellation_fee',
  Cgm = 'cgm',
  CompoundedSemaglutide = 'compounded_semaglutide',
  DexcomPatch = 'dexcom_patch',
  DmDietitian_4Video_30Min = 'dm_dietitian_4_video_30_min',
  DmDietitianVideo_30Min = 'dm_dietitian_video_30_min',
  DmMealPlan = 'dm_meal_plan',
  DmQGlucoseCompare = 'dm_q_glucose_compare',
  DmQGlucoseEvent = 'dm_q_glucose_event',
  DmQGlucoseFasting = 'dm_q_glucose_fasting',
  DmQGlucoseMetrics = 'dm_q_glucose_metrics',
  DmQGlucoseTrends = 'dm_q_glucose_trends',
  DmSupplementReview = 'dm_supplement_review',
  DmWeeklyGoals = 'dm_weekly_goals',
  DowngradeFee = 'downgrade_fee',
  DowngradeFee_6To_3Cycle_1 = 'downgrade_fee_6_to_3_cycle_1',
  DowngradeFee_6To_3Cycle_2 = 'downgrade_fee_6_to_3_cycle_2',
  DowngradeFee_12To_3Cycle_1 = 'downgrade_fee_12_to_3_cycle_1',
  DowngradeFee_12To_3Cycle_2 = 'downgrade_fee_12_to_3_cycle_2',
  DowngradeFee_12To_6Cycle_1 = 'downgrade_fee_12_to_6_cycle_1',
  DowngradeFee_12To_6Cycle_2 = 'downgrade_fee_12_to_6_cycle_2',
  DowngradeFee_12To_6Cycle_3 = 'downgrade_fee_12_to_6_cycle_3',
  DowngradeFee_12To_6Cycle_4 = 'downgrade_fee_12_to_6_cycle_4',
  DowngradeFee_12To_6Cycle_5 = 'downgrade_fee_12_to_6_cycle_5',
  FreeCgmReplacement = 'free_cgm_replacement',
  FreeSoftwareOnlyDppAnnualMembership = 'free_software_only_dpp_annual_membership',
  FreeSoftwareOnlyMedicalAnnualMembership = 'free_software_only_medical_annual_membership',
  FreeSoftwareOnlyOwnSensorAnnualMembership = 'free_software_only_own_sensor_annual_membership',
  FreeSoftwareOnlyVideoCallsAnnualMembership = 'free_software_only_video_calls_annual_membership',
  Influencer_1 = 'influencer_1',
  Influencer_2 = 'influencer_2',
  MedicalConsultAddon = 'medical_consult_addon',
  NonRecurring_1_16Rd = 'non_recurring_1_16_rd',
  NonRecurring_1_32Rd = 'non_recurring_1_32_rd',
  NonRecurring_1NoRd = 'non_recurring_1_no_rd',
  NonRecurring_2_32Rd = 'non_recurring_2_32_rd',
  NonRecurring_2NoRd = 'non_recurring_2_no_rd',
  NonTaxablePrepaidNutrisenseMonthly_1 = 'non_taxable_prepaid_nutrisense_monthly_1',
  NonTaxablePrepaidNutrisenseMonthly_3 = 'non_taxable_prepaid_nutrisense_monthly_3',
  NonTaxablePrepaidNutrisenseMonthly_6 = 'non_taxable_prepaid_nutrisense_monthly_6',
  NonTaxablePrepaidNutrisenseMonthly_12 = 'non_taxable_prepaid_nutrisense_monthly_12',
  NutrisenseDietitian = 'nutrisense_dietitian',
  NutrisenseDietitianLimited = 'nutrisense_dietitian_limited',
  NutrisenseMonthly = 'nutrisense_monthly',
  NutrisenseMonthly_3 = 'nutrisense_monthly_3',
  NutrisenseMonthly_6 = 'nutrisense_monthly_6',
  NutrisenseMonthly_6BuyNowPayLater = 'nutrisense_monthly_6_buy_now_pay_later',
  NutrisenseMonthly_12 = 'nutrisense_monthly_12',
  NutrisenseMonthly_12BuyNowPayLater = 'nutrisense_monthly_12_buy_now_pay_later',
  NutrisensePeriodic_1_3 = 'nutrisense_periodic_1_3',
  NutrisensePeriodic_1_6 = 'nutrisense_periodic_1_6',
  NutrisensePeriodic_2_3 = 'nutrisense_periodic_2_3',
  NutrisensePeriodic_2_6 = 'nutrisense_periodic_2_6',
  NutrisenseSoftwareOnly = 'nutrisense_software_only',
  NutritionCoachFocused = 'nutrition_coach_focused',
  PaidCgmReplacement = 'paid_cgm_replacement',
  PaidDexcomPatch = 'paid_dexcom_patch',
  PaidPatch = 'paid_patch',
  PaidReshipCgmReplacement = 'paid_reship_cgm_replacement',
  Patch = 'patch',
  PrepaidNutrisenseMonthly_1 = 'prepaid_nutrisense_monthly_1',
  PrepaidNutrisenseMonthly_3 = 'prepaid_nutrisense_monthly_3',
  PrepaidNutrisenseMonthly_6 = 'prepaid_nutrisense_monthly_6',
  PrepaidNutrisenseMonthly_12 = 'prepaid_nutrisense_monthly_12',
  ReshipCgmReplacement = 'reship_cgm_replacement',
  ShippingFee = 'shipping_fee',
  SoftwareOnlyAnnualMembership = 'software_only_annual_membership',
  SoftwareOnlyOwnSensorAnnualMembership = 'software_only_own_sensor_annual_membership',
  SupplementMg_2 = 'supplement_mg_2',
  SupplementMg_6 = 'supplement_mg_6',
  SupplementMvt_2 = 'supplement_mvt_2',
  SupplementMvt_6 = 'supplement_mvt_6',
  SupplementQ10_2 = 'supplement_q10_2',
  SupplementQ10_6 = 'supplement_q10_6',
  Tax = 'tax',
  Unknown = 'unknown',
  VideoCallLateCancellationFee = 'video_call_late_cancellation_fee',
  VideoCallLateRescheduleFee = 'video_call_late_reschedule_fee',
  VideoCallNoShowFee = 'video_call_no_show_fee',
  WelcomeKit = 'welcome_kit'
}

export enum BillingProductGroupKind {
  Commitment = 'commitment',
  Dropdown = 'dropdown'
}

export type IBiteAi = {
  __typename?: 'BiteAi'
  dailyValues: Array<IKeyValuePairFloat>
  units: Array<IBiteAiKeyValuePairString>
}

export type IBiteAiKeyValuePairString = {
  __typename?: 'BiteAiKeyValuePairString'
  key: Scalars['String']
  value: Scalars['String']
}

export type IBloodPressureMeasurement = {
  __typename?: 'BloodPressureMeasurement'
  diastolic: Scalars['Float']
  systolic: Scalars['Float']
}

export type IBulkAssign = {
  __typename?: 'BulkAssign'
  coachAssignments: Array<ICoachAssignment>
}

export type IBulkCreateShipment = {
  orderId: Scalars['Int']
  trackingNumber?: InputMaybe<Scalars['String']>
  trackingUrl?: InputMaybe<Scalars['String']>
}

export type ICalculateDuplicateAccountCredit = {
  __typename?: 'CalculateDuplicateAccountCredit'
  creditAmountInCents: Scalars['Int']
}

export type ICalendarDate = {
  __typename?: 'CalendarDate'
  date: Scalars['ISO8601Date']
  score?: Maybe<Scalars['Int']>
}

export type ICancelSubscriptionRequestPayload = {
  cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
  immediateCancellation: Scalars['Boolean']
  subscriptionId: Scalars['ID']
  waiveEarlyCancellationFee: Scalars['Boolean']
  waiveEarlyCancellationSurcharge: Scalars['Boolean']
}

export type ICancelSubscriptionResponsePayload = {
  __typename?: 'CancelSubscriptionResponsePayload'
  cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
  immediateCancellation: Scalars['Boolean']
  subscriptionId: Scalars['ID']
  waiveEarlyCancellationFee: Scalars['Boolean']
  waiveEarlyCancellationSurcharge: Scalars['Boolean']
}

export type IChangeSubscriptionBillingCycleRequestPayload = {
  billingCycleAnchor: Scalars['ISO8601DateTime']
  subscriptionId: Scalars['ID']
}

export type IChangeSubscriptionBillingCycleResponsePayload = {
  __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
  billingCycleAnchor: Scalars['ISO8601DateTime']
  subscriptionId: Scalars['ID']
}

export type ICharge = {
  __typename?: 'Charge'
  amount: Scalars['Float']
  amountRefunded: Scalars['Float']
  created: Scalars['ISO8601DateTime']
  disputeStripeId?: Maybe<Scalars['String']>
  disputed: Scalars['Boolean']
  failureMessage?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invoice?: Maybe<IInvoice>
  paid: Scalars['Boolean']
  providerPaymentMethodId?: Maybe<Scalars['String']>
  refundable: Scalars['Boolean']
  refunded: Scalars['Boolean']
  status: Scalars['String']
  stripeId: Scalars['String']
  user: IUser
}

export type IChargeCollection = {
  __typename?: 'ChargeCollection'
  charges: Array<ICharge>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IChargeDynamicFilter = {
  field: ChargeDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum ChargeDynamicFilterField {
  Amount = 'amount',
  AmountRefunded = 'amount_refunded',
  Created = 'created',
  DisputeStripeId = 'dispute_stripe_id',
  Disputed = 'disputed',
  FailureMessage = 'failure_message',
  Id = 'id',
  InvoiceStripeId = 'invoice_stripe_id',
  InvoiceSubscriptionStripeId = 'invoice_subscription_stripe_id',
  Paid = 'paid',
  Refunded = 'refunded',
  Status = 'status',
  StripeId = 'stripe_id',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserPhoneNumber = 'user_phone_number'
}

export type IChargeDynamicFilterItem = {
  items: Array<IChargeDynamicFilter>
  operator: DynamicFilterOperator
}

export type IChargeSort = {
  direction: SortDirection
  field: ChargeSortField
}

export enum ChargeSortField {
  Amount = 'amount',
  AmountRefunded = 'amount_refunded',
  Created = 'created',
  DisputeStripeId = 'dispute_stripe_id',
  Disputed = 'disputed',
  FailureMessage = 'failure_message',
  Id = 'id',
  InvoiceStripeId = 'invoice_stripe_id',
  InvoiceSubscriptionStripeId = 'invoice_subscription_stripe_id',
  Paid = 'paid',
  Refunded = 'refunded',
  Status = 'status',
  StripeId = 'stripe_id',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserPhoneNumber = 'user_phone_number'
}

export type IChart = {
  __typename?: 'Chart'
  description: Scalars['String']
  meta: IChartMeta
  range: IChartRange
  title: Scalars['String']
  type: Scalars['String']
  values: Array<IValuePair>
  xAxis: Scalars['String']
  yAxis: Scalars['String']
}

export type IChartMeta = {
  __typename?: 'ChartMeta'
  key?: Maybe<Scalars['String']>
  section?: Maybe<Scalars['String']>
  tag?: Maybe<Scalars['String']>
}

export type IChartRange = {
  __typename?: 'ChartRange'
  goal?: Maybe<Scalars['Float']>
  goalMax?: Maybe<Scalars['Float']>
  goalMin?: Maybe<Scalars['Float']>
  max?: Maybe<Scalars['Float']>
  min?: Maybe<Scalars['Float']>
  tickCount?: Maybe<Scalars['Int']>
}

export type ICharts = {
  __typename?: 'Charts'
  charts: Array<IChart>
}

export type IChartsConfig = {
  __typename?: 'ChartsConfig'
  filters: IChartsFilters
}

export type IChartsFilters = {
  __typename?: 'ChartsFilters'
  types: Array<IKeyValuePairStringArray>
}

export type IChatConversation = {
  __typename?: 'ChatConversation'
  assignee?: Maybe<IUser>
  candidateRead: Scalars['Boolean']
  id: Scalars['ID']
  intercomBackfillState?: Maybe<ChatConversationIntercomBackfillState>
  intercomCreatedAt: Scalars['ISO8601DateTime']
  intercomId: Scalars['String']
  kind: ChatConversationKind
  lastMessage?: Maybe<IChatConversationMessage>
  lastNote?: Maybe<IChatConversationNote>
  lastUserMessage?: Maybe<IChatConversationMessage>
  read: Scalars['Boolean']
  state: ChatConversationState
  user: IUser
}

export type IChatConversationEvent = {
  __typename?: 'ChatConversationEvent'
  assignedTo?: Maybe<IUser>
  id: Scalars['ID']
  kind: ChatConversationEventKind
  message?: Maybe<IChatConversationMessage>
  note?: Maybe<IChatConversationNote>
  occurredAt: Scalars['ISO8601DateTime']
  performedBy?: Maybe<IUser>
  snoozedUntil?: Maybe<Scalars['ISO8601DateTime']>
}

export enum ChatConversationEventKind {
  Assignment = 'assignment',
  Close = 'close',
  Message = 'message',
  Note = 'note',
  Open = 'open',
  Snoozed = 'snoozed',
  TimerUnsnooze = 'timer_unsnooze',
  Unsnooze = 'unsnooze'
}

export type IChatConversationEventSort = {
  direction: SortDirection
  field: ChatConversationEventSortField
}

export enum ChatConversationEventSortField {
  OccurredAt = 'occurred_at'
}

export type IChatConversationEventsCollection = {
  __typename?: 'ChatConversationEventsCollection'
  chatConversationEvents: Array<IChatConversationEvent>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export enum ChatConversationIntercomBackfillState {
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending'
}

export enum ChatConversationKind {
  Coach = 'coach',
  Support = 'support'
}

export type IChatConversationMessage = {
  __typename?: 'ChatConversationMessage'
  id: Scalars['ID']
  sender?: Maybe<IUser>
  senderKind: ChatConversationMessageSenderKind
  sentAt: Scalars['ISO8601DateTime']
  value: Scalars['String']
}

export enum ChatConversationMessageSenderKind {
  Automated = 'automated',
  Unknown = 'unknown',
  User = 'user'
}

export type IChatConversationMessageTemplate = {
  __typename?: 'ChatConversationMessageTemplate'
  automatic: Scalars['Boolean']
  createdBy?: Maybe<IUser>
  id: Scalars['ID']
  kind: ChatConversationMessageTemplateKind
  name: Scalars['String']
  private: Scalars['Boolean']
  value: Scalars['String']
}

export enum ChatConversationMessageTemplateKind {
  BasicInitial = 'basic_initial',
  CgmReviewVideoCallsUpsell = 'cgm_review_video_calls_upsell',
  Custom = 'custom',
  DietitianMenuMealPlanInitial = 'dietitian_menu_meal_plan_initial',
  DietitianMenuSupplementReviewInitial = 'dietitian_menu_supplement_review_initial',
  FixedEightWeeksInitial = 'fixed_eight_weeks_initial',
  FreeCallGranted = 'free_call_granted',
  LowDailyScoreVideoCallsUpsell = 'low_daily_score_video_calls_upsell',
  MonthlyWrapUp = 'monthly_wrap_up',
  NutritionistOutOfOffice = 'nutritionist_out_of_office',
  PremiumInitial = 'premium_initial',
  PremiumInitialPaid = 'premium_initial_paid',
  VideoCallCancelled = 'video_call_cancelled',
  VideoInitial = 'video_initial',
  VideoReminder = 'video_reminder',
  VideoWrapUp = 'video_wrap_up'
}

export type IChatConversationMessageTemplateVariable = {
  __typename?: 'ChatConversationMessageTemplateVariable'
  id: Scalars['ID']
  key: Scalars['String']
}

export type IChatConversationMessageTemplateVariableCollection = {
  __typename?: 'ChatConversationMessageTemplateVariableCollection'
  chatConversationMessageTemplateVariables: Array<IChatConversationMessageTemplateVariable>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IChatConversationNote = {
  __typename?: 'ChatConversationNote'
  createdAt: Scalars['ISO8601DateTime']
  createdBy: IUser
  id: Scalars['ID']
  value: Scalars['String']
}

export type IChatConversationSort = {
  direction: SortDirection
  field: ChatConversationSortField
}

export enum ChatConversationSortField {
  LastMessageOrNoteSentAt = 'last_message_or_note_sent_at',
  LastMessageSentAt = 'last_message_sent_at',
  LastUserMessageSentAt = 'last_user_message_sent_at',
  UserCreatedAt = 'user_created_at',
  WaitingSince = 'waiting_since'
}

export enum ChatConversationState {
  Closed = 'closed',
  Open = 'open',
  Snoozed = 'snoozed'
}

export type IChatConversationsCollection = {
  __typename?: 'ChatConversationsCollection'
  chatConversations: Array<IChatConversation>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IChatbot = {
  __typename?: 'Chatbot'
  lastShownCutoffAt: Scalars['ISO8601DateTime']
  responseTimeoutInSeconds: Scalars['Int']
}

export type ICheckoutOption = {
  __typename?: 'CheckoutOption'
  kind: SubscriptionCheckoutOptionKind
}

export type IClientConfig = {
  __typename?: 'ClientConfig'
  activityTypes: Array<Scalars['String']>
  appointmentTypes: Array<IAppointmentType>
  biteAi: IBiteAi
  caProvinces: Array<IState>
  charts: IChartsConfig
  chatbot: IChatbot
  customIngredients: ICustomIngredients
  dailyActions: Array<IDailyActionKind>
  dayTabs: Array<IKeyValuePairString>
  education: IEducation
  events: IEvents
  featureFlags: Array<IFeatureFlag>
  goalMetricOptions: Array<IGoalMetricOption>
  goalOptions: Array<IGoalOption>
  googleFit: IGoogleFit
  healthData: IHealthDataConfig
  healthKit: IHealthKit
  healthMetrics: Array<IHealthMetricType>
  healthSettings: IHealthSettings
  history: IHistory
  insights: IConfig
  journal: IJournalConfig
  mealTypes: Array<Scalars['String']>
  meals: IMeals
  measurementTypes: Array<Scalars['String']>
  measurements: Array<IMeasurementConfig>
  onboarding: IOnboarding
  privacyCenterUrl: Scalars['String']
  questionnaireUrl: Scalars['String']
  referral: IReferral
  reminders: Array<IReminderKind>
  remindersSettings: IRemindersSettings
  researchFaqs: IResearchFaqs
  score: IScore
  sensorSettings: ISensorSettings
  settings: ISettings
  signup: ISignup
  stats: IStatsConfig
  tickets: ITickets
  timeZones: Array<Scalars['String']>
  tutorials: Array<ITutorials>
  usStates: Array<IState>
}

export type ICoachAssign = {
  coachId: Scalars['ID']
  id?: InputMaybe<Scalars['ID']>
  supportType?: InputMaybe<CoachAssignmentSupportType>
  userId: Scalars['ID']
}

export type ICoachAssignment = {
  __typename?: 'CoachAssignment'
  appointment?: Maybe<IAppointment>
  assignedAt?: Maybe<Scalars['ISO8601DateTime']>
  coach?: Maybe<IUser>
  createdAt: Scalars['ISO8601DateTime']
  deactivatedAt?: Maybe<Scalars['ISO8601DateTime']>
  dietitianMenu: Scalars['Boolean']
  id: Scalars['ID']
  kind?: Maybe<CoachAssignmentKind>
  last: Scalars['Boolean']
  state: CoachAssignmentState
  supportType: CoachAssignmentSupportType
  user: IUser
}

export type ICoachAssignmentDynamicFilter = {
  field: CoachAssignmentDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum CoachAssignmentDynamicFilterField {
  AssignedAt = 'assigned_at',
  CoachId = 'coach_id',
  DeactivatedAt = 'deactivated_at',
  Id = 'id',
  Kind = 'kind',
  Last = 'last',
  State = 'state',
  SupportType = 'support_type',
  UserApprovalRequestsState = 'user_approval_requests_state',
  UserCommunicationFrequencyValue = 'user_communication_frequency_value',
  UserDietitianRequestValue = 'user_dietitian_request_value',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserGoalKind = 'user_goal_kind',
  UserHealthGoalValue = 'user_health_goal_value',
  UserKind = 'user_kind',
  UserLastCoreSubscriptionLatestInvoiceLatestChargeCreated = 'user_last_core_subscription_latest_invoice_latest_charge_created',
  UserLastCoreSubscriptionLatestInvoiceLatestChargeStatus = 'user_last_core_subscription_latest_invoice_latest_charge_status',
  UserLastCoreSubscriptionStripeStatus = 'user_last_core_subscription_stripe_status',
  UserLastDietitianSubscriptionStatus = 'user_last_dietitian_subscription_status',
  /** @deprecated use user_last_dietitian_subscription_status */
  UserLastDietitianSubscriptionStripeStatus = 'user_last_dietitian_subscription_stripe_status',
  UserNutritionistGroupValue = 'user_nutritionist_group_value',
  UserOneMonthActiveFeedbackDietitianValue = 'user_one_month_active_feedback_dietitian_value',
  UserOngoingDietitianSubscriptionCancelAt = 'user_ongoing_dietitian_subscription_cancel_at',
  UserOngoingDietitianSubscriptionPlansNickname = 'user_ongoing_dietitian_subscription_plans_nickname',
  UserOngoingDietitianSubscriptionPlansProduct = 'user_ongoing_dietitian_subscription_plans_product',
  UserScheduledVideoCallStatusValue = 'user_scheduled_video_call_status_value',
  UserSensorInfoFirstStartTime = 'user_sensor_info_first_start_time',
  UserSensorInfoLastSensorExpirationDate = 'user_sensor_info_last_sensor_expiration_date',
  UserSensorInfoLastSensorExpired = 'user_sensor_info_last_sensor_expired',
  UserSensorInfoReplacementRequestsCount = 'user_sensor_info_replacement_requests_count',
  UserSensorInfoTotalCount = 'user_sensor_info_total_count',
  UserTestGroupValue = 'user_test_group_value',
  UserUserPropertiesDietitianSensorCountNotes = 'user_user_properties_dietitian_sensor_count_notes'
}

export type ICoachAssignmentDynamicFilterItem = {
  items: Array<ICoachAssignmentDynamicFilter>
  operator: DynamicFilterOperator
}

export type ICoachAssignmentInfo = {
  __typename?: 'CoachAssignmentInfo'
  activeAssignmentsCount: Scalars['Int']
  coachId: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  updatedAt: Scalars['ISO8601DateTime']
}

export enum CoachAssignmentKind {
  AnnualMembership = 'annual_membership',
  CoachingPlan = 'coaching_plan',
  Fixed_8Weeks = 'fixed_8_weeks',
  Influencer = 'influencer',
  NoCoreSubscription = 'no_core_subscription',
  NonRecurring = 'non_recurring',
  Recurring_12Weeks = 'recurring_12_weeks',
  Regular = 'regular'
}

export type ICoachAssignmentSort = {
  direction: SortDirection
  field: CoachAssignmentSortField
}

export enum CoachAssignmentSortField {
  AssignedAt = 'assigned_at',
  CoachFullName = 'coach_full_name',
  DeactivatedAt = 'deactivated_at',
  Id = 'id',
  Kind = 'kind',
  State = 'state',
  SupportType = 'support_type',
  UserApprovalRequestsState = 'user_approval_requests_state',
  UserCommunicationFrequencyValue = 'user_communication_frequency_value',
  UserDietitianRequestValue = 'user_dietitian_request_value',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserGoalKind = 'user_goal_kind',
  UserHealthGoalValue = 'user_health_goal_value',
  UserKind = 'user_kind',
  UserLastCoreSubscriptionLatestInvoiceLatestChargeCreated = 'user_last_core_subscription_latest_invoice_latest_charge_created',
  UserLastCoreSubscriptionLatestInvoiceLatestChargeStatus = 'user_last_core_subscription_latest_invoice_latest_charge_status',
  UserLastCoreSubscriptionStripeStatus = 'user_last_core_subscription_stripe_status',
  UserLastDietitianSubscriptionStatus = 'user_last_dietitian_subscription_status',
  /** @deprecated use user_last_dietitian_subscription_status */
  UserLastDietitianSubscriptionStripeStatus = 'user_last_dietitian_subscription_stripe_status',
  UserNutritionistGroupValue = 'user_nutritionist_group_value',
  UserOneMonthActiveFeedbackDietitianValue = 'user_one_month_active_feedback_dietitian_value',
  UserOngoingDietitianSubscriptionCancelAt = 'user_ongoing_dietitian_subscription_cancel_at',
  UserOngoingDietitianSubscriptionPlansNickname = 'user_ongoing_dietitian_subscription_plans_nickname',
  UserOngoingDietitianSubscriptionPlansProduct = 'user_ongoing_dietitian_subscription_plans_product',
  UserScheduledVideoCallStatusValue = 'user_scheduled_video_call_status_value',
  UserSensorInfoFirstStartTime = 'user_sensor_info_first_start_time',
  UserSensorInfoLastSensorExpirationDate = 'user_sensor_info_last_sensor_expiration_date',
  UserSensorInfoLastSensorExpired = 'user_sensor_info_last_sensor_expired',
  UserSensorInfoReplacementRequestsCount = 'user_sensor_info_replacement_requests_count',
  UserSensorInfoTotalCount = 'user_sensor_info_total_count',
  UserTestGroupValue = 'user_test_group_value',
  UserUserPropertiesDietitianSensorCountNotes = 'user_user_properties_dietitian_sensor_count_notes'
}

export enum CoachAssignmentState {
  Active = 'active',
  Inactive = 'inactive',
  Paused = 'paused',
  Pending = 'pending'
}

export enum CoachAssignmentSupportType {
  AsyncChat = 'async_chat',
  MealPlan = 'meal_plan',
  SupplementReview = 'supplement_review',
  VideoCall = 'video_call',
  VideoChat = 'video_chat'
}

export type ICoachAssignmentsCollection = {
  __typename?: 'CoachAssignmentsCollection'
  coachAssignments: Array<ICoachAssignment>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type ICoachConversationMessagePreview = {
  __typename?: 'CoachConversationMessagePreview'
  value: Scalars['String']
}

export type ICoachConversationScheduledMessage = {
  __typename?: 'CoachConversationScheduledMessage'
  coachAssignment: ICoachAssignment
  id: Scalars['ID']
  kind: ChatConversationMessageTemplateKind
  sendAt?: Maybe<Scalars['ISO8601DateTime']>
  sentAt?: Maybe<Scalars['ISO8601DateTime']>
  state: CoachesConversationScheduledMessageState
  value: Scalars['String']
}

export type ICoachConversationScheduledMessageCollection = {
  __typename?: 'CoachConversationScheduledMessageCollection'
  coachConversationScheduledMessages: Array<ICoachConversationScheduledMessage>
}

export type ICoachConversationScheduledMessageSort = {
  direction: SortDirection
  field: CoachConversationScheduledMessageSortField
}

export enum CoachConversationScheduledMessageSortField {
  SendAt = 'send_at'
}

export type ICoachProfile = {
  __typename?: 'CoachProfile'
  active: Scalars['Boolean']
  bio?: Maybe<Scalars['String']>
  coachId: Scalars['ID']
  credentials?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['ISO8601Date']>
  fullName: Scalars['String']
  getHealthieId?: Maybe<Scalars['String']>
  manager?: Maybe<IUser>
  outOfOffice: Scalars['Boolean']
  photo?: Maybe<Scalars['String']>
  priorityChangedAt?: Maybe<Scalars['ISO8601Date']>
  schedulingPriority?: Maybe<Scalars['Int']>
  specialties: Array<ICoachesProfileSpecialtyDef>
  startDate?: Maybe<Scalars['ISO8601Date']>
  stateLicences: Array<Scalars['String']>
  updatedAt: Scalars['ISO8601Date']
}

export type ICoachProfileCollection = {
  __typename?: 'CoachProfileCollection'
  coachProfiles: Array<ICoachProfile>
}

export enum CoachesConversationScheduledMessageState {
  Canceled = 'canceled',
  Failed = 'failed',
  Pending = 'pending',
  Sent = 'sent'
}

export enum CoachesProfileSpecialty {
  AutoimmuneConditions = 'autoimmune_conditions',
  Bariatric = 'bariatric',
  Cancer = 'cancer',
  ChronicFatigue = 'chronic_fatigue',
  EatingDisorders = 'eating_disorders',
  Fertility = 'fertility',
  FoodAllergies = 'food_allergies',
  GutHealth = 'gut_health',
  HealthOptimization = 'health_optimization',
  HealthyAging = 'healthy_aging',
  HeartDisease = 'heart_disease',
  MensHealth = 'mens_health',
  MindfulEating = 'mindful_eating',
  PrediabetesDiabetes = 'prediabetes_diabetes',
  PrenatalNutrition = 'prenatal_nutrition',
  SportsPerformance = 'sports_performance',
  ThyroidDisorders = 'thyroid_disorders',
  WeightConcerns = 'weight_concerns',
  WomensHealth = 'womens_health'
}

export type ICoachesProfileSpecialtyDef = {
  __typename?: 'CoachesProfileSpecialtyDef'
  key: CoachesProfileSpecialty
  name: Scalars['String']
}

export type ICohort = {
  __typename?: 'Cohort'
  id: Scalars['ID']
  instructor?: Maybe<IUser>
  kind: CoursesCohortKind
  members: Array<IDppUser>
  name: Scalars['String']
  sessions: Array<ICohortSession>
  state: CoursesCohortState
}

export type ICohortCollection = {
  __typename?: 'CohortCollection'
  cohorts: Array<ICohort>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type ICohortDynamicFilter = {
  field: CohortDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum CohortDynamicFilterField {
  ActiveInstructorAssignmentUserFullName = 'active_instructor_assignment_user_full_name',
  Id = 'id',
  Name = 'name',
  State = 'state'
}

export type ICohortDynamicFilterItem = {
  items: Array<ICohortDynamicFilter>
  operator: DynamicFilterOperator
}

export type ICohortSession = {
  __typename?: 'CohortSession'
  attendances: Array<ICohortSessionAttendance>
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  joinUrl?: Maybe<Scalars['String']>
  kind: CoursesCohortSessionKind
  name: Scalars['String']
  position: Scalars['Int']
  scheduledAt: Scalars['ISO8601DateTime']
  scheduledEndAt: Scalars['ISO8601DateTime']
  startUrl?: Maybe<Scalars['String']>
}

export type ICohortSessionAttendance = {
  __typename?: 'CohortSessionAttendance'
  id: Scalars['ID']
  state: CoursesCohortSessionAttendanceState
  user: IUserMin
}

export type ICohortSessionCollection = {
  __typename?: 'CohortSessionCollection'
  pageCount?: Maybe<Scalars['Int']>
  sessions: Array<ICohortSession>
  totalCount: Scalars['Int']
}

export type ICohortSessionData = {
  kind: CoursesCohortSessionKind
  scheduledAt: Scalars['ISO8601DateTime']
}

export type ICohortSessionDynamicFilter = {
  field: CohortSessionSessionDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export type ICohortSessionDynamicFilterItem = {
  items: Array<ICohortSessionDynamicFilter>
  operator: DynamicFilterOperator
}

export type ICohortSessionMember = {
  __typename?: 'CohortSessionMember'
  attendance?: Maybe<ICohortSessionAttendance>
  dppHealthDataRecordsChecklist: IDppHealthDataRecordsChecklist
  id: Scalars['ID']
  user: IUserMin
}

export type ICohortSessionMemberCollection = {
  __typename?: 'CohortSessionMemberCollection'
  members: Array<ICohortSessionMember>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export enum CohortSessionSessionDynamicFilterField {
  Name = 'name',
  Position = 'position',
  ScheduledAt = 'scheduled_at'
}

export enum CohortSessionSessionSortField {
  Name = 'name',
  Position = 'position',
  ScheduledAt = 'scheduled_at'
}

export type ICohortSessionSort = {
  direction: SortDirection
  field: CohortSessionSessionSortField
}

export type ICohortSessionSuggestion = {
  __typename?: 'CohortSessionSuggestion'
  kind: CoursesCohortSessionKind
  name: Scalars['String']
  scheduledAt: Scalars['ISO8601DateTime']
}

export type ICohortSessionSuggestionCollection = {
  __typename?: 'CohortSessionSuggestionCollection'
  suggestions: Array<ICohortSessionSuggestion>
}

export type ICohortSort = {
  direction: SortDirection
  field: CohortSortField
}

export enum CohortSortField {
  ActiveInstructorAssignmentUserFullName = 'active_instructor_assignment_user_full_name',
  Id = 'id',
  Name = 'name',
  State = 'state'
}

export type ICompletedScreen = {
  __typename?: 'CompletedScreen'
  footnote?: Maybe<Scalars['String']>
  header: Scalars['String']
  icon: UiIcon
  intro: Scalars['String']
  primaryActionText?: Maybe<Scalars['String']>
  secondaryActionLink?: Maybe<ILink>
  title?: Maybe<Scalars['String']>
}

export type IConfig = {
  __typename?: 'Config'
  filters: IStatsFilters
  statistics: Array<IStatistics>
}

export type IConfigUnit = {
  __typename?: 'ConfigUnit'
  precision: Scalars['Int']
  ranges: Array<IRangeUnit>
  units: Scalars['String']
}

export type IConversation = {
  __typename?: 'Conversation'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  providerId: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
  userId: Scalars['ID']
}

export type IConversationMessageTemplateCollection = {
  __typename?: 'ConversationMessageTemplateCollection'
  chatConversationMessageTemplates: Array<IChatConversationMessageTemplate>
  /** @deprecated use conversationMessageTemplates */
  coachConversationMessageTemplates: Array<IChatConversationMessageTemplate>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export enum CoursesCohortKind {
  Dpp2021 = 'dpp2021'
}

export enum CoursesCohortMemberEnrollmentState {
  Disenrolled = 'disenrolled',
  Enrolled = 'enrolled'
}

export enum CoursesCohortSessionAttendanceState {
  Attended = 'attended',
  Pending = 'pending',
  Unattended = 'unattended'
}

export enum CoursesCohortSessionKind {
  Dpp2021Module1 = 'dpp2021_module1',
  Dpp2021Module2 = 'dpp2021_module2',
  Dpp2021Module3 = 'dpp2021_module3',
  Dpp2021Module4 = 'dpp2021_module4',
  Dpp2021Module5 = 'dpp2021_module5',
  Dpp2021Module6 = 'dpp2021_module6',
  Dpp2021Module7 = 'dpp2021_module7',
  Dpp2021Module8 = 'dpp2021_module8',
  Dpp2021Module9 = 'dpp2021_module9',
  Dpp2021Module10 = 'dpp2021_module10',
  Dpp2021Module11 = 'dpp2021_module11',
  Dpp2021Module12 = 'dpp2021_module12',
  Dpp2021Module13 = 'dpp2021_module13',
  Dpp2021Module14 = 'dpp2021_module14',
  Dpp2021Module15 = 'dpp2021_module15',
  Dpp2021Module16 = 'dpp2021_module16',
  Dpp2021Module17 = 'dpp2021_module17',
  Dpp2021Module18 = 'dpp2021_module18',
  Dpp2021Module19 = 'dpp2021_module19',
  Dpp2021Module20 = 'dpp2021_module20',
  Dpp2021Module21 = 'dpp2021_module21',
  Dpp2021Module22 = 'dpp2021_module22',
  Dpp2021Module23 = 'dpp2021_module23',
  Dpp2021Module24 = 'dpp2021_module24',
  Dpp2021Module25 = 'dpp2021_module25',
  Dpp2021Module26 = 'dpp2021_module26'
}

export enum CoursesCohortState {
  Draft = 'draft',
  Live = 'live',
  Stopped = 'stopped'
}

export type ICreditRequestPayload = {
  centsAmount: Scalars['Int']
}

export type ICreditResponsePayload = {
  __typename?: 'CreditResponsePayload'
  centsAmount: Scalars['Int']
}

export type ICustomIngredients = {
  __typename?: 'CustomIngredients'
  macros: Array<IMacro>
}

export type IDailyAction = {
  __typename?: 'DailyAction'
  autocomplete: Scalars['Boolean']
  category: UserDailyActionCategory
  completionsCount?: Maybe<Scalars['Int']>
  createdAt: Scalars['ISO8601DateTime']
  description: Scalars['String']
  icon: UiIcon
  id: Scalars['ID']
  kind: UserDailyActionKind
  statKey?: Maybe<Scalars['String']>
  status: UserDailyActionStatus
  title: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export type IDailyActionCollection = {
  __typename?: 'DailyActionCollection'
  completionCounts: Array<IDailyActionCompletionsCount>
  dailyActions: Array<IDailyAction>
}

export type IDailyActionCompletionsCount = {
  __typename?: 'DailyActionCompletionsCount'
  completionCount: Scalars['Int']
  dailyActionId: Scalars['Int']
}

export type IDailyActionKind = {
  __typename?: 'DailyActionKind'
  autocomplete: Scalars['Boolean']
  category: UserDailyActionCategory
  description: Scalars['String']
  key: Scalars['String']
  title: Scalars['String']
}

export type IDailyScore = {
  __typename?: 'DailyScore'
  score?: Maybe<Scalars['Int']>
  scoreMean?: Maybe<Scalars['Int']>
  scorePeak?: Maybe<Scalars['Int']>
  scoreStdDev?: Maybe<Scalars['Int']>
  scoreTimeOutsideRange?: Maybe<Scalars['Int']>
}

export type IDateFilter = {
  endDate?: InputMaybe<Scalars['String']>
  endHour?: InputMaybe<Scalars['Int']>
  excludeTypes?: InputMaybe<Array<Scalars['String']>>
  favorite?: InputMaybe<Scalars['Boolean']>
  graph?: InputMaybe<Scalars['String']>
  includeTypes?: InputMaybe<Array<Scalars['String']>>
  order?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  startHour?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<IKeyValueStringArray>>
}

export type IDeleteDuplicateAccountRequestPayload = {
  creditAmountInCents: Scalars['Int']
  duplicateUserId: Scalars['ID']
  replaceWithDuplicateUserEmail?: InputMaybe<Scalars['Boolean']>
}

export type IDeleteDuplicateAccountResponsePayload = {
  __typename?: 'DeleteDuplicateAccountResponsePayload'
  creditAmountInCents: Scalars['Int']
  duplicateUserId: Scalars['ID']
  replaceWithDuplicateUserEmail?: Maybe<Scalars['Boolean']>
}

export type IDelimiter = {
  __typename?: 'Delimiter'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
}

export enum DexcomConnectionStatus {
  Active = 'active',
  Invalidated = 'invalidated'
}

export type IDexcomConnectionType = {
  __typename?: 'DexcomConnectionType'
  lastSyncedAt?: Maybe<Scalars['ISO8601DateTime']>
  status: DexcomConnectionStatus
}

export enum DexcomResearchStatus {
  AcceptedSensor = 'accepted_sensor',
  CheckoutAcceptedSensor = 'checkout_accepted_sensor',
  CheckoutDeclinedSensor = 'checkout_declined_sensor',
  ConsentAccepted = 'consent_accepted',
  ConsentWithdrawn = 'consent_withdrawn',
  ConsentWithdrawnAdmin = 'consent_withdrawn_admin',
  DeclinedResearchConsent = 'declined_research_consent',
  DeclinedSensor = 'declined_sensor',
  Enrolled = 'enrolled',
  EnteredEligiblePlan = 'entered_eligible_plan',
  MedicalHistoryAccepted = 'medical_history_accepted',
  MedicalHistoryRejected = 'medical_history_rejected',
  NotInEligiblePlan = 'not_in_eligible_plan',
  Participating = 'participating'
}

export type IDietitianSession = {
  __typename?: 'DietitianSession'
  dietitianId: Scalars['ID']
  durationInSeconds: Scalars['Float']
  endedAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  startedAt: Scalars['ISO8601DateTime']
  userId?: Maybe<Scalars['ID']>
}

export type IDirectUpload = {
  __typename?: 'DirectUpload'
  headers: Array<IKeyValuePairString>
  signedId: Scalars['String']
  url: Scalars['String']
}

export type IDirectUploadCollection = {
  __typename?: 'DirectUploadCollection'
  directUploads: Array<IDirectUpload>
}

export type IDoctor = {
  __typename?: 'Doctor'
  avatar?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  credentials?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  getHealthieId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  state: EhrDoctorState
}

export type IDoctorAssignment = {
  __typename?: 'DoctorAssignment'
  doctor: IDoctor
  id: Scalars['ID']
  state: EhrDoctorAssignmentState
}

export type IDppHealthDataRecordChecklist = {
  __typename?: 'DppHealthDataRecordChecklist'
  requiresPhotoUpload: Scalars['Boolean']
  state: DppHealthDataRecordChecklistState
}

export enum DppHealthDataRecordChecklistState {
  Done = 'done',
  Optional = 'optional',
  Pending = 'pending',
  Required = 'required'
}

export type IDppHealthDataRecordsChecklist = {
  __typename?: 'DppHealthDataRecordsChecklist'
  hbA1cChecklist: IDppHealthDataRecordChecklist
  physicalActivityChecklist: IDppHealthDataRecordChecklist
  weightChecklist: IDppHealthDataRecordChecklist
}

export type IDppInsuranceParticipant = {
  __typename?: 'DppInsuranceParticipant'
  a1c?: Maybe<Scalars['String']>
  action?: Maybe<Scalars['String']>
  addressCity: Scalars['String']
  addressState: Scalars['String']
  addressStreet: Scalars['String']
  addressStreet2: Scalars['String']
  addressZipCode: Scalars['String']
  aian: Scalars['String']
  asian: Scalars['String']
  black: Scalars['String']
  cellPhone: Scalars['String']
  dateOfBirth: Scalars['String']
  diffDress: Scalars['String']
  diffErrand: Scalars['String']
  diffHear: Scalars['String']
  diffMem: Scalars['String']
  diffSee: Scalars['String']
  diffWalk: Scalars['String']
  edu: Scalars['String']
  email: Scalars['String']
  enrollHc: Scalars['String']
  enrollMot: Scalars['String']
  ethRaceInfo?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  gdm: Scalars['String']
  gender: Scalars['String']
  glucTest: Scalars['String']
  groupNumber?: Maybe<Scalars['String']>
  height: Scalars['String']
  hispanic: Scalars['String']
  homeNumber?: Maybe<Scalars['String']>
  id: Scalars['ID']
  initialWeight: Scalars['String']
  insurancePlan?: Maybe<Scalars['String']>
  insuranceType: Scalars['String']
  lastName: Scalars['String']
  memberId: Scalars['String']
  mena: Scalars['String']
  middleName?: Maybe<Scalars['String']>
  nhpi: Scalars['String']
  notifications: Scalars['String']
  orgCode: Scalars['String']
  partnerId: Scalars['String']
  payerSource: Scalars['String']
  preferredPhoneNumber?: Maybe<Scalars['String']>
  prefixedCohortId?: Maybe<Scalars['String']>
  riskTest: Scalars['String']
  sex: Scalars['String']
  state: InsuranceHabitNuParticipantState
  userId: Scalars['ID']
  white: Scalars['String']
  workPhone?: Maybe<Scalars['String']>
}

export type IDppInsuranceParticipantCollection = {
  __typename?: 'DppInsuranceParticipantCollection'
  pageCount?: Maybe<Scalars['Int']>
  participants: Array<IDppInsuranceParticipant>
  totalCount: Scalars['Int']
}

export type IDppInsuranceParticipantDynamicFilter = {
  field: DppInsuranceParticipantDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum DppInsuranceParticipantDynamicFilterField {
  CourseCohortId = 'course_cohort_id',
  State = 'state',
  UserId = 'user_id'
}

export type IDppInsuranceParticipantDynamicFilterItem = {
  items: Array<IDppInsuranceParticipantDynamicFilter>
  operator: DynamicFilterOperator
}

export type IDppInsuranceParticipantSort = {
  direction: SortDirection
  field: DppInsuranceParticipantSortField
}

export enum DppInsuranceParticipantSortField {
  CourseCohortId = 'course_cohort_id',
  State = 'state',
  UserId = 'user_id'
}

export type IDppInsuranceSession = {
  __typename?: 'DppInsuranceSession'
  date: Scalars['String']
  deliveryMode: Scalars['String']
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  instructorEmail: Scalars['String']
  name: Scalars['String']
  payerSource: InsuranceHabitNuParticipantPayerSource
  physicalActivity: Scalars['String']
  sessionType: Scalars['String']
  state: InsuranceHabitNuSessionState
  weight: Scalars['String']
}

export type IDppInsuranceSessionCollection = {
  __typename?: 'DppInsuranceSessionCollection'
  pageCount?: Maybe<Scalars['Int']>
  sessions: Array<IDppInsuranceSession>
  totalCount: Scalars['Int']
}

export type IDppInsuranceSessionDynamicFilter = {
  field: DppInsuranceSessionDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum DppInsuranceSessionDynamicFilterField {
  CourseCohortSessionAttendanceSessionCohortInstructorEmail = 'course_cohort_session_attendance_session_cohort_instructor_email',
  CourseCohortSessionAttendanceUserEmail = 'course_cohort_session_attendance_user_email',
  CourseCohortSessionAttendanceUserHabitNuParticipantPayerSource = 'course_cohort_session_attendance_user_habit_nu_participant_payer_source',
  State = 'state'
}

export type IDppInsuranceSessionDynamicFilterItem = {
  items: Array<IDppInsuranceSessionDynamicFilter>
  operator: DynamicFilterOperator
}

export type IDppInsuranceSessionSort = {
  direction: SortDirection
  field: DppInsuranceSessionSortField
}

export enum DppInsuranceSessionSortField {
  CourseCohortSessionAttendanceSessionCohortInstructorEmail = 'course_cohort_session_attendance_session_cohort_instructor_email',
  CourseCohortSessionAttendanceUserHabitNuParticipantPayerSource = 'course_cohort_session_attendance_user_habit_nu_participant_payer_source',
  State = 'state'
}

export type IDppUser = {
  __typename?: 'DppUser'
  dppApprovedAt: Scalars['ISO8601DateTime']
  email: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  lastDppApprovalRequest: IApprovalRequest
  preferredCallDays: Array<Scalars['String']>
  preferredCallTimes: Array<Scalars['String']>
}

export type IDppUserCollection = {
  __typename?: 'DppUserCollection'
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
  users: Array<IDppUser>
}

export type IDppUserDynamicFilter = {
  field: DppUserDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum DppUserDynamicFilterField {
  ApprovalRequestsCreatedAt = 'approval_requests_created_at',
  Email = 'email',
  FullName = 'full_name',
  Id = 'id'
}

export type IDppUserDynamicFilterItem = {
  items: Array<IDppUserDynamicFilter>
  operator: DynamicFilterOperator
}

export type IDppUserSort = {
  direction: SortDirection
  field: DppUserSortField
}

export enum DppUserSortField {
  ApprovalRequestsCreatedAt = 'approval_requests_created_at',
  Email = 'email',
  FullName = 'full_name',
  Id = 'id'
}

export enum DynamicFilterItemOperation {
  Blank = 'blank',
  Cont = 'cont',
  ContAll = 'cont_all',
  ContAny = 'cont_any',
  DoesNotMatch = 'does_not_match',
  DoesNotMatchAll = 'does_not_match_all',
  DoesNotMatchAny = 'does_not_match_any',
  End = 'end',
  EndAll = 'end_all',
  EndAny = 'end_any',
  Eq = 'eq',
  Gt = 'gt',
  GtAll = 'gt_all',
  GtAny = 'gt_any',
  Gteq = 'gteq',
  GteqAll = 'gteq_all',
  GteqAny = 'gteq_any',
  ICont = 'i_cont',
  IContAll = 'i_cont_all',
  IContAny = 'i_cont_any',
  In = 'in',
  IsNull = 'is_null',
  Lt = 'lt',
  LtAll = 'lt_all',
  LtAny = 'lt_any',
  Lteq = 'lteq',
  LteqAll = 'lteq_all',
  LteqAny = 'lteq_any',
  Matches = 'matches',
  MatchesAll = 'matches_all',
  MatchesAny = 'matches_any',
  NotCont = 'not_cont',
  NotContAll = 'not_cont_all',
  NotContAny = 'not_cont_any',
  NotEnd = 'not_end',
  NotEndAll = 'not_end_all',
  NotEndAny = 'not_end_any',
  NotEq = 'not_eq',
  NotEqAll = 'not_eq_all',
  NotICont = 'not_i_cont',
  NotIContAll = 'not_i_cont_all',
  NotIContAny = 'not_i_cont_any',
  NotIn = 'not_in',
  NotNull = 'not_null',
  NotStart = 'not_start',
  NotStartAll = 'not_start_all',
  NotStartAny = 'not_start_any',
  Present = 'present',
  Start = 'start',
  StartAll = 'start_all',
  StartAny = 'start_any'
}

export enum DynamicFilterOperator {
  And = 'and',
  Or = 'or'
}

export type IEducation = {
  __typename?: 'Education'
  collections: Array<IEducationCollection>
}

export type IEducationArticle = {
  __typename?: 'EducationArticle'
  author: Scalars['String']
  imageUrl: Scalars['String']
  subtitle: Scalars['String']
  title: Scalars['String']
  url: Scalars['String']
}

export type IEducationCollection = {
  __typename?: 'EducationCollection'
  articles: Array<IEducationArticle>
  imageUrl: Scalars['String']
  subtitle: Scalars['String']
  title: Scalars['String']
  url: Scalars['String']
}

export type IEhrAppointment = {
  __typename?: 'EhrAppointment'
  addToGcalLink?: Maybe<Scalars['String']>
  category: EhrAppointmentCategory
  getHealthieId?: Maybe<Scalars['ID']>
  getHealthieInstanceKind: EhrGetHealthieInstanceKind
  id: Scalars['ID']
  isGroup: Scalars['Boolean']
  joinUrl?: Maybe<Scalars['String']>
  kind: EhrAppointmentKind
  lastState: IEhrAppointmentState
  meetingAt?: Maybe<Scalars['ISO8601DateTime']>
  startUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']>
}

export enum EhrAppointmentCategory {
  Coaching = 'coaching',
  Dpp = 'dpp',
  Medical = 'medical'
}

export enum EhrAppointmentKind {
  DppSession = 'dpp_session',
  Followup = 'followup',
  Followup_30 = 'followup_30',
  Followup_45 = 'followup_45',
  Followup_90 = 'followup_90',
  GlpSession = 'glp_session',
  GlpSession_10 = 'glp_session_10',
  Initial = 'initial',
  Initial_60 = 'initial_60',
  NoneCovered = 'none_covered'
}

export enum EhrAppointmentReason {
  BerryStreet = 'berry_street',
  BillingSideEffect = 'billing_side_effect',
  CoachesBillableMissingData = 'coaches_billable_missing_data',
  Console = 'console',
  GlpIntakeSurveyRestarted = 'glp_intake_survey_restarted',
  InsuranceSideEffect = 'insurance_side_effect',
  PaymentMethodMissing = 'payment_method_missing',
  StaleFreePending = 'stale_free_pending',
  User = 'user'
}

export type IEhrAppointmentState = {
  __typename?: 'EhrAppointmentState'
  appointmentId: Scalars['ID']
  id: Scalars['ID']
  kind: EhrAppointmentStateKind
  reason: EhrAppointmentReason
}

export enum EhrAppointmentStateKind {
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  InReview = 'in_review',
  LateCancellation = 'late_cancellation',
  LateReschedule = 'late_reschedule',
  NoShow = 'no_show',
  Occurred = 'occurred',
  PendingReschedule = 'pending_reschedule',
  PendingSchedule = 'pending_schedule',
  Rescheduled = 'rescheduled',
  Scheduled = 'scheduled'
}

export enum EhrDoctorAssignmentState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum EhrDoctorState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum EhrGetHealthieInstanceKind {
  BerryStreet = 'berry_street',
  Nutrisense = 'nutrisense'
}

export type IEligibleUpdateProduct = {
  __typename?: 'EligibleUpdateProduct'
  feeRules?: Maybe<Array<IFeeRule>>
  product: IProductMin
}

export type IEligibleUpdateProducts = {
  __typename?: 'EligibleUpdateProducts'
  eligibleUpdateProducts: Array<IEligibleUpdateProduct>
}

export type IEntryGlucose = {
  __typename?: 'EntryGlucose'
  healthyRange: Array<Scalars['Float']>
  range: Array<Scalars['Float']>
  values: Array<IMeasurement>
}

export type IEntryStatistics = {
  __typename?: 'EntryStatistics'
  aucScore?: Maybe<Scalars['Float']>
  glucose2hrDelta?: Maybe<Scalars['Float']>
  glucoseDelta?: Maybe<Scalars['Float']>
  glucoseMax?: Maybe<Scalars['Float']>
  glucoseMin?: Maybe<Scalars['Float']>
  glucoseScore?: Maybe<Scalars['Int']>
  glucoseScore2hrDelta?: Maybe<Scalars['Int']>
  glucoseScoreAuc?: Maybe<Scalars['Int']>
  glucoseScoreDelta?: Maybe<Scalars['Int']>
  glucoseScorePeak?: Maybe<Scalars['Int']>
}

export type IEvent = IActivity | IJournalEntry | IMeal | IMeasurement | INote

export type IEventCollection = {
  __typename?: 'EventCollection'
  events: Array<IEvent>
}

export type IEvents = {
  __typename?: 'Events'
  /** @deprecated event filtering is no longer fetched as of mobile app v2.8.1 */
  filters: IListFilters
}

export type IEventsData = {
  __typename?: 'EventsData'
  events: IEventCollection
}

export type IExpandableModule = {
  __typename?: 'ExpandableModule'
  contentId: Scalars['ID']
  id: Scalars['ID']
  lessons?: Maybe<Array<ILesson>>
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<IProgress>
}

export type IExpandableProgram = {
  __typename?: 'ExpandableProgram'
  contentId: Scalars['ID']
  id: Scalars['ID']
  modules?: Maybe<Array<IExpandableModule>>
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<IProgress>
}

export type IFeatureFlag = {
  __typename?: 'FeatureFlag'
  enabled: Scalars['Boolean']
  key: Scalars['String']
}

export type IFeeRule = {
  __typename?: 'FeeRule'
  fee: Scalars['Int']
  paidCyclesCount: Scalars['Int']
}

export type IFile = {
  __typename?: 'File'
  createdAt: Scalars['ISO8601DateTime']
  downloadFileUrl: Scalars['String']
  fileName: Scalars['String']
  fileType: Scalars['String']
  fileUrl: Scalars['String']
  id: Scalars['ID']
  permanentUrl: Scalars['String']
  tag?: Maybe<UserFileTag>
}

export type IFileCollection = {
  __typename?: 'FileCollection'
  files: Array<IFile>
}

export type IFileDynamicFilter = {
  field: FileDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum FileDynamicFilterField {
  Tag = 'tag'
}

export type IFileDynamicFilterItem = {
  items: Array<IFileDynamicFilter>
  operator: DynamicFilterOperator
}

export type IFileMetadata = {
  byteSize: Scalars['Int']
  checksum: Scalars['String']
  contentType: Scalars['String']
  filename: Scalars['String']
}

export type IFileSort = {
  direction: SortDirection
  field: FileSortField
}

export enum FileSortField {
  CreatedAt = 'created_at'
}

export type IFollowup = {
  __typename?: 'Followup'
  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
  parentAnswer: IAnswer
  parentQuestionKey: SurveysQuestionKey
  question: IQuestion
}

export type IFulfillmentConfiguration = {
  __typename?: 'FulfillmentConfiguration'
  sensorKind: UserSensorKind
  userId: Scalars['ID']
}

export type IGlucoseDailyScore = {
  __typename?: 'GlucoseDailyScore'
  today: IDailyScore
}

export type IGlucoseStatistics = {
  __typename?: 'GlucoseStatistics'
  today: IStat
}

export type IGlucoseThresholdPreset = {
  __typename?: 'GlucoseThresholdPreset'
  description: Scalars['String']
  high: Scalars['Int']
  low: Scalars['Int']
  name: Scalars['String']
}

export type IGoal = {
  __typename?: 'Goal'
  description?: Maybe<Scalars['String']>
  kind: UserGoalKind
  title: Scalars['String']
}

export type IGoalMetric = {
  __typename?: 'GoalMetric'
  highThresholdValue?: Maybe<Scalars['Float']>
  kind: UserGoalMetricKind
  lowThresholdValue?: Maybe<Scalars['Float']>
  primary: Scalars['Boolean']
  section: Scalars['String']
  statKey: Scalars['String']
}

export type IGoalMetricOption = {
  __typename?: 'GoalMetricOption'
  text: Scalars['String']
  value: UserGoalMetricKind
}

export type IGoalOption = {
  __typename?: 'GoalOption'
  text: Scalars['String']
  value: UserGoalKind
}

export type IGoogleFit = {
  __typename?: 'GoogleFit'
  ignore: Array<Scalars['String']>
}

export type IHealthDataConfig = {
  __typename?: 'HealthDataConfig'
  measurementSourceDefs: Array<IHealthDataMeasurementSourceDef>
  measurementTypeDefs: Array<IHealthDataMeasurementTypeDef>
}

export enum HealthDataMeasurementSource {
  Dexcom = 'dexcom',
  Fitbit = 'fitbit',
  Freestylelibre = 'freestylelibre',
  Garmin = 'garmin',
  Googlefit = 'googlefit',
  Healthkit = 'healthkit',
  Ketomojo = 'ketomojo',
  LibreLinkup = 'libre_linkup',
  Nutrisense = 'nutrisense',
  Oura = 'oura',
  Whoop = 'whoop'
}

export type IHealthDataMeasurementSourceDef = {
  __typename?: 'HealthDataMeasurementSourceDef'
  iconDef?: Maybe<IIconDef>
  key: HealthDataMeasurementSource
  label: Scalars['String']
  providesDailyMeasurements: Scalars['Boolean']
  sourceTags: Array<Scalars['String']>
}

export enum HealthDataMeasurementType {
  Appetite = 'appetite',
  BloodGlucose = 'blood_glucose',
  BloodKetones = 'blood_ketones',
  BloodPressure = 'blood_pressure',
  BodyFatPercentage = 'body_fat_percentage',
  BodyTemperature = 'body_temperature',
  BreathKetones = 'breath_ketones',
  CyclingDistance = 'cycling_distance',
  Energy = 'energy',
  ExerciseTime = 'exercise_time',
  FlightsClimbed = 'flights_climbed',
  Focus = 'focus',
  Glucose = 'glucose',
  Mood = 'mood',
  RunningDistance = 'running_distance',
  StepCount = 'step_count',
  Stress = 'stress',
  SwimmingDistance = 'swimming_distance',
  Weight = 'weight'
}

export type IHealthDataMeasurementTypeDef = {
  __typename?: 'HealthDataMeasurementTypeDef'
  iconDef?: Maybe<IIconDef>
  isDailyMeasurement: Scalars['Boolean']
  key: HealthDataMeasurementType
  label: Scalars['String']
}

export enum HealthDataMetricBiomarker {
  Bmi = 'bmi',
  FastedGlucose = 'fasted_glucose',
  FastedInsulin = 'fasted_insulin',
  HbA1c = 'hb_a1c',
  HdlCholesterol = 'hdl_cholesterol',
  Height = 'height',
  HsCrp = 'hs_crp',
  LdlCholesterol = 'ldl_cholesterol',
  TotalCholesterol = 'total_cholesterol',
  Triglycerides = 'triglycerides',
  Tsh = 'tsh',
  VitaminD = 'vitamin_d'
}

export type IHealthKit = {
  __typename?: 'HealthKit'
  ignore: Array<Scalars['String']>
  sourceIgnore: Array<Scalars['String']>
}

export type IHealthMetric = {
  __typename?: 'HealthMetric'
  date: Scalars['ISO8601Date']
  id: Scalars['ID']
  kind: HealthDataMetricBiomarker
  value: Scalars['Float']
}

export type IHealthMetricType = {
  __typename?: 'HealthMetricType'
  key: HealthMetricsParam
  kind: HealthMetricsKind
  label: Scalars['String']
  unit?: Maybe<Scalars['String']>
}

export type IHealthMetricValue = {
  __typename?: 'HealthMetricValue'
  date: Scalars['ISO8601Date']
  id: Scalars['ID']
  value: Scalars['Float']
}

export type IHealthMetricValues = {
  __typename?: 'HealthMetricValues'
  data: Array<IHealthMetricValue>
  kind: HealthDataMetricBiomarker
  title: Scalars['String']
  unit?: Maybe<Scalars['String']>
}

export type IHealthMetrics = {
  __typename?: 'HealthMetrics'
  healthMetrics: Array<IHealthMetricValues>
}

export enum HealthMetricsKind {
  Biomarker = 'biomarker',
  Measurement = 'measurement'
}

export enum HealthMetricsParam {
  BloodPressure = 'blood_pressure',
  Bmi = 'bmi',
  FastedGlucose = 'fasted_glucose',
  FastedInsulin = 'fasted_insulin',
  HbA1c = 'hb_a1c',
  HdlCholesterol = 'hdl_cholesterol',
  Height = 'height',
  HsCrp = 'hs_crp',
  LdlCholesterol = 'ldl_cholesterol',
  TotalCholesterol = 'total_cholesterol',
  Triglycerides = 'triglycerides',
  Tsh = 'tsh',
  VitaminD = 'vitamin_d',
  Weight = 'weight'
}

export type IHealthPortalAppointment = {
  __typename?: 'HealthPortalAppointment'
  actualDuration?: Maybe<Scalars['Int']>
  addToGoogleCalendarLink?: Maybe<Scalars['String']>
  agenda?: Maybe<Scalars['String']>
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  date?: Maybe<Scalars['String']>
  externalVideochatUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  insurancePolicy?: Maybe<IInsurancePolicy>
  meetingAt?: Maybe<Scalars['ISO8601DateTime']>
  meetingStatus?: Maybe<InsuranceBerryStreetAppointmentMeetingStatus>
  nutritionistName?: Maybe<Scalars['String']>
  recurrent: Scalars['Boolean']
  startVideochatUrl?: Maybe<Scalars['String']>
  status: InsuranceBerryStreetAppointmentStatus
  title?: Maybe<Scalars['String']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']>
  user: IUser
}

export enum HealthPortalFeature {
  AdminPanelManageNutritionistAssignment = 'admin_panel_manage_nutritionist_assignment',
  AdminPanelMembers = 'admin_panel_members',
  AdminPanelViewNutritionistAssignment = 'admin_panel_view_nutritionist_assignment',
  AdminPanelViewNutritionistNotifications = 'admin_panel_view_nutritionist_notifications',
  BillingDashboard = 'billing_dashboard',
  Chat = 'chat',
  ChatManagement = 'chat_management',
  ChatbotAssistant = 'chatbot_assistant',
  ChatbotManageKnowledgeBase = 'chatbot_manage_knowledge_base',
  ChatbotUserHistory = 'chatbot_user_history',
  ChatbotViewKnowledgeBase = 'chatbot_view_knowledge_base',
  DexcomResearchViewStatus = 'dexcom_research_view_status',
  DppAssignCohort = 'dpp_assign_cohort',
  DppChat = 'dpp_chat',
  DppCreateCohort = 'dpp_create_cohort',
  DppSubmitReports = 'dpp_submit_reports',
  DppViewCohorts = 'dpp_view_cohorts',
  EditHealthTeamStructure = 'edit_health_team_structure',
  Files = 'files',
  Financials = 'financials',
  HealthMetrics = 'health_metrics',
  HealthMetricsManagement = 'health_metrics_management',
  History = 'history',
  Home = 'home',
  HomeCallScheduling = 'home_call_scheduling',
  HomeMobileAppPresentationMode = 'home_mobile_app_presentation_mode',
  HomeSidebar = 'home_sidebar',
  HomeTimeTracker = 'home_time_tracker',
  Insights = 'insights',
  NutritionistHome = 'nutritionist_home',
  SensorsReplacementRequests = 'sensors_replacement_requests',
  ShipmentsManagement = 'shipments_management',
  ShipmentsViewUser = 'shipments_view_user',
  StopDexcomResearchParticipation = 'stop_dexcom_research_participation',
  SupportAccountRequests = 'support_account_requests',
  SupportAccountRequestsCreate = 'support_account_requests_create',
  SupportAccountRequestsProcessCancelSubscription = 'support_account_requests_process_cancel_subscription',
  SupportAccountRequestsProcessCancelSubscriptionImmediate = 'support_account_requests_process_cancel_subscription_immediate',
  SupportAccountRequestsProcessChangeSubscriptionBillingCycle = 'support_account_requests_process_change_subscription_billing_cycle',
  SupportAccountRequestsProcessCredit = 'support_account_requests_process_credit',
  SupportAccountRequestsProcessDeleteDuplicateAccount = 'support_account_requests_process_delete_duplicate_account',
  SupportAccountRequestsProcessMiscRequest = 'support_account_requests_process_misc_request',
  SupportAccountRequestsProcessOneOffAddon = 'support_account_requests_process_one_off_addon',
  SupportAccountRequestsProcessPauseSubscription = 'support_account_requests_process_pause_subscription',
  SupportAccountRequestsProcessRefund = 'support_account_requests_process_refund',
  SupportAccountRequestsProcessUncancelSubscription = 'support_account_requests_process_uncancel_subscription',
  SupportAccountRequestsProcessUnpauseSubscription = 'support_account_requests_process_unpause_subscription',
  SupportAccountRequestsProcessUpdateSubscription = 'support_account_requests_process_update_subscription',
  SupportAccountRequestsReviewCancelSubscription = 'support_account_requests_review_cancel_subscription',
  SupportAccountRequestsReviewCancelSubscriptionImmediate = 'support_account_requests_review_cancel_subscription_immediate',
  SupportAccountRequestsReviewChangeSubscriptionBillingCycle = 'support_account_requests_review_change_subscription_billing_cycle',
  SupportAccountRequestsReviewCredit = 'support_account_requests_review_credit',
  SupportAccountRequestsReviewDeleteDuplicateAccount = 'support_account_requests_review_delete_duplicate_account',
  SupportAccountRequestsReviewMiscRequest = 'support_account_requests_review_misc_request',
  SupportAccountRequestsReviewOneOffAddon = 'support_account_requests_review_one_off_addon',
  SupportAccountRequestsReviewPauseSubscription = 'support_account_requests_review_pause_subscription',
  SupportAccountRequestsReviewRefund = 'support_account_requests_review_refund',
  SupportAccountRequestsReviewUncancelSubscription = 'support_account_requests_review_uncancel_subscription',
  SupportAccountRequestsReviewUnpauseSubscription = 'support_account_requests_review_unpause_subscription',
  SupportAccountRequestsReviewUpdateSubscription = 'support_account_requests_review_update_subscription',
  SupportResetRescheduleFreePassUsed = 'support_reset_reschedule_free_pass_used',
  SupportTickets = 'support_tickets',
  SurveysManageHealthQuestionnaireApprovals = 'surveys_manage_health_questionnaire_approvals',
  SurveysManagement = 'surveys_management',
  SurveysViewHealthQuestionnaire = 'surveys_view_health_questionnaire',
  SurveysViewHealthQuestionnaireApprovals = 'surveys_view_health_questionnaire_approvals',
  UsersEditAddress = 'users_edit_address',
  UsersEditCoachProfile = 'users_edit_coach_profile',
  UsersEditFulfillmentConfiguration = 'users_edit_fulfillment_configuration',
  UsersEditOrganization = 'users_edit_organization',
  UsersEditProfile = 'users_edit_profile',
  UsersEditSettings = 'users_edit_settings',
  UsersManageRefillConsent = 'users_manage_refill_consent',
  UsersViewFulfillmentConfiguration = 'users_view_fulfillment_configuration',
  UsersViewProfile = 'users_view_profile',
  UsersViewSettings = 'users_view_settings'
}

export type IHealthPortalFeatureCollection = {
  __typename?: 'HealthPortalFeatureCollection'
  features: Array<HealthPortalFeature>
}

export type IHealthPortalNotification = {
  __typename?: 'HealthPortalNotification'
  admin?: Maybe<IUser>
  chatConversation?: Maybe<IChatConversation>
  coachAssignment?: Maybe<ICoachAssignment>
  completedAt?: Maybe<Scalars['ISO8601DateTime']>
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  kind: HealthPortalNotificationKind
  supportTicket?: Maybe<ITicket>
}

export type IHealthPortalNotificationCollection = {
  __typename?: 'HealthPortalNotificationCollection'
  healthPortalNotifications: Array<IHealthPortalNotification>
}

export enum HealthPortalNotificationKind {
  AnnualMembership = 'annual_membership',
  AutomaticInitialSent = 'automatic_initial_sent',
  Canceled = 'canceled',
  CoachChange = 'coach_change',
  CoachEndDateChanged = 'coach_end_date_changed',
  CoachingProgram = 'coaching_program',
  DietitianMenuAssigned = 'dietitian_menu_assigned',
  Fixed_8Weeks = 'fixed_8_weeks',
  InsuranceConfirmed = 'insurance_confirmed',
  InsuranceConfirmedWithPatientResponsibility = 'insurance_confirmed_with_patient_responsibility',
  InsuranceRejected = 'insurance_rejected',
  MidpointPulseSubmitted = 'midpoint_pulse_submitted',
  MonthlyWrapUpSent = 'monthly_wrap_up_sent',
  NewComplimentaryAddOn = 'new_complimentary_add_on',
  NewPaidAddOn = 'new_paid_add_on',
  Paused = 'paused',
  PremiumInitialSent = 'premium_initial_sent',
  Recurring_12Weeks = 'recurring_12_weeks',
  Resumed = 'resumed',
  RetentionTest = 'retention_test',
  SupportConversationAssigned = 'support_conversation_assigned',
  TicketCommentCreated = 'ticket_comment_created',
  TicketCommentSubscribed = 'ticket_comment_subscribed',
  TicketStatusChanged = 'ticket_status_changed',
  VideoCallScheduled = 'video_call_scheduled'
}

export type IHealthPortalNotificationsSort = {
  direction: SortDirection
  field: HealthPortalNotificationsSortField
}

export enum HealthPortalNotificationsSortField {
  CreatedAt = 'created_at'
}

export type IHealthSettings = {
  __typename?: 'HealthSettings'
  dashboardGraphOptions: Array<IStringSelect>
  fastingEnd: Scalars['Int']
  fastingStart: Scalars['Int']
  glucoseHighOptions: Array<INumericalSelect>
  glucoseLowOptions: Array<INumericalSelect>
  glucoseSourceOptions: Array<IStringSelect>
  glucoseThresholdPresets: Array<IGlucoseThresholdPreset>
  ketoneHighOptions: Array<INumericalSelect>
  ketoneLowOptions: Array<INumericalSelect>
  macroGoalSettings: IMacroGoalSettings
  scanReminderOptions: Array<INumericalSelect>
  trackingModeOptions: Array<IStringSelect>
  unitSystemOptions: Array<IStringSelect>
}

export type IHistory = {
  __typename?: 'History'
  filters: IHistoryFilters
}

export type IHistoryFilters = {
  __typename?: 'HistoryFilters'
  order: Array<Scalars['String']>
  orderBy: Array<Scalars['String']>
  types: Array<IKeyValuePairStringArray>
}

export type IHoursInterval = {
  __typename?: 'HoursInterval'
  glucoseCheckOptions: Array<INumericalSelect>
}

export type IIconDef = {
  __typename?: 'IconDef'
  name: Scalars['String']
  pack: Scalars['String']
}

export type IIngredient = {
  __typename?: 'Ingredient'
  calories: Scalars['Float']
  createdAt: Scalars['ISO8601DateTime']
  description: Scalars['String']
  id: Scalars['ID']
  nutrition: Array<IKeyValuePairFloat>
  servingAmount: Scalars['Float']
  servingUnits: Scalars['String']
  thirdPartyIngredientId?: Maybe<Scalars['String']>
  thirdPartyIngredientSource?: Maybe<IngredientSource>
}

export type IIngredientData = {
  calories: Scalars['Float']
  description: Scalars['String']
  ingredientId?: InputMaybe<Scalars['ID']>
  mealId?: InputMaybe<Scalars['ID']>
  nutrition: Array<IKeyValueFloat>
  servingAmount: Scalars['Float']
  servingUnits: Scalars['String']
  thirdPartyIngredientId?: InputMaybe<Scalars['String']>
  thirdPartyIngredientSource?: InputMaybe<IngredientSource>
}

export enum IngredientSource {
  BiteAi = 'biteAI',
  Myfitnesspal = 'myfitnesspal',
  Nutritionix = 'nutritionix'
}

export type IInlineAlert = {
  __typename?: 'InlineAlert'
  message: Scalars['String']
  type: UiInlineAlert
}

export enum InsuranceBerryStreetAppointmentChargeType {
  Free = 'free',
  InsuranceCovered = 'insurance_covered',
  Paid = 'paid'
}

export enum InsuranceBerryStreetAppointmentMeetingStatus {
  Cancelled = 'cancelled',
  LateCancellation = 'late_cancellation',
  NoShow = 'no_show',
  Occurred = 'occurred',
  PendingReschedule = 'pending_reschedule',
  Rescheduled = 'rescheduled'
}

export enum InsuranceBerryStreetAppointmentSchedulingRule {
  FreeSingle = 'free_single',
  NoScheduling = 'no_scheduling',
  PaidBundle = 'paid_bundle',
  PaidSingle = 'paid_single'
}

export enum InsuranceBerryStreetAppointmentStatus {
  Deleted = 'deleted',
  InReview = 'in_review',
  ReviewCompleted = 'review_completed',
  SchedulingPending = 'scheduling_pending'
}

export enum InsuranceBerryStreetAppointmentType {
  DppSession = 'dpp_session',
  Followup = 'followup',
  Followup_30 = 'followup_30',
  Followup_45 = 'followup_45',
  Followup_90 = 'followup_90',
  Initial = 'initial',
  Initial_60 = 'initial_60',
  NoneCovered = 'none_covered'
}

export enum InsuranceHabitNuParticipantPayerSource {
  Commercial = 'commercial',
  DualEligible = 'dual_eligible',
  Employer = 'employer',
  Free = 'free',
  GovernmentMilitary = 'government_military',
  Grant = 'grant',
  Medicaid = 'medicaid',
  Medicare = 'medicare',
  SelfPay = 'self_pay',
  VentureCapital = 'venture_capital'
}

export enum InsuranceHabitNuParticipantState {
  Incomplete = 'incomplete',
  Init = 'init',
  PendingCreate = 'pending_create',
  PendingUpdate = 'pending_update',
  Processed = 'processed',
  ProcessingCreate = 'processing_create',
  ProcessingUpdate = 'processing_update'
}

export enum InsuranceHabitNuSessionState {
  Incomplete = 'incomplete',
  Pending = 'pending',
  Processed = 'processed',
  Processing = 'processing'
}

export type IInsurancePolicy = {
  __typename?: 'InsurancePolicy'
  /** @deprecated User last_state instead */
  eligibilityStatus?: Maybe<UserInsurancePolicyEligibilityStatus>
  id: Scalars['ID']
  initialAppointmentType?: Maybe<IAppointmentType>
  initialAppointmentTypes: Array<IAppointmentType>
  lastState?: Maybe<IInsurancePolicyState>
  states?: Maybe<Array<IInsurancePolicyState>>
  /** @deprecated User last_state instead */
  status?: Maybe<UserInsurancePolicyStatus>
  unitsAuthorized?: Maybe<Scalars['Int']>
  unitsLeft?: Maybe<Scalars['Int']>
  unitsOccurred?: Maybe<Scalars['Int']>
  unitsUsed?: Maybe<Scalars['Int']>
}

export type IInsurancePolicyState = {
  __typename?: 'InsurancePolicyState'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  kind: UserInsurancePolicyStateKind
  reason?: Maybe<Scalars['String']>
}

export type IIntegrationUser = {
  __typename?: 'IntegrationUser'
  accessToken: Scalars['String']
  accessTokenExpirationDate: Scalars['ISO8601DateTime']
  idToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type IIntegrationUserSetting = {
  accessToken: Scalars['String']
  accessTokenExpirationDate: Scalars['ISO8601DateTime']
  idToken?: InputMaybe<Scalars['String']>
  refreshToken: Scalars['String']
}

export type IIntegrationsSync = {
  __typename?: 'IntegrationsSync'
  dexcomUser?: Maybe<IIntegrationUser>
  ketoMojoUser?: Maybe<IIntegrationUser>
  lastSyncedDexcom?: Maybe<Scalars['ISO8601DateTime']>
  lastSyncedHealth?: Maybe<Scalars['ISO8601DateTime']>
  lastSyncedKetoMojo?: Maybe<Scalars['ISO8601DateTime']>
  lastSyncedLibreView?: Maybe<Scalars['ISO8601DateTime']>
}

export type IIntegrationsSyncSettings = {
  dexcomUser?: InputMaybe<IIntegrationUserSetting>
  ketoMojoUser?: InputMaybe<IIntegrationUserSetting>
  lastSyncedDexcom?: InputMaybe<Scalars['ISO8601DateTime']>
  lastSyncedHealth?: InputMaybe<Scalars['ISO8601DateTime']>
  lastSyncedKetoMojo?: InputMaybe<Scalars['ISO8601DateTime']>
  lastSyncedLibreView?: InputMaybe<Scalars['ISO8601DateTime']>
}

export type IInterval = {
  __typename?: 'Interval'
  description: Scalars['String']
  key: Scalars['String']
  suffix: Scalars['String']
}

export type IIntroScreen = {
  __typename?: 'IntroScreen'
  footnote?: Maybe<Scalars['String']>
  header: Scalars['String']
  icon?: Maybe<UiIcon>
  imageUrl?: Maybe<Scalars['String']>
  inlineAlert?: Maybe<IInlineAlert>
  /** @deprecated use intro_markdown */
  intro?: Maybe<Scalars['String']>
  introMarkdown?: Maybe<Scalars['String']>
}

export type IInvoice = {
  __typename?: 'Invoice'
  created: Scalars['ISO8601DateTime']
  downloadUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  latestCharge?: Maybe<ICharge>
  paidAt?: Maybe<Scalars['ISO8601DateTime']>
  periodStart: Scalars['ISO8601DateTime']
  /** @deprecated not used anymore */
  primaryInvoiceLine?: Maybe<IInvoiceLine>
  primaryProduct: IProductMin
  receiptNumber?: Maybe<Scalars['String']>
  status: InvoiceStatus
  stripeId: Scalars['String']
  /** @deprecated use status enum field */
  stripeStatus: Scalars['String']
  subscription?: Maybe<ISubscription>
  total?: Maybe<Scalars['Float']>
  updatedAt: Scalars['ISO8601DateTime']
  user: IUser
}

export type IInvoiceCollection = {
  __typename?: 'InvoiceCollection'
  invoices: Array<IInvoice>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IInvoiceDynamicFilter = {
  field: InvoiceDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum InvoiceDynamicFilterField {
  Created = 'created',
  DownloadUrl = 'download_url',
  PaidAt = 'paid_at',
  ReceiptNumber = 'receipt_number',
  StripeId = 'stripe_id',
  SubscriptionStripeId = 'subscription_stripe_id',
  Total = 'total',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserStripeId = 'user_stripe_id'
}

export type IInvoiceDynamicFilterItem = {
  items: Array<IInvoiceDynamicFilter>
  operator: DynamicFilterOperator
}

export type IInvoiceLine = {
  __typename?: 'InvoiceLine'
  id: Scalars['ID']
  plan?: Maybe<IPlan>
}

export type IInvoiceOrder = {
  __typename?: 'InvoiceOrder'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  invoice: IInvoice
  lastProcessingAttemptAt?: Maybe<Scalars['ISO8601DateTime']>
  orderId?: Maybe<Scalars['Int']>
  processedAt?: Maybe<Scalars['ISO8601DateTime']>
  product: Scalars['String']
  productType: Scalars['String']
  quantity: Scalars['Int']
  state: InvoiceOrderState
  stateReason?: Maybe<Scalars['String']>
  updatedAt: Scalars['ISO8601DateTime']
  user: IUser
}

export type IInvoiceOrderDynamicFilter = {
  field: InvoiceOrderDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum InvoiceOrderDynamicFilterField {
  CreatedAt = 'created_at',
  Id = 'id',
  InvoiceId = 'invoice_id',
  InvoicePaidAt = 'invoice_paid_at',
  InvoicePeriodStart = 'invoice_period_start',
  InvoiceStripeId = 'invoice_stripe_id',
  LastProcessingAttemptAt = 'last_processing_attempt_at',
  OrderId = 'order_id',
  ProcessedAt = 'processed_at',
  State = 'state',
  StateReason = 'state_reason',
  UpdatedAt = 'updated_at',
  UserEmail = 'user_email'
}

export type IInvoiceOrderDynamicFilterItem = {
  items: Array<IInvoiceOrderDynamicFilter>
  operator: DynamicFilterOperator
}

export type IInvoiceOrderSort = {
  direction: SortDirection
  field: InvoiceOrderSortField
}

export enum InvoiceOrderSortField {
  CreatedAt = 'created_at',
  Id = 'id',
  InvoiceId = 'invoice_id',
  InvoicePaidAt = 'invoice_paid_at',
  InvoicePeriodStart = 'invoice_period_start',
  InvoiceStripeId = 'invoice_stripe_id',
  LastProcessingAttemptAt = 'last_processing_attempt_at',
  OrderId = 'order_id',
  ProcessedAt = 'processed_at',
  State = 'state',
  StateReason = 'state_reason',
  UpdatedAt = 'updated_at',
  UserEmail = 'user_email'
}

export enum InvoiceOrderState {
  Flagged = 'flagged',
  Ignored = 'ignored',
  Pending = 'pending',
  Processed = 'processed'
}

export type IInvoiceSort = {
  direction: SortDirection
  field: InvoiceSortField
}

export enum InvoiceSortField {
  Created = 'created',
  DownloadUrl = 'download_url',
  PaidAt = 'paid_at',
  ReceiptNumber = 'receipt_number',
  StripeId = 'stripe_id',
  SubscriptionStripeId = 'subscription_stripe_id',
  Total = 'total',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserStripeId = 'user_stripe_id'
}

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void'
}

export type IJournalConfig = {
  __typename?: 'JournalConfig'
  measurementTypes: Array<IMeasurementType>
  range: IJournalRange
}

export type IJournalEntry = {
  __typename?: 'JournalEntry'
  body: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  createdBy: Scalars['String']
  creatorId: Scalars['ID']
  id: Scalars['ID']
  kind: NoteKind
  measurements?: Maybe<Array<IMeasurement>>
  occurredAt: Scalars['ISO8601DateTime']
  score?: Maybe<Scalars['Float']>
  title: Scalars['String']
}

export type IJournalRange = {
  __typename?: 'JournalRange'
  max: Scalars['Int']
  min: Scalars['Int']
}

export type IKeyValueFloat = {
  key: Scalars['String']
  value: Scalars['Float']
}

export type IKeyValuePairFloat = {
  __typename?: 'KeyValuePairFloat'
  key: Scalars['String']
  value: Scalars['Float']
}

export type IKeyValuePairString = {
  __typename?: 'KeyValuePairString'
  key: Scalars['String']
  value: Scalars['String']
}

export type IKeyValuePairStringArray = {
  __typename?: 'KeyValuePairStringArray'
  key: Scalars['String']
  value: Array<Scalars['String']>
}

export type IKeyValueStringArray = {
  key: Scalars['String']
  value: Array<Scalars['String']>
}

export type IKnowledgeAnswer = {
  __typename?: 'KnowledgeAnswer'
  id: Scalars['ID']
  knowledgeQuestions: Array<IKnowledgeQuestion>
  knowledgeSources: Array<IKnowledgeSource>
  title: Scalars['String']
  value: Scalars['String']
}

export type IKnowledgeAnswerCollection = {
  __typename?: 'KnowledgeAnswerCollection'
  knowledgeAnswers: Array<IKnowledgeAnswer>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IKnowledgeAnswerDynamicFilter = {
  field: KnowledgeAnswerDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum KnowledgeAnswerDynamicFilterField {
  Id = 'id',
  QuestionsValue = 'questions_value',
  SourcesTitle = 'sources_title',
  SourcesUrl = 'sources_url',
  Title = 'title',
  Value = 'value'
}

export type IKnowledgeAnswerDynamicFilterItem = {
  items: Array<IKnowledgeAnswerDynamicFilter>
  operator: DynamicFilterOperator
}

export type IKnowledgeAnswerSort = {
  direction: SortDirection
  field: KnowledgeAnswerSortField
}

export enum KnowledgeAnswerSortField {
  Id = 'id',
  Title = 'title',
  Value = 'value'
}

export type IKnowledgeQuestion = {
  __typename?: 'KnowledgeQuestion'
  id: Scalars['ID']
  knowledgeAnswers: Array<IKnowledgeAnswer>
  knowledgeTags: Array<IKnowledgeTag>
  value: Scalars['String']
}

export type IKnowledgeQuestionCollection = {
  __typename?: 'KnowledgeQuestionCollection'
  knowledgeQuestions: Array<IKnowledgeQuestion>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IKnowledgeQuestionDynamicFilter = {
  field: KnowledgeQuestionDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum KnowledgeQuestionDynamicFilterField {
  AnswersSourcesTitle = 'answers_sources_title',
  AnswersSourcesUrl = 'answers_sources_url',
  AnswersTitle = 'answers_title',
  Id = 'id',
  TagsId = 'tags_id',
  TagsValue = 'tags_value',
  Value = 'value'
}

export type IKnowledgeQuestionDynamicFilterItem = {
  items: Array<IKnowledgeQuestionDynamicFilter>
  operator: DynamicFilterOperator
}

export type IKnowledgeQuestionSort = {
  direction: SortDirection
  field: KnowledgeQuestionSortField
}

export enum KnowledgeQuestionSortField {
  Id = 'id',
  Value = 'value'
}

export type IKnowledgeSource = {
  __typename?: 'KnowledgeSource'
  id: Scalars['ID']
  knowledgeAnswers: Array<IKnowledgeAnswer>
  title: Scalars['String']
  url: Scalars['String']
}

export type IKnowledgeSourceCollection = {
  __typename?: 'KnowledgeSourceCollection'
  knowledgeSources: Array<IKnowledgeSource>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IKnowledgeSourceDynamicFilter = {
  field: KnowledgeSourceDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum KnowledgeSourceDynamicFilterField {
  AnswersQuestionsValue = 'answers_questions_value',
  AnswersTitle = 'answers_title',
  Id = 'id',
  Title = 'title',
  Url = 'url'
}

export type IKnowledgeSourceDynamicFilterItem = {
  items: Array<IKnowledgeSourceDynamicFilter>
  operator: DynamicFilterOperator
}

export type IKnowledgeSourceSort = {
  direction: SortDirection
  field: KnowledgeSourceSortField
}

export enum KnowledgeSourceSortField {
  Id = 'id',
  Url = 'url'
}

export type IKnowledgeTag = {
  __typename?: 'KnowledgeTag'
  id: Scalars['ID']
  value: Scalars['String']
}

export type IKnowledgeTagCollection = {
  __typename?: 'KnowledgeTagCollection'
  knowledgeTags: Array<IKnowledgeTag>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IKnowledgeTagDynamicFilter = {
  field: KnowledgeTagDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum KnowledgeTagDynamicFilterField {
  Id = 'id',
  Value = 'value'
}

export type IKnowledgeTagDynamicFilterItem = {
  items: Array<IKnowledgeTagDynamicFilter>
  operator: DynamicFilterOperator
}

export type IKnowledgeTagSort = {
  direction: SortDirection
  field: KnowledgeTagSortField
}

export enum KnowledgeTagSortField {
  Id = 'id',
  Value = 'value'
}

export type ILesson = {
  __typename?: 'Lesson'
  contentId: Scalars['ID']
  id: Scalars['ID']
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<IProgress>
}

export type ILibreAccount = {
  __typename?: 'LibreAccount'
  libreId: Scalars['String']
}

export type ILink = {
  __typename?: 'Link'
  text: Scalars['String']
  url: Scalars['String']
}

export type IListConversations = {
  __typename?: 'ListConversations'
  conversations: Array<IConversation>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IListFiles = {
  __typename?: 'ListFiles'
  files: Array<IFile>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IListFilters = {
  __typename?: 'ListFilters'
  order: Array<Scalars['String']>
  orderBy: Array<Scalars['String']>
  types: Array<Scalars['String']>
}

export type IListInvoiceOrders = {
  __typename?: 'ListInvoiceOrders'
  invoiceOrders: Array<IInvoiceOrder>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IListNotes = {
  __typename?: 'ListNotes'
  notes: Array<INote>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type IListOrders = {
  __typename?: 'ListOrders'
  orders: Array<IOrder>
  totalRecords: Scalars['Int']
}

export type IListShipments = {
  __typename?: 'ListShipments'
  shipments: Array<IShipment>
  totalRecords: Scalars['Int']
}

export type IMacro = {
  __typename?: 'Macro'
  indent: Scalars['Int']
  name: Scalars['String']
}

export type IMacroGoalSettings = {
  __typename?: 'MacroGoalSettings'
  caloriesMax: Scalars['Int']
  carbsMax: Scalars['Int']
  fatMax: Scalars['Int']
  proteinMax: Scalars['Int']
}

export type IMacroGoals = {
  __typename?: 'MacroGoals'
  calories: Scalars['Int']
  carbs: Scalars['Int']
  fat: Scalars['Int']
  netCarbs?: Maybe<Scalars['Int']>
  protein: Scalars['Int']
}

export type IMacroGoalsDaily = {
  calories?: InputMaybe<Scalars['Int']>
  carbs?: InputMaybe<Scalars['Int']>
  fat?: InputMaybe<Scalars['Int']>
  netCarbs?: InputMaybe<Scalars['Int']>
  protein?: InputMaybe<Scalars['Int']>
}

export type IMeal = {
  __typename?: 'Meal'
  avatar?: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  createdBy: IUser
  description: Scalars['String']
  experimentId?: Maybe<Scalars['ID']>
  favorite: Scalars['Boolean']
  generatedOccurredAt?: Maybe<Scalars['Boolean']>
  glucose: IEntryGlucose
  id: Scalars['ID']
  ingredients: Array<IIngredient>
  kind?: Maybe<MealKind>
  nutrition: Array<IKeyValuePairFloat>
  occurredAt: Scalars['ISO8601DateTime']
  source: MealSource
  statistics: IEntryStatistics
  /** @deprecated use kind enum field */
  time?: Maybe<Scalars['String']>
  title: Scalars['String']
  /** @deprecated use kind enum field */
  type: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export enum MealKind {
  Breakfast = 'breakfast',
  Dessert = 'dessert',
  Dinner = 'dinner',
  Drink = 'drink',
  Lunch = 'lunch',
  Snack = 'snack',
  Supplement = 'supplement'
}

export enum MealSource {
  HealthPortal = 'health_portal',
  MobileApp = 'mobile_app',
  MyFitnessPal = 'my_fitness_pal'
}

export type IMeals = {
  __typename?: 'Meals'
  filters: IListFilters
}

export type IMeasurement = {
  __typename?: 'Measurement'
  createdAt: Scalars['ISO8601DateTime']
  cumulative: Scalars['Boolean']
  description: Scalars['String']
  externalSource?: Maybe<Scalars['String']>
  id: Scalars['ID']
  interpolated: Scalars['Boolean']
  occurredAt: Scalars['ISO8601DateTime']
  scan?: Maybe<IScan>
  timestamp: Scalars['ISO8601DateTime']
  title: Scalars['String']
  type: Scalars['String']
  units: Scalars['String']
  value?: Maybe<Scalars['Float']>
  values?: Maybe<IMeasurementMultiValue>
}

export type IMeasurementCollection = {
  __typename?: 'MeasurementCollection'
  measurements: Array<IMeasurement>
}

export type IMeasurementConfig = {
  __typename?: 'MeasurementConfig'
  imperial: IMeasurementConfigUnit
  metric: IMeasurementConfigUnit
  name: Scalars['String']
}

export type IMeasurementConfigUnit = {
  __typename?: 'MeasurementConfigUnit'
  maxThreshold?: Maybe<Scalars['Float']>
  minThreshold?: Maybe<Scalars['Float']>
  precision: Scalars['Int']
  units: Scalars['String']
}

export type IMeasurementFilter = {
  endDate?: InputMaybe<Scalars['String']>
  endHour?: InputMaybe<Scalars['Int']>
  excludeTypes?: InputMaybe<Array<Scalars['String']>>
  favorite?: InputMaybe<Scalars['Boolean']>
  graph?: InputMaybe<Scalars['String']>
  includeTypes?: InputMaybe<Array<Scalars['String']>>
  order?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  startHour?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<IKeyValueStringArray>>
}

export type IMeasurementMultiValue = IBloodPressureMeasurement | ISingleValueMeasurement

export type IMeasurementType = {
  __typename?: 'MeasurementType'
  reverse?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export type IMessage = {
  __typename?: 'Message'
  content: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  knowledgeQuestions: Array<IKnowledgeQuestion>
  question?: Maybe<Scalars['String']>
  updatedAt: Scalars['ISO8601DateTime']
  userId: Scalars['ID']
  userMessage?: Maybe<IMessage>
}

export type IMiscRequestPayload = {
  requestDetails: Scalars['String']
}

export type IMiscRequestResponsePayload = {
  __typename?: 'MiscRequestResponsePayload'
  requestDetails: Scalars['String']
}

export enum MobileAppFeature {
  AdminAccess = 'admin_access',
  AdminImpersonate = 'admin_impersonate',
  ScanCgm = 'scan_cgm'
}

export type IMutationType = {
  __typename?: 'MutationType'
  addKnowledgeQuestionTag: IKnowledgeQuestion
  backfillChatConversation: IChatConversation
  bulkCoachAssign: IBulkAssign
  bulkCreateCourseCohortMemberEnrollments: ISuccess
  bulkCreateFiles: IFileCollection
  bulkCreateShipments: IListShipments
  bulkFollowCoachAssignments: ISuccess
  bulkShipOrders: IListOrders
  bulkUnfollowCoachAssignments: ISuccess
  bulkUpdateCoachProfiles: ICoachProfileCollection
  bulkUpdateHealthPortalNotifications: IHealthPortalNotificationCollection
  bulkUpsertCourseCohortSessions: ICohort
  /** @deprecated use submit_account_request instead */
  createAccountRequest: IAccountRequest
  createChatConversationMessageTemplate: IChatConversationMessageTemplate
  createChatConversationNote: IChatConversationNote
  createChatToken: IToken
  createChatbotDelimiter: IDelimiter
  createCourseCohort: ICohort
  createDietitianSession: IDietitianSession
  createDirectUploads: IDirectUploadCollection
  createFilesFromDirectUploads: IFileCollection
  createHealthMetric: IHealthMetric
  createKnowledgeAnswer: IKnowledgeAnswer
  createKnowledgeQuestion: IKnowledgeQuestion
  createKnowledgeSource: IKnowledgeSource
  createKnowledgeTag: IKnowledgeTag
  /** @deprecated Use :upsert_note */
  createNote: INote
  createOrder: IOrder
  createRefillConsent: IUserRefillConsent
  createReplacementRequest: IReplacementRequest
  createReplacementRequestInvoice: IReplacementRequest
  createShipment: IShipment
  createSyncRequest: ISuccess
  createTicket: ITicket
  createTicketComment: ITicketComment
  deleteChatConversationMessageTemplate: ISuccess
  deleteFile: IFile
  deleteHealthMetric: ISuccess
  deleteKnowledgeAnswer: IKnowledgeAnswer
  deleteKnowledgeQuestion: IKnowledgeQuestion
  deleteKnowledgeSource: IKnowledgeSource
  deleteKnowledgeTag: IKnowledgeTag
  deleteMeal: IMeal
  deleteNote: INote
  deleteOrder: IOrder
  deleteReplacementRequest: IReplacementRequest
  deleteScan: IMeasurement
  deleteShipment: IShipment
  deleteTicket: ITicket
  deleteUserProperty: IUserProperty
  exportAccountRequestsData: ISuccess
  invalidateRefillConsent: IUserRefillConsent
  linkKnowledgeAnswer: IKnowledgeAnswer
  linkKnowledgeQuestion: IKnowledgeQuestion
  processDppInsuranceParticipants: ISuccess
  processDppInsuranceSessions: ISuccess
  promoteToCoachChatConversation: ISuccess
  removeKnowledgeQuestionTag: IKnowledgeQuestion
  resendMissingPrescriptionsShipments: IListShipments
  resendShipment: IShipment
  restartSurveyLink: ISurveyLink
  sendAppointmentSmsReminder: ISuccess
  sendChatConversationMessage: ISuccess
  setPrimaryGoalMetrics: Array<IGoalMetric>
  signInThirdPartyUser: IUserAuth
  signInUser: IUserAuth
  signOutUser: ISuccess
  /** @deprecated use sign_out_user instead */
  signoutUser: ISuccess
  stopDexcomResearchParticipation: ISuccess
  submitAccountRequest: IAccountRequest
  unlinkKnowledgeAnswer: IKnowledgeAnswer
  unlinkKnowledgeQuestion: IKnowledgeQuestion
  updateAccountRequest: IAccountRequest
  updateAddress: IAddress
  updateAppointment: ISuccess
  updateChatConversation: IChatConversation
  updateChatConversationMessageTemplate: IChatConversationMessageTemplate
  updateChatConversationNote: IChatConversationNote
  updateChatConversationState: IChatConversation
  updateCoachConversationScheduledMessage: ICoachConversationScheduledMessage
  updateCourseCohort: ICohort
  updateCourseCohortInstructor: ICohort
  updateCourseCohortState: ICohort
  updateFulfillmentConfiguration: IFulfillmentConfiguration
  updateGoalMetric: IGoalMetric
  updateHealthMetric: IHealthMetric
  updateInvoiceOrder: IInvoiceOrder
  updateKnowledgeAnswer: IKnowledgeAnswer
  updateKnowledgeQuestion: IKnowledgeQuestion
  updateKnowledgeSource: IKnowledgeSource
  updateKnowledgeTag: IKnowledgeTag
  updateOrder: IOrder
  updateReplacementRequest: IReplacementRequest
  updateSensor: ISensor
  updateSettings: IUserSettings
  updateShipment: IShipment
  updateTicket: ITicket
  updateUser: IUser
  updateUserApprovalStatus: IUser
  updateUserOrganization: IUser
  updateUserProperty: IUserProperty
  upsertCoachProfile: ICoachProfile
  upsertCourseCohortSessionAttendance: ICohortSessionAttendance
  upsertDailyAction: IDailyAction
  upsertMeal: IMeal
  upsertNote: INote
  upsertPrimaryGoal: IGoal
}

export type IMutationTypeAddKnowledgeQuestionTagArgs = {
  id: Scalars['ID']
  knowledgeTagId: Scalars['ID']
}

export type IMutationTypeBackfillChatConversationArgs = {
  id: Scalars['ID']
}

export type IMutationTypeBulkCoachAssignArgs = {
  coachAssignments?: InputMaybe<Array<ICoachAssign>>
}

export type IMutationTypeBulkCreateCourseCohortMemberEnrollmentsArgs = {
  cohortId: Scalars['ID']
  state: CoursesCohortMemberEnrollmentState
  userIds: Array<Scalars['ID']>
}

export type IMutationTypeBulkCreateFilesArgs = {
  urls?: InputMaybe<Array<Scalars['String']>>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeBulkCreateShipmentsArgs = {
  provider?: InputMaybe<ShippingOrderProvider>
  shipmentsAttributes?: InputMaybe<Array<IBulkCreateShipment>>
  shippedAt?: InputMaybe<Scalars['String']>
}

export type IMutationTypeBulkFollowCoachAssignmentsArgs = {
  coachId: Scalars['ID']
}

export type IMutationTypeBulkShipOrdersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  provider?: InputMaybe<ShippingOrderProvider>
}

export type IMutationTypeBulkUnfollowCoachAssignmentsArgs = {
  coachId: Scalars['ID']
}

export type IMutationTypeBulkUpdateCoachProfilesArgs = {
  coachProfiles?: InputMaybe<Array<IUpdateCoachProfile>>
}

export type IMutationTypeBulkUpdateHealthPortalNotificationsArgs = {
  healthPortalNotifications?: InputMaybe<Array<IUpdateHealthPortalNotification>>
}

export type IMutationTypeBulkUpsertCourseCohortSessionsArgs = {
  id?: InputMaybe<Scalars['ID']>
  sessionsData?: InputMaybe<Array<ICohortSessionData>>
}

export type IMutationTypeCreateAccountRequestArgs = {
  kind?: InputMaybe<OpsAccountRequestKind>
  notes?: InputMaybe<Scalars['String']>
  payload?: InputMaybe<IAccountRequestPayloadUnionSubmissionType>
  priority?: InputMaybe<OpsAccountRequestPriority>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateChatConversationMessageTemplateArgs = {
  name?: InputMaybe<Scalars['String']>
  private?: InputMaybe<Scalars['Boolean']>
  value?: InputMaybe<Scalars['String']>
}

export type IMutationTypeCreateChatConversationNoteArgs = {
  body?: InputMaybe<Scalars['String']>
  conversationId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateChatTokenArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateChatbotDelimiterArgs = {
  providerConversationId: Scalars['ID']
}

export type IMutationTypeCreateCourseCohortArgs = {
  instructorId?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<CoursesCohortKind>
  name?: InputMaybe<Scalars['String']>
  sessionsData?: InputMaybe<Array<ICohortSessionData>>
}

export type IMutationTypeCreateDietitianSessionArgs = {
  endedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  startedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateDirectUploadsArgs = {
  files?: InputMaybe<Array<IFileMetadata>>
}

export type IMutationTypeCreateFilesFromDirectUploadsArgs = {
  signedIds?: InputMaybe<Array<Scalars['String']>>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateHealthMetricArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>
  kind?: InputMaybe<HealthDataMetricBiomarker>
  value?: InputMaybe<Scalars['Float']>
}

export type IMutationTypeCreateKnowledgeAnswerArgs = {
  title: Scalars['String']
  value: Scalars['String']
}

export type IMutationTypeCreateKnowledgeQuestionArgs = {
  value: Scalars['String']
}

export type IMutationTypeCreateKnowledgeSourceArgs = {
  title: Scalars['String']
  url: Scalars['String']
}

export type IMutationTypeCreateKnowledgeTagArgs = {
  value: Scalars['String']
}

export type IMutationTypeCreateNoteArgs = {
  body?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  pinned?: InputMaybe<Scalars['Boolean']>
  time?: InputMaybe<Scalars['String']>
}

export type IMutationTypeCreateOrderArgs = {
  invoiceOrderId?: InputMaybe<Scalars['ID']>
  notes?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productType?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  sensorKind?: InputMaybe<UserSensorKind>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateReplacementRequestArgs = {
  chargeAmount?: InputMaybe<ReplacementRequestChargeAmount>
  dateFellOff?: InputMaybe<Scalars['String']>
  dateNotified?: InputMaybe<Scalars['String']>
  daysWorn?: InputMaybe<Scalars['Int']>
  errorType?: InputMaybe<ReplacementRequestErrorType>
  howItFellOff?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  replacementReason?: InputMaybe<ReplacementRequestReplacementReason>
  sensorId?: InputMaybe<Scalars['ID']>
  serialNumber?: InputMaybe<Scalars['String']>
  testedWithLibreApp?: InputMaybe<ReplacementRequestTestedWithLibreApp>
  wearingPatch?: InputMaybe<ReplacementRequestWearingPatch>
}

export type IMutationTypeCreateReplacementRequestInvoiceArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateShipmentArgs = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  deliveredAt?: InputMaybe<Scalars['ISO8601DateTime']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  orderId?: InputMaybe<Scalars['Int']>
  provider?: InputMaybe<Scalars['String']>
  providerReceivedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  providerShipmentId?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Int']>
  shippedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  state?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
  trackingNumber?: InputMaybe<Scalars['String']>
  trackingUrl?: InputMaybe<Scalars['String']>
  zipCode?: InputMaybe<Scalars['String']>
}

export type IMutationTypeCreateSyncRequestArgs = {
  userId: Scalars['ID']
}

export type IMutationTypeCreateTicketArgs = {
  description?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<TicketKind>
  priority?: InputMaybe<TicketPriority>
  reason?: InputMaybe<TicketReason>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeCreateTicketCommentArgs = {
  body?: InputMaybe<Scalars['String']>
  mentionedAdminIds?: InputMaybe<Array<Scalars['ID']>>
  ticketId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeDeleteChatConversationMessageTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeDeleteFileArgs = {
  id: Scalars['ID']
}

export type IMutationTypeDeleteHealthMetricArgs = {
  id: Scalars['ID']
}

export type IMutationTypeDeleteKnowledgeAnswerArgs = {
  id: Scalars['ID']
}

export type IMutationTypeDeleteKnowledgeQuestionArgs = {
  id: Scalars['ID']
}

export type IMutationTypeDeleteKnowledgeSourceArgs = {
  id: Scalars['ID']
}

export type IMutationTypeDeleteKnowledgeTagArgs = {
  id: Scalars['ID']
}

export type IMutationTypeDeleteMealArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeDeleteNoteArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeDeleteOrderArgs = {
  deletionReason?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
}

export type IMutationTypeDeleteReplacementRequestArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeDeleteScanArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeDeleteShipmentArgs = {
  id?: InputMaybe<Scalars['Int']>
}

export type IMutationTypeDeleteTicketArgs = {
  id: Scalars['ID']
}

export type IMutationTypeDeleteUserPropertyArgs = {
  id: Scalars['ID']
}

export type IMutationTypeExportAccountRequestsDataArgs = {
  status?: InputMaybe<OpsAccountRequestStatus>
}

export type IMutationTypeLinkKnowledgeAnswerArgs = {
  id: Scalars['ID']
  knowledgeSourceId: Scalars['ID']
}

export type IMutationTypeLinkKnowledgeQuestionArgs = {
  id: Scalars['ID']
  knowledgeAnswerId: Scalars['ID']
}

export type IMutationTypePromoteToCoachChatConversationArgs = {
  id: Scalars['ID']
}

export type IMutationTypeRemoveKnowledgeQuestionTagArgs = {
  id: Scalars['ID']
  knowledgeTagId: Scalars['ID']
}

export type IMutationTypeResendShipmentArgs = {
  id?: InputMaybe<Scalars['Int']>
}

export type IMutationTypeRestartSurveyLinkArgs = {
  id: Scalars['ID']
  questionKeys?: InputMaybe<Array<RestartSurveyLinkQuestionKeys>>
}

export type IMutationTypeSendAppointmentSmsReminderArgs = {
  id: Scalars['ID']
}

export type IMutationTypeSendChatConversationMessageArgs = {
  body?: InputMaybe<Scalars['String']>
  conversationId?: InputMaybe<Scalars['ID']>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeSetPrimaryGoalMetricsArgs = {
  kinds?: InputMaybe<Array<UserGoalMetricKind>>
}

export type IMutationTypeSignInThirdPartyUserArgs = {
  provider?: InputMaybe<ThirdPartySignInProvider>
  providerToken?: InputMaybe<Scalars['String']>
  providerUserId?: InputMaybe<Scalars['String']>
}

export type IMutationTypeSignInUserArgs = {
  email?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}

export type IMutationTypeStopDexcomResearchParticipationArgs = {
  userId: Scalars['ID']
}

export type IMutationTypeSubmitAccountRequestArgs = {
  kind?: InputMaybe<OpsAccountRequestKind>
  notes?: InputMaybe<Scalars['String']>
  payload?: InputMaybe<IAccountRequestPayloadUnionSubmissionType>
  priority?: InputMaybe<OpsAccountRequestPriority>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeUnlinkKnowledgeAnswerArgs = {
  id: Scalars['ID']
  knowledgeSourceId: Scalars['ID']
}

export type IMutationTypeUnlinkKnowledgeQuestionArgs = {
  id: Scalars['ID']
  knowledgeAnswerId: Scalars['ID']
}

export type IMutationTypeUpdateAccountRequestArgs = {
  accountRequestId?: InputMaybe<Scalars['ID']>
  notes?: InputMaybe<Scalars['String']>
  priority?: InputMaybe<OpsAccountRequestPriority>
  status?: InputMaybe<OpsAccountRequestStatus>
}

export type IMutationTypeUpdateAddressArgs = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<AddressCountry>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  state?: InputMaybe<AddressState>
  street?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
  zipCode?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateAppointmentArgs = {
  actualDuration?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
  meetingStatus?: InputMaybe<InsuranceBerryStreetAppointmentMeetingStatus>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type IMutationTypeUpdateChatConversationArgs = {
  id?: InputMaybe<Scalars['ID']>
  read?: InputMaybe<Scalars['Boolean']>
}

export type IMutationTypeUpdateChatConversationMessageTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  private?: InputMaybe<Scalars['Boolean']>
  value?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateChatConversationNoteArgs = {
  body?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeUpdateChatConversationStateArgs = {
  id?: InputMaybe<Scalars['ID']>
  snoozedUntil?: InputMaybe<Scalars['ISO8601DateTime']>
  state?: InputMaybe<ChatConversationState>
}

export type IMutationTypeUpdateCoachConversationScheduledMessageArgs = {
  scheduledMessageId?: InputMaybe<Scalars['ID']>
  sendAt?: InputMaybe<Scalars['ISO8601DateTime']>
  state?: InputMaybe<CoachesConversationScheduledMessageState>
  value?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateCourseCohortArgs = {
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateCourseCohortInstructorArgs = {
  id?: InputMaybe<Scalars['ID']>
  instructorId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeUpdateCourseCohortStateArgs = {
  id?: InputMaybe<Scalars['ID']>
  state?: InputMaybe<CoursesCohortState>
}

export type IMutationTypeUpdateFulfillmentConfigurationArgs = {
  sensorKind: Scalars['String']
  userId: Scalars['ID']
}

export type IMutationTypeUpdateGoalMetricArgs = {
  highThresholdValue?: InputMaybe<Scalars['Float']>
  kind?: InputMaybe<UserGoalMetricKind>
  lowThresholdValue?: InputMaybe<Scalars['Float']>
}

export type IMutationTypeUpdateHealthMetricArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>
  id?: InputMaybe<Scalars['ID']>
  value?: InputMaybe<Scalars['Float']>
}

export type IMutationTypeUpdateInvoiceOrderArgs = {
  id?: InputMaybe<Scalars['ID']>
  state?: InputMaybe<InvoiceOrderState>
  stateReason?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateKnowledgeAnswerArgs = {
  id: Scalars['ID']
  title?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateKnowledgeQuestionArgs = {
  id: Scalars['ID']
  value: Scalars['String']
}

export type IMutationTypeUpdateKnowledgeSourceArgs = {
  id: Scalars['ID']
  title?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateKnowledgeTagArgs = {
  id: Scalars['ID']
  value: Scalars['String']
}

export type IMutationTypeUpdateOrderArgs = {
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  notes?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productType?: InputMaybe<Scalars['String']>
  provider?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  sensorKind?: InputMaybe<UserSensorKind>
}

export type IMutationTypeUpdateReplacementRequestArgs = {
  chargeAmount?: InputMaybe<ReplacementRequestChargeAmount>
  dateFellOff?: InputMaybe<Scalars['String']>
  dateNotified?: InputMaybe<Scalars['String']>
  daysWorn?: InputMaybe<Scalars['Int']>
  errorType?: InputMaybe<ReplacementRequestErrorType>
  howItFellOff?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  notes?: InputMaybe<Scalars['String']>
  replacementReason?: InputMaybe<ReplacementRequestReplacementReason>
  testedWithLibreApp?: InputMaybe<ReplacementRequestTestedWithLibreApp>
  wearingPatch?: InputMaybe<ReplacementRequestWearingPatch>
}

export type IMutationTypeUpdateSensorArgs = {
  baselineAdjustment?: InputMaybe<Scalars['Int']>
}

export type IMutationTypeUpdateSettingsArgs = {
  appVersionReminder?: InputMaybe<Scalars['Boolean']>
  canScanExpired?: InputMaybe<Scalars['Boolean']>
  dashboardGraph?: InputMaybe<Scalars['String']>
  dexcomSync?: InputMaybe<Scalars['Boolean']>
  displayActivityScore?: InputMaybe<Scalars['Boolean']>
  displayDailyScore?: InputMaybe<Scalars['Boolean']>
  displayMealScore?: InputMaybe<Scalars['Boolean']>
  fastingEnd?: InputMaybe<Scalars['Int']>
  fastingStart?: InputMaybe<Scalars['Int']>
  glucoseHighThreshold?: InputMaybe<Scalars['Int']>
  glucoseLowThreshold?: InputMaybe<Scalars['Int']>
  glucoseSource?: InputMaybe<Scalars['String']>
  googleFitSync?: InputMaybe<Scalars['Boolean']>
  healthKitSync?: InputMaybe<Scalars['Boolean']>
  integrationsSyncSettings?: InputMaybe<IIntegrationsSyncSettings>
  ketoMojoSync?: InputMaybe<Scalars['Boolean']>
  ketoneHighThreshold?: InputMaybe<Scalars['Float']>
  ketoneLowThreshold?: InputMaybe<Scalars['Float']>
  libreViewSync?: InputMaybe<Scalars['Boolean']>
  macroGoalsDaily?: InputMaybe<IMacroGoalsDaily>
  patternsNotifications?: InputMaybe<Scalars['Boolean']>
  reminders?: InputMaybe<Scalars['Boolean']>
  scanReminder?: InputMaybe<Scalars['Int']>
  showNetCarbs?: InputMaybe<Scalars['Boolean']>
  statsSettings?: InputMaybe<Array<IStatsSettingsOrder>>
  trackingMode?: InputMaybe<TrackingMode>
  unitSystem?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateShipmentArgs = {
  deliveredAt?: InputMaybe<Scalars['ISO8601DateTime']>
  id?: InputMaybe<Scalars['Int']>
  provider?: InputMaybe<Scalars['String']>
  providerReceivedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  providerShipmentId?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Int']>
  shippedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  status?: InputMaybe<Scalars['String']>
  trackingNumber?: InputMaybe<Scalars['String']>
  trackingUrl?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateTicketArgs = {
  assignedToId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  externalLink?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  priority?: InputMaybe<TicketPriority>
  status?: InputMaybe<TicketStatus>
}

export type IMutationTypeUpdateUserArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  sex?: InputMaybe<Scalars['String']>
  truepillPatientToken?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpdateUserApprovalStatusArgs = {
  approvalStatus?: InputMaybe<UpdateUserApprovalStatuses>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<UserApprovalRequestKind>
}

export type IMutationTypeUpdateUserOrganizationArgs = {
  id: Scalars['ID']
  organizationId: Scalars['Int']
}

export type IMutationTypeUpdateUserPropertyArgs = {
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
  value?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpsertCoachProfileArgs = {
  bio?: InputMaybe<Scalars['String']>
  credentials?: InputMaybe<Scalars['String']>
  outOfOffice?: InputMaybe<Scalars['Boolean']>
  stateLicences?: InputMaybe<Array<Scalars['String']>>
}

export type IMutationTypeUpsertCourseCohortSessionAttendanceArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sessionId?: InputMaybe<Scalars['ID']>
  state?: InputMaybe<CoursesCohortSessionAttendanceState>
  userId?: InputMaybe<Scalars['ID']>
}

export type IMutationTypeUpsertDailyActionArgs = {
  kind?: InputMaybe<UserDailyActionKind>
  status?: InputMaybe<UserDailyActionStatus>
}

export type IMutationTypeUpsertMealArgs = {
  description?: InputMaybe<Scalars['String']>
  favorite?: InputMaybe<Scalars['Boolean']>
  favoriteId?: InputMaybe<Scalars['ID']>
  id?: InputMaybe<Scalars['ID']>
  imageUrl?: InputMaybe<Scalars['String']>
  ingredients?: InputMaybe<Array<IIngredientData>>
  kind?: InputMaybe<MealKind>
  photo?: InputMaybe<Scalars['Upload']>
  tags?: InputMaybe<Array<Scalars['String']>>
  time?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpsertNoteArgs = {
  body?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  pinned?: InputMaybe<Scalars['Boolean']>
  time?: InputMaybe<Scalars['String']>
}

export type IMutationTypeUpsertPrimaryGoalArgs = {
  description?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<UserGoalKind>
}

export type INote = {
  __typename?: 'Note'
  body: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  createdBy: Scalars['String']
  creator: IUser
  /** @deprecated Use creator instead */
  creatorId: Scalars['ID']
  id: Scalars['ID']
  kind: NoteKind
  occurredAt: Scalars['ISO8601DateTime']
  pinnedAt?: Maybe<Scalars['ISO8601DateTime']>
  title: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export type INoteDynamicFilter = {
  field: NoteDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum NoteDynamicFilterField {
  Kind = 'kind'
}

export type INoteDynamicFilterItem = {
  items: Array<INoteDynamicFilter>
  operator: DynamicFilterOperator
}

export enum NoteKind {
  DietitianNote = 'dietitian_note',
  DietitianUpNext = 'dietitian_up_next',
  GlucoseChart = 'glucose_chart',
  MemberNote = 'member_note',
  OpsAccountRequest = 'ops_account_request'
}

export type INoteSort = {
  direction: SortDirection
  field: NoteSortField
}

export enum NoteSortField {
  Id = 'id',
  Kind = 'kind',
  OccurredAt = 'occurred_at'
}

export enum NotificationEngineReminderFrequency {
  Bimonthly = 'bimonthly',
  Daily = 'daily',
  HoursInterval = 'hours_interval',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Weekly = 'weekly'
}

export enum NotificationEngineReminderKind {
  Breakfast = 'breakfast',
  Dinner = 'dinner',
  Exercise = 'exercise',
  Fasting = 'fasting',
  GlucoseCheck = 'glucose_check',
  Journal = 'journal',
  Lunch = 'lunch',
  Meditation = 'meditation',
  OtherMeal = 'other_meal',
  Sleep = 'sleep',
  SubmitLabResults = 'submit_lab_results',
  Weight = 'weight'
}

export type INumericPair = {
  __typename?: 'NumericPair'
  x: Scalars['Float']
  y?: Maybe<Scalars['Float']>
}

export type INumericalSelect = {
  __typename?: 'NumericalSelect'
  text: Scalars['String']
  value: Scalars['Float']
}

export type INutritionCard = {
  __typename?: 'NutritionCard'
  nutrition: INutritionStatistics
  score: IGlucoseDailyScore
  statistics: IGlucoseStatistics
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type INutritionStatistics = {
  __typename?: 'NutritionStatistics'
  today: Array<IKeyValuePairFloat>
}

export type IOnboarding = {
  __typename?: 'Onboarding'
  tutorials: Array<ITutorials>
}

export type IOneOffAddonRequestPayload = {
  discountCentsAmount?: InputMaybe<Scalars['Int']>
  paid: Scalars['Boolean']
  product: BillingProduct
}

export type IOneOffAddonResponsePayload = {
  __typename?: 'OneOffAddonResponsePayload'
  discountCentsAmount?: Maybe<Scalars['Int']>
  paid: Scalars['Boolean']
  product: BillingProduct
}

export enum OpsAccountRequestKind {
  CancelSubscription = 'cancel_subscription',
  ChangeSubscriptionBillingCycle = 'change_subscription_billing_cycle',
  Credit = 'credit',
  DeleteDuplicateAccount = 'delete_duplicate_account',
  MiscRequest = 'misc_request',
  OneOffAddon = 'one_off_addon',
  PauseSubscription = 'pause_subscription',
  Refund = 'refund',
  UncancelSubscription = 'uncancel_subscription',
  UnpauseSubscription = 'unpause_subscription',
  UpdateSubscription = 'update_subscription',
  UpsertLimitedSupport = 'upsert_limited_support'
}

export enum OpsAccountRequestKindsCancelSubscriptionReason {
  AppAnalytics = 'app_analytics',
  AppComplexity = 'app_complexity',
  AppDietitianCommunication = 'app_dietitian_communication',
  CgmAccuracy = 'cgm_accuracy',
  CgmCgmUncomfortable = 'cgm_cgm_uncomfortable',
  CgmScanningFrequency = 'cgm_scanning_frequency',
  CgmSkinSensitivity = 'cgm_skin_sensitivity',
  CgmTroubleshooting = 'cgm_troubleshooting',
  CompetitorStelo = 'competitor_stelo',
  HealthDoctorSuggestionToCancel = 'health_doctor_suggestion_to_cancel',
  HealthEmergency = 'health_emergency',
  HealthInsulin = 'health_insulin',
  HealthMetHealthGoals = 'health_met_health_goals',
  HealthPregnancy = 'health_pregnancy',
  HealthSurgery = 'health_surgery',
  PriceDispute = 'price_dispute',
  PriceGotInsuranceToCover = 'price_got_insurance_to_cover',
  PricePersonalFinances = 'price_personal_finances',
  PriceTooExpensive = 'price_too_expensive',
  RejectedRejected = 'rejected_rejected',
  ShippingDelay = 'shipping_delay',
  ShippingGapBetweenSensors = 'shipping_gap_between_sensors',
  SubscriptionChangedMind = 'subscription_changed_mind',
  SubscriptionEndOfSubscription = 'subscription_end_of_subscription',
  SubscriptionTooManySensors = 'subscription_too_many_sensors',
  SubscriptionTooMuchOfACommitment = 'subscription_too_much_of_a_commitment'
}

export enum OpsAccountRequestKindsPauseSubscriptionReason {
  MedicalProcedure = 'medical_procedure',
  NeedBreak = 'need_break',
  ProactiveSnooze = 'proactive_snooze',
  TooManySensors = 'too_many_sensors',
  Vacation = 'vacation'
}

export type IOpsAccountRequestKindsPauseSubscriptionReasonDef = {
  __typename?: 'OpsAccountRequestKindsPauseSubscriptionReasonDef'
  copy: Scalars['String']
  key: OpsAccountRequestKindsPauseSubscriptionReason
}

export enum OpsAccountRequestKindsRefundReason {
  BnplAppFunctionality = 'bnpl_app_functionality',
  BnplCgmFunctionality = 'bnpl_cgm_functionality',
  BnplFinancialFinancialChallanges = 'bnpl_financial_financial_challanges',
  BnplNoHqBuyersRemorse = 'bnpl_no_hq_buyers_remorse',
  BnplNoHqChallengedByProcess = 'bnpl_no_hq_challenged_by_process',
  BnplRejectedRejected = 'bnpl_rejected_rejected',
  ConclusionCancellingAutoRenewalDefinitionOfNoCommitment = 'conclusion_cancelling_auto_renewal_definition_of_no_commitment',
  ConclusionCancellingAutoRenewalFinishedCommitment = 'conclusion_cancelling_auto_renewal_finished_commitment',
  ConclusionEarlyCancellationPolicyCancellationFee = 'conclusion_early_cancellation_policy_cancellation_fee',
  ConclusionEarlyCancellationPolicyDiscountClawback = 'conclusion_early_cancellation_policy_discount_clawback',
  MembershipAppFunctionality = 'membership_app_functionality',
  MembershipCgmFunctionality = 'membership_cgm_functionality',
  MembershipCgmMismatchOnMakeOrModel = 'membership_cgm_mismatch_on_make_or_model',
  MembershipCgmUnmetExpectations = 'membership_cgm_unmet_expectations',
  MembershipDiscountMissedAddingCoupon = 'membership_discount_missed_adding_coupon',
  MembershipFinancialFinancialChallenges = 'membership_financial_financial_challenges',
  MembershipForgettingUnpauseForgettingUnpause = 'membership_forgetting_unpause_forgetting_unpause',
  MembershipHealthHealthChallenges = 'membership_health_health_challenges',
  MembershipLogisticsCgm = 'membership_logistics_cgm',
  MembershipLogisticsPatches = 'membership_logistics_patches',
  MembershipNnSupportNutritionistMatching = 'membership_nn_support_nutritionist_matching',
  MembershipNnSupportServiceLevel = 'membership_nn_support_service_level',
  MembershipUnhappyWithCgmFunctional = 'membership_unhappy_with_cgm_functional',
  MembershipUnhappyWithCgmMismatchOnMakeOrModel = 'membership_unhappy_with_cgm_mismatch_on_make_or_model',
  MembershipUnhappyWithCgmMismatchedExpectationOnMonitoring = 'membership_unhappy_with_cgm_mismatched_expectation_on_monitoring',
  MembershipUnsatisfactoryNnSupportMisinformation = 'membership_unsatisfactory_nn_support_misinformation',
  MembershipUnsatisfactoryNnSupportNutritionistMatching = 'membership_unsatisfactory_nn_support_nutritionist_matching',
  MembershipUnsatisfactoryNnSupportServiceLevel = 'membership_unsatisfactory_nn_support_service_level',
  NsErrorAgent = 'ns_error_agent',
  NsErrorBug = 'ns_error_bug',
  NsErrorMarketing = 'ns_error_marketing',
  NsGiftCardRemorse = 'ns_gift_card_remorse',
  NsNnVideoChangedMind = 'ns_nn_video_changed_mind',
  NsOutOfStockOutOfStock = 'ns_out_of_stock_out_of_stock',
  NsTestTest = 'ns_test_test',
  SignUpChangedMindBuyersRemorse = 'sign_up_changed_mind_buyers_remorse',
  SignUpChangedMindDidNotKnowWasMonthly = 'sign_up_changed_mind_did_not_know_was_monthly',
  SignUpConfussionMistakeChild = 'sign_up_confussion_mistake_child',
  SignUpConfussionProgramDetails = 'sign_up_confussion_program_details',
  SignUpDiscountMissedAddingCoupon = 'sign_up_discount_missed_adding_coupon',
  SignUpDuplicateAccountsDuplicateAccounts = 'sign_up_duplicate_accounts_duplicate_accounts',
  SignUpFinancialChallengesFinancialChallenges = 'sign_up_financial_challenges_financial_challenges',
  SignUpGovernmentIdGovernmentId = 'sign_up_government_id_government_id',
  SignUpHealthChallengesHealthChallenges = 'sign_up_health_challenges_health_challenges',
  SignUpMispurchaseChild = 'sign_up_mispurchase_child',
  SignUpMispurchaseConfusion = 'sign_up_mispurchase_confusion',
  SignUpMispurchaseLackOfInformation = 'sign_up_mispurchase_lack_of_information',
  SignUpNoHqBuyersRemorse = 'sign_up_no_hq_buyers_remorse',
  SignUpNoHqChallengedByProcess = 'sign_up_no_hq_challenged_by_process',
  SignUpNoHqDontKnowNeedToFillItOut = 'sign_up_no_hq_dont_know_need_to_fill_it_out',
  SignUpNoHqFinancialChallanges = 'sign_up_no_hq_financial_challanges',
  SignUpNoHqForgets = 'sign_up_no_hq_forgets',
  SignUpNoHqLinkDoesntWork = 'sign_up_no_hq_link_doesnt_work',
  SignUpNoHqRefusedToFillOut = 'sign_up_no_hq_refused_to_fill_out',
  SignUpRejectedRejected = 'sign_up_rejected_rejected',
  SignUpTechIssuesTechIssues = 'sign_up_tech_issues_tech_issues',
  SignUpUserExperienceUserExperience = 'sign_up_user_experience_user_experience'
}

export type IOpsAccountRequestKindsRefundReasonDef = {
  __typename?: 'OpsAccountRequestKindsRefundReasonDef'
  description: Scalars['String']
  journey: Scalars['String']
  key: OpsAccountRequestKindsRefundReason
  reasonOne: Scalars['String']
  reasonTwo: Scalars['String']
}

export enum OpsAccountRequestPriority {
  High = 'high',
  Regular = 'regular'
}

export enum OpsAccountRequestStatus {
  Escalated = 'escalated',
  Failed = 'failed',
  Processed = 'processed',
  Processing = 'processing',
  Rejected = 'rejected',
  Reviewed = 'reviewed'
}

export type IOrder = {
  __typename?: 'Order'
  address?: Maybe<IAddress>
  addressId: Scalars['Int']
  createdAt: Scalars['ISO8601DateTime']
  email: Scalars['String']
  id: Scalars['Int']
  includable: ShippingOrderIncludable
  invoice?: Maybe<IInvoice>
  notes?: Maybe<Scalars['String']>
  product: Scalars['String']
  productType: ShippingOrderProductType
  provider?: Maybe<ShippingOrderProvider>
  quantity: Scalars['Int']
  replacementRequest?: Maybe<IReplacementRequest>
  sensorKind?: Maybe<UserSensorKind>
  updatedAt: Scalars['ISO8601DateTime']
  user?: Maybe<IUser>
  userId: Scalars['Int']
}

export type IOrderDynamicFilter = {
  field: OrderDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum OrderDynamicFilterField {
  AddressCity = 'address_city',
  AddressCountry = 'address_country',
  AddressName = 'address_name',
  AddressState = 'address_state',
  AddressStreet = 'address_street',
  AddressStreet_2 = 'address_street_2',
  AddressZipCode = 'address_zip_code',
  CreatedAt = 'created_at',
  Email = 'email',
  Id = 'id',
  Includable = 'includable',
  InvoiceStripeId = 'invoice_stripe_id',
  Notes = 'notes',
  Product = 'product',
  ProductType = 'product_type',
  Provider = 'provider',
  Quantity = 'quantity',
  ReplacementRequestReplacementReason = 'replacement_request_replacement_reason',
  SensorKind = 'sensor_kind',
  UpdatedAt = 'updated_at',
  UserDateOfBirth = 'user_date_of_birth',
  UserEmail = 'user_email',
  UserFirstName = 'user_first_name',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserLastName = 'user_last_name',
  UserPhoneNumber = 'user_phone_number',
  UserSex = 'user_sex'
}

export type IOrderDynamicFilterItem = {
  items: Array<IOrderDynamicFilter>
  operator: DynamicFilterOperator
}

export type IOrderSort = {
  direction: SortDirection
  field: OrderSortField
}

export enum OrderSortField {
  AddressCity = 'address_city',
  AddressCountry = 'address_country',
  AddressName = 'address_name',
  AddressState = 'address_state',
  AddressStreet = 'address_street',
  AddressStreet_2 = 'address_street_2',
  AddressZipCode = 'address_zip_code',
  CreatedAt = 'created_at',
  Email = 'email',
  Id = 'id',
  Includable = 'includable',
  InvoiceStripeId = 'invoice_stripe_id',
  Notes = 'notes',
  Product = 'product',
  ProductType = 'product_type',
  Provider = 'provider',
  Quantity = 'quantity',
  ReplacementRequestReplacementReason = 'replacement_request_replacement_reason',
  SensorKind = 'sensor_kind',
  UpdatedAt = 'updated_at',
  UserDateOfBirth = 'user_date_of_birth',
  UserEmail = 'user_email',
  UserFirstName = 'user_first_name',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserLastName = 'user_last_name',
  UserPhoneNumber = 'user_phone_number',
  UserSex = 'user_sex'
}

export type IOrganization = {
  __typename?: 'Organization'
  id: Scalars['Int']
  isNutrisense: Scalars['Boolean']
  name: Scalars['String']
}

export type IOrganizationsCollection = {
  __typename?: 'OrganizationsCollection'
  organizations: Array<IOrganization>
}

export type IOutcome = {
  __typename?: 'Outcome'
  id: Scalars['ID']
  key: Scalars['String']
  reason: IReason
}

export type IPagination = {
  endTime?: InputMaybe<Scalars['ISO8601DateTime']>
  first?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  startTime?: InputMaybe<Scalars['ISO8601DateTime']>
  type?: InputMaybe<Scalars['String']>
}

export type IPauseSubscriptionRequestPayload = {
  cycleCount: Scalars['Int']
  reason: OpsAccountRequestKindsPauseSubscriptionReason
  subscriptionId: Scalars['ID']
}

export type IPauseSubscriptionResponsePayload = {
  __typename?: 'PauseSubscriptionResponsePayload'
  cycleCount: Scalars['Int']
  reason: OpsAccountRequestKindsPauseSubscriptionReason
  subscriptionId: Scalars['ID']
}

export type IPaymentMethod = {
  __typename?: 'PaymentMethod'
  cardType: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  expirationDate: Scalars['ISO8601Date']
  last4: Scalars['String']
  stripeId: Scalars['String']
}

export type IPlan = {
  __typename?: 'Plan'
  amount: Scalars['Float']
  id: Scalars['ID']
  nickname: Scalars['String']
  product: IProductMin
  stripeId: Scalars['String']
}

export type IPrivateSurveyLinkCollection = {
  __typename?: 'PrivateSurveyLinkCollection'
  pageCount?: Maybe<Scalars['Int']>
  surveyLinks: Array<ISurveyLink>
  totalCount: Scalars['Int']
}

export type IPrivateUserCollection = {
  __typename?: 'PrivateUserCollection'
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
  users: Array<IUser>
}

export type IProduct = {
  __typename?: 'Product'
  addon: Scalars['Boolean']
  billingDateChangeable: Scalars['Boolean']
  commitmentMonths?: Maybe<Scalars['Int']>
  core: Scalars['Boolean']
  description?: Maybe<Scalars['String']>
  details?: Maybe<Array<Scalars['String']>>
  dietitian: Scalars['Boolean']
  dietitianMenu: Scalars['Boolean']
  dietitianTrialDays?: Maybe<Scalars['Int']>
  eligibilityListItems: Array<Scalars['String']>
  eligibleUpgradeProducts: Array<IProductMin>
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  includedOptions?: Maybe<Array<Scalars['String']>>
  includesCgm: Scalars['Boolean']
  incompatibleAddons: Array<IProductMin>
  insuranceCoverage: Scalars['Boolean']
  interval: IInterval
  key: Scalars['String']
  order: Scalars['Int']
  /** @deprecated Use price instead */
  plan: IProductPlan
  price: Scalars['Float']
  productGroup?: Maybe<IProductGroup>
  programsName?: Maybe<Scalars['String']>
  recurring: Scalars['Boolean']
  requiresShipping: Scalars['Boolean']
  schedulingRule: ISchedulingRule
  shortDescription?: Maybe<Scalars['String']>
  title: Scalars['String']
  trialDays?: Maybe<Scalars['Int']>
  uncancelable: Scalars['Boolean']
}

export type IProductCollection = {
  __typename?: 'ProductCollection'
  products: Array<IProduct>
}

export type IProductGroup = {
  __typename?: 'ProductGroup'
  description: Scalars['String']
  details: Array<Scalars['String']>
  id: Scalars['ID']
  interval: IInterval
  key: Scalars['String']
  kind?: Maybe<BillingProductGroupKind>
  order: Scalars['Int']
  shortDescription: Scalars['String']
  title: Scalars['String']
}

export type IProductMin = {
  __typename?: 'ProductMin'
  details?: Maybe<Array<Scalars['String']>>
  eligibleUpgradeProducts: Array<IProductMin>
  id: Scalars['ID']
  includesCgm: Scalars['Boolean']
  incompatibleAddons: Array<IProductMin>
  interval: IInterval
  key: Scalars['String']
  requiresShipping: Scalars['Boolean']
  title: Scalars['String']
  uncancelable: Scalars['Boolean']
}

export type IProductPlan = {
  __typename?: 'ProductPlan'
  amount: Scalars['Int']
  id: Scalars['ID']
  stripeId: Scalars['String']
}

export type IProgramCollection = {
  __typename?: 'ProgramCollection'
  programs: Array<IExpandableProgram>
}

export type IProgress = {
  __typename?: 'Progress'
  complete: Scalars['Boolean']
}

export type IQueryType = {
  __typename?: 'QueryType'
  allAccountRequests: IAccountRequestCollection
  allAppointments?: Maybe<IAppointmentCollection>
  allApprovalRequests: IApprovalRequestCollection
  allCharges: IChargeCollection
  allCharts: ICharts
  allChatConversationEvents: IChatConversationEventsCollection
  allChatConversationMessageTemplateVariables: IChatConversationMessageTemplateVariableCollection
  allChatConversationMessageTemplates: IConversationMessageTemplateCollection
  allChatConversations: IChatConversationsCollection
  allChatbotConversations: IListConversations
  allCoachAssignments: ICoachAssignmentsCollection
  allCoachConversationScheduledMessages: ICoachConversationScheduledMessageCollection
  allCourseCohortSessionMembers: ICohortSessionMemberCollection
  allCourseCohortSessions: ICohortSessionCollection
  allCourseCohorts: ICohortCollection
  allCourses: IProgramCollection
  allDppInsuranceParticipants: IDppInsuranceParticipantCollection
  allDppInsuranceSessions: IDppInsuranceSessionCollection
  allDppUsers: IDppUserCollection
  allEvents: IEventsData
  allFiles: IListFiles
  allHealthPortalNotifications: IHealthPortalNotificationCollection
  allInvoiceOrders: IListInvoiceOrders
  allInvoices: IInvoiceCollection
  allKnowledgeAnswers: IKnowledgeAnswerCollection
  allKnowledgeQuestions: IKnowledgeQuestionCollection
  allKnowledgeSources: IKnowledgeSourceCollection
  allKnowledgeTags: IKnowledgeTagCollection
  allMeasurements: IMeasurementCollection
  allNotes: IListNotes
  allNutrition: INutritionCard
  allOrders: IListOrders
  allOrganizations: IOrganizationsCollection
  allReplacementRequests: IReplacementRequestCollection
  allScans: IMeasurementCollection
  allSensors: ISensorCollection
  allShipments: IListShipments
  allStats: IStats
  allSubscriptions: ISubscriptionCollection
  allSurveyLinks: IPrivateSurveyLinkCollection
  allTicketComments: ITicketCommentCollection
  allTickets: ITicketCollection
  allUserProperties: IUserPropertiesCollection
  allUsers: IPrivateUserCollection
  allUsersAutocomplete: IUserCollection
  appointment: IHealthPortalAppointment
  authorizedFeatures: IHealthPortalFeatureCollection
  calculateDuplicateAccountCredit: ICalculateDuplicateAccountCredit
  calendar: IUserCalendar
  chatConversation: IChatConversation
  chatbotMessage: IMessage
  clientConfig: IClientConfig
  cohortSuggestedSessions: ICohortSessionSuggestionCollection
  courseCohort: ICohort
  courseCohortSession: ICohortSession
  currentAdmin?: Maybe<IUser>
  currentUser?: Maybe<IUser>
  distinctUserPropertyValues: Array<Scalars['String']>
  eligibleProducts: IProductCollection
  eligibleUpdateProducts: IEligibleUpdateProducts
  healthMetrics: IHealthMetrics
  listUserDailyActions: IDailyActionCollection
  nextNutritionistAppointment?: Maybe<IAppointment>
  nextUserAppointment?: Maybe<IAppointment>
  order: IOrder
  previewCoachConversationMessage: ICoachConversationMessagePreview
  resetPasswordRequest: ISuccess
  showAccountRequestsConfig: IAccountRequestsConfig
  user: IUser
  userInsurancePolicy: IInsurancePolicy
  userSurveyLinks: ISurveyLinkCollection
}

export type IQueryTypeAllAccountRequestsArgs = {
  dynamicFilters?: InputMaybe<Array<IAccountRequestDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IAccountRequestSort>>
  status?: InputMaybe<OpsAccountRequestStatus>
}

export type IQueryTypeAllAppointmentsArgs = {
  dynamicFilters?: InputMaybe<Array<IAppointmentDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IAppointmentSort>>
}

export type IQueryTypeAllApprovalRequestsArgs = {
  dynamicFilters?: InputMaybe<Array<IApprovalRequestDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IApprovalRequestSort>>
}

export type IQueryTypeAllChargesArgs = {
  dynamicFilters?: InputMaybe<Array<IChargeDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IChargeSort>>
}

export type IQueryTypeAllChartsArgs = {
  filter?: InputMaybe<IDateFilter>
}

export type IQueryTypeAllChatConversationEventsArgs = {
  conversationId?: InputMaybe<Scalars['ID']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IChatConversationEventSort>>
}

export type IQueryTypeAllChatConversationMessageTemplatesArgs = {
  excludeAutomatic?: InputMaybe<Scalars['Boolean']>
  kinds?: InputMaybe<Array<ChatConversationMessageTemplateKind>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type IQueryTypeAllChatConversationsArgs = {
  active?: InputMaybe<Scalars['Boolean']>
  forCoach?: InputMaybe<Scalars['Boolean']>
  kinds?: InputMaybe<Array<ChatConversationKind>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IChatConversationSort>>
  states?: InputMaybe<Array<ChatConversationState>>
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

export type IQueryTypeAllCoachAssignmentsArgs = {
  dynamicFilters?: InputMaybe<Array<ICoachAssignmentDynamicFilterItem>>
  last?: InputMaybe<Scalars['Boolean']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ICoachAssignmentSort>>
}

export type IQueryTypeAllCoachConversationScheduledMessagesArgs = {
  kinds?: InputMaybe<Array<ChatConversationMessageTemplateKind>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ICoachConversationScheduledMessageSort>>
  states?: InputMaybe<Array<CoachesConversationScheduledMessageState>>
  userId?: InputMaybe<Scalars['ID']>
}

export type IQueryTypeAllCourseCohortSessionMembersArgs = {
  sessionId: Scalars['ID']
}

export type IQueryTypeAllCourseCohortSessionsArgs = {
  cohortId?: InputMaybe<Scalars['ID']>
  dynamicFilters?: InputMaybe<Array<ICohortSessionDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ICohortSessionSort>>
}

export type IQueryTypeAllCourseCohortsArgs = {
  dynamicFilters?: InputMaybe<Array<ICohortDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ICohortSort>>
}

export type IQueryTypeAllDppInsuranceParticipantsArgs = {
  dynamicFilters?: InputMaybe<Array<IDppInsuranceParticipantDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IDppInsuranceParticipantSort>>
}

export type IQueryTypeAllDppInsuranceSessionsArgs = {
  dynamicFilters?: InputMaybe<Array<IDppInsuranceSessionDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IDppInsuranceSessionSort>>
}

export type IQueryTypeAllDppUsersArgs = {
  dynamicFilters?: InputMaybe<Array<IDppUserDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IDppUserSort>>
}

export type IQueryTypeAllEventsArgs = {
  filter?: InputMaybe<IDateFilter>
  pagination?: InputMaybe<IPagination>
}

export type IQueryTypeAllFilesArgs = {
  dynamicFilters?: InputMaybe<Array<IFileDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IFileSort>>
}

export type IQueryTypeAllHealthPortalNotificationsArgs = {
  completed?: InputMaybe<Scalars['Boolean']>
  completedLastMonth?: InputMaybe<Scalars['Boolean']>
  forReview?: InputMaybe<Scalars['Boolean']>
  kinds?: InputMaybe<Array<HealthPortalNotificationKind>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IHealthPortalNotificationsSort>>
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

export type IQueryTypeAllInvoiceOrdersArgs = {
  dynamicFilters?: InputMaybe<Array<IInvoiceOrderDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IInvoiceOrderSort>>
}

export type IQueryTypeAllInvoicesArgs = {
  dynamicFilters?: InputMaybe<Array<IInvoiceDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IInvoiceSort>>
}

export type IQueryTypeAllKnowledgeAnswersArgs = {
  dynamicFilters?: InputMaybe<Array<IKnowledgeAnswerDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeAnswerSort>>
}

export type IQueryTypeAllKnowledgeQuestionsArgs = {
  dynamicFilters?: InputMaybe<Array<IKnowledgeQuestionDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeQuestionSort>>
}

export type IQueryTypeAllKnowledgeSourcesArgs = {
  dynamicFilters?: InputMaybe<Array<IKnowledgeSourceDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeSourceSort>>
}

export type IQueryTypeAllKnowledgeTagsArgs = {
  dynamicFilters?: InputMaybe<Array<IKnowledgeTagDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeTagSort>>
}

export type IQueryTypeAllMeasurementsArgs = {
  filter?: InputMaybe<IMeasurementFilter>
  pagination?: InputMaybe<IPagination>
}

export type IQueryTypeAllNotesArgs = {
  dynamicFilters?: InputMaybe<Array<INoteDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<INoteSort>>
  userId?: InputMaybe<Scalars['ID']>
}

export type IQueryTypeAllNutritionArgs = {
  filter?: InputMaybe<IDateFilter>
}

export type IQueryTypeAllOrdersArgs = {
  dynamicFilters?: InputMaybe<Array<IOrderDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IOrderSort>>
}

export type IQueryTypeAllOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

export type IQueryTypeAllReplacementRequestsArgs = {
  dynamicFilters?: InputMaybe<Array<IReplacementRequestDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IReplacementRequestSort>>
}

export type IQueryTypeAllScansArgs = {
  filter?: InputMaybe<IMeasurementFilter>
  pagination?: InputMaybe<IPagination>
}

export type IQueryTypeAllShipmentsArgs = {
  dynamicFilters?: InputMaybe<Array<IShipmentDynamicFilterItem>>
  orderId?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IShipmentSort>>
}

export type IQueryTypeAllStatsArgs = {
  filter?: InputMaybe<IDateFilter>
}

export type IQueryTypeAllSubscriptionsArgs = {
  dynamicFilters?: InputMaybe<Array<ISubscriptionDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ISubscriptionSort>>
}

export type IQueryTypeAllSurveyLinksArgs = {
  dynamicFilters?: InputMaybe<Array<ISurveyLinkDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ISurveyLinkSort>>
}

export type IQueryTypeAllTicketCommentsArgs = {
  dynamicFilters?: InputMaybe<Array<ITicketCommentDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ITicketCommentSort>>
  ticketId?: InputMaybe<Scalars['ID']>
}

export type IQueryTypeAllTicketsArgs = {
  dynamicFilters?: InputMaybe<Array<ITicketDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ITicketSort>>
}

export type IQueryTypeAllUsersArgs = {
  dynamicFilters?: InputMaybe<Array<IUserDynamicFilterItem>>
  forHqApproval?: InputMaybe<Scalars['Boolean']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IUserSort>>
}

export type IQueryTypeAllUsersAutocompleteArgs = {
  limit?: InputMaybe<Scalars['Int']>
  query: Scalars['String']
}

export type IQueryTypeAppointmentArgs = {
  id?: InputMaybe<Scalars['ID']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type IQueryTypeCalculateDuplicateAccountCreditArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

export type IQueryTypeCalendarArgs = {
  filter?: InputMaybe<IDateFilter>
}

export type IQueryTypeChatConversationArgs = {
  id: Scalars['ID']
}

export type IQueryTypeChatbotMessageArgs = {
  id: Scalars['ID']
}

export type IQueryTypeCohortSuggestedSessionsArgs = {
  kind: CoursesCohortKind
  startTime: Scalars['ISO8601DateTime']
}

export type IQueryTypeCourseCohortArgs = {
  id: Scalars['ID']
}

export type IQueryTypeCourseCohortSessionArgs = {
  id: Scalars['ID']
}

export type IQueryTypeDistinctUserPropertyValuesArgs = {
  kind: Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
  value: Scalars['String']
}

export type IQueryTypeEligibleProductsArgs = {
  products?: InputMaybe<Array<BillingProduct>>
  userId?: InputMaybe<Scalars['ID']>
}

export type IQueryTypeEligibleUpdateProductsArgs = {
  userId: Scalars['ID']
}

export type IQueryTypeListUserDailyActionsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  status?: InputMaybe<UserDailyActionStatus>
}

export type IQueryTypeNextUserAppointmentArgs = {
  id: Scalars['ID']
}

export type IQueryTypeOrderArgs = {
  id?: InputMaybe<Scalars['Int']>
}

export type IQueryTypePreviewCoachConversationMessageArgs = {
  messageTemplateId?: InputMaybe<Scalars['ID']>
  userId?: InputMaybe<Scalars['ID']>
}

export type IQueryTypeResetPasswordRequestArgs = {
  email?: InputMaybe<Scalars['String']>
}

export type IQueryTypeUserArgs = {
  id: Scalars['ID']
}

export type IQueryTypeUserInsurancePolicyArgs = {
  userId: Scalars['ID']
}

export type IQuestion = {
  __typename?: 'Question'
  imageUrl?: Maybe<Scalars['String']>
  key: SurveysQuestionKey
  label?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  required: Scalars['Boolean']
  response: IResponse
}

export type IQuestionsGroup = {
  __typename?: 'QuestionsGroup'
  banner?: Maybe<Scalars['String']>
  introScreen?: Maybe<IIntroScreen>
  questions: Array<IQuestion>
}

export type IRange = {
  __typename?: 'Range'
  max: Scalars['Float']
  min: Scalars['Float']
}

export type IRangePair = {
  __typename?: 'RangePair'
  x: IRange
  y: Scalars['Float']
}

export type IRangeUnit = {
  __typename?: 'RangeUnit'
  from?: Maybe<Scalars['Float']>
  rating: Scalars['String']
  to?: Maybe<Scalars['Float']>
  type: Scalars['String']
}

export type IReason = {
  __typename?: 'Reason'
  headline: Scalars['String']
  inlineAlert?: Maybe<IInlineAlert>
  message: IReasonMessage
  notes: Array<Scalars['String']>
}

export type IReasonMessage = {
  __typename?: 'ReasonMessage'
  content: Scalars['String']
  links?: Maybe<Array<IReasonMessageLink>>
}

export type IReasonMessageLink = {
  __typename?: 'ReasonMessageLink'
  placeholder: Scalars['String']
  url: Scalars['String']
}

export type IReferral = {
  __typename?: 'Referral'
  credits: Scalars['Int']
  description: Scalars['String']
  discount: Scalars['Int']
  message: Scalars['String']
  title: Scalars['String']
}

export type IRefundRequestPayload = {
  amountInCents: Scalars['Int']
  chargeId: Scalars['String']
  refundReason: OpsAccountRequestKindsRefundReason
  subscriptionCancellationReason?: InputMaybe<OpsAccountRequestKindsCancelSubscriptionReason>
  subscriptionCancellationRequired: Scalars['Boolean']
}

export type IRefundResponsePayload = {
  __typename?: 'RefundResponsePayload'
  amountInCents: Scalars['Int']
  chargeId: Scalars['ID']
  refundReason?: Maybe<IOpsAccountRequestKindsRefundReasonDef>
  subscriptionCancellationReason?: Maybe<OpsAccountRequestKindsCancelSubscriptionReason>
  subscriptionCancellationRequired: Scalars['Boolean']
}

export type IReminderFrequency = {
  __typename?: 'ReminderFrequency'
  key: NotificationEngineReminderFrequency
}

export type IReminderKind = {
  __typename?: 'ReminderKind'
  allowedFrequencies: Array<IReminderFrequency>
  icon: Scalars['String']
  key: NotificationEngineReminderKind
  title: Scalars['String']
}

export type IRemindersSettings = {
  __typename?: 'RemindersSettings'
  hoursInterval: IHoursInterval
}

export type IReplacementRequest = {
  __typename?: 'ReplacementRequest'
  chargeAmount: ReplacementRequestChargeAmount
  createdAt: Scalars['ISO8601DateTime']
  createdBy?: Maybe<IUser>
  createdById?: Maybe<Scalars['ID']>
  dateFellOff?: Maybe<Scalars['String']>
  dateNotified?: Maybe<Scalars['String']>
  daysWorn?: Maybe<Scalars['String']>
  errorType: ReplacementRequestErrorType
  howItFellOff: Scalars['String']
  id: Scalars['ID']
  invoice?: Maybe<IInvoice>
  notes: Scalars['String']
  providerCompensation: ReplacementRequestProviderCompensation
  replacementReason: ReplacementRequestReplacementReason
  sensor?: Maybe<ISensor>
  /** @deprecated Use sensor field instead */
  serialNumber?: Maybe<Scalars['String']>
  state: ReplacementRequestState
  testedWithLibreApp?: Maybe<ReplacementRequestTestedWithLibreApp>
  user: IUser
  userId: Scalars['ID']
  wearingPatch: ReplacementRequestWearingPatch
}

export enum ReplacementRequestChargeAmount {
  FreeReplacement_1Cgm = 'free_replacement_1_cgm',
  FreeReplacement_2Cgm = 'free_replacement_2_cgm',
  PaidReship_1Cgm = 'paid_reship_1_cgm',
  PaidReship_2Cgm = 'paid_reship_2_cgm',
  PaidReship_3Cgm = 'paid_reship_3_cgm',
  ReplacementFee_1Cgm = 'replacement_fee_1_cgm',
  ReplacementFee_2Cgm = 'replacement_fee_2_cgm',
  Reship_1Cgm = 'reship_1_cgm',
  Reship_2Cgm = 'reship_2_cgm',
  Reship_3Cgm = 'reship_3_cgm'
}

export type IReplacementRequestCollection = {
  __typename?: 'ReplacementRequestCollection'
  pageCount?: Maybe<Scalars['Int']>
  replacementRequests: Array<IReplacementRequest>
  totalCount: Scalars['Int']
}

export type IReplacementRequestDynamicFilter = {
  field: ReplacementRequestDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum ReplacementRequestDynamicFilterField {
  ChargeAmount = 'charge_amount',
  CreatedAt = 'created_at',
  CreatedByEmail = 'created_by_email',
  CreatedById = 'created_by_id',
  DateFellOff = 'date_fell_off',
  DateNotified = 'date_notified',
  DaysWorn = 'days_worn',
  ErrorType = 'error_type',
  HowItFellOff = 'how_it_fell_off',
  Id = 'id',
  InvoiceStripeId = 'invoice_stripe_id',
  Notes = 'notes',
  ReplacementReason = 'replacement_reason',
  SensorModel = 'sensor_model',
  SensorSerialNumber = 'sensor_serial_number',
  State = 'state',
  TestedWithLibreApp = 'tested_with_libre_app',
  UpdatedAt = 'updated_at',
  UserEmail = 'user_email',
  UserId = 'user_id',
  WearingPatch = 'wearing_patch'
}

export type IReplacementRequestDynamicFilterItem = {
  items: Array<IReplacementRequestDynamicFilter>
  operator: DynamicFilterOperator
}

export enum ReplacementRequestErrorType {
  Malfunction = 'malfunction',
  ReturnToSender = 'return_to_sender',
  Unknown = 'unknown',
  UserError = 'user_error'
}

export enum ReplacementRequestProviderCompensation {
  TruepillFull = 'truepill_full',
  Unknown = 'unknown'
}

export enum ReplacementRequestReplacementReason {
  ActivationError_0Reading = 'activation_error_0_reading',
  ActivationErrorActivationLoop = 'activation_error_activation_loop',
  ActivationErrorNoScans = 'activation_error_no_scans',
  ApplicationErrorDeploymentEarlyDeployment = 'application_error_deployment_early_deployment',
  ApplicationErrorDeploymentSensorStuck = 'application_error_deployment_sensor_stuck',
  ApplicationErrorNeedleBent = 'application_error_needle_bent',
  ApplicationErrorNeedleFellOut = 'application_error_needle_fell_out',
  ApplicationErrorNeedleStuck = 'application_error_needle_stuck',
  ApplicationErrorSensorPlacement = 'application_error_sensor_placement',
  ApplicationErrorUnknown = 'application_error_unknown',
  CalibrationErrorAbnormalData = 'calibration_error_abnormal_data',
  CalibrationErrorHighValues = 'calibration_error_high_values',
  CalibrationErrorLowValues = 'calibration_error_low_values',
  DamageSportsActivity = 'damage_sports_activity',
  DamageWaterSwimmingSauna = 'damage_water_swimming_sauna',
  DamagedInTransit = 'damaged_in_transit',
  DexcomSensorFailure = 'dexcom_sensor_failure',
  DiscomfortDrewBlood = 'discomfort_drew_blood',
  DiscomfortGeneralPain = 'discomfort_general_pain',
  DiscomfortSkinIrritation = 'discomfort_skin_irritation',
  FellOffAdhesiveMalfunction = 'fell_off_adhesive_malfunction',
  FellOffBumped = 'fell_off_bumped',
  FellOffChanging = 'fell_off_changing',
  FellOffDueToPatch = 'fell_off_due_to_patch',
  FellOffShowering = 'fell_off_showering',
  FellOffSleeping = 'fell_off_sleeping',
  FellOffUnknown = 'fell_off_unknown',
  FellOffWorkingOut = 'fell_off_working_out',
  HealthMriXRayImaging = 'health_mri_x_ray_imaging',
  HealthSurgery = 'health_surgery',
  MissingLost = 'missing_lost',
  Other = 'other',
  ProductEarlyDecline = 'product_early_decline',
  ProductSensorMissing = 'product_sensor_missing',
  Refused = 'refused',
  RemovedWOTroubleshooting = 'removed_w_o_troubleshooting',
  Retention = 'retention',
  ScanningError_0Reading = 'scanning_error_0_reading',
  ScanningErrorNfc = 'scanning_error_nfc',
  SentExpiredSensor = 'sent_expired_sensor',
  Stolen = 'stolen',
  Unclaimed = 'unclaimed',
  WrongAddress = 'wrong_address'
}

export type IReplacementRequestSort = {
  direction: SortDirection
  field: ReplacementRequestSortField
}

export enum ReplacementRequestSortField {
  ChargeAmount = 'charge_amount',
  CreatedAt = 'created_at',
  CreatedByEmail = 'created_by_email',
  CreatedById = 'created_by_id',
  DateFellOff = 'date_fell_off',
  DateNotified = 'date_notified',
  DaysWorn = 'days_worn',
  ErrorType = 'error_type',
  HowItFellOff = 'how_it_fell_off',
  Id = 'id',
  InvoiceStripeId = 'invoice_stripe_id',
  Notes = 'notes',
  ReplacementReason = 'replacement_reason',
  SensorModel = 'sensor_model',
  SensorSerialNumber = 'sensor_serial_number',
  State = 'state',
  TestedWithLibreApp = 'tested_with_libre_app',
  UpdatedAt = 'updated_at',
  UserEmail = 'user_email',
  UserId = 'user_id',
  WearingPatch = 'wearing_patch'
}

export enum ReplacementRequestState {
  Completed = 'completed',
  Init = 'init',
  Processing = 'processing'
}

export enum ReplacementRequestTestedWithLibreApp {
  No = 'no',
  Yes = 'yes'
}

export enum ReplacementRequestWearingPatch {
  No = 'no',
  NotApplicable = 'not_applicable',
  Unknown = 'unknown',
  Yes = 'yes'
}

export type IResearchFaqs = {
  __typename?: 'ResearchFaqs'
  dataProtectionUrl: Scalars['String']
  eligibilityUrl: Scalars['String']
  gettingStartedUrl: Scalars['String']
  rootUrl: Scalars['String']
  sensorComparisonUrl: Scalars['String']
}

export type IResearchState = {
  __typename?: 'ResearchState'
  status: DexcomResearchStatus
}

export type IResponse = {
  __typename?: 'Response'
  answersGroups?: Maybe<Array<IAnswersGroup>>
  kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
  kindMetadata?: Maybe<Scalars['JSON']>
}

export enum RestartSurveyLinkQuestionKeys {
  GovernmentIdCard = 'government_id_card'
}

export type IScan = {
  __typename?: 'Scan'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  measurements: Array<IMeasurement>
  sensor?: Maybe<ISensor>
}

export type ISchedule = {
  __typename?: 'Schedule'
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>
  completedAt?: Maybe<Scalars['ISO8601DateTime']>
  createdAt: Scalars['ISO8601DateTime']
  currentPhaseEndAt?: Maybe<Scalars['ISO8601DateTime']>
  currentPhaseStartAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  phases: Array<ISchedulePhase>
  releasedAt?: Maybe<Scalars['ISO8601DateTime']>
  status: SubscriptionScheduleStatus
  stripeId: Scalars['String']
  subscription?: Maybe<ISubscription>
  updatedAt: Scalars['ISO8601DateTime']
}

export type ISchedulePhase = {
  __typename?: 'SchedulePhase'
  billingCycleAnchorChange?: Maybe<SubscriptionSchedulePhaseBillingCycleAnchorChange>
  createdAt: Scalars['ISO8601DateTime']
  description?: Maybe<Scalars['String']>
  endAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  price: Scalars['Float']
  primaryProduct?: Maybe<IProductMin>
  startAt: Scalars['ISO8601DateTime']
  updatedAt: Scalars['ISO8601DateTime']
}

export type ISchedulingRule = {
  __typename?: 'SchedulingRule'
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  count?: Maybe<Scalars['Int']>
  key: InsuranceBerryStreetAppointmentSchedulingRule
}

export type IScore = {
  __typename?: 'Score'
  danger: Scalars['Int']
  max: Scalars['Int']
  warning: Scalars['Int']
}

export type ISensor = {
  __typename?: 'Sensor'
  activationDate: Scalars['ISO8601Date']
  activationTime: Scalars['ISO8601DateTime']
  baselineAdjustment: Scalars['Int']
  daysRemaining: Scalars['Int']
  expirationDate: Scalars['ISO8601Date']
  expirationTime: Scalars['ISO8601DateTime']
  expired: Scalars['Boolean']
  id: Scalars['ID']
  lastStatusChange?: Maybe<ISensorStatusChange>
  model: Scalars['String']
  modelEnum?: Maybe<SensorModel>
  serialNumber: Scalars['String']
  status?: Maybe<Scalars['String']>
}

export type ISensorCollection = {
  __typename?: 'SensorCollection'
  sensors: Array<ISensor>
}

export type ISensorMode = {
  __typename?: 'SensorMode'
  kind: UserExperienceSensorModeKind
}

export enum SensorModel {
  DexcomG7 = 'dexcom_g7',
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre2Eu = 'libre2_eu',
  Libre3 = 'libre3',
  LibrePro = 'libre_pro',
  LibreUs = 'libre_us',
  Unknown = 'unknown'
}

export type ISensorSettings = {
  __typename?: 'SensorSettings'
  baselineAdjustmentOptions: Array<INumericalSelect>
}

export enum SensorStatus {
  Active = 'active',
  Error = 'error',
  Expired = 'expired',
  Failure = 'failure',
  InsertionDetection = 'insertion_detection',
  InsertionFailed = 'insertion_failed',
  Paired = 'paired',
  Ready = 'ready',
  Shutdown = 'shutdown',
  Starting = 'starting',
  Unknown = 'unknown'
}

export type ISensorStatusChange = {
  __typename?: 'SensorStatusChange'
  createdAt: Scalars['ISO8601Date']
  failure: Scalars['Boolean']
  status: SensorStatus
}

export type ISettings = {
  __typename?: 'Settings'
  cancellationPolicy: Scalars['String']
  privacyPolicy: Scalars['String']
  telehealth: Scalars['String']
  termsOfUse: Scalars['String']
}

export type IShipment = {
  __typename?: 'Shipment'
  address: IAddress
  addressId: Scalars['Int']
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>
  createdAt: Scalars['ISO8601DateTime']
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>
  eta?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['Int']
  location?: Maybe<Scalars['String']>
  order: IOrder
  orderId: Scalars['Int']
  provider?: Maybe<ShippingOrderProvider>
  providerError?: Maybe<Scalars['String']>
  providerReceivedAt?: Maybe<Scalars['ISO8601DateTime']>
  providerShipmentId?: Maybe<Scalars['ID']>
  quantity: Scalars['Int']
  shippedAt?: Maybe<Scalars['ISO8601DateTime']>
  status?: Maybe<ShippingShipmentStatus>
  trackingNumber?: Maybe<Scalars['String']>
  trackingUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['ISO8601DateTime']
  user: IUser
}

export type IShipmentDynamicFilter = {
  field: ShipmentDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum ShipmentDynamicFilterField {
  AddressCity = 'address_city',
  AddressCountry = 'address_country',
  AddressId = 'address_id',
  AddressName = 'address_name',
  AddressState = 'address_state',
  AddressStreet = 'address_street',
  AddressStreet_2 = 'address_street_2',
  AddressZipCode = 'address_zip_code',
  CanceledAt = 'canceled_at',
  CreatedAt = 'created_at',
  DeliveredAt = 'delivered_at',
  Eta = 'eta',
  Id = 'id',
  Location = 'location',
  OrderCreatedAt = 'order_created_at',
  OrderId = 'order_id',
  OrderInvoiceStripeId = 'order_invoice_stripe_id',
  OrderNotes = 'order_notes',
  OrderProduct = 'order_product',
  OrderProductType = 'order_product_type',
  OrderQuantity = 'order_quantity',
  OrderReplacementRequestProviderCompensation = 'order_replacement_request_provider_compensation',
  OrderSensorKind = 'order_sensor_kind',
  OrderUpdatedAt = 'order_updated_at',
  Provider = 'provider',
  ProviderError = 'provider_error',
  ProviderReceivedAt = 'provider_received_at',
  ProviderShipmentId = 'provider_shipment_id',
  Quantity = 'quantity',
  ShippedAt = 'shipped_at',
  Status = 'status',
  TrackingNumber = 'tracking_number',
  TrackingUrl = 'tracking_url',
  UpdatedAt = 'updated_at',
  UserActiveRefillConsentGivenAt = 'user_active_refill_consent_given_at',
  UserDateOfBirth = 'user_date_of_birth',
  UserEmail = 'user_email',
  UserFirstName = 'user_first_name',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserLastName = 'user_last_name',
  UserPhoneNumber = 'user_phone_number',
  UserSex = 'user_sex',
  UserTruepillPatientToken = 'user_truepill_patient_token'
}

export type IShipmentDynamicFilterItem = {
  items: Array<IShipmentDynamicFilter>
  operator: DynamicFilterOperator
}

export type IShipmentSort = {
  direction: SortDirection
  field: ShipmentSortField
}

export enum ShipmentSortField {
  AddressCity = 'address_city',
  AddressCountry = 'address_country',
  AddressId = 'address_id',
  AddressName = 'address_name',
  AddressState = 'address_state',
  AddressStreet = 'address_street',
  AddressStreet_2 = 'address_street_2',
  AddressZipCode = 'address_zip_code',
  CanceledAt = 'canceled_at',
  CreatedAt = 'created_at',
  DeliveredAt = 'delivered_at',
  Eta = 'eta',
  Id = 'id',
  Location = 'location',
  OrderCreatedAt = 'order_created_at',
  OrderId = 'order_id',
  OrderInvoiceStripeId = 'order_invoice_stripe_id',
  OrderNotes = 'order_notes',
  OrderProduct = 'order_product',
  OrderProductType = 'order_product_type',
  OrderQuantity = 'order_quantity',
  OrderSensorKind = 'order_sensor_kind',
  OrderUpdatedAt = 'order_updated_at',
  Provider = 'provider',
  ProviderError = 'provider_error',
  ProviderReceivedAt = 'provider_received_at',
  ProviderShipmentId = 'provider_shipment_id',
  Quantity = 'quantity',
  ShippedAt = 'shipped_at',
  Status = 'status',
  TrackingNumber = 'tracking_number',
  TrackingUrl = 'tracking_url',
  UpdatedAt = 'updated_at',
  UserActiveRefillConsentGivenAt = 'user_active_refill_consent_given_at',
  UserDateOfBirth = 'user_date_of_birth',
  UserEmail = 'user_email',
  UserFirstName = 'user_first_name',
  UserFullName = 'user_full_name',
  UserId = 'user_id',
  UserLastName = 'user_last_name',
  UserPhoneNumber = 'user_phone_number',
  UserSex = 'user_sex',
  UserTruepillPatientToken = 'user_truepill_patient_token'
}

export enum ShippingOrderIncludable {
  Regular = 'regular',
  WelcomeKit = 'welcome_kit'
}

export enum ShippingOrderProductType {
  Cgm = 'cgm',
  FreeReplacement = 'free_replacement',
  NoShipping = 'no_shipping',
  PaidReplacement = 'paid_replacement',
  Patch = 'patch',
  Semaglutide = 'semaglutide',
  WelcomeKit = 'welcome_kit'
}

export enum ShippingOrderProvider {
  GiftHealth = 'gift_health',
  GiftHealthManual = 'gift_health_manual',
  Nutrisense = 'nutrisense',
  Precision = 'precision',
  Truepill = 'truepill'
}

export enum ShippingShipmentStatus {
  Canceled = 'canceled',
  Delivered = 'delivered',
  Failure = 'failure',
  Returned = 'returned',
  Shipped = 'shipped'
}

export type IShowAlertOnCloseBehavior = {
  __typename?: 'ShowAlertOnCloseBehavior'
  body: Scalars['String']
  cancelTitle: Scalars['String']
  confirmTitle: Scalars['String']
  icon?: Maybe<UiIcon>
  title: Scalars['String']
}

export enum SignUpState {
  Complete = 'complete',
  PendingPurchase = 'pending_purchase'
}

export type ISignup = {
  __typename?: 'Signup'
  learnMore: Scalars['String']
}

export type ISingleValueMeasurement = {
  __typename?: 'SingleValueMeasurement'
  value: Scalars['Float']
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type IStat = {
  __typename?: 'Stat'
  mean: Scalars['Float']
}

export type IStatMeta = {
  __typename?: 'StatMeta'
  key: Scalars['String']
  section: Scalars['String']
}

export type IStatRange = {
  __typename?: 'StatRange'
  danger?: Maybe<Scalars['Float']>
  max?: Maybe<Scalars['Float']>
  min?: Maybe<Scalars['Float']>
  reverse?: Maybe<Scalars['Boolean']>
  warning?: Maybe<Scalars['Float']>
}

export type IState = {
  __typename?: 'State'
  abbreviation: Scalars['String']
  name: Scalars['String']
}

export type IStatistic = {
  __typename?: 'Statistic'
  change: Scalars['Int']
  description: Scalars['String']
  meta: IStatMeta
  range: IStatRange
  title: Scalars['String']
  type: Scalars['String']
  units: Scalars['String']
  value: Scalars['Float']
  valuePrev: Scalars['Float']
}

export type IStatistics = {
  __typename?: 'Statistics'
  imperial: IConfigUnit
  metric: IConfigUnit
  name: Scalars['String']
}

export type IStats = {
  __typename?: 'Stats'
  statistics: Array<IStatistic>
}

export type IStatsConfig = {
  __typename?: 'StatsConfig'
  filters: IStatsFilters
}

export type IStatsFilters = {
  __typename?: 'StatsFilters'
  types: Array<IKeyValuePairStringArray>
}

export type IStatsSettings = {
  __typename?: 'StatsSettings'
  key: Scalars['String']
  order: Scalars['Int']
  section: Scalars['String']
  visible: Scalars['Boolean']
}

export type IStatsSettingsOrder = {
  key: Scalars['String']
  order: Scalars['Int']
  section: Scalars['String']
  visible: Scalars['Boolean']
}

export type IStringPair = {
  __typename?: 'StringPair'
  /** @deprecated Removed in v3.0.0 */
  name?: Maybe<Scalars['String']>
  x: Scalars['String']
  y?: Maybe<Scalars['Float']>
}

export type IStringSelect = {
  __typename?: 'StringSelect'
  text: Scalars['String']
  value: Scalars['String']
}

export type ISubscription = {
  __typename?: 'Subscription'
  allowedPauseCycles: Scalars['Int']
  attachedSchedule?: Maybe<ISchedule>
  cancelAt?: Maybe<Scalars['ISO8601DateTime']>
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>
  checkoutOptions: Array<ICheckoutOption>
  commitmentEndAt?: Maybe<Scalars['ISO8601DateTime']>
  createdAt: Scalars['ISO8601DateTime']
  currentPeriodEndAt?: Maybe<Scalars['ISO8601DateTime']>
  dietitianAddOn: Scalars['Boolean']
  endAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  latestInvoice?: Maybe<IInvoice>
  paidMonthsCount: Scalars['Int']
  paused: Scalars['Boolean']
  pausedMonthsCount: Scalars['Int']
  /** @deprecated Removed usage in v2.7.0 */
  plans: Array<IPlan>
  price: Scalars['Float']
  /** @deprecated Use price and primary_product */
  primaryPlan: IPlan
  primaryProduct: IProductMin
  resetBillingCycleAnchorAt?: Maybe<Scalars['ISO8601DateTime']>
  resumesAt?: Maybe<Scalars['ISO8601DateTime']>
  startAt: Scalars['ISO8601DateTime']
  status: SubscriptionStatus
  stripeId: Scalars['String']
  /** @deprecated Use status enum field */
  stripeStatus: Scalars['String']
  subscribedAt: Scalars['ISO8601DateTime']
  trialEndAt?: Maybe<Scalars['ISO8601DateTime']>
  updatedAt: Scalars['ISO8601DateTime']
  user: IUser
}

export enum SubscriptionCheckoutOptionKind {
  Prescription = 'prescription',
  Research = 'research'
}

export type ISubscriptionCollection = {
  __typename?: 'SubscriptionCollection'
  pageCount?: Maybe<Scalars['Int']>
  subscriptions: Array<ISubscription>
  totalCount: Scalars['Int']
}

export type ISubscriptionDynamicFilter = {
  field: SubscriptionDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum SubscriptionDynamicFilterField {
  CancelAt = 'cancel_at',
  CanceledAt = 'canceled_at',
  CreatedAt = 'created_at',
  CurrentPeriodEndAt = 'current_period_end_at',
  EndAt = 'end_at',
  PaidMonthsCount = 'paid_months_count',
  Paused = 'paused',
  PausedMonthsCount = 'paused_months_count',
  Plans = 'plans',
  ResumesAt = 'resumes_at',
  StartAt = 'start_at',
  Status = 'status',
  StripeId = 'stripe_id',
  /** @deprecated use status */
  StripeStatus = 'stripe_status',
  SubscribedAt = 'subscribed_at',
  TrialEndAt = 'trial_end_at',
  UpdatedAt = 'updated_at',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserId = 'user_id'
}

export type ISubscriptionDynamicFilterItem = {
  items: Array<ISubscriptionDynamicFilter>
  operator: DynamicFilterOperator
}

export enum SubscriptionSchedulePhaseBillingCycleAnchorChange {
  Automatic = 'automatic',
  PhaseStart = 'phase_start'
}

export enum SubscriptionScheduleStatus {
  Active = 'active',
  Canceled = 'canceled',
  Completed = 'completed',
  NotStarted = 'not_started',
  Released = 'released'
}

export type ISubscriptionSort = {
  direction: SortDirection
  field: SubscriptionSortField
}

export enum SubscriptionSortField {
  CancelAt = 'cancel_at',
  CanceledAt = 'canceled_at',
  CreatedAt = 'created_at',
  CurrentPeriodEndAt = 'current_period_end_at',
  EndAt = 'end_at',
  PaidMonthsCount = 'paid_months_count',
  Paused = 'paused',
  PausedMonthsCount = 'paused_months_count',
  Plans = 'plans',
  ResumesAt = 'resumes_at',
  StartAt = 'start_at',
  Status = 'status',
  StripeId = 'stripe_id',
  /** @deprecated use status */
  StripeStatus = 'stripe_status',
  SubscribedAt = 'subscribed_at',
  TrialEndAt = 'trial_end_at',
  UpdatedAt = 'updated_at',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name'
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type ISuccess = {
  __typename?: 'Success'
  success: Scalars['Boolean']
}

export type ISurvey = {
  __typename?: 'Survey'
  /** @deprecated use survey_link.early_exit_deep_link instead */
  allowEarlyExitBehavior?: Maybe<IAllowEarlyExitBehavior>
  behavior: IBehavior
  completedScreen?: Maybe<ICompletedScreen>
  completionFlashMessage?: Maybe<Scalars['String']>
  followups: Array<IFollowup>
  header?: Maybe<Scalars['String']>
  kind: SurveysConfigKind
  questionsGroups: Array<IQuestionsGroup>
  research: Scalars['Boolean']
  showAlertOnCloseBehavior?: Maybe<IShowAlertOnCloseBehavior>
  version: Scalars['String']
}

export type ISurveyLink = {
  __typename?: 'SurveyLink'
  completionDeepLink?: Maybe<Scalars['String']>
  earlyExitDeepLink?: Maybe<Scalars['String']>
  finished: Scalars['Boolean']
  finishedAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  inProgress: Scalars['Boolean']
  outcome?: Maybe<IOutcome>
  responses: Array<ISurveyResponse>
  startedAt: Scalars['ISO8601DateTime']
  state: SurveysUserLinkState
  survey: ISurvey
  user?: Maybe<IUser>
}

export type ISurveyLinkCollection = {
  __typename?: 'SurveyLinkCollection'
  surveyLinks: Array<ISurveyLink>
}

export type ISurveyLinkDynamicFilter = {
  field: SurveyLinkDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum SurveyLinkDynamicFilterField {
  FinishedAt = 'finished_at',
  StartedAt = 'started_at',
  State = 'state',
  SurveyKind = 'survey_kind',
  SurveyVersion = 'survey_version',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name'
}

export type ISurveyLinkDynamicFilterItem = {
  items: Array<ISurveyLinkDynamicFilter>
  operator: DynamicFilterOperator
}

export type ISurveyLinkSort = {
  direction: SortDirection
  field: SurveyLinkSortField
}

export enum SurveyLinkSortField {
  FinishedAt = 'finished_at',
  StartedAt = 'started_at',
  State = 'state',
  SurveyKind = 'survey_kind',
  SurveyVersion = 'survey_version',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name'
}

export type ISurveyResponse = {
  __typename?: 'SurveyResponse'
  answer: Scalars['JSON']
  id: Scalars['ID']
  questionKey: SurveysQuestionKey
  surveyLink: ISurveyLink
}

export enum SurveysConfigKind {
  AccountInterest = 'account_interest',
  Cancellation = 'cancellation',
  CareEligibility = 'care_eligibility',
  CareIntake = 'care_intake',
  CgmPrerequisite = 'cgm_prerequisite',
  Demographics = 'demographics',
  DexcomResearchConsent = 'dexcom_research_consent',
  DietaryHabits = 'dietary_habits',
  DppEligibility = 'dpp_eligibility',
  DppEvidence = 'dpp_evidence',
  ExerciseHabits = 'exercise_habits',
  Goals = 'goals',
  Health = 'health',
  HealthUpdate = 'health_update',
  Insurance = 'insurance',
  InsuranceEarlyExit = 'insurance_early_exit',
  InsuranceFallback = 'insurance_fallback',
  MedicalDisclaimer = 'medical_disclaimer',
  MedicalHistory = 'medical_history',
  MidpointPulse = 'midpoint_pulse',
  OneMonthActiveFeedback = 'one_month_active_feedback',
  OwnSensor = 'own_sensor',
  SleepHabits = 'sleep_habits'
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind {
  Inline = 'inline',
  Standalone = 'standalone'
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder {
  Random = 'random',
  Serial = 'serial'
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseKind {
  Address = 'address',
  CheckboxMulti = 'checkbox_multi',
  Consent = 'consent',
  DateInput = 'date_input',
  FileUpload = 'file_upload',
  HeightImperial = 'height_imperial',
  PhoneNumber = 'phone_number',
  Radio = 'radio',
  SelectSingle = 'select_single',
  Slider = 'slider',
  SliderWithSkip = 'slider_with_skip',
  TabsTextInput = 'tabs_text_input',
  TextArea = 'text_area',
  TextAreaWithSkip = 'text_area_with_skip',
  TextInput = 'text_input'
}

export enum SurveysQuestionKey {
  Address = 'address',
  AdhesiveAllergy = 'adhesive_allergy',
  AetnaInsurancePlanType = 'aetna_insurance_plan_type',
  AgeRange = 'age_range',
  AlcoholicBeveragesFrequency = 'alcoholic_beverages_frequency',
  AnnualIncomeRange = 'annual_income_range',
  AnthemInsurancePlanType = 'anthem_insurance_plan_type',
  AnythingElse = 'anything_else',
  AvoidingDiabetesPriority = 'avoiding_diabetes_priority',
  BedtimeRoutine = 'bedtime_routine',
  BedtimeRoutineOther = 'bedtime_routine_other',
  BetterStatementAboutOwnHealth = 'better_statement_about_own_health',
  BiologicalSex = 'biological_sex',
  BirthDate = 'birth_date',
  BloodPressureRisk = 'blood_pressure_risk',
  BloodTestRisk = 'blood_test_risk',
  BlueCrossBlueShieldInsurancePlanType = 'blue_cross_blue_shield_insurance_plan_type',
  Bmi = 'bmi',
  BmiRange = 'bmi_range',
  BodyFat = 'body_fat',
  BodyPhoto = 'body_photo',
  BreakfastFrequency = 'breakfast_frequency',
  CancellationReason = 'cancellation_reason',
  CancellationReasonOther = 'cancellation_reason_other',
  CancerTreatment = 'cancer_treatment',
  CgmDevice = 'cgm_device',
  CgmDeviceOther = 'cgm_device_other',
  CignaInsurancePlanType = 'cigna_insurance_plan_type',
  CoachBetterSupport = 'coach_better_support',
  CoachFocus = 'coach_focus',
  CoachValueSoFar = 'coach_value_so_far',
  CoachingStyle = 'coaching_style',
  CommunicationFrequency = 'communication_frequency',
  ComplimentarySupportDecision = 'complimentary_support_decision',
  ConfidentInterpretGlucoseData = 'confident_interpret_glucose_data',
  ConfirmReject = 'confirm_reject',
  CurrentHba1cLevel = 'current_hba1c_level',
  DairyProductsFrequency = 'dairy_products_frequency',
  DefineProgress = 'define_progress',
  DescribesYou = 'describes_you',
  DescribesYouOther = 'describes_you_other',
  DexcomAppCompatibility = 'dexcom_app_compatibility',
  DexcomResearchConsent = 'dexcom_research_consent',
  Diabetes = 'diabetes',
  DiabetesDiagnosis = 'diabetes_diagnosis',
  DiabetesMedication = 'diabetes_medication',
  DiagnosedConditions = 'diagnosed_conditions',
  DiagnosedConditions2 = 'diagnosed_conditions2',
  DiagnosedConditionsOther = 'diagnosed_conditions_other',
  Diet = 'diet',
  DietOther = 'diet_other',
  DietaryGuidelinesRestrictions = 'dietary_guidelines_restrictions',
  DietaryGuidelinesRestrictionsOther = 'dietary_guidelines_restrictions_other',
  DietaryHabitsChanges = 'dietary_habits_changes',
  DietaryHabitsChangesDetails = 'dietary_habits_changes_details',
  DietaryHabitsChangesDetailsOther = 'dietary_habits_changes_details_other',
  DinnerFrequency = 'dinner_frequency',
  Disabilities = 'disabilities',
  DiscoverNs = 'discover_ns',
  DiscoverNsBlog = 'discover_ns_blog',
  DiscoverNsDoctor = 'discover_ns_doctor',
  DiscoverNsFacebook = 'discover_ns_facebook',
  DiscoverNsFamily = 'discover_ns_family',
  DiscoverNsFriend = 'discover_ns_friend',
  DiscoverNsInfluencer = 'discover_ns_influencer',
  DiscoverNsInstagram = 'discover_ns_instagram',
  DiscoverNsLinkedin = 'discover_ns_linkedin',
  DiscoverNsMagazine = 'discover_ns_magazine',
  DiscoverNsMedia = 'discover_ns_media',
  DiscoverNsNewsletter = 'discover_ns_newsletter',
  DiscoverNsOther = 'discover_ns_other',
  DiscoverNsPinterest = 'discover_ns_pinterest',
  DiscoverNsPodcast = 'discover_ns_podcast',
  DiscoverNsRadio = 'discover_ns_radio',
  DiscoverNsReddit = 'discover_ns_reddit',
  DiscoverNsSearchEngine = 'discover_ns_search_engine',
  DiscoverNsTikTok = 'discover_ns_tik_tok',
  DiscoverNsTwitter = 'discover_ns_twitter',
  DiscoverNsYoutube = 'discover_ns_youtube',
  DppCommercialInsurancePlan = 'dpp_commercial_insurance_plan',
  DppCommercialInsurancePlanOther = 'dpp_commercial_insurance_plan_other',
  DppHbA1cEvidence = 'dpp_hb_a1c_evidence',
  DppHealthcareProfessionalRecommendation = 'dpp_healthcare_professional_recommendation',
  DppInsurancePlan = 'dpp_insurance_plan',
  DppPreferredCallDays = 'dpp_preferred_call_days',
  DppPreferredCallTimes = 'dpp_preferred_call_times',
  DppProgramMotivation = 'dpp_program_motivation',
  EarliestWakeUpTime = 'earliest_wake_up_time',
  EatOutFrequency = 'eat_out_frequency',
  EatingDisorder = 'eating_disorder',
  EatingDisorderType = 'eating_disorder_type',
  EducationLevel = 'education_level',
  EducationLevelOther = 'education_level_other',
  ElectronicDevicesBeforeBedtime = 'electronic_devices_before_bedtime',
  EmploymentStatus = 'employment_status',
  EmploymentStatusOther = 'employment_status_other',
  EnergyLevelsChanges = 'energy_levels_changes',
  Ethnicity = 'ethnicity',
  EthnicityOther = 'ethnicity_other',
  ExerciseDuration = 'exercise_duration',
  ExerciseHabitsChanges = 'exercise_habits_changes',
  ExerciseHabitsChangesDetails = 'exercise_habits_changes_details',
  ExerciseHabitsChangesDetailsOther = 'exercise_habits_changes_details_other',
  ExerciseLocation = 'exercise_location',
  ExerciseLocationOther = 'exercise_location_other',
  ExerciseMotivation = 'exercise_motivation',
  ExerciseMotivationOther = 'exercise_motivation_other',
  ExerciseTypes = 'exercise_types',
  ExerciseTypesOther = 'exercise_types_other',
  FallAsleepTime = 'fall_asleep_time',
  FamiliarPrediabeticsConfirmation = 'familiar_prediabetics_confirmation',
  FamilyDiabetes = 'family_diabetes',
  FamilyMedicalConditions = 'family_medical_conditions',
  FastingGlucoseToleranceBloodTest = 'fasting_glucose_tolerance_blood_test',
  FishSeafoodFrequency = 'fish_seafood_frequency',
  FoodAllergies = 'food_allergies',
  FoodAllergiesIntolerances = 'food_allergies_intolerances',
  FoodAllergiesIntolerancesOther = 'food_allergies_intolerances_other',
  FoodAllergiesOther = 'food_allergies_other',
  FoodSymptoms = 'food_symptoms',
  FoodSymptomsOther = 'food_symptoms_other',
  FruitsVegetablesFrequency = 'fruits_vegetables_frequency',
  Gender = 'gender',
  GestationalDiabetesDiagnosis = 'gestational_diabetes_diagnosis',
  GlpAdditionalHealthConditions = 'glp_additional_health_conditions',
  GlpConditions = 'glp_conditions',
  GlpConsiderPregnant = 'glp_consider_pregnant',
  GlpElevatedTriglycerides = 'glp_elevated_triglycerides',
  GlpElevatedTriglyceridesValue = 'glp_elevated_triglycerides_value',
  GlpGallbladderDisease = 'glp_gallbladder_disease',
  GlpGallbladderRemoved = 'glp_gallbladder_removed',
  GlpGastricBypassSurgery = 'glp_gastric_bypass_surgery',
  GlpGastricBypassSurgeryDate = 'glp_gastric_bypass_surgery_date',
  GlpGastricBypassSurgeryType = 'glp_gastric_bypass_surgery_type',
  GlpGoal = 'glp_goal',
  GlpGoalOther = 'glp_goal_other',
  GlpHeaviestWeightInPounds = 'glp_heaviest_weight_in_pounds',
  GlpHistoryBipolarDepression = 'glp_history_bipolar_depression',
  GlpIncreasePhysicalActivity = 'glp_increase_physical_activity',
  GlpIndianAsianDescent = 'glp_indian_asian_descent',
  GlpKidneyDisease = 'glp_kidney_disease',
  GlpKidneyDiseaseGfr = 'glp_kidney_disease_gfr',
  GlpLastInPersonMedicalEvaluationDate = 'glp_last_in_person_medical_evaluation_date',
  GlpPhysicianQuestions = 'glp_physician_questions',
  GlpPrescriptionMedications = 'glp_prescription_medications',
  GlpPsychiatricFacility = 'glp_psychiatric_facility',
  GlpReduceCaloricIntake = 'glp_reduce_caloric_intake',
  GlpRestrictedConditions = 'glp_restricted_conditions',
  GlpTakingWeightMedication = 'glp_taking_weight_medication',
  GlpTriedWeightManagement = 'glp_tried_weight_management',
  GlpTriedWeightManagementOther = 'glp_tried_weight_management_other',
  GlpWeightGoalInPounds = 'glp_weight_goal_in_pounds',
  GlucoseManagementKnowledge = 'glucose_management_knowledge',
  GoalsPrevention = 'goals_prevention',
  GoalsPreventionOther = 'goals_prevention_other',
  GovernmentIdCard = 'government_id_card',
  GroupCoachingParticipation = 'group_coaching_participation',
  HavingOwnHealthUpsAndDownsConfirmation = 'having_own_health_ups_and_downs_confirmation',
  Hba1cBloodTest = 'hba1c_blood_test',
  Hba1cBloodTestRange = 'hba1c_blood_test_range',
  Hba1cBloodTestResult = 'hba1c_blood_test_result',
  Hba1cMeasurementTime = 'hba1c_measurement_time',
  HealthConditions = 'health_conditions',
  HealthConditionsImpactExercise = 'health_conditions_impact_exercise',
  HealthConditionsOther = 'health_conditions_other',
  HealthGoal = 'health_goal',
  HealthInsuranceCard = 'health_insurance_card',
  Height = 'height',
  HeightInInches = 'height_in_inches',
  HighmarkInsurancePlanType = 'highmark_insurance_plan_type',
  HipCircumferenceInInches = 'hip_circumference_in_inches',
  HorizonInsurancePlanType = 'horizon_insurance_plan_type',
  InjuriesImpactExercise = 'injuries_impact_exercise',
  Insulin = 'insulin',
  InsulinOrCancerTreatment = 'insulin_or_cancer_treatment',
  InsuranceEarlyExitReason = 'insurance_early_exit_reason',
  InsuranceEarlyExitReasonOther = 'insurance_early_exit_reason_other',
  InsuranceGroupNumber = 'insurance_group_number',
  InsuranceMemberId = 'insurance_member_id',
  InsurancePlan = 'insurance_plan',
  InsurancePlanOther = 'insurance_plan_other',
  InsurancePolicyHolder = 'insurance_policy_holder',
  InsurancePolicyHolderDateOfBirth = 'insurance_policy_holder_date_of_birth',
  InsurancePolicyHolderFirstName = 'insurance_policy_holder_first_name',
  InsurancePolicyHolderLastName = 'insurance_policy_holder_last_name',
  InsurancePolicyHolderOther = 'insurance_policy_holder_other',
  InsuranceReimbursementPossibility = 'insurance_reimbursement_possibility',
  InsuranceVideoCallGoal = 'insurance_video_call_goal',
  InsuranceVideoCallGoalOther = 'insurance_video_call_goal_other',
  LabWork = 'lab_work',
  LabWorkResults = 'lab_work_results',
  LearnSpecificFoodsAffectDiabetes = 'learn_specific_foods_affect_diabetes',
  LifestyleChanges = 'lifestyle_changes',
  LifestyleConfidence = 'lifestyle_confidence',
  LikelihoodFollowWorkoutPlan = 'likelihood_follow_workout_plan',
  LikelihoodToChangeSleepHabits = 'likelihood_to_change_sleep_habits',
  LongTermWeightGoalEffort = 'long_term_weight_goal_effort',
  LunchFrequency = 'lunch_frequency',
  MedicalDisclaimerConsent = 'medical_disclaimer_consent',
  MedicationChanges = 'medication_changes',
  Medications = 'medications',
  MetabolismDisorder = 'metabolism_disorder',
  MobileAppMedicalDisclaimer = 'mobile_app_medical_disclaimer',
  MorningRestfulness = 'morning_restfulness',
  NfcSupported = 'nfc_supported',
  NotUnderstandingOwnHealthConfirmation = 'not_understanding_own_health_confirmation',
  NsValueInPastTwoWeeks = 'ns_value_in_past_two_weeks',
  NutritionKnowledge = 'nutrition_knowledge',
  NutritionistContinueReachGoals = 'nutritionist_continue_reach_goals',
  NutritionistStartStopReachGoals = 'nutritionist_start_stop_reach_goals',
  Occupation = 'occupation',
  OneMonthActiveFeedbackDietitian = 'one_month_active_feedback_dietitian',
  OtherAllergies = 'other_allergies',
  OtherAllergiesList = 'other_allergies_list',
  OtherInsurancePlanType = 'other_insurance_plan_type',
  OwnGlucometer = 'own_glucometer',
  PainOrDiscomfortDuringSleep = 'pain_or_discomfort_during_sleep',
  PastHealthConditions = 'past_health_conditions',
  PharmacyServicesConsent = 'pharmacy_services_consent',
  PhoneNumber = 'phone_number',
  PhysicalActivity = 'physical_activity',
  PhysicalActivityFrequency = 'physical_activity_frequency',
  PoultryFrequency = 'poultry_frequency',
  PreExistingMedicalConditions = 'pre_existing_medical_conditions',
  PreExistingMedicalConditionsList = 'pre_existing_medical_conditions_list',
  Pregnancy = 'pregnancy',
  PregnancyTerms = 'pregnancy_terms',
  PrescriptionMedications = 'prescription_medications',
  PrescriptionMedicationsList = 'prescription_medications_list',
  PrimaryJoiningGoal = 'primary_joining_goal',
  PrimaryJoiningGoalOther = 'primary_joining_goal_other',
  ProcessedFoodFrequency = 'processed_food_frequency',
  RecurringSubscriptionConsent = 'recurring_subscription_consent',
  RedMeatFrequency = 'red_meat_frequency',
  ReversedConditions = 'reversed_conditions',
  ReversedConditionsOther = 'reversed_conditions_other',
  SanaBenefitsInsurancePlanType = 'sana_benefits_insurance_plan_type',
  Sex = 'sex',
  ShareWithCoach = 'share_with_coach',
  SittingHours = 'sitting_hours',
  SleepChanges = 'sleep_changes',
  SleepChangesDetails = 'sleep_changes_details',
  SleepChangesDetailsOther = 'sleep_changes_details_other',
  SleepDisorderDiagnosis = 'sleep_disorder_diagnosis',
  SleepHours = 'sleep_hours',
  SnackFrequency = 'snack_frequency',
  StepsPerDay = 'steps_per_day',
  Stress = 'stress',
  SubstancesBeforeBedtime = 'substances_before_bedtime',
  SuccessLookLike = 'success_look_like',
  SugaryDrinksFrequency = 'sugary_drinks_frequency',
  Supplements = 'supplements',
  SurgeriesHospitalizations = 'surgeries_hospitalizations',
  TelehealthConsent = 'telehealth_consent',
  Type_2DiabetesComplications = 'type_2_diabetes_complications',
  Type_2DiabetesDate = 'type_2_diabetes_date',
  Type_2DiabetesManagement = 'type_2_diabetes_management',
  UnfamiliarPrediabeticsConfirmation = 'unfamiliar_prediabetics_confirmation',
  UnitedHealthcareInsurancePlanType = 'united_healthcare_insurance_plan_type',
  WaistCircumferenceInInches = 'waist_circumference_in_inches',
  WakeUpFrequency = 'wake_up_frequency',
  Weight = 'weight',
  WeightGoalEffortWorksForYou = 'weight_goal_effort_works_for_you',
  WeightInPounds = 'weight_in_pounds',
  WorkedWithTrainer = 'worked_with_trainer'
}

export enum SurveysUserLinkState {
  Completed = 'completed',
  Processed = 'processed',
  Restarted = 'restarted',
  Started = 'started',
  Stopped = 'stopped'
}

export enum ThirdPartySignInProvider {
  Apple = 'apple',
  Google = 'google'
}

export type ITicket = {
  __typename?: 'Ticket'
  assignedTo?: Maybe<IUser>
  createdAt: Scalars['ISO8601DateTime']
  createdBy: IUser
  description: Scalars['String']
  externalLink?: Maybe<Scalars['String']>
  id: Scalars['ID']
  kind: TicketKind
  priority: TicketPriority
  reason?: Maybe<TicketReason>
  status: TicketStatus
  user: IUser
}

export type ITicketCollection = {
  __typename?: 'TicketCollection'
  pageCount?: Maybe<Scalars['Int']>
  tickets: Array<ITicket>
  totalCount: Scalars['Int']
}

export type ITicketComment = {
  __typename?: 'TicketComment'
  body: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  createdBy: IUserMin
  id: Scalars['ID']
  ticketId: Scalars['ID']
}

export type ITicketCommentCollection = {
  __typename?: 'TicketCommentCollection'
  pageCount?: Maybe<Scalars['Int']>
  ticketComments: Array<ITicketComment>
  totalCount: Scalars['Int']
}

export type ITicketCommentDynamicFilter = {
  field: TicketCommentDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum TicketCommentDynamicFilterField {
  Id = 'id'
}

export type ITicketCommentDynamicFilterItem = {
  items: Array<ITicketCommentDynamicFilter>
  operator: DynamicFilterOperator
}

export type ITicketCommentSort = {
  direction: SortDirection
  field: TicketCommentSortField
}

export enum TicketCommentSortField {
  CreatedAt = 'created_at'
}

export type ITicketDynamicFilter = {
  field: TicketDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum TicketDynamicFilterField {
  AssignedToEmail = 'assigned_to_email',
  AssignedToFullName = 'assigned_to_full_name',
  CreatedAt = 'created_at',
  CreatedByEmail = 'created_by_email',
  Id = 'id',
  Kind = 'kind',
  Priority = 'priority',
  Reason = 'reason',
  Status = 'status',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name',
  UserId = 'user_id'
}

export type ITicketDynamicFilterItem = {
  items: Array<ITicketDynamicFilter>
  operator: DynamicFilterOperator
}

export enum TicketKind {
  Subscription = 'subscription',
  Troubleshooting = 'troubleshooting'
}

export enum TicketPriority {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export enum TicketReason {
  Accounts = 'accounts',
  Addons = 'addons',
  AppLogin = 'app_login',
  AppMealLogging = 'app_meal_logging',
  AppQuestionnaires = 'app_questionnaires',
  Billing = 'billing',
  Cancellation = 'cancellation',
  GeneralInquiries = 'general_inquiries',
  GeneralNutritionSupport = 'general_nutrition_support',
  InformationUpdate = 'information_update',
  Insurance = 'insurance',
  IntegrationHealth = 'integration_health',
  IntegrationsDexcom = 'integrations_dexcom',
  IntegrationsOther = 'integrations_other',
  Patches = 'patches',
  Pauses = 'pauses',
  Payments = 'payments',
  SensorActivation = 'sensor_activation',
  SensorCalibration = 'sensor_calibration',
  SensorFellOff = 'sensor_fell_off',
  SensorOther = 'sensor_other',
  SensorPainSkinIrritation = 'sensor_pain_skin_irritation',
  SensorScanning = 'sensor_scanning',
  Shipments = 'shipments',
  SubscriptionUpdate = 'subscription_update',
  VideoCalls = 'video_calls'
}

export type ITicketReasonDef = {
  __typename?: 'TicketReasonDef'
  key: TicketReason
  kind: TicketKind
  title: Scalars['String']
}

export type ITicketSort = {
  direction: SortDirection
  field: TicketSortField
}

export enum TicketSortField {
  AssignedToEmail = 'assigned_to_email',
  AssignedToFullName = 'assigned_to_full_name',
  CreatedAt = 'created_at',
  CreatedByEmail = 'created_by_email',
  Id = 'id',
  Kind = 'kind',
  Priority = 'priority',
  Reason = 'reason',
  Status = 'status',
  UserEmail = 'user_email',
  UserFullName = 'user_full_name'
}

export enum TicketStatus {
  CompleteNoMemberResponse = 'complete_no_member_response',
  Completed = 'completed',
  FirstOutreachSent = 'first_outreach_sent',
  InProgress = 'in_progress',
  Pending = 'pending',
  PendingBugFix = 'pending_bug_fix',
  PendingLeadership = 'pending_leadership',
  PendingMemberResponse = 'pending_member_response'
}

export type ITickets = {
  __typename?: 'Tickets'
  reasonDefs: Array<ITicketReasonDef>
}

export type ITimePair = {
  __typename?: 'TimePair'
  interpolated: Scalars['Boolean']
  x: Scalars['ISO8601DateTime']
  y: Scalars['Float']
}

export type IToken = {
  __typename?: 'Token'
  value: Scalars['String']
}

export enum TrackingMode {
  Cgm = 'cgm',
  Logging = 'logging'
}

export type ITutorialStep = {
  __typename?: 'TutorialStep'
  description?: Maybe<Scalars['String']>
  moreUrl?: Maybe<Scalars['String']>
  photoUrlDark?: Maybe<Scalars['String']>
  photoUrlLight?: Maybe<Scalars['String']>
  title: Scalars['String']
  videoUrl?: Maybe<Scalars['String']>
}

export type ITutorials = {
  __typename?: 'Tutorials'
  key: Scalars['String']
  value: Array<ITutorialStep>
}

export enum UiIcon {
  Avocado = 'avocado',
  Bed = 'bed',
  Bluetooth = 'bluetooth',
  BookmarkSimple = 'bookmark_simple',
  Carrot = 'carrot',
  ChartLine = 'chart_line',
  Coaching = 'coaching',
  Confetti = 'confetti',
  Done = 'done',
  Drop = 'drop',
  Fish = 'fish',
  Food = 'food',
  ForkKnife = 'fork_knife',
  Gift = 'gift',
  Glucose = 'glucose',
  Goals = 'goals',
  Hamburger = 'hamburger',
  Health = 'health',
  Lifecycle = 'lifecycle',
  MoonStars = 'moon_stars',
  PersonSimpleWalk = 'person_simple_walk',
  Plant = 'plant',
  Sleep = 'sleep',
  Warning = 'warning'
}

export enum UiInlineAlert {
  Danger = 'danger',
  Info = 'info',
  Neutral = 'neutral',
  Success = 'success',
  Warning = 'warning'
}

export type IUiState = {
  __typename?: 'UIState'
  name: UiStateNames
  value: Scalars['Boolean']
}

export enum UiStateNames {
  CheckoutCompleted = 'checkout_completed',
  DexcomSensorPrompt = 'dexcom_sensor_prompt',
  OwnSensorGlucoseDetected = 'own_sensor_glucose_detected',
  OwnSensorOnboardingCompleted = 'own_sensor_onboarding_completed',
  OwnSensorTutorialCompleted = 'own_sensor_tutorial_completed'
}

export type IUncancelSubscriptionRequestPayload = {
  subscriptionId: Scalars['ID']
}

export type IUncancelSubscriptionResponsePayload = {
  __typename?: 'UncancelSubscriptionResponsePayload'
  subscriptionId: Scalars['ID']
}

export type IUnpauseSubscriptionRequestPayload = {
  resumeDate?: InputMaybe<Scalars['ISO8601Date']>
  subscriptionId: Scalars['ID']
}

export type IUnpauseSubscriptionResponsePayload = {
  __typename?: 'UnpauseSubscriptionResponsePayload'
  resumeDate?: Maybe<Scalars['ISO8601Date']>
  subscriptionId: Scalars['ID']
}

export type IUpdateCoachProfile = {
  active?: InputMaybe<Scalars['Boolean']>
  bio?: InputMaybe<Scalars['String']>
  credentials?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  getHealthieId?: InputMaybe<Scalars['String']>
  managerId?: InputMaybe<Scalars['ID']>
  outOfOffice?: InputMaybe<Scalars['Boolean']>
  schedulingPriority?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  stateLicences?: InputMaybe<Array<Scalars['String']>>
  userId: Scalars['ID']
}

export type IUpdateHealthPortalNotification = {
  completed: Scalars['Boolean']
  notificationId: Scalars['ID']
}

export type IUpdateSubscriptionResponsePayload = {
  __typename?: 'UpdateSubscriptionResponsePayload'
  nextProduct: BillingProduct
  oldProduct?: Maybe<BillingProduct>
  waiveFee?: Maybe<Scalars['Boolean']>
}

export enum UpdateUserApprovalStatuses {
  Approved = 'approved',
  Rejected = 'rejected'
}

export type IUpgradeSubscriptionRequestPayload = {
  nextProduct: BillingProduct
  oldProduct?: InputMaybe<BillingProduct>
  waiveFee?: InputMaybe<Scalars['Boolean']>
}

export type IUpsertLimitedSupportResponsePayload = {
  __typename?: 'UpsertLimitedSupportResponsePayload'
  limitedSupportCancelAt: Scalars['ISO8601Date']
  limitedSupportSubscriptionId?: Maybe<Scalars['ID']>
}

export type IUser = {
  __typename?: 'User'
  activeRefillConsent?: Maybe<IUserRefillConsent>
  addonLimits: Array<IAddonLimit>
  address?: Maybe<IAddress>
  age?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  coachAssignmentInfo: ICoachAssignmentInfo
  coachAssignments?: Maybe<Array<ICoachAssignment>>
  coachChatConversation?: Maybe<IChatConversation>
  coachProfile?: Maybe<ICoachProfile>
  compoundedSemaglutideConsent: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  /** @deprecated use ongoing_dietitian_subscription */
  currentDietitianSubscription?: Maybe<ISubscription>
  dateOfBirth?: Maybe<Scalars['String']>
  dexcomConnection?: Maybe<IDexcomConnectionType>
  dexcomSensorPromptUiState?: Maybe<Scalars['Boolean']>
  eligibleForInsuranceCoverage: Scalars['Boolean']
  email: Scalars['String']
  features: Array<MobileAppFeature>
  firstName: Scalars['String']
  floridaDigitalBillOfRightsConsent: Scalars['Boolean']
  fulfillmentConfiguration: IFulfillmentConfiguration
  fullName: Scalars['String']
  futurePaymentsConsent: Scalars['Boolean']
  goalMetrics: Array<IGoalMetric>
  governmentIdCard?: Maybe<Scalars['String']>
  healthReject?: Maybe<Scalars['String']>
  healthieId?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  intercomDigest?: Maybe<Scalars['String']>
  isCoach: Scalars['Boolean']
  isSupport: Scalars['Boolean']
  kind: UserKind
  lastAnsweredHealthSurveyQuestionKey?: Maybe<SurveysQuestionKey>
  lastAnsweredMedicalHistorySurveyQuestionKey?: Maybe<SurveysQuestionKey>
  lastAppPlatform?: Maybe<Scalars['String']>
  lastAppVersion?: Maybe<Scalars['String']>
  /** @deprecated use last_prescription_approval_request */
  lastApprovalRequest?: Maybe<IApprovalRequest>
  lastAsyncChatAssignment?: Maybe<ICoachAssignment>
  /** @deprecated use last_async_chat_assignment */
  lastCoachAssignment?: Maybe<ICoachAssignment>
  lastCoreSubscription?: Maybe<ISubscription>
  lastDexcomResearchState?: Maybe<IResearchState>
  lastDietitianSubscription?: Maybe<ISubscription>
  lastDoctorAssignment?: Maybe<IDoctorAssignment>
  lastDppApprovalRequest?: Maybe<IApprovalRequest>
  lastGlpApprovalRequest?: Maybe<IApprovalRequest>
  lastMedicalAppointment?: Maybe<IEhrAppointment>
  lastName: Scalars['String']
  lastPrescriptionApprovalRequest?: Maybe<IApprovalRequest>
  lastResearchApprovalRequest?: Maybe<IApprovalRequest>
  lastVideoChatAssignment?: Maybe<ICoachAssignment>
  /** @deprecated use core subscription */
  latestInvoice?: Maybe<IInvoice>
  libreAccount?: Maybe<ILibreAccount>
  nutrisenseAdmin: Scalars['Boolean']
  nutrisenseGetHealthieId?: Maybe<Scalars['Int']>
  ongoingCareNonRefundableConsent: Scalars['Boolean']
  ongoingCoreSubscription?: Maybe<ISubscription>
  ongoingDietitianSubscription?: Maybe<ISubscription>
  organization: IOrganization
  /** @deprecated intents are deprecated */
  paymentFailedProviderErrorMessage?: Maybe<Scalars['String']>
  paymentMethod?: Maybe<IPaymentMethod>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberConsent?: Maybe<Scalars['Boolean']>
  primaryGoal?: Maybe<IGoal>
  referralPartner: IUserReferralPartnerDef
  referralUrl: Scalars['String']
  refillConsentNeeded: Scalars['Boolean']
  role: UserRole
  sensor?: Maybe<ISensor>
  sensorInfo?: Maybe<IUserSensorInfo>
  settings: IUserSettings
  sex?: Maybe<Scalars['String']>
  /** @deprecated deprecated in 2.8 */
  signUpState: SignUpState
  stripeCreditBalance: Scalars['Float']
  stripeId?: Maybe<Scalars['String']>
  subscriptions: Array<ISubscription>
  surveyLinks: Array<ISurveyLink>
  telehealthConsent: Scalars['Boolean']
  timeZone: Scalars['String']
  truepillPatientToken?: Maybe<Scalars['String']>
  uiStates: Array<IUiState>
  userProperties?: Maybe<IUserProperties>
  uxSensorMode: ISensorMode
}

export enum UserApprovalRequestKind {
  Dpp = 'dpp',
  Glp = 'glp',
  Prescription = 'prescription',
  Research = 'research'
}

export enum UserApprovalRequestState {
  Approved = 'approved',
  ConsentRejected = 'consent_rejected',
  ConsentWithdrawn = 'consent_withdrawn',
  DidNotComplete = 'did_not_complete',
  Init = 'init',
  PendingApproval = 'pending_approval',
  PendingMember = 'pending_member',
  PendingRejection = 'pending_rejection',
  PendingUpdate = 'pending_update',
  Rejected = 'rejected',
  Stale = 'stale'
}

export type IUserAuth = {
  __typename?: 'UserAuth'
  /** @deprecated Use user_presenter instead */
  features: Array<MobileAppFeature>
  role?: Maybe<UserRole>
  status?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  token?: Maybe<Scalars['String']>
  user?: Maybe<IUser>
}

export type IUserCalendar = {
  __typename?: 'UserCalendar'
  dates: Array<ICalendarDate>
}

export type IUserCollection = {
  __typename?: 'UserCollection'
  users: Array<IUserMin>
}

export enum UserDailyActionCategory {
  Activity = 'activity',
  Experiment = 'experiment',
  Journal = 'journal',
  Nutrition = 'nutrition',
  Trends = 'trends'
}

export enum UserDailyActionKind {
  Carbs_15Grams_30MinutesBeforeExercise = 'carbs_15_grams_30_minutes_before_exercise',
  Carbs_30GramsFromBread = 'carbs_30_grams_from_bread',
  Carbs_30GramsFromDessert = 'carbs_30_grams_from_dessert',
  Carbs_30GramsFromFruit = 'carbs_30_grams_from_fruit',
  Carbs_30GramsFromRice = 'carbs_30_grams_from_rice',
  Carbs_30GramsFromStarchyVegetable = 'carbs_30_grams_from_starchy_vegetable',
  CompareMeals = 'compare_meals',
  DigestionQuality = 'digestion_quality',
  FastingWindow = 'fasting_window',
  Fiber_25Grams = 'fiber_25_grams',
  Fiber_38Grams = 'fiber_38_grams',
  GlucoseAverage = 'glucose_average',
  GlucoseMax = 'glucose_max',
  GlucoseMorningAverage = 'glucose_morning_average',
  GlucoseSleepAverage = 'glucose_sleep_average',
  GlucoseTimeInRange = 'glucose_time_in_range',
  GlucoseVariability = 'glucose_variability',
  HalfOfPlateNonStarchyVegetables = 'half_of_plate_non_starchy_vegetables',
  HighFiberAndProteinBreakfast = 'high_fiber_and_protein_breakfast',
  HungerLevels = 'hunger_levels',
  LastMeal_3HoursBeforeBed = 'last_meal_3_hours_before_bed',
  LowCarbDinner = 'low_carb_dinner',
  NoNakedCarbs = 'no_naked_carbs',
  NoScreenTime_1HourBeforeBed = 'no_screen_time_1_hour_before_bed',
  NonStarchVegetables_3To_5Cups = 'non_starch_vegetables_3_to_5_cups',
  Protein_30GramsPerMeal = 'protein_30_grams_per_meal',
  ProteinAfterExercise = 'protein_after_exercise',
  QuarterOfPlateProtein = 'quarter_of_plate_protein',
  Sleep_7To_9Hours = 'sleep_7_to_9_hours',
  SleepQuality = 'sleep_quality',
  Steps_7500 = 'steps_7500',
  StressLevels = 'stress_levels',
  VegetableOrProteinBeforeStarchyCarbs = 'vegetable_or_protein_before_starchy_carbs',
  Walk_15To_30MinutesAfterMeals = 'walk_15_to_30_minutes_after_meals',
  Water_8Glasses = 'water_8_glasses',
  Weight = 'weight'
}

export enum UserDailyActionStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type IUserDynamicFilter = {
  field: UserDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum UserDynamicFilterField {
  AddressCity = 'address_city',
  AddressState = 'address_state',
  AddressStreet = 'address_street',
  AddressStreet_2 = 'address_street_2',
  AddressZipCode = 'address_zip_code',
  ApprovalRequestsCreatedAt = 'approval_requests_created_at',
  ApprovalRequestsState = 'approval_requests_state',
  CoachProfileGetHealthieId = 'coach_profile_get_healthie_id',
  CoachProfileManagerId = 'coach_profile_manager_id',
  CommunicationFrequencyValue = 'communication_frequency_value',
  /** @deprecated use ongoing_dietitian_subscription_cancel_at */
  CurrentDietitianSubscriptionCancelAt = 'current_dietitian_subscription_cancel_at',
  DateOfBirth = 'date_of_birth',
  DexcomSensorPromptUiState = 'dexcom_sensor_prompt_ui_state',
  DietitianRequestValue = 'dietitian_request_value',
  DietitianSensorCountNotesValue = 'dietitian_sensor_count_notes_value',
  Email = 'email',
  FulfillmentConfigurationSensorKind = 'fulfillment_configuration_sensor_kind',
  FullName = 'full_name',
  GoalKind = 'goal_kind',
  HealthGoalValue = 'health_goal_value',
  Id = 'id',
  IsCoach = 'is_coach',
  IsSupport = 'is_support',
  Kind = 'kind',
  LastAsyncChatAssignmentCoachId = 'last_async_chat_assignment_coach_id',
  LastAsyncChatAssignmentKind = 'last_async_chat_assignment_kind',
  LastAsyncChatAssignmentState = 'last_async_chat_assignment_state',
  /** @deprecated use last_async_chat_assignment_coach_id */
  LastCoachAssignmentCoachId = 'last_coach_assignment_coach_id',
  /** @deprecated use last_async_chat_assignment_kind */
  LastCoachAssignmentKind = 'last_coach_assignment_kind',
  /** @deprecated use last_async_chat_assignment_state */
  LastCoachAssignmentState = 'last_coach_assignment_state',
  LastCoreSubscriptionLatestInvoiceLatestChargeCreated = 'last_core_subscription_latest_invoice_latest_charge_created',
  LastCoreSubscriptionLatestInvoiceLatestChargeStatus = 'last_core_subscription_latest_invoice_latest_charge_status',
  LastCoreSubscriptionLatestInvoicePrimaryInvoiceLinePlanNickname = 'last_core_subscription_latest_invoice_primary_invoice_line_plan_nickname',
  LastCoreSubscriptionPrimaryProduct = 'last_core_subscription_primary_product',
  LastCoreSubscriptionStatus = 'last_core_subscription_status',
  /** @deprecated use last_core_subscription status */
  LastCoreSubscriptionStripeStatus = 'last_core_subscription_stripe_status',
  LastDietitianSubscriptionStatus = 'last_dietitian_subscription_status',
  /** @deprecated use last_dietitian_subscription_status */
  LastDietitianSubscriptionStripeStatus = 'last_dietitian_subscription_stripe_status',
  NutritionistGroupValue = 'nutritionist_group_value',
  OneMonthActiveFeedbackDietitianValue = 'one_month_active_feedback_dietitian_value',
  OngoingDietitianSubscriptionCancelAt = 'ongoing_dietitian_subscription_cancel_at',
  OngoingDietitianSubscriptionPlansNickname = 'ongoing_dietitian_subscription_plans_nickname',
  PhoneNumber = 'phone_number',
  Role = 'role',
  ScheduledVideoCallStatusValue = 'scheduled_video_call_status_value',
  SensorInfoFirstStartTime = 'sensor_info_first_start_time',
  SensorInfoLastSensorExpirationDate = 'sensor_info_last_sensor_expiration_date',
  SensorInfoLastSensorExpired = 'sensor_info_last_sensor_expired',
  SensorInfoReplacementRequestsCount = 'sensor_info_replacement_requests_count',
  SensorInfoTotalCount = 'sensor_info_total_count',
  StripeId = 'stripe_id',
  TestGroupValue = 'test_group_value'
}

export type IUserDynamicFilterItem = {
  items: Array<IUserDynamicFilter>
  operator: DynamicFilterOperator
}

export enum UserExperienceSensorModeKind {
  ActiveResearchDexcom = 'active_research_dexcom',
  Default = 'default',
  OwnSensorDexcomG6 = 'own_sensor_dexcom_g6',
  OwnSensorDexcomG7 = 'own_sensor_dexcom_g7',
  OwnSensorDexcomStelo = 'own_sensor_dexcom_stelo',
  OwnSensorLibre1 = 'own_sensor_libre1',
  OwnSensorLibre2 = 'own_sensor_libre2',
  OwnSensorLibre3 = 'own_sensor_libre3',
  PendingLibre3 = 'pending_libre3',
  PendingResearchDexcom = 'pending_research_dexcom'
}

export enum UserFileTag {
  BodyPhoto = 'body_photo',
  DexcomResearchConsent = 'dexcom_research_consent',
  DppHbA1cEvidence = 'dpp_hb_a1c_evidence',
  DppWeightEvidence = 'dpp_weight_evidence',
  GovernmentIdCard = 'government_id_card',
  HealthInsuranceCard = 'health_insurance_card',
  IntercomAttachment = 'intercom_attachment',
  LabWorkResults = 'lab_work_results'
}

export enum UserGoalKind {
  ImproveGlucoseValues = 'improve_glucose_values',
  LearnAboutGlucoseLevels = 'learn_about_glucose_levels',
  LoseWeight = 'lose_weight',
  ManageChronicCondition = 'manage_chronic_condition',
  OptimizeFitnessPerformance = 'optimize_fitness_performance',
  Other = 'other',
  PreventDisease = 'prevent_disease',
  PromoteLongevity = 'promote_longevity'
}

export enum UserGoalMetricKind {
  GlucoseMax = 'glucose_max',
  GlucoseMean = 'glucose_mean',
  GlucoseMin = 'glucose_min',
  GlucoseMorningAverage = 'glucose_morning_average',
  GlucoseSleepAverage = 'glucose_sleep_average',
  GlucoseStandardDeviation = 'glucose_standard_deviation',
  GlucoseTimeWithinRange = 'glucose_time_within_range',
  JournalAppetite = 'journal_appetite',
  JournalEnergy = 'journal_energy',
  JournalFocus = 'journal_focus',
  JournalMood = 'journal_mood',
  JournalStress = 'journal_stress',
  MeasurementsWeight = 'measurements_weight'
}

export enum UserInsurancePolicyEligibilityStatus {
  Coinsurance = 'coinsurance',
  Copay = 'copay',
  Deductible = 'deductible',
  Eligible = 'eligible',
  NotEligible = 'not_eligible',
  NotSupported = 'not_supported',
  Pending = 'pending'
}

export enum UserInsurancePolicyStateKind {
  Canceled = 'canceled',
  CanceledInsuranceNotAccepted = 'canceled_insurance_not_accepted',
  CanceledNoProvidersInn = 'canceled_no_providers_inn',
  Created = 'created',
  Eligible = 'eligible',
  EligiblePatientResponsibility = 'eligible_patient_responsibility',
  EligiblePreAuth = 'eligible_pre_auth',
  Hold = 'hold',
  InReview = 'in_review',
  NotEligible = 'not_eligible',
  NotSupported = 'not_supported'
}

export enum UserInsurancePolicyStatus {
  Created = 'created',
  InReview = 'in_review',
  Reviewed = 'reviewed',
  Submitted = 'submitted'
}

export enum UserKind {
  Influencer = 'influencer',
  Internal = 'internal',
  Investor = 'investor',
  Regular = 'regular'
}

export type IUserMin = {
  __typename?: 'UserMin'
  email: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  phoneNumber: Scalars['String']
  timeZone: Scalars['String']
}

export type IUserProperties = {
  __typename?: 'UserProperties'
  communicationFrequency?: Maybe<IUserProperty>
  complimentarySupportDecision?: Maybe<IUserProperty>
  dietitianRequest?: Maybe<IUserProperty>
  dietitianSensorCountNotes?: Maybe<IUserProperty>
  healthGoal?: Maybe<IUserProperty>
  nutritionistGroup?: Maybe<IUserProperty>
  oneMonthActiveFeedbackDietitian?: Maybe<IUserProperty>
  scheduledVideoCallStatus?: Maybe<IUserProperty>
  testGroup?: Maybe<IUserProperty>
  videoCallRescheduleFreePassUsed?: Maybe<IUserProperty>
}

export type IUserPropertiesCollection = {
  __typename?: 'UserPropertiesCollection'
  userProperties: Array<IUserProperty>
}

export type IUserProperty = {
  __typename?: 'UserProperty'
  id: Scalars['Int']
  kind: UserPropertyKind
  userId: Scalars['ID']
  value: Scalars['String']
}

export enum UserPropertyKind {
  ActiveInterventions = 'active_interventions',
  Background = 'background',
  BmiRange = 'bmi_range',
  CoachingStyle = 'coaching_style',
  CommunicationFrequency = 'communication_frequency',
  ComplimentarySupportDecision = 'complimentary_support_decision',
  CustomKeyPoint = 'custom_key_point',
  Diet = 'diet',
  DietitianNotes = 'dietitian_notes',
  DietitianRequest = 'dietitian_request',
  DietitianSensorCountNotes = 'dietitian_sensor_count_notes',
  Education = 'education',
  FoodAllergies = 'food_allergies',
  FutureInterventions = 'future_interventions',
  GlucoseManagementKnowledge = 'glucose_management_knowledge',
  HealthConditions = 'health_conditions',
  HealthGoal = 'health_goal',
  Height = 'height',
  InitialInsuranceCallDuration = 'initial_insurance_call_duration',
  InsuranceReimbursementInterest = 'insurance_reimbursement_interest',
  InsuranceSurveySkipReason = 'insurance_survey_skip_reason',
  InsuranceVideoCallGoal = 'insurance_video_call_goal',
  Libre_3Outage = 'libre_3_outage',
  Libre_3OutagePreShipmentNotice = 'libre_3_outage_pre_shipment_notice',
  Medications = 'medications',
  NutritionistGroup = 'nutritionist_group',
  OneMonthActiveFeedbackDietitian = 'one_month_active_feedback_dietitian',
  OwnCgmDevice = 'own_cgm_device',
  OwnGlucometer = 'own_glucometer',
  PrimaryJoiningGoal = 'primary_joining_goal',
  ScheduledVideoCallStatus = 'scheduled_video_call_status',
  StepsPerDay = 'steps_per_day',
  TestGroup = 'test_group',
  VideoCallRescheduleFreePassUsed = 'video_call_reschedule_free_pass_used',
  Weight = 'weight'
}

export enum UserReferralPartner {
  LoseIt = 'lose_it',
  NoPartner = 'no_partner',
  Tovala = 'tovala'
}

export type IUserReferralPartnerDef = {
  __typename?: 'UserReferralPartnerDef'
  key: UserReferralPartner
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  schedulingWithoutPaymentMethod: Scalars['Boolean']
}

export type IUserRefillConsent = {
  __typename?: 'UserRefillConsent'
  givenAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  invalidatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export enum UserRole {
  Admin = 'admin',
  CustomerSupport = 'customer_support',
  Engineer = 'engineer',
  ExternalAdmin = 'external_admin',
  Nutritionist = 'nutritionist',
  NutritionistManager = 'nutritionist_manager',
  Operations = 'operations',
  User = 'user'
}

export type IUserSensorInfo = {
  __typename?: 'UserSensorInfo'
  firstActivationDate?: Maybe<Scalars['ISO8601Date']>
  firstStartTime?: Maybe<Scalars['ISO8601DateTime']>
  lastSensor?: Maybe<ISensor>
  replacementRequestsCount: Scalars['Int']
  totalCount: Scalars['Int']
}

export enum UserSensorKind {
  DexcomG7 = 'dexcom_g7',
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre3 = 'libre3'
}

export type IUserSettings = {
  __typename?: 'UserSettings'
  /** @deprecated Removed in v2.6.0 */
  appVersionReminder: Scalars['Boolean']
  canScanExpired: Scalars['Boolean']
  dashboardGraph: Scalars['String']
  dexcomSync: Scalars['Boolean']
  displayActivityScore: Scalars['Boolean']
  displayDailyScore: Scalars['Boolean']
  displayMealScore: Scalars['Boolean']
  fastingEnd: Scalars['Int']
  fastingStart: Scalars['Int']
  glucoseHighThreshold: Scalars['Int']
  glucoseLowThreshold: Scalars['Int']
  googleFitSync: Scalars['Boolean']
  healthKitSync: Scalars['Boolean']
  id: Scalars['ID']
  integrationsSyncSettings: IIntegrationsSync
  journalNotifications: Scalars['Boolean']
  ketoMojoSync: Scalars['Boolean']
  ketoneHighThreshold: Scalars['Float']
  ketoneLowThreshold: Scalars['Float']
  libreViewSync: Scalars['Boolean']
  macroGoalsDaily: IMacroGoals
  patternsNotifications: Scalars['Boolean']
  reminders: Scalars['Boolean']
  scanReminder: Scalars['Int']
  showNetCarbs: Scalars['Boolean']
  statsSettings: Array<IStatsSettings>
  /** @deprecated Removed in v2.6.0 */
  trackingMode: TrackingMode
  unitSystem: Scalars['String']
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type IUserSort = {
  direction: SortDirection
  field: UserSortField
}

export enum UserSortField {
  AddressCity = 'address_city',
  AddressState = 'address_state',
  AddressStreet = 'address_street',
  AddressStreet_2 = 'address_street_2',
  AddressZipCode = 'address_zip_code',
  ApprovalRequestsCreatedAt = 'approval_requests_created_at',
  ApprovalRequestsState = 'approval_requests_state',
  CoachAssignmentInfoActiveAssignmentsCount = 'coach_assignment_info_active_assignments_count',
  CoachProfileEndDate = 'coach_profile_end_date',
  CoachProfileStartDate = 'coach_profile_start_date',
  CommunicationFrequencyValue = 'communication_frequency_value',
  /** @deprecated use ongoing_dietitian_subscription_cancel_at */
  CurrentDietitianSubscriptionCancelAt = 'current_dietitian_subscription_cancel_at',
  DateOfBirth = 'date_of_birth',
  DietitianRequestValue = 'dietitian_request_value',
  DietitianSensorCountNotesValue = 'dietitian_sensor_count_notes_value',
  Email = 'email',
  FulfillmentConfigurationSensorKind = 'fulfillment_configuration_sensor_kind',
  FullName = 'full_name',
  GoalKind = 'goal_kind',
  HealthGoalValue = 'health_goal_value',
  Id = 'id',
  Kind = 'kind',
  LastAsyncChatAssignmentCoachFullName = 'last_async_chat_assignment_coach_full_name',
  LastAsyncChatAssignmentKind = 'last_async_chat_assignment_kind',
  LastAsyncChatAssignmentState = 'last_async_chat_assignment_state',
  /** @deprecated use last_async_chat_assignment_coach_full_name */
  LastCoachAssignmentCoachFullName = 'last_coach_assignment_coach_full_name',
  /** @deprecated use last_async_chat_assignment_kind */
  LastCoachAssignmentKind = 'last_coach_assignment_kind',
  /** @deprecated use last_async_chat_assignment_state */
  LastCoachAssignmentState = 'last_coach_assignment_state',
  LastCoreSubscriptionLatestInvoiceLatestChargeCreated = 'last_core_subscription_latest_invoice_latest_charge_created',
  LastCoreSubscriptionLatestInvoiceLatestChargeStatus = 'last_core_subscription_latest_invoice_latest_charge_status',
  LastCoreSubscriptionLatestInvoicePrimaryInvoiceLinePlanNickname = 'last_core_subscription_latest_invoice_primary_invoice_line_plan_nickname',
  LastCoreSubscriptionPrimaryProduct = 'last_core_subscription_primary_product',
  LastCoreSubscriptionStatus = 'last_core_subscription_status',
  /** @deprecated use last_core_subscription_status */
  LastCoreSubscriptionStripeStatus = 'last_core_subscription_stripe_status',
  LastDietitianSubscriptionStatus = 'last_dietitian_subscription_status',
  /** @deprecated use last_dietitian_subscription_status */
  LastDietitianSubscriptionStripeStatus = 'last_dietitian_subscription_stripe_status',
  NutritionistGroupValue = 'nutritionist_group_value',
  OneMonthActiveFeedbackDietitianValue = 'one_month_active_feedback_dietitian_value',
  OngoingDietitianSubscriptionCancelAt = 'ongoing_dietitian_subscription_cancel_at',
  OngoingDietitianSubscriptionPlansNickname = 'ongoing_dietitian_subscription_plans_nickname',
  PhoneNumber = 'phone_number',
  ScheduledVideoCallStatusValue = 'scheduled_video_call_status_value',
  SensorInfoFirstStartTime = 'sensor_info_first_start_time',
  SensorInfoLastSensorExpirationDate = 'sensor_info_last_sensor_expiration_date',
  SensorInfoLastSensorExpired = 'sensor_info_last_sensor_expired',
  SensorInfoReplacementRequestsCount = 'sensor_info_replacement_requests_count',
  SensorInfoTotalCount = 'sensor_info_total_count',
  TestGroupValue = 'test_group_value'
}

export type IValuePair = INumericPair | IRangePair | IStringPair | ITimePair

export type ICoreAccountRequestFieldsFragment = {
  __typename?: 'AccountRequest'
  id: string
  escalatedAt?: any | null
  processedAt?: any | null
  reviewedAt?: any | null
  rejectedAt?: any | null
  failedAt?: any | null
  priority: OpsAccountRequestPriority
  kind: OpsAccountRequestKind
  errorMessage?: string | null
  notes?: string | null
  status: OpsAccountRequestStatus
  user: { __typename?: 'User'; id: string; fullName: string; email: string }
  escalatedBy?: { __typename?: 'User'; fullName: string } | null
  processedBy?: { __typename?: 'User'; fullName: string } | null
  reviewedBy?: { __typename?: 'User'; fullName: string } | null
  rejectedBy?: { __typename?: 'User'; fullName: string } | null
  payload:
    | {
        __typename?: 'CancelSubscriptionResponsePayload'
        subscriptionId: string
        immediateCancellation: boolean
        waiveEarlyCancellationFee: boolean
        waiveEarlyCancellationSurcharge: boolean
        cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
      }
    | {
        __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
        subscriptionId: string
        billingCycleAnchor: any
      }
    | { __typename?: 'CreditResponsePayload'; centsAmount: number }
    | {
        __typename?: 'DeleteDuplicateAccountResponsePayload'
        duplicateUserId: string
        creditAmountInCents: number
        replaceWithDuplicateUserEmail?: boolean | null
      }
    | { __typename?: 'MiscRequestResponsePayload'; requestDetails: string }
    | {
        __typename?: 'OneOffAddonResponsePayload'
        product: BillingProduct
        paid: boolean
        discountCentsAmount?: number | null
      }
    | {
        __typename?: 'PauseSubscriptionResponsePayload'
        subscriptionId: string
        cycleCount: number
        reason: OpsAccountRequestKindsPauseSubscriptionReason
      }
    | {
        __typename?: 'RefundResponsePayload'
        chargeId: string
        amountInCents: number
        subscriptionCancellationRequired: boolean
        subscriptionCancellationReason?: OpsAccountRequestKindsCancelSubscriptionReason | null
        refundReason?: {
          __typename?: 'OpsAccountRequestKindsRefundReasonDef'
          journey: string
          reasonOne: string
          reasonTwo: string
        } | null
      }
    | { __typename?: 'UncancelSubscriptionResponsePayload'; subscriptionId: string }
    | {
        __typename?: 'UnpauseSubscriptionResponsePayload'
        subscriptionId: string
        resumeDate?: any | null
      }
    | {
        __typename?: 'UpdateSubscriptionResponsePayload'
        oldProduct?: BillingProduct | null
        nextProduct: BillingProduct
      }
    | {
        __typename?: 'UpsertLimitedSupportResponsePayload'
        limitedSupportSubscriptionId?: string | null
        limitedSupportCancelAt: any
      }
}

export type ICancelSubscriptionResponsePayloadFragment = {
  __typename?: 'CancelSubscriptionResponsePayload'
  subscriptionId: string
  immediateCancellation: boolean
  waiveEarlyCancellationFee: boolean
  waiveEarlyCancellationSurcharge: boolean
  cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
}

export type IChangeSubscriptionBillingCycleResponsePayloadFragment = {
  __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
  subscriptionId: string
  billingCycleAnchor: any
}

export type ICreditResponsePayloadFragment = {
  __typename?: 'CreditResponsePayload'
  centsAmount: number
}

export type IDeleteDuplicateAccountResponsePayloadFragment = {
  __typename?: 'DeleteDuplicateAccountResponsePayload'
  duplicateUserId: string
  creditAmountInCents: number
  replaceWithDuplicateUserEmail?: boolean | null
}

export type IMiscRequestResponsePayloadFragment = {
  __typename?: 'MiscRequestResponsePayload'
  requestDetails: string
}

export type IOneOffAddonResponsePayloadFragment = {
  __typename?: 'OneOffAddonResponsePayload'
  product: BillingProduct
  paid: boolean
  discountCentsAmount?: number | null
}

export type IPauseSubscriptionResponsePayloadFragment = {
  __typename?: 'PauseSubscriptionResponsePayload'
  subscriptionId: string
  cycleCount: number
  reason: OpsAccountRequestKindsPauseSubscriptionReason
}

export type IRefundResponsePayloadFragment = {
  __typename?: 'RefundResponsePayload'
  chargeId: string
  amountInCents: number
  subscriptionCancellationRequired: boolean
  subscriptionCancellationReason?: OpsAccountRequestKindsCancelSubscriptionReason | null
  refundReason?: {
    __typename?: 'OpsAccountRequestKindsRefundReasonDef'
    journey: string
    reasonOne: string
    reasonTwo: string
  } | null
}

export type IUncancelSubscriptionResponsePayloadFragment = {
  __typename?: 'UncancelSubscriptionResponsePayload'
  subscriptionId: string
}

export type IUnpauseSubscriptionResponsePayloadFragment = {
  __typename?: 'UnpauseSubscriptionResponsePayload'
  subscriptionId: string
  resumeDate?: any | null
}

export type IUpdateSubscriptionResponsePayloadFragment = {
  __typename?: 'UpdateSubscriptionResponsePayload'
  oldProduct?: BillingProduct | null
  nextProduct: BillingProduct
}

export type IUpsertLimitedSupportResponsePayloadFragment = {
  __typename?: 'UpsertLimitedSupportResponsePayload'
  limitedSupportSubscriptionId?: string | null
  limitedSupportCancelAt: any
}

export type ICoreAddressFieldsFragment = {
  __typename?: 'Address'
  id: string
  name: string
  street: string
  street2: string
  city: string
  state?: AddressState | null
  zipCode: string
  country?: string | null
}

export type IAllEventsActivityFragment = {
  __typename: 'Activity'
  createdAt: any
  description: string
  endedAt: any
  favorite: boolean
  id: string
  length: number
  occurredAt: any
  startedAt: any
  status?: string | null
  title: string
  type: string
  statistics: {
    __typename?: 'EntryStatistics'
    aucScore?: number | null
    glucose2hrDelta?: number | null
    glucoseDelta?: number | null
    glucoseMax?: number | null
    glucoseMin?: number | null
    glucoseScore?: number | null
    glucoseScore2hrDelta?: number | null
    glucoseScoreAuc?: number | null
    glucoseScoreDelta?: number | null
    glucoseScorePeak?: number | null
  }
}

export type IAllEventsJournalEntryFragment = {
  __typename?: 'JournalEntry'
  id: string
  title: string
  body: string
  occurredAt: any
  createdAt: any
  creatorId: string
  score?: number | null
  measurements?: Array<{
    __typename: 'Measurement'
    createdAt: any
    cumulative: boolean
    description: string
    id: string
    occurredAt: any
    timestamp: any
    title: string
    type: string
    units: string
    value?: number | null
    values?:
      | { __typename?: 'BloodPressureMeasurement'; diastolic: number; systolic: number }
      | { __typename?: 'SingleValueMeasurement'; value: number }
      | null
    scan?: { __typename?: 'Scan'; createdAt: any; id: string } | null
  }> | null
}

export type IAllEventsMealFragment = {
  __typename: 'Meal'
  avatar?: string | null
  occurredAt: any
  createdAt: any
  description: string
  experimentId?: string | null
  favorite: boolean
  source: MealSource
  id: string
  title: string
  kind?: MealKind | null
  updatedAt: any
  mealCreatedBy: { __typename?: 'User'; id: string }
  statistics: {
    __typename?: 'EntryStatistics'
    aucScore?: number | null
    glucose2hrDelta?: number | null
    glucoseDelta?: number | null
    glucoseMax?: number | null
    glucoseMin?: number | null
    glucoseScore?: number | null
    glucoseScore2hrDelta?: number | null
    glucoseScoreAuc?: number | null
    glucoseScoreDelta?: number | null
    glucoseScorePeak?: number | null
  }
  ingredients: Array<{
    __typename?: 'Ingredient'
    calories: number
    createdAt: any
    description: string
    id: string
    servingAmount: number
    servingUnits: string
  }>
  glucose: {
    __typename?: 'EntryGlucose'
    range: Array<number>
    healthyRange: Array<number>
    values: Array<{ __typename?: 'Measurement'; timestamp: any; value?: number | null }>
  }
  nutrition: Array<{ __typename?: 'KeyValuePairFloat'; key: string; value: number }>
}

export type IAllEventsMeasurementFragment = {
  __typename: 'Measurement'
  createdAt: any
  cumulative: boolean
  description: string
  id: string
  occurredAt: any
  timestamp: any
  title: string
  type: string
  units: string
  value?: number | null
  scan?: { __typename?: 'Scan'; createdAt: any; id: string } | null
  values?:
    | { __typename?: 'BloodPressureMeasurement'; diastolic: number; systolic: number }
    | { __typename?: 'SingleValueMeasurement'; value: number }
    | null
}

export type IAllEventsNoteFragment = {
  __typename: 'Note'
  body: string
  createdAt: any
  creatorId: string
  id: string
  occurredAt: any
  title: string
  createdBy: string
}

export type IAllMeasurementsMeasurementFragment = {
  __typename?: 'Measurement'
  id: string
  occurredAt: any
  timestamp: any
  type: string
  units: string
  value?: number | null
}

export type ICoreAppointmentFieldsFragment = {
  __typename?: 'HealthPortalAppointment'
  id: string
  meetingAt?: any | null
  meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
  actualDuration?: number | null
  title?: string | null
  appointmentType?: InsuranceBerryStreetAppointmentType | null
  chargeType?: InsuranceBerryStreetAppointmentChargeType | null
  externalVideochatUrl?: string | null
  startVideochatUrl?: string | null
  agenda?: string | null
  user: { __typename?: 'User'; id: string; fullName: string; timeZone: string }
  insurancePolicy?: {
    __typename?: 'InsurancePolicy'
    unitsLeft?: number | null
    unitsUsed?: number | null
    unitsAuthorized?: number | null
    lastState?: {
      __typename?: 'InsurancePolicyState'
      kind: UserInsurancePolicyStateKind
      reason?: string | null
    } | null
  } | null
}

export type ICoreChatConversationFieldsFragment = {
  __typename?: 'ChatConversation'
  id: string
  kind: ChatConversationKind
  state: ChatConversationState
  read: boolean
  candidateRead: boolean
  intercomCreatedAt: any
  intercomBackfillState?: ChatConversationIntercomBackfillState | null
  lastMessage?: {
    __typename?: 'ChatConversationMessage'
    id: string
    value: string
    sender?: { __typename?: 'User'; id: string; fullName: string } | null
  } | null
  user: { __typename?: 'User'; id: string; fullName: string }
}

export type ICoreCoachAssignmentFieldsFragment = {
  __typename?: 'CoachAssignment'
  id: string
  state: CoachAssignmentState
  last: boolean
  createdAt: any
  assignedAt?: any | null
  deactivatedAt?: any | null
  kind?: CoachAssignmentKind | null
  supportType: CoachAssignmentSupportType
  coach?: { __typename?: 'User'; id: string; fullName: string } | null
  appointment?: { __typename?: 'Appointment'; date?: string | null } | null
}

export type ICoreCohortFieldsFragment = {
  __typename?: 'Cohort'
  id: string
  name: string
  kind: CoursesCohortKind
  state: CoursesCohortState
  instructor?: {
    __typename?: 'User'
    createdAt: any
    email: string
    timeZone: string
    avatar?: string | null
    firstName: string
    fullName: string
    id: string
    lastName: string
    role: UserRole
    sex?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    lastAppVersion?: string | null
    lastAppPlatform?: string | null
    stripeId?: string | null
    healthieId?: number | null
    stripeCreditBalance: number
    kind: UserKind
    age?: string | null
    isCoach: boolean
    lastPrescriptionApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      state: UserApprovalRequestState
      createdAt: any
    } | null
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
    sensorInfo?: {
      __typename?: 'UserSensorInfo'
      totalCount: number
      replacementRequestsCount: number
    } | null
    settings: {
      __typename?: 'UserSettings'
      id: string
      appVersionReminder: boolean
      dashboardGraph: string
      dexcomSync: boolean
      displayActivityScore: boolean
      displayDailyScore: boolean
      displayMealScore: boolean
      fastingEnd: number
      fastingStart: number
      glucoseHighThreshold: number
      glucoseLowThreshold: number
      googleFitSync: boolean
      healthKitSync: boolean
      ketoMojoSync: boolean
      ketoneHighThreshold: number
      ketoneLowThreshold: number
      reminders: boolean
      scanReminder: number
      showNetCarbs: boolean
      unitSystem: string
      integrationsSyncSettings: {
        __typename?: 'IntegrationsSync'
        lastSyncedHealth?: any | null
        lastSyncedLibreView?: any | null
        lastSyncedDexcom?: any | null
        lastSyncedKetoMojo?: any | null
      }
      macroGoalsDaily: {
        __typename?: 'MacroGoals'
        calories: number
        carbs: number
        fat: number
        netCarbs?: number | null
        protein: number
      }
    }
    sensor?: {
      __typename?: 'Sensor'
      activationDate: any
      baselineAdjustment: number
      daysRemaining: number
      expirationDate: any
      expirationTime: any
      expired: boolean
      model: string
      serialNumber: string
      status?: string | null
    } | null
    organization: { __typename?: 'Organization'; id: number; name: string }
    ongoingDietitianSubscription?: {
      __typename?: 'Subscription'
      id: string
      dietitianAddOn: boolean
      stripeStatus: string
      status: SubscriptionStatus
      paidMonthsCount: number
      cancelAt?: any | null
      startAt: any
      paused: boolean
      resumesAt?: any | null
      commitmentEndAt?: any | null
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    ongoingCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      paidMonthsCount: number
      paused: boolean
      cancelAt?: any | null
      commitmentEndAt?: any | null
      plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    lastDietitianSubscription?: {
      __typename?: 'Subscription'
      stripeStatus: string
      status: SubscriptionStatus
      paused: boolean
      resumesAt?: any | null
    } | null
    lastCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      stripeStatus: string
      status: SubscriptionStatus
      latestInvoice?: {
        __typename?: 'Invoice'
        latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
      } | null
    } | null
    lastAsyncChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    lastVideoChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    activeRefillConsent?: {
      __typename?: 'UserRefillConsent'
      id: string
      givenAt: any
      invalidatedAt?: any | null
    } | null
    fulfillmentConfiguration: {
      __typename?: 'FulfillmentConfiguration'
      sensorKind: UserSensorKind
    }
    lastDexcomResearchState?: { __typename?: 'ResearchState'; status: DexcomResearchStatus } | null
    coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
    primaryGoal?: {
      __typename?: 'Goal'
      title: string
      kind: UserGoalKind
      description?: string | null
    } | null
    goalMetrics: Array<{
      __typename?: 'GoalMetric'
      primary: boolean
      kind: UserGoalMetricKind
      lowThresholdValue?: number | null
      highThresholdValue?: number | null
      section: string
      statKey: string
    }>
    coachProfile?: {
      __typename?: 'CoachProfile'
      stateLicences: Array<string>
      outOfOffice: boolean
    } | null
    referralPartner: {
      __typename?: 'UserReferralPartnerDef'
      key: UserReferralPartner
      logoUrl?: string | null
    }
  } | null
  members: Array<{
    __typename?: 'DppUser'
    id: string
    fullName: string
    email: string
    preferredCallDays: Array<string>
    preferredCallTimes: Array<string>
    lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
  }>
  sessions: Array<{
    __typename?: 'CohortSession'
    id: string
    kind: CoursesCohortSessionKind
    name: string
    position: number
    scheduledAt: any
    scheduledEndAt: any
    cancelledAt?: any | null
    joinUrl?: string | null
  }>
}

export type ICoreCohortSessionFieldsFragment = {
  __typename?: 'CohortSession'
  id: string
  kind: CoursesCohortSessionKind
  name: string
  position: number
  scheduledAt: any
  scheduledEndAt: any
  cancelledAt?: any | null
  joinUrl?: string | null
}

export type ICoreCohortSessionAttendanceFieldsFragment = {
  __typename?: 'CohortSessionAttendance'
  id: string
  state: CoursesCohortSessionAttendanceState
}

export type ICoreDppUserFieldsFragment = {
  __typename?: 'DppUser'
  id: string
  fullName: string
  email: string
  preferredCallDays: Array<string>
  preferredCallTimes: Array<string>
  lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
}

export type IUserFragment = {
  __typename?: 'User'
  createdAt: any
  email: string
  timeZone: string
  avatar?: string | null
  firstName: string
  fullName: string
  id: string
  lastName: string
  role: UserRole
  sex?: string | null
  dateOfBirth?: string | null
  phoneNumber?: string | null
  lastAppVersion?: string | null
  lastAppPlatform?: string | null
  stripeId?: string | null
  healthieId?: number | null
  stripeCreditBalance: number
  kind: UserKind
  age?: string | null
  isCoach: boolean
  lastPrescriptionApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    state: UserApprovalRequestState
    createdAt: any
  } | null
  address?: {
    __typename?: 'Address'
    id: string
    name: string
    street: string
    street2: string
    city: string
    state?: AddressState | null
    zipCode: string
    country?: string | null
  } | null
  sensorInfo?: {
    __typename?: 'UserSensorInfo'
    totalCount: number
    replacementRequestsCount: number
  } | null
  settings: {
    __typename?: 'UserSettings'
    id: string
    appVersionReminder: boolean
    dashboardGraph: string
    dexcomSync: boolean
    displayActivityScore: boolean
    displayDailyScore: boolean
    displayMealScore: boolean
    fastingEnd: number
    fastingStart: number
    glucoseHighThreshold: number
    glucoseLowThreshold: number
    googleFitSync: boolean
    healthKitSync: boolean
    ketoMojoSync: boolean
    ketoneHighThreshold: number
    ketoneLowThreshold: number
    reminders: boolean
    scanReminder: number
    showNetCarbs: boolean
    unitSystem: string
    integrationsSyncSettings: {
      __typename?: 'IntegrationsSync'
      lastSyncedHealth?: any | null
      lastSyncedLibreView?: any | null
      lastSyncedDexcom?: any | null
      lastSyncedKetoMojo?: any | null
    }
    macroGoalsDaily: {
      __typename?: 'MacroGoals'
      calories: number
      carbs: number
      fat: number
      netCarbs?: number | null
      protein: number
    }
  }
  sensor?: {
    __typename?: 'Sensor'
    activationDate: any
    baselineAdjustment: number
    daysRemaining: number
    expirationDate: any
    expirationTime: any
    expired: boolean
    model: string
    serialNumber: string
    status?: string | null
  } | null
  organization: { __typename?: 'Organization'; id: number; name: string }
  ongoingDietitianSubscription?: {
    __typename?: 'Subscription'
    id: string
    dietitianAddOn: boolean
    stripeStatus: string
    status: SubscriptionStatus
    paidMonthsCount: number
    cancelAt?: any | null
    startAt: any
    paused: boolean
    resumesAt?: any | null
    commitmentEndAt?: any | null
    primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
  } | null
  ongoingCoreSubscription?: {
    __typename?: 'Subscription'
    id: string
    paidMonthsCount: number
    paused: boolean
    cancelAt?: any | null
    commitmentEndAt?: any | null
    plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
    primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
  } | null
  lastDietitianSubscription?: {
    __typename?: 'Subscription'
    stripeStatus: string
    status: SubscriptionStatus
    paused: boolean
    resumesAt?: any | null
  } | null
  lastCoreSubscription?: {
    __typename?: 'Subscription'
    id: string
    stripeStatus: string
    status: SubscriptionStatus
    latestInvoice?: {
      __typename?: 'Invoice'
      latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
    } | null
  } | null
  lastAsyncChatAssignment?: {
    __typename?: 'CoachAssignment'
    id: string
    state: CoachAssignmentState
    last: boolean
    createdAt: any
    assignedAt?: any | null
    deactivatedAt?: any | null
    kind?: CoachAssignmentKind | null
    supportType: CoachAssignmentSupportType
    coach?: { __typename?: 'User'; id: string; fullName: string } | null
    appointment?: { __typename?: 'Appointment'; date?: string | null } | null
  } | null
  lastVideoChatAssignment?: {
    __typename?: 'CoachAssignment'
    id: string
    state: CoachAssignmentState
    last: boolean
    createdAt: any
    assignedAt?: any | null
    deactivatedAt?: any | null
    kind?: CoachAssignmentKind | null
    supportType: CoachAssignmentSupportType
    coach?: { __typename?: 'User'; id: string; fullName: string } | null
    appointment?: { __typename?: 'Appointment'; date?: string | null } | null
  } | null
  activeRefillConsent?: {
    __typename?: 'UserRefillConsent'
    id: string
    givenAt: any
    invalidatedAt?: any | null
  } | null
  fulfillmentConfiguration: { __typename?: 'FulfillmentConfiguration'; sensorKind: UserSensorKind }
  lastDexcomResearchState?: { __typename?: 'ResearchState'; status: DexcomResearchStatus } | null
  coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
  primaryGoal?: {
    __typename?: 'Goal'
    title: string
    kind: UserGoalKind
    description?: string | null
  } | null
  goalMetrics: Array<{
    __typename?: 'GoalMetric'
    primary: boolean
    kind: UserGoalMetricKind
    lowThresholdValue?: number | null
    highThresholdValue?: number | null
    section: string
    statKey: string
  }>
  coachProfile?: {
    __typename?: 'CoachProfile'
    stateLicences: Array<string>
    outOfOffice: boolean
  } | null
  referralPartner: {
    __typename?: 'UserReferralPartnerDef'
    key: UserReferralPartner
    logoUrl?: string | null
  }
}

export type ICoreDirectUploadFieldsFragment = {
  __typename?: 'DirectUpload'
  url: string
  signedId: string
  headers: Array<{ __typename?: 'KeyValuePairString'; key: string; value: string }>
}

export type ICoreFileFieldsFragment = {
  __typename?: 'File'
  id: string
  downloadFileUrl: string
  permanentUrl: string
  fileUrl: string
  fileName: string
  fileType: string
  createdAt: any
}

export type ICoreHealthPortalNotificationFieldsFragment = {
  __typename?: 'HealthPortalNotification'
  id: string
  completedAt?: any | null
  createdAt: any
  kind: HealthPortalNotificationKind
  coachAssignment?: {
    __typename?: 'CoachAssignment'
    kind?: CoachAssignmentKind | null
    supportType: CoachAssignmentSupportType
    user: {
      __typename?: 'User'
      id: string
      fullName: string
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        cancelAt?: any | null
        resumesAt?: any | null
      } | null
      coachAssignments?: Array<{
        __typename?: 'CoachAssignment'
        id: string
        kind?: CoachAssignmentKind | null
      }> | null
    }
    appointment?: { __typename?: 'Appointment'; date?: string | null } | null
  } | null
  chatConversation?: { __typename?: 'ChatConversation'; id: string } | null
  supportTicket?: {
    __typename?: 'Ticket'
    id: string
    description: string
    externalLink?: string | null
    kind: TicketKind
    reason?: TicketReason | null
    status: TicketStatus
    priority: TicketPriority
    createdAt: any
    createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
    user: { __typename?: 'User'; id: string; email: string; fullName: string }
    assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
  } | null
}

export type ICoreInvoiceOrderFieldsFragment = {
  __typename?: 'InvoiceOrder'
  id: string
  quantity: number
  product: string
  productType: string
  processedAt?: any | null
  orderId?: number | null
  lastProcessingAttemptAt?: any | null
  createdAt: any
  updatedAt: any
  state: InvoiceOrderState
  stateReason?: string | null
  invoice: { __typename?: 'Invoice'; stripeId: string; paidAt?: any | null }
  user: {
    __typename?: 'User'
    id: string
    email: string
    firstName: string
    lastName: string
    fulfillmentConfiguration: {
      __typename?: 'FulfillmentConfiguration'
      sensorKind: UserSensorKind
    }
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
  }
}

export type ICoreKnowledgeAnswerFieldsFragment = {
  __typename?: 'KnowledgeAnswer'
  id: string
  title: string
  value: string
  knowledgeQuestions: Array<{ __typename?: 'KnowledgeQuestion'; id: string; value: string }>
  knowledgeSources: Array<{
    __typename?: 'KnowledgeSource'
    id: string
    title: string
    url: string
  }>
}

export type ICoreKnowledgeQuestionFieldsFragment = {
  __typename?: 'KnowledgeQuestion'
  id: string
  value: string
  knowledgeAnswers: Array<{
    __typename?: 'KnowledgeAnswer'
    id: string
    title: string
    value: string
    knowledgeSources: Array<{
      __typename?: 'KnowledgeSource'
      id: string
      title: string
      url: string
    }>
  }>
  knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
}

export type ICoreMeasurementFieldsFragment = {
  __typename?: 'Measurement'
  id: string
  type: string
  title: string
  description: string
  value?: number | null
  units: string
  occurredAt: any
  createdAt: any
}

export type ICoreOrderFieldsFragment = {
  __typename?: 'Order'
  id: number
  userId: number
  product: string
  productType: ShippingOrderProductType
  sensorKind?: UserSensorKind | null
  provider?: ShippingOrderProvider | null
  quantity: number
  notes?: string | null
  createdAt: any
  updatedAt: any
  includable: ShippingOrderIncludable
  invoice?: { __typename?: 'Invoice'; stripeId: string } | null
  replacementRequest?: {
    __typename?: 'ReplacementRequest'
    replacementReason: ReplacementRequestReplacementReason
  } | null
}

export type ICoreReplacementRequestFieldsFragment = {
  __typename?: 'ReplacementRequest'
  id: string
  userId: string
  createdById?: string | null
  chargeAmount: ReplacementRequestChargeAmount
  createdAt: any
  daysWorn?: string | null
  dateFellOff?: string | null
  dateNotified?: string | null
  errorType: ReplacementRequestErrorType
  howItFellOff: string
  notes: string
  replacementReason: ReplacementRequestReplacementReason
  testedWithLibreApp?: ReplacementRequestTestedWithLibreApp | null
  wearingPatch: ReplacementRequestWearingPatch
  state: ReplacementRequestState
  sensor?: { __typename?: 'Sensor'; id: string; serialNumber: string; model: string } | null
  createdBy?: { __typename?: 'User'; email: string } | null
  user: { __typename?: 'User'; email: string }
  invoice?: { __typename?: 'Invoice'; stripeId: string } | null
}

export type ICoreScanFieldsFragment = { __typename?: 'Scan'; id: string; createdAt: any }

export type ICoreShipmentFieldsFragment = {
  __typename?: 'Shipment'
  id: number
  orderId: number
  addressId: number
  status?: ShippingShipmentStatus | null
  trackingNumber?: string | null
  trackingUrl?: string | null
  createdAt: any
  updatedAt: any
  shippedAt?: any | null
  deliveredAt?: any | null
  canceledAt?: any | null
  providerShipmentId?: string | null
  providerError?: string | null
  providerReceivedAt?: any | null
  provider?: ShippingOrderProvider | null
  quantity: number
}

export type ICoreTicketFieldsFragment = {
  __typename?: 'Ticket'
  id: string
  description: string
  externalLink?: string | null
  kind: TicketKind
  reason?: TicketReason | null
  status: TicketStatus
  priority: TicketPriority
  createdAt: any
  createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
  user: { __typename?: 'User'; id: string; email: string; fullName: string }
  assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
}

export type ICreateAccountRequestMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<OpsAccountRequestKind>
  priority?: InputMaybe<OpsAccountRequestPriority>
  notes?: InputMaybe<Scalars['String']>
  payload?: InputMaybe<IAccountRequestPayloadUnionSubmissionType>
}>

export type ICreateAccountRequestMutation = {
  __typename?: 'MutationType'
  createAccountRequest: {
    __typename?: 'AccountRequest'
    id: string
    escalatedAt?: any | null
    processedAt?: any | null
    reviewedAt?: any | null
    rejectedAt?: any | null
    failedAt?: any | null
    priority: OpsAccountRequestPriority
    kind: OpsAccountRequestKind
    errorMessage?: string | null
    notes?: string | null
    status: OpsAccountRequestStatus
    user: { __typename?: 'User'; id: string; fullName: string; email: string }
    escalatedBy?: { __typename?: 'User'; fullName: string } | null
    processedBy?: { __typename?: 'User'; fullName: string } | null
    reviewedBy?: { __typename?: 'User'; fullName: string } | null
    rejectedBy?: { __typename?: 'User'; fullName: string } | null
    payload:
      | {
          __typename?: 'CancelSubscriptionResponsePayload'
          subscriptionId: string
          immediateCancellation: boolean
          waiveEarlyCancellationFee: boolean
          waiveEarlyCancellationSurcharge: boolean
          cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
        }
      | {
          __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
          subscriptionId: string
          billingCycleAnchor: any
        }
      | { __typename?: 'CreditResponsePayload'; centsAmount: number }
      | {
          __typename?: 'DeleteDuplicateAccountResponsePayload'
          duplicateUserId: string
          creditAmountInCents: number
          replaceWithDuplicateUserEmail?: boolean | null
        }
      | { __typename?: 'MiscRequestResponsePayload'; requestDetails: string }
      | {
          __typename?: 'OneOffAddonResponsePayload'
          product: BillingProduct
          paid: boolean
          discountCentsAmount?: number | null
        }
      | {
          __typename?: 'PauseSubscriptionResponsePayload'
          subscriptionId: string
          cycleCount: number
          reason: OpsAccountRequestKindsPauseSubscriptionReason
        }
      | {
          __typename?: 'RefundResponsePayload'
          chargeId: string
          amountInCents: number
          subscriptionCancellationRequired: boolean
          subscriptionCancellationReason?: OpsAccountRequestKindsCancelSubscriptionReason | null
          refundReason?: {
            __typename?: 'OpsAccountRequestKindsRefundReasonDef'
            journey: string
            reasonOne: string
            reasonTwo: string
          } | null
        }
      | { __typename?: 'UncancelSubscriptionResponsePayload'; subscriptionId: string }
      | {
          __typename?: 'UnpauseSubscriptionResponsePayload'
          subscriptionId: string
          resumeDate?: any | null
        }
      | {
          __typename?: 'UpdateSubscriptionResponsePayload'
          oldProduct?: BillingProduct | null
          nextProduct: BillingProduct
        }
      | {
          __typename?: 'UpsertLimitedSupportResponsePayload'
          limitedSupportSubscriptionId?: string | null
          limitedSupportCancelAt: any
        }
  }
}

export type IExportAccountRequestsDataMutationVariables = Exact<{
  status?: InputMaybe<OpsAccountRequestStatus>
}>

export type IExportAccountRequestsDataMutation = {
  __typename?: 'MutationType'
  exportAccountRequestsData: { __typename?: 'Success'; success: boolean }
}

export type ISubmitAccountRequestMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<OpsAccountRequestKind>
  priority?: InputMaybe<OpsAccountRequestPriority>
  notes?: InputMaybe<Scalars['String']>
  payload?: InputMaybe<IAccountRequestPayloadUnionSubmissionType>
}>

export type ISubmitAccountRequestMutation = {
  __typename?: 'MutationType'
  submitAccountRequest: {
    __typename?: 'AccountRequest'
    id: string
    escalatedAt?: any | null
    processedAt?: any | null
    reviewedAt?: any | null
    rejectedAt?: any | null
    failedAt?: any | null
    priority: OpsAccountRequestPriority
    kind: OpsAccountRequestKind
    errorMessage?: string | null
    notes?: string | null
    status: OpsAccountRequestStatus
    user: { __typename?: 'User'; id: string; fullName: string; email: string }
    escalatedBy?: { __typename?: 'User'; fullName: string } | null
    processedBy?: { __typename?: 'User'; fullName: string } | null
    reviewedBy?: { __typename?: 'User'; fullName: string } | null
    rejectedBy?: { __typename?: 'User'; fullName: string } | null
    payload:
      | {
          __typename?: 'CancelSubscriptionResponsePayload'
          subscriptionId: string
          immediateCancellation: boolean
          waiveEarlyCancellationFee: boolean
          waiveEarlyCancellationSurcharge: boolean
          cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
        }
      | {
          __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
          subscriptionId: string
          billingCycleAnchor: any
        }
      | { __typename?: 'CreditResponsePayload'; centsAmount: number }
      | {
          __typename?: 'DeleteDuplicateAccountResponsePayload'
          duplicateUserId: string
          creditAmountInCents: number
          replaceWithDuplicateUserEmail?: boolean | null
        }
      | { __typename?: 'MiscRequestResponsePayload'; requestDetails: string }
      | {
          __typename?: 'OneOffAddonResponsePayload'
          product: BillingProduct
          paid: boolean
          discountCentsAmount?: number | null
        }
      | {
          __typename?: 'PauseSubscriptionResponsePayload'
          subscriptionId: string
          cycleCount: number
          reason: OpsAccountRequestKindsPauseSubscriptionReason
        }
      | {
          __typename?: 'RefundResponsePayload'
          chargeId: string
          amountInCents: number
          subscriptionCancellationRequired: boolean
          subscriptionCancellationReason?: OpsAccountRequestKindsCancelSubscriptionReason | null
          refundReason?: {
            __typename?: 'OpsAccountRequestKindsRefundReasonDef'
            journey: string
            reasonOne: string
            reasonTwo: string
          } | null
        }
      | { __typename?: 'UncancelSubscriptionResponsePayload'; subscriptionId: string }
      | {
          __typename?: 'UnpauseSubscriptionResponsePayload'
          subscriptionId: string
          resumeDate?: any | null
        }
      | {
          __typename?: 'UpdateSubscriptionResponsePayload'
          oldProduct?: BillingProduct | null
          nextProduct: BillingProduct
        }
      | {
          __typename?: 'UpsertLimitedSupportResponsePayload'
          limitedSupportSubscriptionId?: string | null
          limitedSupportCancelAt: any
        }
  }
}

export type IUpdateAccountRequestMutationVariables = Exact<{
  accountRequestId: Scalars['ID']
  status?: InputMaybe<OpsAccountRequestStatus>
  notes?: InputMaybe<Scalars['String']>
}>

export type IUpdateAccountRequestMutation = {
  __typename?: 'MutationType'
  updateAccountRequest: {
    __typename?: 'AccountRequest'
    id: string
    escalatedAt?: any | null
    processedAt?: any | null
    reviewedAt?: any | null
    rejectedAt?: any | null
    failedAt?: any | null
    priority: OpsAccountRequestPriority
    kind: OpsAccountRequestKind
    errorMessage?: string | null
    notes?: string | null
    status: OpsAccountRequestStatus
    user: { __typename?: 'User'; id: string; fullName: string; email: string }
    escalatedBy?: { __typename?: 'User'; fullName: string } | null
    processedBy?: { __typename?: 'User'; fullName: string } | null
    reviewedBy?: { __typename?: 'User'; fullName: string } | null
    rejectedBy?: { __typename?: 'User'; fullName: string } | null
    payload:
      | {
          __typename?: 'CancelSubscriptionResponsePayload'
          subscriptionId: string
          immediateCancellation: boolean
          waiveEarlyCancellationFee: boolean
          waiveEarlyCancellationSurcharge: boolean
          cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
        }
      | {
          __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
          subscriptionId: string
          billingCycleAnchor: any
        }
      | { __typename?: 'CreditResponsePayload'; centsAmount: number }
      | {
          __typename?: 'DeleteDuplicateAccountResponsePayload'
          duplicateUserId: string
          creditAmountInCents: number
          replaceWithDuplicateUserEmail?: boolean | null
        }
      | { __typename?: 'MiscRequestResponsePayload'; requestDetails: string }
      | {
          __typename?: 'OneOffAddonResponsePayload'
          product: BillingProduct
          paid: boolean
          discountCentsAmount?: number | null
        }
      | {
          __typename?: 'PauseSubscriptionResponsePayload'
          subscriptionId: string
          cycleCount: number
          reason: OpsAccountRequestKindsPauseSubscriptionReason
        }
      | {
          __typename?: 'RefundResponsePayload'
          chargeId: string
          amountInCents: number
          subscriptionCancellationRequired: boolean
          subscriptionCancellationReason?: OpsAccountRequestKindsCancelSubscriptionReason | null
          refundReason?: {
            __typename?: 'OpsAccountRequestKindsRefundReasonDef'
            journey: string
            reasonOne: string
            reasonTwo: string
          } | null
        }
      | { __typename?: 'UncancelSubscriptionResponsePayload'; subscriptionId: string }
      | {
          __typename?: 'UnpauseSubscriptionResponsePayload'
          subscriptionId: string
          resumeDate?: any | null
        }
      | {
          __typename?: 'UpdateSubscriptionResponsePayload'
          oldProduct?: BillingProduct | null
          nextProduct: BillingProduct
        }
      | {
          __typename?: 'UpsertLimitedSupportResponsePayload'
          limitedSupportSubscriptionId?: string | null
          limitedSupportCancelAt: any
        }
  }
}

export type IAddKnowledgeQuestionTagMutationVariables = Exact<{
  id: Scalars['ID']
  knowledgeTagId: Scalars['ID']
}>

export type IAddKnowledgeQuestionTagMutation = {
  __typename?: 'MutationType'
  addKnowledgeQuestionTag: {
    __typename?: 'KnowledgeQuestion'
    id: string
    value: string
    knowledgeAnswers: Array<{
      __typename?: 'KnowledgeAnswer'
      id: string
      title: string
      value: string
      knowledgeSources: Array<{
        __typename?: 'KnowledgeSource'
        id: string
        title: string
        url: string
      }>
    }>
    knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
  }
}

export type ISendAppointmentSmsReminderMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ISendAppointmentSmsReminderMutation = {
  __typename?: 'MutationType'
  sendAppointmentSmsReminder: { __typename?: 'Success'; success: boolean }
}

export type IUpdateAppointmentMutationVariables = Exact<{
  id: Scalars['ID']
  actualDuration?: InputMaybe<Scalars['Int']>
  meetingStatus?: InputMaybe<InsuranceBerryStreetAppointmentMeetingStatus>
}>

export type IUpdateAppointmentMutation = {
  __typename?: 'MutationType'
  updateAppointment: { __typename?: 'Success'; success: boolean }
}

export type IBackfillChatConversationMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IBackfillChatConversationMutation = {
  __typename?: 'MutationType'
  backfillChatConversation: {
    __typename?: 'ChatConversation'
    id: string
    kind: ChatConversationKind
    state: ChatConversationState
    read: boolean
    candidateRead: boolean
    intercomCreatedAt: any
    intercomBackfillState?: ChatConversationIntercomBackfillState | null
    lastMessage?: {
      __typename?: 'ChatConversationMessage'
      id: string
      value: string
      sender?: { __typename?: 'User'; id: string; fullName: string } | null
    } | null
    user: { __typename?: 'User'; id: string; fullName: string }
  }
}

export type IBulkCoachAssignMutationVariables = Exact<{
  coachAssignments?: InputMaybe<Array<ICoachAssign> | ICoachAssign>
}>

export type IBulkCoachAssignMutation = {
  __typename?: 'MutationType'
  bulkCoachAssign: {
    __typename?: 'BulkAssign'
    coachAssignments: Array<{
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    }>
  }
}

export type IBulkCreateFilesMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  urls?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type IBulkCreateFilesMutation = {
  __typename?: 'MutationType'
  bulkCreateFiles: {
    __typename?: 'FileCollection'
    files: Array<{
      __typename?: 'File'
      tag?: UserFileTag | null
      id: string
      downloadFileUrl: string
      permanentUrl: string
      fileUrl: string
      fileName: string
      fileType: string
      createdAt: any
    }>
  }
}

export type IBulkCreateShipmentsMutationVariables = Exact<{
  provider: ShippingOrderProvider
  shippedAt: Scalars['String']
  shipmentsAttributes?: InputMaybe<Array<IBulkCreateShipment> | IBulkCreateShipment>
}>

export type IBulkCreateShipmentsMutation = {
  __typename?: 'MutationType'
  bulkCreateShipments: {
    __typename?: 'ListShipments'
    totalRecords: number
    shipments: Array<{
      __typename?: 'Shipment'
      id: number
      orderId: number
      addressId: number
      status?: ShippingShipmentStatus | null
      trackingNumber?: string | null
      trackingUrl?: string | null
      createdAt: any
      updatedAt: any
      shippedAt?: any | null
      deliveredAt?: any | null
      canceledAt?: any | null
      providerShipmentId?: string | null
      providerError?: string | null
      providerReceivedAt?: any | null
      provider?: ShippingOrderProvider | null
      quantity: number
      order: {
        __typename?: 'Order'
        id: number
        createdAt: any
        product: string
        productType: ShippingOrderProductType
        sensorKind?: UserSensorKind | null
        quantity: number
        invoice?: { __typename?: 'Invoice'; stripeId: string } | null
      }
      address: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      }
      user: {
        __typename?: 'User'
        id: string
        email: string
        firstName: string
        lastName: string
        phoneNumber?: string | null
        dateOfBirth?: string | null
        sex?: string | null
        truepillPatientToken?: string | null
        activeRefillConsent?: { __typename?: 'UserRefillConsent'; givenAt: any } | null
      }
    }>
  }
}

export type IBulkFollowCoachAssignmentsMutationVariables = Exact<{
  coachId: Scalars['ID']
}>

export type IBulkFollowCoachAssignmentsMutation = {
  __typename?: 'MutationType'
  bulkFollowCoachAssignments: { __typename?: 'Success'; success: boolean }
}

export type IBulkShipOrdersMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
  provider: ShippingOrderProvider
}>

export type IBulkShipOrdersMutation = {
  __typename?: 'MutationType'
  bulkShipOrders: {
    __typename?: 'ListOrders'
    totalRecords: number
    orders: Array<{
      __typename?: 'Order'
      id: number
      userId: number
      product: string
      productType: ShippingOrderProductType
      sensorKind?: UserSensorKind | null
      provider?: ShippingOrderProvider | null
      quantity: number
      notes?: string | null
      createdAt: any
      updatedAt: any
      includable: ShippingOrderIncludable
      invoice?: { __typename?: 'Invoice'; stripeId: string } | null
      replacementRequest?: {
        __typename?: 'ReplacementRequest'
        replacementReason: ReplacementRequestReplacementReason
      } | null
    }>
  }
}

export type IBulkUnfollowCoachAssignmentsMutationVariables = Exact<{
  coachId: Scalars['ID']
}>

export type IBulkUnfollowCoachAssignmentsMutation = {
  __typename?: 'MutationType'
  bulkUnfollowCoachAssignments: { __typename?: 'Success'; success: boolean }
}

export type IBulkUpdateHealthPortalNotificationsMutationVariables = Exact<{
  healthPortalNotifications?: InputMaybe<
    Array<IUpdateHealthPortalNotification> | IUpdateHealthPortalNotification
  >
}>

export type IBulkUpdateHealthPortalNotificationsMutation = {
  __typename?: 'MutationType'
  bulkUpdateHealthPortalNotifications: {
    __typename?: 'HealthPortalNotificationCollection'
    healthPortalNotifications: Array<{
      __typename?: 'HealthPortalNotification'
      id: string
      completedAt?: any | null
      createdAt: any
      kind: HealthPortalNotificationKind
      coachAssignment?: {
        __typename?: 'CoachAssignment'
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        user: {
          __typename?: 'User'
          id: string
          fullName: string
          ongoingDietitianSubscription?: {
            __typename?: 'Subscription'
            cancelAt?: any | null
            resumesAt?: any | null
          } | null
          coachAssignments?: Array<{
            __typename?: 'CoachAssignment'
            id: string
            kind?: CoachAssignmentKind | null
          }> | null
        }
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      chatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      supportTicket?: {
        __typename?: 'Ticket'
        id: string
        description: string
        externalLink?: string | null
        kind: TicketKind
        reason?: TicketReason | null
        status: TicketStatus
        priority: TicketPriority
        createdAt: any
        createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
        user: { __typename?: 'User'; id: string; email: string; fullName: string }
        assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
      } | null
    }>
  }
}

export type IBulkUpdateCoachProfilesMutationVariables = Exact<{
  coachProfiles?: InputMaybe<Array<IUpdateCoachProfile> | IUpdateCoachProfile>
}>

export type IBulkUpdateCoachProfilesMutation = {
  __typename?: 'MutationType'
  bulkUpdateCoachProfiles: {
    __typename?: 'CoachProfileCollection'
    coachProfiles: Array<{
      __typename?: 'CoachProfile'
      fullName: string
      startDate?: any | null
      endDate?: any | null
    }>
  }
}

export type ICreateChatConversationNoteMutationVariables = Exact<{
  conversationId: Scalars['ID']
  body: Scalars['String']
}>

export type ICreateChatConversationNoteMutation = {
  __typename?: 'MutationType'
  createChatConversationNote: {
    __typename?: 'ChatConversationNote'
    id: string
    value: string
    createdAt: any
    createdBy: { __typename?: 'User'; id: string; fullName: string }
  }
}

export type IUpdateChatConversationNoteMutationVariables = Exact<{
  id: Scalars['ID']
  body: Scalars['String']
}>

export type IUpdateChatConversationNoteMutation = {
  __typename?: 'MutationType'
  updateChatConversationNote: {
    __typename?: 'ChatConversationNote'
    id: string
    value: string
    createdAt: any
    createdBy: { __typename?: 'User'; id: string; fullName: string }
  }
}

export type IUpdateChatConversationStateMutationVariables = Exact<{
  id: Scalars['ID']
  state: ChatConversationState
  snoozedUntil?: InputMaybe<Scalars['ISO8601DateTime']>
}>

export type IUpdateChatConversationStateMutation = {
  __typename?: 'MutationType'
  updateChatConversationState: {
    __typename?: 'ChatConversation'
    id: string
    kind: ChatConversationKind
    state: ChatConversationState
    read: boolean
    candidateRead: boolean
    intercomCreatedAt: any
    intercomBackfillState?: ChatConversationIntercomBackfillState | null
    lastMessage?: {
      __typename?: 'ChatConversationMessage'
      id: string
      value: string
      sender?: { __typename?: 'User'; id: string; fullName: string } | null
    } | null
    user: { __typename?: 'User'; id: string; fullName: string }
  }
}

export type IBulkCreateCourseCohortMemberEnrollmentsMutationVariables = Exact<{
  cohortId: Scalars['ID']
  userIds: Array<Scalars['ID']> | Scalars['ID']
  state: CoursesCohortMemberEnrollmentState
}>

export type IBulkCreateCourseCohortMemberEnrollmentsMutation = {
  __typename?: 'MutationType'
  bulkCreateCourseCohortMemberEnrollments: { __typename?: 'Success'; success: boolean }
}

export type IBulkUpsertCourseCohortSessionsMutationVariables = Exact<{
  id: Scalars['ID']
  sessionsData: Array<ICohortSessionData> | ICohortSessionData
}>

export type IBulkUpsertCourseCohortSessionsMutation = {
  __typename?: 'MutationType'
  bulkUpsertCourseCohortSessions: {
    __typename?: 'Cohort'
    id: string
    name: string
    kind: CoursesCohortKind
    state: CoursesCohortState
    instructor?: {
      __typename?: 'User'
      createdAt: any
      email: string
      timeZone: string
      avatar?: string | null
      firstName: string
      fullName: string
      id: string
      lastName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      lastAppVersion?: string | null
      lastAppPlatform?: string | null
      stripeId?: string | null
      healthieId?: number | null
      stripeCreditBalance: number
      kind: UserKind
      age?: string | null
      isCoach: boolean
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        state: UserApprovalRequestState
        createdAt: any
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      sensorInfo?: {
        __typename?: 'UserSensorInfo'
        totalCount: number
        replacementRequestsCount: number
      } | null
      settings: {
        __typename?: 'UserSettings'
        id: string
        appVersionReminder: boolean
        dashboardGraph: string
        dexcomSync: boolean
        displayActivityScore: boolean
        displayDailyScore: boolean
        displayMealScore: boolean
        fastingEnd: number
        fastingStart: number
        glucoseHighThreshold: number
        glucoseLowThreshold: number
        googleFitSync: boolean
        healthKitSync: boolean
        ketoMojoSync: boolean
        ketoneHighThreshold: number
        ketoneLowThreshold: number
        reminders: boolean
        scanReminder: number
        showNetCarbs: boolean
        unitSystem: string
        integrationsSyncSettings: {
          __typename?: 'IntegrationsSync'
          lastSyncedHealth?: any | null
          lastSyncedLibreView?: any | null
          lastSyncedDexcom?: any | null
          lastSyncedKetoMojo?: any | null
        }
        macroGoalsDaily: {
          __typename?: 'MacroGoals'
          calories: number
          carbs: number
          fat: number
          netCarbs?: number | null
          protein: number
        }
      }
      sensor?: {
        __typename?: 'Sensor'
        activationDate: any
        baselineAdjustment: number
        daysRemaining: number
        expirationDate: any
        expirationTime: any
        expired: boolean
        model: string
        serialNumber: string
        status?: string | null
      } | null
      organization: { __typename?: 'Organization'; id: number; name: string }
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        dietitianAddOn: boolean
        stripeStatus: string
        status: SubscriptionStatus
        paidMonthsCount: number
        cancelAt?: any | null
        startAt: any
        paused: boolean
        resumesAt?: any | null
        commitmentEndAt?: any | null
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        paidMonthsCount: number
        paused: boolean
        cancelAt?: any | null
        commitmentEndAt?: any | null
        plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      lastDietitianSubscription?: {
        __typename?: 'Subscription'
        stripeStatus: string
        status: SubscriptionStatus
        paused: boolean
        resumesAt?: any | null
      } | null
      lastCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        stripeStatus: string
        status: SubscriptionStatus
        latestInvoice?: {
          __typename?: 'Invoice'
          latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
        } | null
      } | null
      lastAsyncChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      activeRefillConsent?: {
        __typename?: 'UserRefillConsent'
        id: string
        givenAt: any
        invalidatedAt?: any | null
      } | null
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      lastDexcomResearchState?: {
        __typename?: 'ResearchState'
        status: DexcomResearchStatus
      } | null
      coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      primaryGoal?: {
        __typename?: 'Goal'
        title: string
        kind: UserGoalKind
        description?: string | null
      } | null
      goalMetrics: Array<{
        __typename?: 'GoalMetric'
        primary: boolean
        kind: UserGoalMetricKind
        lowThresholdValue?: number | null
        highThresholdValue?: number | null
        section: string
        statKey: string
      }>
      coachProfile?: {
        __typename?: 'CoachProfile'
        stateLicences: Array<string>
        outOfOffice: boolean
      } | null
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        logoUrl?: string | null
      }
    } | null
    members: Array<{
      __typename?: 'DppUser'
      id: string
      fullName: string
      email: string
      preferredCallDays: Array<string>
      preferredCallTimes: Array<string>
      lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
    }>
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      kind: CoursesCohortSessionKind
      name: string
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
    }>
  }
}

export type ICreateCourseCohortMutationVariables = Exact<{
  kind: CoursesCohortKind
  name: Scalars['String']
  instructorId: Scalars['ID']
  sessionsData?: InputMaybe<Array<ICohortSessionData> | ICohortSessionData>
}>

export type ICreateCourseCohortMutation = {
  __typename?: 'MutationType'
  createCourseCohort: {
    __typename?: 'Cohort'
    id: string
    name: string
    kind: CoursesCohortKind
    state: CoursesCohortState
    instructor?: {
      __typename?: 'User'
      createdAt: any
      email: string
      timeZone: string
      avatar?: string | null
      firstName: string
      fullName: string
      id: string
      lastName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      lastAppVersion?: string | null
      lastAppPlatform?: string | null
      stripeId?: string | null
      healthieId?: number | null
      stripeCreditBalance: number
      kind: UserKind
      age?: string | null
      isCoach: boolean
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        state: UserApprovalRequestState
        createdAt: any
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      sensorInfo?: {
        __typename?: 'UserSensorInfo'
        totalCount: number
        replacementRequestsCount: number
      } | null
      settings: {
        __typename?: 'UserSettings'
        id: string
        appVersionReminder: boolean
        dashboardGraph: string
        dexcomSync: boolean
        displayActivityScore: boolean
        displayDailyScore: boolean
        displayMealScore: boolean
        fastingEnd: number
        fastingStart: number
        glucoseHighThreshold: number
        glucoseLowThreshold: number
        googleFitSync: boolean
        healthKitSync: boolean
        ketoMojoSync: boolean
        ketoneHighThreshold: number
        ketoneLowThreshold: number
        reminders: boolean
        scanReminder: number
        showNetCarbs: boolean
        unitSystem: string
        integrationsSyncSettings: {
          __typename?: 'IntegrationsSync'
          lastSyncedHealth?: any | null
          lastSyncedLibreView?: any | null
          lastSyncedDexcom?: any | null
          lastSyncedKetoMojo?: any | null
        }
        macroGoalsDaily: {
          __typename?: 'MacroGoals'
          calories: number
          carbs: number
          fat: number
          netCarbs?: number | null
          protein: number
        }
      }
      sensor?: {
        __typename?: 'Sensor'
        activationDate: any
        baselineAdjustment: number
        daysRemaining: number
        expirationDate: any
        expirationTime: any
        expired: boolean
        model: string
        serialNumber: string
        status?: string | null
      } | null
      organization: { __typename?: 'Organization'; id: number; name: string }
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        dietitianAddOn: boolean
        stripeStatus: string
        status: SubscriptionStatus
        paidMonthsCount: number
        cancelAt?: any | null
        startAt: any
        paused: boolean
        resumesAt?: any | null
        commitmentEndAt?: any | null
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        paidMonthsCount: number
        paused: boolean
        cancelAt?: any | null
        commitmentEndAt?: any | null
        plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      lastDietitianSubscription?: {
        __typename?: 'Subscription'
        stripeStatus: string
        status: SubscriptionStatus
        paused: boolean
        resumesAt?: any | null
      } | null
      lastCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        stripeStatus: string
        status: SubscriptionStatus
        latestInvoice?: {
          __typename?: 'Invoice'
          latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
        } | null
      } | null
      lastAsyncChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      activeRefillConsent?: {
        __typename?: 'UserRefillConsent'
        id: string
        givenAt: any
        invalidatedAt?: any | null
      } | null
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      lastDexcomResearchState?: {
        __typename?: 'ResearchState'
        status: DexcomResearchStatus
      } | null
      coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      primaryGoal?: {
        __typename?: 'Goal'
        title: string
        kind: UserGoalKind
        description?: string | null
      } | null
      goalMetrics: Array<{
        __typename?: 'GoalMetric'
        primary: boolean
        kind: UserGoalMetricKind
        lowThresholdValue?: number | null
        highThresholdValue?: number | null
        section: string
        statKey: string
      }>
      coachProfile?: {
        __typename?: 'CoachProfile'
        stateLicences: Array<string>
        outOfOffice: boolean
      } | null
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        logoUrl?: string | null
      }
    } | null
    members: Array<{
      __typename?: 'DppUser'
      id: string
      fullName: string
      email: string
      preferredCallDays: Array<string>
      preferredCallTimes: Array<string>
      lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
    }>
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      kind: CoursesCohortSessionKind
      name: string
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
    }>
  }
}

export type IProcessDppInsuranceParticipantsMutationVariables = Exact<{ [key: string]: never }>

export type IProcessDppInsuranceParticipantsMutation = {
  __typename?: 'MutationType'
  processDppInsuranceParticipants: { __typename?: 'Success'; success: boolean }
}

export type IProcessDppInsuranceSessionsMutationVariables = Exact<{ [key: string]: never }>

export type IProcessDppInsuranceSessionsMutation = {
  __typename?: 'MutationType'
  processDppInsuranceSessions: { __typename?: 'Success'; success: boolean }
}

export type IUpdateCourseCohortMutationVariables = Exact<{
  id: Scalars['ID']
  name: Scalars['String']
}>

export type IUpdateCourseCohortMutation = {
  __typename?: 'MutationType'
  updateCourseCohort: {
    __typename?: 'Cohort'
    id: string
    name: string
    kind: CoursesCohortKind
    state: CoursesCohortState
    instructor?: {
      __typename?: 'User'
      createdAt: any
      email: string
      timeZone: string
      avatar?: string | null
      firstName: string
      fullName: string
      id: string
      lastName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      lastAppVersion?: string | null
      lastAppPlatform?: string | null
      stripeId?: string | null
      healthieId?: number | null
      stripeCreditBalance: number
      kind: UserKind
      age?: string | null
      isCoach: boolean
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        state: UserApprovalRequestState
        createdAt: any
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      sensorInfo?: {
        __typename?: 'UserSensorInfo'
        totalCount: number
        replacementRequestsCount: number
      } | null
      settings: {
        __typename?: 'UserSettings'
        id: string
        appVersionReminder: boolean
        dashboardGraph: string
        dexcomSync: boolean
        displayActivityScore: boolean
        displayDailyScore: boolean
        displayMealScore: boolean
        fastingEnd: number
        fastingStart: number
        glucoseHighThreshold: number
        glucoseLowThreshold: number
        googleFitSync: boolean
        healthKitSync: boolean
        ketoMojoSync: boolean
        ketoneHighThreshold: number
        ketoneLowThreshold: number
        reminders: boolean
        scanReminder: number
        showNetCarbs: boolean
        unitSystem: string
        integrationsSyncSettings: {
          __typename?: 'IntegrationsSync'
          lastSyncedHealth?: any | null
          lastSyncedLibreView?: any | null
          lastSyncedDexcom?: any | null
          lastSyncedKetoMojo?: any | null
        }
        macroGoalsDaily: {
          __typename?: 'MacroGoals'
          calories: number
          carbs: number
          fat: number
          netCarbs?: number | null
          protein: number
        }
      }
      sensor?: {
        __typename?: 'Sensor'
        activationDate: any
        baselineAdjustment: number
        daysRemaining: number
        expirationDate: any
        expirationTime: any
        expired: boolean
        model: string
        serialNumber: string
        status?: string | null
      } | null
      organization: { __typename?: 'Organization'; id: number; name: string }
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        dietitianAddOn: boolean
        stripeStatus: string
        status: SubscriptionStatus
        paidMonthsCount: number
        cancelAt?: any | null
        startAt: any
        paused: boolean
        resumesAt?: any | null
        commitmentEndAt?: any | null
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        paidMonthsCount: number
        paused: boolean
        cancelAt?: any | null
        commitmentEndAt?: any | null
        plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      lastDietitianSubscription?: {
        __typename?: 'Subscription'
        stripeStatus: string
        status: SubscriptionStatus
        paused: boolean
        resumesAt?: any | null
      } | null
      lastCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        stripeStatus: string
        status: SubscriptionStatus
        latestInvoice?: {
          __typename?: 'Invoice'
          latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
        } | null
      } | null
      lastAsyncChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      activeRefillConsent?: {
        __typename?: 'UserRefillConsent'
        id: string
        givenAt: any
        invalidatedAt?: any | null
      } | null
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      lastDexcomResearchState?: {
        __typename?: 'ResearchState'
        status: DexcomResearchStatus
      } | null
      coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      primaryGoal?: {
        __typename?: 'Goal'
        title: string
        kind: UserGoalKind
        description?: string | null
      } | null
      goalMetrics: Array<{
        __typename?: 'GoalMetric'
        primary: boolean
        kind: UserGoalMetricKind
        lowThresholdValue?: number | null
        highThresholdValue?: number | null
        section: string
        statKey: string
      }>
      coachProfile?: {
        __typename?: 'CoachProfile'
        stateLicences: Array<string>
        outOfOffice: boolean
      } | null
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        logoUrl?: string | null
      }
    } | null
    members: Array<{
      __typename?: 'DppUser'
      id: string
      fullName: string
      email: string
      preferredCallDays: Array<string>
      preferredCallTimes: Array<string>
      lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
    }>
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      kind: CoursesCohortSessionKind
      name: string
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
    }>
  }
}

export type IUpdateCourseCohortInstructorMutationVariables = Exact<{
  id: Scalars['ID']
  instructorId: Scalars['ID']
}>

export type IUpdateCourseCohortInstructorMutation = {
  __typename?: 'MutationType'
  updateCourseCohortInstructor: {
    __typename?: 'Cohort'
    id: string
    name: string
    kind: CoursesCohortKind
    state: CoursesCohortState
    instructor?: {
      __typename?: 'User'
      createdAt: any
      email: string
      timeZone: string
      avatar?: string | null
      firstName: string
      fullName: string
      id: string
      lastName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      lastAppVersion?: string | null
      lastAppPlatform?: string | null
      stripeId?: string | null
      healthieId?: number | null
      stripeCreditBalance: number
      kind: UserKind
      age?: string | null
      isCoach: boolean
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        state: UserApprovalRequestState
        createdAt: any
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      sensorInfo?: {
        __typename?: 'UserSensorInfo'
        totalCount: number
        replacementRequestsCount: number
      } | null
      settings: {
        __typename?: 'UserSettings'
        id: string
        appVersionReminder: boolean
        dashboardGraph: string
        dexcomSync: boolean
        displayActivityScore: boolean
        displayDailyScore: boolean
        displayMealScore: boolean
        fastingEnd: number
        fastingStart: number
        glucoseHighThreshold: number
        glucoseLowThreshold: number
        googleFitSync: boolean
        healthKitSync: boolean
        ketoMojoSync: boolean
        ketoneHighThreshold: number
        ketoneLowThreshold: number
        reminders: boolean
        scanReminder: number
        showNetCarbs: boolean
        unitSystem: string
        integrationsSyncSettings: {
          __typename?: 'IntegrationsSync'
          lastSyncedHealth?: any | null
          lastSyncedLibreView?: any | null
          lastSyncedDexcom?: any | null
          lastSyncedKetoMojo?: any | null
        }
        macroGoalsDaily: {
          __typename?: 'MacroGoals'
          calories: number
          carbs: number
          fat: number
          netCarbs?: number | null
          protein: number
        }
      }
      sensor?: {
        __typename?: 'Sensor'
        activationDate: any
        baselineAdjustment: number
        daysRemaining: number
        expirationDate: any
        expirationTime: any
        expired: boolean
        model: string
        serialNumber: string
        status?: string | null
      } | null
      organization: { __typename?: 'Organization'; id: number; name: string }
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        dietitianAddOn: boolean
        stripeStatus: string
        status: SubscriptionStatus
        paidMonthsCount: number
        cancelAt?: any | null
        startAt: any
        paused: boolean
        resumesAt?: any | null
        commitmentEndAt?: any | null
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        paidMonthsCount: number
        paused: boolean
        cancelAt?: any | null
        commitmentEndAt?: any | null
        plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      lastDietitianSubscription?: {
        __typename?: 'Subscription'
        stripeStatus: string
        status: SubscriptionStatus
        paused: boolean
        resumesAt?: any | null
      } | null
      lastCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        stripeStatus: string
        status: SubscriptionStatus
        latestInvoice?: {
          __typename?: 'Invoice'
          latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
        } | null
      } | null
      lastAsyncChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      activeRefillConsent?: {
        __typename?: 'UserRefillConsent'
        id: string
        givenAt: any
        invalidatedAt?: any | null
      } | null
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      lastDexcomResearchState?: {
        __typename?: 'ResearchState'
        status: DexcomResearchStatus
      } | null
      coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      primaryGoal?: {
        __typename?: 'Goal'
        title: string
        kind: UserGoalKind
        description?: string | null
      } | null
      goalMetrics: Array<{
        __typename?: 'GoalMetric'
        primary: boolean
        kind: UserGoalMetricKind
        lowThresholdValue?: number | null
        highThresholdValue?: number | null
        section: string
        statKey: string
      }>
      coachProfile?: {
        __typename?: 'CoachProfile'
        stateLicences: Array<string>
        outOfOffice: boolean
      } | null
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        logoUrl?: string | null
      }
    } | null
    members: Array<{
      __typename?: 'DppUser'
      id: string
      fullName: string
      email: string
      preferredCallDays: Array<string>
      preferredCallTimes: Array<string>
      lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
    }>
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      kind: CoursesCohortSessionKind
      name: string
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
    }>
  }
}

export type IUpdateCourseCohortStateMutationVariables = Exact<{
  id: Scalars['ID']
  state: CoursesCohortState
}>

export type IUpdateCourseCohortStateMutation = {
  __typename?: 'MutationType'
  updateCourseCohortState: {
    __typename?: 'Cohort'
    id: string
    name: string
    kind: CoursesCohortKind
    state: CoursesCohortState
    instructor?: {
      __typename?: 'User'
      createdAt: any
      email: string
      timeZone: string
      avatar?: string | null
      firstName: string
      fullName: string
      id: string
      lastName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      lastAppVersion?: string | null
      lastAppPlatform?: string | null
      stripeId?: string | null
      healthieId?: number | null
      stripeCreditBalance: number
      kind: UserKind
      age?: string | null
      isCoach: boolean
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        state: UserApprovalRequestState
        createdAt: any
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      sensorInfo?: {
        __typename?: 'UserSensorInfo'
        totalCount: number
        replacementRequestsCount: number
      } | null
      settings: {
        __typename?: 'UserSettings'
        id: string
        appVersionReminder: boolean
        dashboardGraph: string
        dexcomSync: boolean
        displayActivityScore: boolean
        displayDailyScore: boolean
        displayMealScore: boolean
        fastingEnd: number
        fastingStart: number
        glucoseHighThreshold: number
        glucoseLowThreshold: number
        googleFitSync: boolean
        healthKitSync: boolean
        ketoMojoSync: boolean
        ketoneHighThreshold: number
        ketoneLowThreshold: number
        reminders: boolean
        scanReminder: number
        showNetCarbs: boolean
        unitSystem: string
        integrationsSyncSettings: {
          __typename?: 'IntegrationsSync'
          lastSyncedHealth?: any | null
          lastSyncedLibreView?: any | null
          lastSyncedDexcom?: any | null
          lastSyncedKetoMojo?: any | null
        }
        macroGoalsDaily: {
          __typename?: 'MacroGoals'
          calories: number
          carbs: number
          fat: number
          netCarbs?: number | null
          protein: number
        }
      }
      sensor?: {
        __typename?: 'Sensor'
        activationDate: any
        baselineAdjustment: number
        daysRemaining: number
        expirationDate: any
        expirationTime: any
        expired: boolean
        model: string
        serialNumber: string
        status?: string | null
      } | null
      organization: { __typename?: 'Organization'; id: number; name: string }
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        dietitianAddOn: boolean
        stripeStatus: string
        status: SubscriptionStatus
        paidMonthsCount: number
        cancelAt?: any | null
        startAt: any
        paused: boolean
        resumesAt?: any | null
        commitmentEndAt?: any | null
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        paidMonthsCount: number
        paused: boolean
        cancelAt?: any | null
        commitmentEndAt?: any | null
        plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      lastDietitianSubscription?: {
        __typename?: 'Subscription'
        stripeStatus: string
        status: SubscriptionStatus
        paused: boolean
        resumesAt?: any | null
      } | null
      lastCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        stripeStatus: string
        status: SubscriptionStatus
        latestInvoice?: {
          __typename?: 'Invoice'
          latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
        } | null
      } | null
      lastAsyncChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      activeRefillConsent?: {
        __typename?: 'UserRefillConsent'
        id: string
        givenAt: any
        invalidatedAt?: any | null
      } | null
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      lastDexcomResearchState?: {
        __typename?: 'ResearchState'
        status: DexcomResearchStatus
      } | null
      coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      primaryGoal?: {
        __typename?: 'Goal'
        title: string
        kind: UserGoalKind
        description?: string | null
      } | null
      goalMetrics: Array<{
        __typename?: 'GoalMetric'
        primary: boolean
        kind: UserGoalMetricKind
        lowThresholdValue?: number | null
        highThresholdValue?: number | null
        section: string
        statKey: string
      }>
      coachProfile?: {
        __typename?: 'CoachProfile'
        stateLicences: Array<string>
        outOfOffice: boolean
      } | null
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        logoUrl?: string | null
      }
    } | null
    members: Array<{
      __typename?: 'DppUser'
      id: string
      fullName: string
      email: string
      preferredCallDays: Array<string>
      preferredCallTimes: Array<string>
      lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
    }>
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      kind: CoursesCohortSessionKind
      name: string
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
    }>
  }
}

export type IUpsertCourseCohortSessionAttendanceMutationVariables = Exact<{
  sessionId: Scalars['ID']
  userId: Scalars['ID']
  state: CoursesCohortSessionAttendanceState
}>

export type IUpsertCourseCohortSessionAttendanceMutation = {
  __typename?: 'MutationType'
  upsertCourseCohortSessionAttendance: {
    __typename?: 'CohortSessionAttendance'
    id: string
    state: CoursesCohortSessionAttendanceState
  }
}

export type ICreateChatConversationMessageTemplateMutationVariables = Exact<{
  name: Scalars['String']
  value: Scalars['String']
  private: Scalars['Boolean']
}>

export type ICreateChatConversationMessageTemplateMutation = {
  __typename?: 'MutationType'
  createChatConversationMessageTemplate: {
    __typename?: 'ChatConversationMessageTemplate'
    id: string
    name: string
    value: string
    private: boolean
    kind: ChatConversationMessageTemplateKind
  }
}

export type ICreateChatTokenMutationVariables = Exact<{
  userId: Scalars['ID']
}>

export type ICreateChatTokenMutation = {
  __typename?: 'MutationType'
  createChatToken: { __typename?: 'Token'; value: string }
}

export type ICreateChatbotDelimiterMutationVariables = Exact<{
  providerConversationId: Scalars['ID']
}>

export type ICreateChatbotDelimiterMutation = {
  __typename?: 'MutationType'
  createChatbotDelimiter: { __typename?: 'Delimiter'; id: string; createdAt: any }
}

export type ICreateDietitianSessionMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  startedAt: Scalars['ISO8601DateTime']
  endedAt: Scalars['ISO8601DateTime']
}>

export type ICreateDietitianSessionMutation = {
  __typename?: 'MutationType'
  createDietitianSession: {
    __typename?: 'DietitianSession'
    id: string
    dietitianId: string
    userId?: string | null
    startedAt: any
    endedAt: any
    durationInSeconds: number
  }
}

export type ICreateDirectUploadsMutationVariables = Exact<{
  files?: InputMaybe<Array<IFileMetadata> | IFileMetadata>
}>

export type ICreateDirectUploadsMutation = {
  __typename?: 'MutationType'
  createDirectUploads: {
    __typename?: 'DirectUploadCollection'
    directUploads: Array<{
      __typename?: 'DirectUpload'
      url: string
      signedId: string
      headers: Array<{ __typename?: 'KeyValuePairString'; key: string; value: string }>
    }>
  }
}

export type ICreateFilesFromDirectUploadsMutationVariables = Exact<{
  userId: Scalars['ID']
  signedIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type ICreateFilesFromDirectUploadsMutation = {
  __typename?: 'MutationType'
  createFilesFromDirectUploads: {
    __typename?: 'FileCollection'
    files: Array<{
      __typename?: 'File'
      tag?: UserFileTag | null
      id: string
      downloadFileUrl: string
      permanentUrl: string
      fileUrl: string
      fileName: string
      fileType: string
      createdAt: any
    }>
  }
}

export type ICreateKnowledgeAnswerMutationVariables = Exact<{
  title: Scalars['String']
  value: Scalars['String']
}>

export type ICreateKnowledgeAnswerMutation = {
  __typename?: 'MutationType'
  createKnowledgeAnswer: {
    __typename?: 'KnowledgeAnswer'
    id: string
    title: string
    value: string
    knowledgeQuestions: Array<{ __typename?: 'KnowledgeQuestion'; id: string; value: string }>
    knowledgeSources: Array<{
      __typename?: 'KnowledgeSource'
      id: string
      title: string
      url: string
    }>
  }
}

export type ICreateKnowledgeQuestionMutationVariables = Exact<{
  value: Scalars['String']
}>

export type ICreateKnowledgeQuestionMutation = {
  __typename?: 'MutationType'
  createKnowledgeQuestion: { __typename?: 'KnowledgeQuestion'; id: string; value: string }
}

export type ICreateKnowledgeSourceMutationVariables = Exact<{
  title: Scalars['String']
  url: Scalars['String']
}>

export type ICreateKnowledgeSourceMutation = {
  __typename?: 'MutationType'
  createKnowledgeSource: { __typename?: 'KnowledgeSource'; id: string; title: string; url: string }
}

export type ICreateKnowledgeTagMutationVariables = Exact<{
  value: Scalars['String']
}>

export type ICreateKnowledgeTagMutation = {
  __typename?: 'MutationType'
  createKnowledgeTag: { __typename?: 'KnowledgeTag'; id: string; value: string }
}

export type ICreateOrderMutationVariables = Exact<{
  userId: Scalars['ID']
  product: Scalars['String']
  productType: Scalars['String']
  quantity: Scalars['Int']
  sensorKind?: InputMaybe<UserSensorKind>
  notes?: InputMaybe<Scalars['String']>
  invoiceOrderId?: InputMaybe<Scalars['ID']>
}>

export type ICreateOrderMutation = {
  __typename?: 'MutationType'
  createOrder: {
    __typename?: 'Order'
    id: number
    userId: number
    product: string
    productType: ShippingOrderProductType
    sensorKind?: UserSensorKind | null
    provider?: ShippingOrderProvider | null
    quantity: number
    notes?: string | null
    createdAt: any
    updatedAt: any
    includable: ShippingOrderIncludable
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
    replacementRequest?: {
      __typename?: 'ReplacementRequest'
      replacementReason: ReplacementRequestReplacementReason
    } | null
  }
}

export type ICreateReplacementRequestMutationVariables = Exact<{
  chargeAmount: ReplacementRequestChargeAmount
  daysWorn?: InputMaybe<Scalars['Int']>
  dateFellOff: Scalars['String']
  dateNotified: Scalars['String']
  errorType: ReplacementRequestErrorType
  howItFellOff?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  replacementReason: ReplacementRequestReplacementReason
  sensorId?: InputMaybe<Scalars['ID']>
  testedWithLibreApp?: InputMaybe<ReplacementRequestTestedWithLibreApp>
  wearingPatch: ReplacementRequestWearingPatch
}>

export type ICreateReplacementRequestMutation = {
  __typename?: 'MutationType'
  createReplacementRequest: {
    __typename?: 'ReplacementRequest'
    id: string
    userId: string
    createdById?: string | null
    chargeAmount: ReplacementRequestChargeAmount
    createdAt: any
    daysWorn?: string | null
    dateFellOff?: string | null
    dateNotified?: string | null
    errorType: ReplacementRequestErrorType
    howItFellOff: string
    notes: string
    replacementReason: ReplacementRequestReplacementReason
    testedWithLibreApp?: ReplacementRequestTestedWithLibreApp | null
    wearingPatch: ReplacementRequestWearingPatch
    state: ReplacementRequestState
    sensor?: { __typename?: 'Sensor'; id: string; serialNumber: string; model: string } | null
    createdBy?: { __typename?: 'User'; email: string } | null
    user: { __typename?: 'User'; email: string }
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
  }
}

export type ICreateReplacementRequestInvoiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
}>

export type ICreateReplacementRequestInvoiceMutation = {
  __typename?: 'MutationType'
  createReplacementRequestInvoice: {
    __typename?: 'ReplacementRequest'
    id: string
    userId: string
    createdById?: string | null
    chargeAmount: ReplacementRequestChargeAmount
    createdAt: any
    daysWorn?: string | null
    dateFellOff?: string | null
    dateNotified?: string | null
    errorType: ReplacementRequestErrorType
    howItFellOff: string
    notes: string
    replacementReason: ReplacementRequestReplacementReason
    testedWithLibreApp?: ReplacementRequestTestedWithLibreApp | null
    wearingPatch: ReplacementRequestWearingPatch
    state: ReplacementRequestState
    sensor?: { __typename?: 'Sensor'; id: string; serialNumber: string; model: string } | null
    createdBy?: { __typename?: 'User'; email: string } | null
    user: { __typename?: 'User'; email: string }
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
  }
}

export type ICreateShipmentMutationVariables = Exact<{
  orderId: Scalars['Int']
  status?: InputMaybe<Scalars['String']>
  quantity: Scalars['Int']
  trackingNumber?: InputMaybe<Scalars['String']>
  trackingUrl?: InputMaybe<Scalars['String']>
  provider?: InputMaybe<Scalars['String']>
  providerShipmentId?: InputMaybe<Scalars['ID']>
  providerReceivedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  shippedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  deliveredAt?: InputMaybe<Scalars['ISO8601DateTime']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  zipCode?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
}>

export type ICreateShipmentMutation = {
  __typename?: 'MutationType'
  createShipment: {
    __typename?: 'Shipment'
    id: number
    orderId: number
    addressId: number
    status?: ShippingShipmentStatus | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    createdAt: any
    updatedAt: any
    shippedAt?: any | null
    deliveredAt?: any | null
    canceledAt?: any | null
    providerShipmentId?: string | null
    providerError?: string | null
    providerReceivedAt?: any | null
    provider?: ShippingOrderProvider | null
    quantity: number
    order: {
      __typename?: 'Order'
      id: number
      createdAt: any
      product: string
      productType: ShippingOrderProductType
      sensorKind?: UserSensorKind | null
      quantity: number
      invoice?: { __typename?: 'Invoice'; stripeId: string } | null
    }
    address: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    }
    user: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      phoneNumber?: string | null
      dateOfBirth?: string | null
      sex?: string | null
      truepillPatientToken?: string | null
      activeRefillConsent?: { __typename?: 'UserRefillConsent'; givenAt: any } | null
    }
  }
}

export type IUpsertDailyActionMutationVariables = Exact<{
  kind: UserDailyActionKind
  status: UserDailyActionStatus
}>

export type IUpsertDailyActionMutation = {
  __typename?: 'MutationType'
  upsertDailyAction: {
    __typename?: 'DailyAction'
    id: string
    title: string
    description: string
    status: UserDailyActionStatus
    kind: UserDailyActionKind
    autocomplete: boolean
    category: UserDailyActionCategory
    createdAt: any
    updatedAt: any
  }
}

export type IDeleteChatConversationMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteChatConversationMessageTemplateMutation = {
  __typename?: 'MutationType'
  deleteChatConversationMessageTemplate: { __typename?: 'Success'; success: boolean }
}

export type IDeleteFileMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteFileMutation = {
  __typename?: 'MutationType'
  deleteFile: {
    __typename?: 'File'
    id: string
    downloadFileUrl: string
    permanentUrl: string
    fileUrl: string
    fileName: string
    fileType: string
    createdAt: any
  }
}

export type IDeleteKnowledgeAnswerMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteKnowledgeAnswerMutation = {
  __typename?: 'MutationType'
  deleteKnowledgeAnswer: {
    __typename?: 'KnowledgeAnswer'
    id: string
    title: string
    value: string
  }
}

export type IDeleteKnowledgeQuestionMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteKnowledgeQuestionMutation = {
  __typename?: 'MutationType'
  deleteKnowledgeQuestion: { __typename?: 'KnowledgeQuestion'; id: string; value: string }
}

export type IDeleteKnowledgeSourceMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteKnowledgeSourceMutation = {
  __typename?: 'MutationType'
  deleteKnowledgeSource: { __typename?: 'KnowledgeSource'; id: string; url: string }
}

export type IDeleteKnowledgeTagMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteKnowledgeTagMutation = {
  __typename?: 'MutationType'
  deleteKnowledgeTag: { __typename?: 'KnowledgeTag'; id: string; value: string }
}

export type IDeleteMealMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
}>

export type IDeleteMealMutation = {
  __typename?: 'MutationType'
  deleteMeal: { __typename?: 'Meal'; id: string }
}

export type IDeleteNoteMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
}>

export type IDeleteNoteMutation = {
  __typename?: 'MutationType'
  deleteNote: {
    __typename?: 'Note'
    body: string
    createdAt: any
    creatorId: string
    id: string
    occurredAt: any
    title: string
  }
}

export type IDeleteOrderMutationVariables = Exact<{
  id: Scalars['Int']
  deletionReason: Scalars['String']
}>

export type IDeleteOrderMutation = {
  __typename?: 'MutationType'
  deleteOrder: {
    __typename?: 'Order'
    id: number
    userId: number
    product: string
    productType: ShippingOrderProductType
    sensorKind?: UserSensorKind | null
    provider?: ShippingOrderProvider | null
    quantity: number
    notes?: string | null
    createdAt: any
    updatedAt: any
    includable: ShippingOrderIncludable
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
    replacementRequest?: {
      __typename?: 'ReplacementRequest'
      replacementReason: ReplacementRequestReplacementReason
    } | null
  }
}

export type IDeleteReplacementRequestMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
}>

export type IDeleteReplacementRequestMutation = {
  __typename?: 'MutationType'
  deleteReplacementRequest: {
    __typename?: 'ReplacementRequest'
    id: string
    userId: string
    createdById?: string | null
    chargeAmount: ReplacementRequestChargeAmount
    createdAt: any
    daysWorn?: string | null
    dateFellOff?: string | null
    dateNotified?: string | null
    errorType: ReplacementRequestErrorType
    howItFellOff: string
    notes: string
    replacementReason: ReplacementRequestReplacementReason
    testedWithLibreApp?: ReplacementRequestTestedWithLibreApp | null
    wearingPatch: ReplacementRequestWearingPatch
    state: ReplacementRequestState
    sensor?: { __typename?: 'Sensor'; id: string; serialNumber: string; model: string } | null
    createdBy?: { __typename?: 'User'; email: string } | null
    user: { __typename?: 'User'; email: string }
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
  }
}

export type IDeleteShipmentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
}>

export type IDeleteShipmentMutation = {
  __typename?: 'MutationType'
  deleteShipment: {
    __typename?: 'Shipment'
    id: number
    orderId: number
    addressId: number
    status?: ShippingShipmentStatus | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    createdAt: any
    updatedAt: any
    shippedAt?: any | null
    deliveredAt?: any | null
    canceledAt?: any | null
    providerShipmentId?: string | null
    providerError?: string | null
    providerReceivedAt?: any | null
    provider?: ShippingOrderProvider | null
    quantity: number
  }
}

export type IDeleteUserPropertyMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteUserPropertyMutation = {
  __typename?: 'MutationType'
  deleteUserProperty: { __typename?: 'UserProperty'; id: number }
}

export type IStopDexcomResearchParticipationMutationVariables = Exact<{
  userId: Scalars['ID']
}>

export type IStopDexcomResearchParticipationMutation = {
  __typename?: 'MutationType'
  stopDexcomResearchParticipation: { __typename?: 'Success'; success: boolean }
}

export type IInvalidateRefillConsentMutationVariables = Exact<{ [key: string]: never }>

export type IInvalidateRefillConsentMutation = {
  __typename?: 'MutationType'
  invalidateRefillConsent: {
    __typename?: 'UserRefillConsent'
    id: string
    givenAt: any
    invalidatedAt?: any | null
  }
}

export type ILinkKnowledgeAnswerMutationVariables = Exact<{
  id: Scalars['ID']
  knowledgeSourceId: Scalars['ID']
}>

export type ILinkKnowledgeAnswerMutation = {
  __typename?: 'MutationType'
  linkKnowledgeAnswer: {
    __typename?: 'KnowledgeAnswer'
    id: string
    title: string
    value: string
    knowledgeQuestions: Array<{ __typename?: 'KnowledgeQuestion'; id: string; value: string }>
    knowledgeSources: Array<{
      __typename?: 'KnowledgeSource'
      id: string
      title: string
      url: string
    }>
  }
}

export type ILinkKnowledgeQuestionMutationVariables = Exact<{
  id: Scalars['ID']
  knowledgeAnswerId: Scalars['ID']
}>

export type ILinkKnowledgeQuestionMutation = {
  __typename?: 'MutationType'
  linkKnowledgeQuestion: {
    __typename?: 'KnowledgeQuestion'
    id: string
    value: string
    knowledgeAnswers: Array<{
      __typename?: 'KnowledgeAnswer'
      id: string
      title: string
      value: string
      knowledgeSources: Array<{
        __typename?: 'KnowledgeSource'
        id: string
        title: string
        url: string
      }>
    }>
    knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
  }
}

export type IPromoteToCoachChatConversationMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IPromoteToCoachChatConversationMutation = {
  __typename?: 'MutationType'
  promoteToCoachChatConversation: { __typename?: 'Success'; success: boolean }
}

export type IRemoveKnowledgeQuestionTagMutationVariables = Exact<{
  id: Scalars['ID']
  knowledgeTagId: Scalars['ID']
}>

export type IRemoveKnowledgeQuestionTagMutation = {
  __typename?: 'MutationType'
  removeKnowledgeQuestionTag: {
    __typename?: 'KnowledgeQuestion'
    id: string
    value: string
    knowledgeAnswers: Array<{
      __typename?: 'KnowledgeAnswer'
      id: string
      title: string
      value: string
      knowledgeSources: Array<{
        __typename?: 'KnowledgeSource'
        id: string
        title: string
        url: string
      }>
    }>
    knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
  }
}

export type IResendMissingPrescriptionsShipmentsMutationVariables = Exact<{ [key: string]: never }>

export type IResendMissingPrescriptionsShipmentsMutation = {
  __typename?: 'MutationType'
  resendMissingPrescriptionsShipments: {
    __typename?: 'ListShipments'
    totalRecords: number
    shipments: Array<{
      __typename?: 'Shipment'
      id: number
      orderId: number
      addressId: number
      status?: ShippingShipmentStatus | null
      trackingNumber?: string | null
      trackingUrl?: string | null
      createdAt: any
      updatedAt: any
      shippedAt?: any | null
      deliveredAt?: any | null
      canceledAt?: any | null
      providerShipmentId?: string | null
      providerError?: string | null
      providerReceivedAt?: any | null
      provider?: ShippingOrderProvider | null
      quantity: number
    }>
  }
}

export type IResendShipmentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
}>

export type IResendShipmentMutation = {
  __typename?: 'MutationType'
  resendShipment: {
    __typename?: 'Shipment'
    id: number
    orderId: number
    addressId: number
    status?: ShippingShipmentStatus | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    createdAt: any
    updatedAt: any
    shippedAt?: any | null
    deliveredAt?: any | null
    canceledAt?: any | null
    providerShipmentId?: string | null
    providerError?: string | null
    providerReceivedAt?: any | null
    provider?: ShippingOrderProvider | null
    quantity: number
  }
}

export type IRestartSurveyLinkMutationVariables = Exact<{
  id: Scalars['ID']
  questionKeys?: InputMaybe<Array<RestartSurveyLinkQuestionKeys> | RestartSurveyLinkQuestionKeys>
}>

export type IRestartSurveyLinkMutation = {
  __typename?: 'MutationType'
  restartSurveyLink: { __typename?: 'SurveyLink'; id: string; state: SurveysUserLinkState }
}

export type ISendChatConversationMessageMutationVariables = Exact<{
  userId: Scalars['ID']
  body: Scalars['String']
  conversationId?: InputMaybe<Scalars['ID']>
}>

export type ISendChatConversationMessageMutation = {
  __typename?: 'MutationType'
  sendChatConversationMessage: { __typename?: 'Success'; success: boolean }
}

export type ISetPrimaryGoalMetricsMutationVariables = Exact<{
  kinds?: InputMaybe<Array<UserGoalMetricKind> | UserGoalMetricKind>
}>

export type ISetPrimaryGoalMetricsMutation = {
  __typename?: 'MutationType'
  setPrimaryGoalMetrics: Array<{
    __typename?: 'GoalMetric'
    primary: boolean
    kind: UserGoalMetricKind
    lowThresholdValue?: number | null
    highThresholdValue?: number | null
    section: string
    statKey: string
  }>
}

export type ISignInThirdPartyUserMutationVariables = Exact<{
  provider: ThirdPartySignInProvider
  providerToken: Scalars['String']
}>

export type ISignInThirdPartyUserMutation = {
  __typename?: 'MutationType'
  signInThirdPartyUser: {
    __typename?: 'UserAuth'
    role?: UserRole | null
    token?: string | null
    user?: { __typename?: 'User'; id: string } | null
  }
}

export type ISignInUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}>

export type ISignInUserMutation = {
  __typename?: 'MutationType'
  signInUser: {
    __typename?: 'UserAuth'
    role?: UserRole | null
    token?: string | null
    user?: { __typename?: 'User'; id: string } | null
  }
}

export type ISignOutUserMutationVariables = Exact<{ [key: string]: never }>

export type ISignOutUserMutation = {
  __typename?: 'MutationType'
  signOutUser: { __typename?: 'Success'; success: boolean }
}

export type ICreateTicketCommentMutationVariables = Exact<{
  ticketId: Scalars['ID']
  body: Scalars['String']
  mentionedAdminIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type ICreateTicketCommentMutation = {
  __typename?: 'MutationType'
  createTicketComment: {
    __typename?: 'TicketComment'
    body: string
    createdAt: any
    createdBy: {
      __typename?: 'UserMin'
      id: string
      fullName: string
      email: string
      phoneNumber: string
      timeZone: string
    }
  }
}

export type ICreateTicketMutationVariables = Exact<{
  userId: Scalars['ID']
  description: Scalars['String']
  kind: TicketKind
  reason: TicketReason
  priority: TicketPriority
}>

export type ICreateTicketMutation = {
  __typename?: 'MutationType'
  createTicket: {
    __typename?: 'Ticket'
    id: string
    description: string
    externalLink?: string | null
    kind: TicketKind
    reason?: TicketReason | null
    status: TicketStatus
    priority: TicketPriority
    createdAt: any
    createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
    user: { __typename?: 'User'; id: string; email: string; fullName: string }
    assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
  }
}

export type IDeleteTicketMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteTicketMutation = {
  __typename?: 'MutationType'
  deleteTicket: {
    __typename?: 'Ticket'
    id: string
    description: string
    externalLink?: string | null
    kind: TicketKind
    reason?: TicketReason | null
    status: TicketStatus
    priority: TicketPriority
    createdAt: any
    createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
    user: { __typename?: 'User'; id: string; email: string; fullName: string }
    assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
  }
}

export type IUpdateTicketMutationVariables = Exact<{
  id: Scalars['ID']
  assignedToId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  status?: InputMaybe<TicketStatus>
  priority?: InputMaybe<TicketPriority>
  externalLink?: InputMaybe<Scalars['String']>
}>

export type IUpdateTicketMutation = {
  __typename?: 'MutationType'
  updateTicket: {
    __typename?: 'Ticket'
    id: string
    description: string
    externalLink?: string | null
    kind: TicketKind
    reason?: TicketReason | null
    status: TicketStatus
    priority: TicketPriority
    createdAt: any
    createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
    user: { __typename?: 'User'; id: string; email: string; fullName: string }
    assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
  }
}

export type IUnlinkKnowledgeAnswerMutationVariables = Exact<{
  id: Scalars['ID']
  knowledgeSourceId: Scalars['ID']
}>

export type IUnlinkKnowledgeAnswerMutation = {
  __typename?: 'MutationType'
  unlinkKnowledgeAnswer: {
    __typename?: 'KnowledgeAnswer'
    id: string
    title: string
    value: string
    knowledgeQuestions: Array<{ __typename?: 'KnowledgeQuestion'; id: string; value: string }>
    knowledgeSources: Array<{
      __typename?: 'KnowledgeSource'
      id: string
      title: string
      url: string
    }>
  }
}

export type IUnlinkKnowledgeQuestionMutationVariables = Exact<{
  id: Scalars['ID']
  knowledgeAnswerId: Scalars['ID']
}>

export type IUnlinkKnowledgeQuestionMutation = {
  __typename?: 'MutationType'
  unlinkKnowledgeQuestion: {
    __typename?: 'KnowledgeQuestion'
    id: string
    value: string
    knowledgeAnswers: Array<{
      __typename?: 'KnowledgeAnswer'
      id: string
      title: string
      value: string
      knowledgeSources: Array<{
        __typename?: 'KnowledgeSource'
        id: string
        title: string
        url: string
      }>
    }>
    knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
  }
}

export type IUpdateAddressMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  street?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  state?: InputMaybe<AddressState>
  country?: InputMaybe<AddressCountry>
  zipCode?: InputMaybe<Scalars['String']>
}>

export type IUpdateAddressMutation = {
  __typename?: 'MutationType'
  updateAddress: {
    __typename?: 'Address'
    id: string
    name: string
    street: string
    street2: string
    city: string
    state?: AddressState | null
    zipCode: string
    country?: string | null
  }
}

export type IUpdateChatConversationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  read?: InputMaybe<Scalars['Boolean']>
}>

export type IUpdateChatConversationMutation = {
  __typename?: 'MutationType'
  updateChatConversation: {
    __typename?: 'ChatConversation'
    id: string
    kind: ChatConversationKind
    state: ChatConversationState
    read: boolean
    candidateRead: boolean
    intercomCreatedAt: any
    intercomBackfillState?: ChatConversationIntercomBackfillState | null
    lastMessage?: {
      __typename?: 'ChatConversationMessage'
      id: string
      value: string
      sender?: { __typename?: 'User'; id: string; fullName: string } | null
    } | null
    user: { __typename?: 'User'; id: string; fullName: string }
  }
}

export type IUpdateChatConversationMessageTemplateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
  private?: InputMaybe<Scalars['Boolean']>
}>

export type IUpdateChatConversationMessageTemplateMutation = {
  __typename?: 'MutationType'
  updateChatConversationMessageTemplate: {
    __typename?: 'ChatConversationMessageTemplate'
    id: string
    name: string
    value: string
    private: boolean
    kind: ChatConversationMessageTemplateKind
  }
}

export type IUpdateCoachConversationScheduledMessageMutationVariables = Exact<{
  scheduledMessageId: Scalars['ID']
  state?: InputMaybe<CoachesConversationScheduledMessageState>
  sendAt?: InputMaybe<Scalars['ISO8601DateTime']>
  value?: InputMaybe<Scalars['String']>
}>

export type IUpdateCoachConversationScheduledMessageMutation = {
  __typename?: 'MutationType'
  updateCoachConversationScheduledMessage: {
    __typename?: 'CoachConversationScheduledMessage'
    id: string
    kind: ChatConversationMessageTemplateKind
    state: CoachesConversationScheduledMessageState
    sendAt?: any | null
    sentAt?: any | null
  }
}

export type IUpdateFulfillmentConfigurationMutationVariables = Exact<{
  userId: Scalars['ID']
  sensorKind: Scalars['String']
}>

export type IUpdateFulfillmentConfigurationMutation = {
  __typename?: 'MutationType'
  updateFulfillmentConfiguration: {
    __typename?: 'FulfillmentConfiguration'
    userId: string
    sensorKind: UserSensorKind
  }
}

export type IUpdateGoalMetricMutationVariables = Exact<{
  kind: UserGoalMetricKind
  lowThresholdValue?: InputMaybe<Scalars['Float']>
  highThresholdValue?: InputMaybe<Scalars['Float']>
}>

export type IUpdateGoalMetricMutation = {
  __typename?: 'MutationType'
  updateGoalMetric: {
    __typename?: 'GoalMetric'
    primary: boolean
    kind: UserGoalMetricKind
    lowThresholdValue?: number | null
    highThresholdValue?: number | null
    section: string
    statKey: string
  }
}

export type IUpdateInvoiceOrderMutationVariables = Exact<{
  id: Scalars['ID']
  state?: InputMaybe<InvoiceOrderState>
  stateReason?: InputMaybe<Scalars['String']>
}>

export type IUpdateInvoiceOrderMutation = {
  __typename?: 'MutationType'
  updateInvoiceOrder: {
    __typename?: 'InvoiceOrder'
    id: string
    quantity: number
    product: string
    productType: string
    processedAt?: any | null
    orderId?: number | null
    lastProcessingAttemptAt?: any | null
    createdAt: any
    updatedAt: any
    state: InvoiceOrderState
    stateReason?: string | null
    invoice: { __typename?: 'Invoice'; stripeId: string; paidAt?: any | null }
    user: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
    }
  }
}

export type IUpdateKnowledgeAnswerMutationVariables = Exact<{
  id: Scalars['ID']
  title?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}>

export type IUpdateKnowledgeAnswerMutation = {
  __typename?: 'MutationType'
  updateKnowledgeAnswer: {
    __typename?: 'KnowledgeAnswer'
    id: string
    title: string
    value: string
    knowledgeQuestions: Array<{ __typename?: 'KnowledgeQuestion'; id: string; value: string }>
    knowledgeSources: Array<{
      __typename?: 'KnowledgeSource'
      id: string
      title: string
      url: string
    }>
  }
}

export type IUpdateKnowledgeQuestionMutationVariables = Exact<{
  id: Scalars['ID']
  value: Scalars['String']
}>

export type IUpdateKnowledgeQuestionMutation = {
  __typename?: 'MutationType'
  updateKnowledgeQuestion: {
    __typename?: 'KnowledgeQuestion'
    id: string
    value: string
    knowledgeAnswers: Array<{
      __typename?: 'KnowledgeAnswer'
      id: string
      title: string
      value: string
      knowledgeSources: Array<{
        __typename?: 'KnowledgeSource'
        id: string
        title: string
        url: string
      }>
    }>
    knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
  }
}

export type IUpdateKnowledgeSourceMutationVariables = Exact<{
  id: Scalars['ID']
  title?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}>

export type IUpdateKnowledgeSourceMutation = {
  __typename?: 'MutationType'
  updateKnowledgeSource: { __typename?: 'KnowledgeSource'; id: string; title: string; url: string }
}

export type IUpdateKnowledgeTagMutationVariables = Exact<{
  id: Scalars['ID']
  value: Scalars['String']
}>

export type IUpdateKnowledgeTagMutation = {
  __typename?: 'MutationType'
  updateKnowledgeTag: { __typename?: 'KnowledgeTag'; id: string; value: string }
}

export type IUpdateOrderMutationVariables = Exact<{
  id: Scalars['Int']
  notes?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productType?: InputMaybe<Scalars['String']>
  sensorKind?: InputMaybe<UserSensorKind>
  quantity?: InputMaybe<Scalars['Int']>
}>

export type IUpdateOrderMutation = {
  __typename?: 'MutationType'
  updateOrder: {
    __typename?: 'Order'
    id: number
    userId: number
    product: string
    productType: ShippingOrderProductType
    sensorKind?: UserSensorKind | null
    provider?: ShippingOrderProvider | null
    quantity: number
    notes?: string | null
    createdAt: any
    updatedAt: any
    includable: ShippingOrderIncludable
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
    replacementRequest?: {
      __typename?: 'ReplacementRequest'
      replacementReason: ReplacementRequestReplacementReason
    } | null
  }
}

export type IUpdateReplacementRequestMutationVariables = Exact<{
  id: Scalars['ID']
  chargeAmount?: InputMaybe<ReplacementRequestChargeAmount>
  daysWorn?: InputMaybe<Scalars['Int']>
  dateFellOff?: InputMaybe<Scalars['String']>
  dateNotified?: InputMaybe<Scalars['String']>
  errorType?: InputMaybe<ReplacementRequestErrorType>
  howItFellOff?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  replacementReason?: InputMaybe<ReplacementRequestReplacementReason>
  testedWithLibreApp?: InputMaybe<ReplacementRequestTestedWithLibreApp>
  wearingPatch?: InputMaybe<ReplacementRequestWearingPatch>
}>

export type IUpdateReplacementRequestMutation = {
  __typename?: 'MutationType'
  updateReplacementRequest: {
    __typename?: 'ReplacementRequest'
    id: string
    userId: string
    createdById?: string | null
    chargeAmount: ReplacementRequestChargeAmount
    createdAt: any
    daysWorn?: string | null
    dateFellOff?: string | null
    dateNotified?: string | null
    errorType: ReplacementRequestErrorType
    howItFellOff: string
    notes: string
    replacementReason: ReplacementRequestReplacementReason
    testedWithLibreApp?: ReplacementRequestTestedWithLibreApp | null
    wearingPatch: ReplacementRequestWearingPatch
    state: ReplacementRequestState
    sensor?: { __typename?: 'Sensor'; id: string; serialNumber: string; model: string } | null
    createdBy?: { __typename?: 'User'; email: string } | null
    user: { __typename?: 'User'; email: string }
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
  }
}

export type IUpdateSensorMutationVariables = Exact<{
  baselineAdjustment: Scalars['Int']
}>

export type IUpdateSensorMutation = {
  __typename?: 'MutationType'
  updateSensor: {
    __typename?: 'Sensor'
    serialNumber: string
    activationDate: any
    expirationDate: any
    expirationTime: any
    daysRemaining: number
    baselineAdjustment: number
  }
}

export type IUpdateShipmentMutationVariables = Exact<{
  id: Scalars['Int']
  status?: InputMaybe<Scalars['String']>
  trackingNumber?: InputMaybe<Scalars['String']>
  trackingUrl?: InputMaybe<Scalars['String']>
  providerShipmentId?: InputMaybe<Scalars['ID']>
  provider?: InputMaybe<Scalars['String']>
  providerReceivedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  quantity?: InputMaybe<Scalars['Int']>
  shippedAt?: InputMaybe<Scalars['ISO8601DateTime']>
  deliveredAt?: InputMaybe<Scalars['ISO8601DateTime']>
}>

export type IUpdateShipmentMutation = {
  __typename?: 'MutationType'
  updateShipment: {
    __typename?: 'Shipment'
    id: number
    orderId: number
    addressId: number
    status?: ShippingShipmentStatus | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    createdAt: any
    updatedAt: any
    shippedAt?: any | null
    deliveredAt?: any | null
    canceledAt?: any | null
    providerShipmentId?: string | null
    providerError?: string | null
    providerReceivedAt?: any | null
    provider?: ShippingOrderProvider | null
    quantity: number
  }
}

export type IUpdateUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<Scalars['String']>
  sex?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  dateOfBirth?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  truepillPatientToken?: InputMaybe<Scalars['String']>
}>

export type IUpdateUserMutation = {
  __typename?: 'MutationType'
  updateUser: {
    __typename?: 'User'
    createdAt: any
    email: string
    timeZone: string
    avatar?: string | null
    firstName: string
    fullName: string
    id: string
    lastName: string
    role: UserRole
    sex?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    lastAppVersion?: string | null
    lastAppPlatform?: string | null
    stripeId?: string | null
    healthieId?: number | null
    stripeCreditBalance: number
    kind: UserKind
    age?: string | null
    isCoach: boolean
    lastPrescriptionApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      state: UserApprovalRequestState
      createdAt: any
    } | null
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
    sensorInfo?: {
      __typename?: 'UserSensorInfo'
      totalCount: number
      replacementRequestsCount: number
    } | null
    settings: {
      __typename?: 'UserSettings'
      id: string
      appVersionReminder: boolean
      dashboardGraph: string
      dexcomSync: boolean
      displayActivityScore: boolean
      displayDailyScore: boolean
      displayMealScore: boolean
      fastingEnd: number
      fastingStart: number
      glucoseHighThreshold: number
      glucoseLowThreshold: number
      googleFitSync: boolean
      healthKitSync: boolean
      ketoMojoSync: boolean
      ketoneHighThreshold: number
      ketoneLowThreshold: number
      reminders: boolean
      scanReminder: number
      showNetCarbs: boolean
      unitSystem: string
      integrationsSyncSettings: {
        __typename?: 'IntegrationsSync'
        lastSyncedHealth?: any | null
        lastSyncedLibreView?: any | null
        lastSyncedDexcom?: any | null
        lastSyncedKetoMojo?: any | null
      }
      macroGoalsDaily: {
        __typename?: 'MacroGoals'
        calories: number
        carbs: number
        fat: number
        netCarbs?: number | null
        protein: number
      }
    }
    sensor?: {
      __typename?: 'Sensor'
      activationDate: any
      baselineAdjustment: number
      daysRemaining: number
      expirationDate: any
      expirationTime: any
      expired: boolean
      model: string
      serialNumber: string
      status?: string | null
    } | null
    organization: { __typename?: 'Organization'; id: number; name: string }
    ongoingDietitianSubscription?: {
      __typename?: 'Subscription'
      id: string
      dietitianAddOn: boolean
      stripeStatus: string
      status: SubscriptionStatus
      paidMonthsCount: number
      cancelAt?: any | null
      startAt: any
      paused: boolean
      resumesAt?: any | null
      commitmentEndAt?: any | null
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    ongoingCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      paidMonthsCount: number
      paused: boolean
      cancelAt?: any | null
      commitmentEndAt?: any | null
      plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    lastDietitianSubscription?: {
      __typename?: 'Subscription'
      stripeStatus: string
      status: SubscriptionStatus
      paused: boolean
      resumesAt?: any | null
    } | null
    lastCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      stripeStatus: string
      status: SubscriptionStatus
      latestInvoice?: {
        __typename?: 'Invoice'
        latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
      } | null
    } | null
    lastAsyncChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    lastVideoChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    activeRefillConsent?: {
      __typename?: 'UserRefillConsent'
      id: string
      givenAt: any
      invalidatedAt?: any | null
    } | null
    fulfillmentConfiguration: {
      __typename?: 'FulfillmentConfiguration'
      sensorKind: UserSensorKind
    }
    lastDexcomResearchState?: { __typename?: 'ResearchState'; status: DexcomResearchStatus } | null
    coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
    primaryGoal?: {
      __typename?: 'Goal'
      title: string
      kind: UserGoalKind
      description?: string | null
    } | null
    goalMetrics: Array<{
      __typename?: 'GoalMetric'
      primary: boolean
      kind: UserGoalMetricKind
      lowThresholdValue?: number | null
      highThresholdValue?: number | null
      section: string
      statKey: string
    }>
    coachProfile?: {
      __typename?: 'CoachProfile'
      stateLicences: Array<string>
      outOfOffice: boolean
    } | null
    referralPartner: {
      __typename?: 'UserReferralPartnerDef'
      key: UserReferralPartner
      logoUrl?: string | null
    }
  }
}

export type IUpdateUserApprovalStatusMutationVariables = Exact<{
  id: Scalars['ID']
  approvalStatus: UpdateUserApprovalStatuses
  kind?: InputMaybe<UserApprovalRequestKind>
}>

export type IUpdateUserApprovalStatusMutation = {
  __typename?: 'MutationType'
  updateUserApprovalStatus: { __typename?: 'User'; id: string }
}

export type IUpdateUserOrganizationMutationVariables = Exact<{
  id: Scalars['ID']
  organizationId: Scalars['Int']
}>

export type IUpdateUserOrganizationMutation = {
  __typename?: 'MutationType'
  updateUserOrganization: {
    __typename?: 'User'
    createdAt: any
    email: string
    timeZone: string
    avatar?: string | null
    firstName: string
    fullName: string
    id: string
    lastName: string
    role: UserRole
    sex?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    lastAppVersion?: string | null
    lastAppPlatform?: string | null
    stripeId?: string | null
    healthieId?: number | null
    stripeCreditBalance: number
    kind: UserKind
    age?: string | null
    isCoach: boolean
    lastPrescriptionApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      state: UserApprovalRequestState
      createdAt: any
    } | null
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
    sensorInfo?: {
      __typename?: 'UserSensorInfo'
      totalCount: number
      replacementRequestsCount: number
    } | null
    settings: {
      __typename?: 'UserSettings'
      id: string
      appVersionReminder: boolean
      dashboardGraph: string
      dexcomSync: boolean
      displayActivityScore: boolean
      displayDailyScore: boolean
      displayMealScore: boolean
      fastingEnd: number
      fastingStart: number
      glucoseHighThreshold: number
      glucoseLowThreshold: number
      googleFitSync: boolean
      healthKitSync: boolean
      ketoMojoSync: boolean
      ketoneHighThreshold: number
      ketoneLowThreshold: number
      reminders: boolean
      scanReminder: number
      showNetCarbs: boolean
      unitSystem: string
      integrationsSyncSettings: {
        __typename?: 'IntegrationsSync'
        lastSyncedHealth?: any | null
        lastSyncedLibreView?: any | null
        lastSyncedDexcom?: any | null
        lastSyncedKetoMojo?: any | null
      }
      macroGoalsDaily: {
        __typename?: 'MacroGoals'
        calories: number
        carbs: number
        fat: number
        netCarbs?: number | null
        protein: number
      }
    }
    sensor?: {
      __typename?: 'Sensor'
      activationDate: any
      baselineAdjustment: number
      daysRemaining: number
      expirationDate: any
      expirationTime: any
      expired: boolean
      model: string
      serialNumber: string
      status?: string | null
    } | null
    organization: { __typename?: 'Organization'; id: number; name: string }
    ongoingDietitianSubscription?: {
      __typename?: 'Subscription'
      id: string
      dietitianAddOn: boolean
      stripeStatus: string
      status: SubscriptionStatus
      paidMonthsCount: number
      cancelAt?: any | null
      startAt: any
      paused: boolean
      resumesAt?: any | null
      commitmentEndAt?: any | null
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    ongoingCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      paidMonthsCount: number
      paused: boolean
      cancelAt?: any | null
      commitmentEndAt?: any | null
      plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    lastDietitianSubscription?: {
      __typename?: 'Subscription'
      stripeStatus: string
      status: SubscriptionStatus
      paused: boolean
      resumesAt?: any | null
    } | null
    lastCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      stripeStatus: string
      status: SubscriptionStatus
      latestInvoice?: {
        __typename?: 'Invoice'
        latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
      } | null
    } | null
    lastAsyncChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    lastVideoChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    activeRefillConsent?: {
      __typename?: 'UserRefillConsent'
      id: string
      givenAt: any
      invalidatedAt?: any | null
    } | null
    fulfillmentConfiguration: {
      __typename?: 'FulfillmentConfiguration'
      sensorKind: UserSensorKind
    }
    lastDexcomResearchState?: { __typename?: 'ResearchState'; status: DexcomResearchStatus } | null
    coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
    primaryGoal?: {
      __typename?: 'Goal'
      title: string
      kind: UserGoalKind
      description?: string | null
    } | null
    goalMetrics: Array<{
      __typename?: 'GoalMetric'
      primary: boolean
      kind: UserGoalMetricKind
      lowThresholdValue?: number | null
      highThresholdValue?: number | null
      section: string
      statKey: string
    }>
    coachProfile?: {
      __typename?: 'CoachProfile'
      stateLicences: Array<string>
      outOfOffice: boolean
    } | null
    referralPartner: {
      __typename?: 'UserReferralPartnerDef'
      key: UserReferralPartner
      logoUrl?: string | null
    }
  }
}

export type IUpdateUserPropertyMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  kind: Scalars['String']
  value: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
}>

export type IUpdateUserPropertyMutation = {
  __typename?: 'MutationType'
  updateUserProperty: {
    __typename?: 'UserProperty'
    id: number
    kind: UserPropertyKind
    value: string
    userId: string
  }
}

export type IUpdateSettingsMutationVariables = Exact<{
  appVersionReminder?: InputMaybe<Scalars['Boolean']>
  dashboardGraph?: InputMaybe<Scalars['String']>
  dexcomSync?: InputMaybe<Scalars['Boolean']>
  displayActivityScore?: InputMaybe<Scalars['Boolean']>
  displayDailyScore?: InputMaybe<Scalars['Boolean']>
  displayMealScore?: InputMaybe<Scalars['Boolean']>
  fastingEnd?: InputMaybe<Scalars['Int']>
  fastingStart?: InputMaybe<Scalars['Int']>
  glucoseHighThreshold?: InputMaybe<Scalars['Int']>
  glucoseLowThreshold?: InputMaybe<Scalars['Int']>
  googleFitSync?: InputMaybe<Scalars['Boolean']>
  healthKitSync?: InputMaybe<Scalars['Boolean']>
  ketoMojoSync?: InputMaybe<Scalars['Boolean']>
  ketoneHighThreshold?: InputMaybe<Scalars['Float']>
  ketoneLowThreshold?: InputMaybe<Scalars['Float']>
  macroGoalsDaily?: InputMaybe<IMacroGoalsDaily>
  reminders?: InputMaybe<Scalars['Boolean']>
  scanReminder?: InputMaybe<Scalars['Int']>
  showNetCarbs?: InputMaybe<Scalars['Boolean']>
  unitSystem?: InputMaybe<Scalars['String']>
}>

export type IUpdateSettingsMutation = {
  __typename?: 'MutationType'
  updateSettings: {
    __typename?: 'UserSettings'
    id: string
    appVersionReminder: boolean
    dashboardGraph: string
    dexcomSync: boolean
    displayActivityScore: boolean
    displayDailyScore: boolean
    displayMealScore: boolean
    fastingEnd: number
    fastingStart: number
    glucoseHighThreshold: number
    glucoseLowThreshold: number
    googleFitSync: boolean
    healthKitSync: boolean
    ketoMojoSync: boolean
    ketoneHighThreshold: number
    ketoneLowThreshold: number
    reminders: boolean
    scanReminder: number
    showNetCarbs: boolean
    unitSystem: string
    macroGoalsDaily: {
      __typename?: 'MacroGoals'
      calories: number
      carbs: number
      fat: number
      netCarbs?: number | null
      protein: number
    }
  }
}

export type IUpsertCoachProfileMutationVariables = Exact<{
  stateLicences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  outOfOffice?: InputMaybe<Scalars['Boolean']>
}>

export type IUpsertCoachProfileMutation = {
  __typename?: 'MutationType'
  upsertCoachProfile: {
    __typename?: 'CoachProfile'
    stateLicences: Array<string>
    outOfOffice: boolean
  }
}

export type IUpsertMealMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<MealKind>
  favorite?: InputMaybe<Scalars['Boolean']>
  description: Scalars['String']
  time: Scalars['String']
  ingredients?: InputMaybe<Array<IIngredientData> | IIngredientData>
}>

export type IUpsertMealMutation = {
  __typename?: 'MutationType'
  upsertMeal: {
    __typename: 'Meal'
    avatar?: string | null
    occurredAt: any
    createdAt: any
    description: string
    experimentId?: string | null
    favorite: boolean
    source: MealSource
    id: string
    title: string
    kind?: MealKind | null
    updatedAt: any
    mealCreatedBy: { __typename?: 'User'; id: string }
    statistics: {
      __typename?: 'EntryStatistics'
      aucScore?: number | null
      glucose2hrDelta?: number | null
      glucoseDelta?: number | null
      glucoseMax?: number | null
      glucoseMin?: number | null
      glucoseScore?: number | null
      glucoseScore2hrDelta?: number | null
      glucoseScoreAuc?: number | null
      glucoseScoreDelta?: number | null
      glucoseScorePeak?: number | null
    }
    ingredients: Array<{
      __typename?: 'Ingredient'
      calories: number
      createdAt: any
      description: string
      id: string
      servingAmount: number
      servingUnits: string
    }>
    glucose: {
      __typename?: 'EntryGlucose'
      range: Array<number>
      healthyRange: Array<number>
      values: Array<{ __typename?: 'Measurement'; timestamp: any; value?: number | null }>
    }
    nutrition: Array<{ __typename?: 'KeyValuePairFloat'; key: string; value: number }>
  }
}

export type IUpsertNoteMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  body: Scalars['String']
  time?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<Scalars['String']>
  pinned?: InputMaybe<Scalars['Boolean']>
}>

export type IUpsertNoteMutation = {
  __typename?: 'MutationType'
  upsertNote: {
    __typename?: 'Note'
    id: string
    body: string
    kind: NoteKind
    occurredAt: any
    pinnedAt?: any | null
  }
}

export type IUpsertPrimaryGoalMutationVariables = Exact<{
  kind: UserGoalKind
  description?: InputMaybe<Scalars['String']>
}>

export type IUpsertPrimaryGoalMutation = {
  __typename?: 'MutationType'
  upsertPrimaryGoal: {
    __typename?: 'Goal'
    title: string
    kind: UserGoalKind
    description?: string | null
  }
}

export type IAllAccountRequestsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IAccountRequestSort> | IAccountRequestSort>
  dynamicFilters?: InputMaybe<
    Array<IAccountRequestDynamicFilterItem> | IAccountRequestDynamicFilterItem
  >
  status?: InputMaybe<OpsAccountRequestStatus>
}>

export type IAllAccountRequestsQuery = {
  __typename?: 'QueryType'
  allAccountRequests: {
    __typename?: 'AccountRequestCollection'
    totalCount: number
    accountRequests: Array<{
      __typename?: 'AccountRequest'
      id: string
      escalatedAt?: any | null
      processedAt?: any | null
      reviewedAt?: any | null
      rejectedAt?: any | null
      failedAt?: any | null
      priority: OpsAccountRequestPriority
      kind: OpsAccountRequestKind
      errorMessage?: string | null
      notes?: string | null
      status: OpsAccountRequestStatus
      user: { __typename?: 'User'; id: string; fullName: string; email: string }
      escalatedBy?: { __typename?: 'User'; fullName: string } | null
      processedBy?: { __typename?: 'User'; fullName: string } | null
      reviewedBy?: { __typename?: 'User'; fullName: string } | null
      rejectedBy?: { __typename?: 'User'; fullName: string } | null
      payload:
        | {
            __typename?: 'CancelSubscriptionResponsePayload'
            subscriptionId: string
            immediateCancellation: boolean
            waiveEarlyCancellationFee: boolean
            waiveEarlyCancellationSurcharge: boolean
            cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
          }
        | {
            __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
            subscriptionId: string
            billingCycleAnchor: any
          }
        | { __typename?: 'CreditResponsePayload'; centsAmount: number }
        | {
            __typename?: 'DeleteDuplicateAccountResponsePayload'
            duplicateUserId: string
            creditAmountInCents: number
            replaceWithDuplicateUserEmail?: boolean | null
          }
        | { __typename?: 'MiscRequestResponsePayload'; requestDetails: string }
        | {
            __typename?: 'OneOffAddonResponsePayload'
            product: BillingProduct
            paid: boolean
            discountCentsAmount?: number | null
          }
        | {
            __typename?: 'PauseSubscriptionResponsePayload'
            subscriptionId: string
            cycleCount: number
            reason: OpsAccountRequestKindsPauseSubscriptionReason
          }
        | {
            __typename?: 'RefundResponsePayload'
            chargeId: string
            amountInCents: number
            subscriptionCancellationRequired: boolean
            subscriptionCancellationReason?: OpsAccountRequestKindsCancelSubscriptionReason | null
            refundReason?: {
              __typename?: 'OpsAccountRequestKindsRefundReasonDef'
              journey: string
              reasonOne: string
              reasonTwo: string
            } | null
          }
        | { __typename?: 'UncancelSubscriptionResponsePayload'; subscriptionId: string }
        | {
            __typename?: 'UnpauseSubscriptionResponsePayload'
            subscriptionId: string
            resumeDate?: any | null
          }
        | {
            __typename?: 'UpdateSubscriptionResponsePayload'
            oldProduct?: BillingProduct | null
            nextProduct: BillingProduct
          }
        | {
            __typename?: 'UpsertLimitedSupportResponsePayload'
            limitedSupportSubscriptionId?: string | null
            limitedSupportCancelAt: any
          }
    }>
  }
}

export type ICalculateDuplicateAccountCreditQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type ICalculateDuplicateAccountCreditQuery = {
  __typename?: 'QueryType'
  calculateDuplicateAccountCredit: {
    __typename?: 'CalculateDuplicateAccountCredit'
    creditAmountInCents: number
  }
}

export type IShowAccountRequestsConfigQueryVariables = Exact<{ [key: string]: never }>

export type IShowAccountRequestsConfigQuery = {
  __typename?: 'QueryType'
  showAccountRequestsConfig: {
    __typename?: 'AccountRequestsConfig'
    pauseReasons: Array<{
      __typename?: 'OpsAccountRequestKindsPauseSubscriptionReasonDef'
      key: OpsAccountRequestKindsPauseSubscriptionReason
      copy: string
    }>
    refundReasons: Array<{
      __typename?: 'OpsAccountRequestKindsRefundReasonDef'
      key: OpsAccountRequestKindsRefundReason
      description: string
    }>
  }
}

export type IAdminPanelAllCoachAssignmentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ICoachAssignmentSort> | ICoachAssignmentSort>
  dynamicFilters?: InputMaybe<
    Array<ICoachAssignmentDynamicFilterItem> | ICoachAssignmentDynamicFilterItem
  >
  last?: InputMaybe<Scalars['Boolean']>
}>

export type IAdminPanelAllCoachAssignmentsQuery = {
  __typename?: 'QueryType'
  allCoachAssignments: {
    __typename?: 'CoachAssignmentsCollection'
    totalCount: number
    coachAssignments: Array<{
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      dietitianMenu: boolean
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      user: {
        __typename?: 'User'
        id: string
        fullName: string
        email: string
        kind: UserKind
        lastPrescriptionApprovalRequest?: {
          __typename?: 'ApprovalRequest'
          state: UserApprovalRequestState
          createdAt: any
        } | null
        primaryGoal?: {
          __typename?: 'Goal'
          title: string
          kind: UserGoalKind
          description?: string | null
        } | null
        userProperties?: {
          __typename?: 'UserProperties'
          testGroup?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
          oneMonthActiveFeedbackDietitian?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
          dietitianRequest?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
          scheduledVideoCallStatus?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
          healthGoal?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
          communicationFrequency?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
          dietitianSensorCountNotes?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
          nutritionistGroup?: {
            __typename?: 'UserProperty'
            id: number
            kind: UserPropertyKind
            value: string
          } | null
        } | null
        lastDietitianSubscription?: {
          __typename?: 'Subscription'
          stripeStatus: string
          status: SubscriptionStatus
          paused: boolean
          resumesAt?: any | null
        } | null
        ongoingDietitianSubscription?: {
          __typename?: 'Subscription'
          id: string
          cancelAt?: any | null
          stripeStatus: string
          status: SubscriptionStatus
          dietitianAddOn: boolean
          plans: Array<{ __typename?: 'Plan'; nickname: string }>
          primaryProduct: { __typename?: 'ProductMin'; title: string }
        } | null
        lastCoreSubscription?: {
          __typename?: 'Subscription'
          id: string
          stripeStatus: string
          status: SubscriptionStatus
          latestInvoice?: {
            __typename?: 'Invoice'
            latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
          } | null
        } | null
        sensorInfo?: {
          __typename?: 'UserSensorInfo'
          totalCount: number
          replacementRequestsCount: number
          firstStartTime?: any | null
          lastSensor?: { __typename?: 'Sensor'; expired: boolean; expirationDate: any } | null
        } | null
        coachAssignments?: Array<{
          __typename?: 'CoachAssignment'
          id: string
          state: CoachAssignmentState
          last: boolean
          createdAt: any
          assignedAt?: any | null
          deactivatedAt?: any | null
          kind?: CoachAssignmentKind | null
          supportType: CoachAssignmentSupportType
          coach?: { __typename?: 'User'; id: string; fullName: string } | null
          appointment?: { __typename?: 'Appointment'; date?: string | null } | null
        }> | null
      }
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    }>
  }
}

export type IAdminPanelAllCoachesQueryVariables = Exact<{
  sorts?: InputMaybe<Array<IUserSort> | IUserSort>
  dynamicFilters?: InputMaybe<Array<IUserDynamicFilterItem> | IUserDynamicFilterItem>
}>

export type IAdminPanelAllCoachesQuery = {
  __typename?: 'QueryType'
  allUsers: {
    __typename?: 'PrivateUserCollection'
    users: Array<{
      __typename?: 'User'
      id: string
      fullName: string
      coachAssignmentInfo: { __typename?: 'CoachAssignmentInfo'; activeAssignmentsCount: number }
    }>
  }
}

export type IAllHealthPortalNotificationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IHealthPortalNotificationsSort> | IHealthPortalNotificationsSort>
  completed?: InputMaybe<Scalars['Boolean']>
  forReview?: InputMaybe<Scalars['Boolean']>
  kinds?: InputMaybe<Array<HealthPortalNotificationKind> | HealthPortalNotificationKind>
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type IAllHealthPortalNotificationsQuery = {
  __typename?: 'QueryType'
  allHealthPortalNotifications: {
    __typename?: 'HealthPortalNotificationCollection'
    healthPortalNotifications: Array<{
      __typename?: 'HealthPortalNotification'
      id: string
      completedAt?: any | null
      createdAt: any
      kind: HealthPortalNotificationKind
      coachAssignment?: {
        __typename?: 'CoachAssignment'
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        user: {
          __typename?: 'User'
          id: string
          fullName: string
          ongoingDietitianSubscription?: {
            __typename?: 'Subscription'
            cancelAt?: any | null
            resumesAt?: any | null
          } | null
          coachAssignments?: Array<{
            __typename?: 'CoachAssignment'
            id: string
            kind?: CoachAssignmentKind | null
          }> | null
        }
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      chatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      supportTicket?: {
        __typename?: 'Ticket'
        id: string
        description: string
        externalLink?: string | null
        kind: TicketKind
        reason?: TicketReason | null
        status: TicketStatus
        priority: TicketPriority
        createdAt: any
        createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
        user: { __typename?: 'User'; id: string; email: string; fullName: string }
        assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
      } | null
    }>
  }
}

export type IAdminPanelAllUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IUserSort> | IUserSort>
  dynamicFilters?: InputMaybe<Array<IUserDynamicFilterItem> | IUserDynamicFilterItem>
}>

export type IAdminPanelAllUsersQuery = {
  __typename?: 'QueryType'
  allUsers: {
    __typename?: 'PrivateUserCollection'
    totalCount: number
    users: Array<{
      __typename?: 'User'
      id: string
      fullName: string
      email: string
      sensorInfo?: { __typename?: 'UserSensorInfo'; totalCount: number } | null
      coachAssignments?: Array<{
        __typename?: 'CoachAssignment'
        kind?: CoachAssignmentKind | null
        last: boolean
      }> | null
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        cancelAt?: any | null
        status: SubscriptionStatus
        plans: Array<{ __typename?: 'Plan'; nickname: string }>
      } | null
      lastDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        stripeStatus: string
        status: SubscriptionStatus
      } | null
      primaryGoal?: {
        __typename?: 'Goal'
        title: string
        kind: UserGoalKind
        description?: string | null
      } | null
      userProperties?: {
        __typename?: 'UserProperties'
        oneMonthActiveFeedbackDietitian?: {
          __typename?: 'UserProperty'
          id: number
          kind: UserPropertyKind
          value: string
        } | null
        dietitianRequest?: {
          __typename?: 'UserProperty'
          id: number
          kind: UserPropertyKind
          value: string
        } | null
        scheduledVideoCallStatus?: {
          __typename?: 'UserProperty'
          id: number
          kind: UserPropertyKind
          value: string
        } | null
        testGroup?: {
          __typename?: 'UserProperty'
          id: number
          kind: UserPropertyKind
          value: string
        } | null
        healthGoal?: {
          __typename?: 'UserProperty'
          id: number
          kind: UserPropertyKind
          value: string
        } | null
        videoCallRescheduleFreePassUsed?: {
          __typename?: 'UserProperty'
          id: number
          kind: UserPropertyKind
          value: string
        } | null
      } | null
    }>
  }
}

export type IAllChartsQueryVariables = Exact<{
  filter?: InputMaybe<IDateFilter>
}>

export type IAllChartsQuery = {
  __typename?: 'QueryType'
  allCharts: {
    __typename?: 'Charts'
    charts: Array<{
      __typename?: 'Chart'
      type: string
      title: string
      description: string
      xAxis: string
      yAxis: string
      range: {
        __typename?: 'ChartRange'
        min?: number | null
        max?: number | null
        goal?: number | null
        goalMin?: number | null
        goalMax?: number | null
      }
      meta: {
        __typename?: 'ChartMeta'
        key?: string | null
        tag?: string | null
        section?: string | null
      }
      values: Array<
        | { __typename?: 'NumericPair'; xNumericPair: number; yNumericPair?: number | null }
        | {
            __typename?: 'RangePair'
            yRangePair: number
            xRangePair: { __typename?: 'Range'; min: number; max: number }
          }
        | {
            __typename?: 'StringPair'
            name?: string | null
            xStringPair: string
            yStringPair?: number | null
          }
        | { __typename?: 'TimePair'; interpolated: boolean; xTimePair: any; yTimePair: number }
      >
    }>
  }
}

export type IAllChatConversationMessageTemplateVariablesQueryVariables = Exact<{
  [key: string]: never
}>

export type IAllChatConversationMessageTemplateVariablesQuery = {
  __typename?: 'QueryType'
  allChatConversationMessageTemplateVariables: {
    __typename?: 'ChatConversationMessageTemplateVariableCollection'
    chatConversationMessageTemplateVariables: Array<{
      __typename?: 'ChatConversationMessageTemplateVariable'
      id: string
      key: string
    }>
  }
}

export type IAllChatConversationMessageTemplatesQueryVariables = Exact<{
  kinds?: InputMaybe<
    Array<ChatConversationMessageTemplateKind> | ChatConversationMessageTemplateKind
  >
  excludeAutomatic?: InputMaybe<Scalars['Boolean']>
}>

export type IAllChatConversationMessageTemplatesQuery = {
  __typename?: 'QueryType'
  allChatConversationMessageTemplates: {
    __typename?: 'ConversationMessageTemplateCollection'
    chatConversationMessageTemplates: Array<{
      __typename?: 'ChatConversationMessageTemplate'
      id: string
      value: string
      kind: ChatConversationMessageTemplateKind
      name: string
      private: boolean
      automatic: boolean
      createdBy?: { __typename?: 'User'; id: string } | null
    }>
  }
}

export type IAllChatbotConversationsQueryVariables = Exact<{ [key: string]: never }>

export type IAllChatbotConversationsQuery = {
  __typename?: 'QueryType'
  allChatbotConversations: {
    __typename?: 'ListConversations'
    conversations: Array<{
      __typename?: 'Conversation'
      id: string
      userId: string
      providerId: string
      createdAt: any
      updatedAt: any
    }>
  }
}

export type IAllCoachConversationScheduledMessagesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  kinds?: InputMaybe<
    Array<ChatConversationMessageTemplateKind> | ChatConversationMessageTemplateKind
  >
  states?: InputMaybe<
    Array<CoachesConversationScheduledMessageState> | CoachesConversationScheduledMessageState
  >
  sorts?: InputMaybe<
    Array<ICoachConversationScheduledMessageSort> | ICoachConversationScheduledMessageSort
  >
}>

export type IAllCoachConversationScheduledMessagesQuery = {
  __typename?: 'QueryType'
  allCoachConversationScheduledMessages: {
    __typename?: 'CoachConversationScheduledMessageCollection'
    coachConversationScheduledMessages: Array<{
      __typename?: 'CoachConversationScheduledMessage'
      id: string
      kind: ChatConversationMessageTemplateKind
      state: CoachesConversationScheduledMessageState
      sendAt?: any | null
      sentAt?: any | null
      coachAssignment: {
        __typename?: 'CoachAssignment'
        id: string
        user: { __typename?: 'User'; id: string; fullName: string }
        appointment?: { __typename?: 'Appointment'; id: string } | null
      }
    }>
  }
}

export type IAllEventsQueryVariables = Exact<{
  filter?: InputMaybe<IDateFilter>
  pagination?: InputMaybe<IPagination>
}>

export type IAllEventsQuery = {
  __typename?: 'QueryType'
  allEvents: {
    __typename?: 'EventsData'
    events: {
      __typename?: 'EventCollection'
      events: Array<
        | {
            __typename: 'Activity'
            createdAt: any
            description: string
            endedAt: any
            favorite: boolean
            id: string
            length: number
            occurredAt: any
            startedAt: any
            status?: string | null
            title: string
            type: string
            statistics: {
              __typename?: 'EntryStatistics'
              aucScore?: number | null
              glucose2hrDelta?: number | null
              glucoseDelta?: number | null
              glucoseMax?: number | null
              glucoseMin?: number | null
              glucoseScore?: number | null
              glucoseScore2hrDelta?: number | null
              glucoseScoreAuc?: number | null
              glucoseScoreDelta?: number | null
              glucoseScorePeak?: number | null
            }
          }
        | {
            __typename?: 'JournalEntry'
            id: string
            title: string
            body: string
            occurredAt: any
            createdAt: any
            creatorId: string
            score?: number | null
            measurements?: Array<{
              __typename: 'Measurement'
              createdAt: any
              cumulative: boolean
              description: string
              id: string
              occurredAt: any
              timestamp: any
              title: string
              type: string
              units: string
              value?: number | null
              values?:
                | { __typename?: 'BloodPressureMeasurement'; diastolic: number; systolic: number }
                | { __typename?: 'SingleValueMeasurement'; value: number }
                | null
              scan?: { __typename?: 'Scan'; createdAt: any; id: string } | null
            }> | null
          }
        | {
            __typename: 'Meal'
            avatar?: string | null
            occurredAt: any
            createdAt: any
            description: string
            experimentId?: string | null
            favorite: boolean
            source: MealSource
            id: string
            title: string
            kind?: MealKind | null
            updatedAt: any
            mealCreatedBy: { __typename?: 'User'; id: string }
            statistics: {
              __typename?: 'EntryStatistics'
              aucScore?: number | null
              glucose2hrDelta?: number | null
              glucoseDelta?: number | null
              glucoseMax?: number | null
              glucoseMin?: number | null
              glucoseScore?: number | null
              glucoseScore2hrDelta?: number | null
              glucoseScoreAuc?: number | null
              glucoseScoreDelta?: number | null
              glucoseScorePeak?: number | null
            }
            ingredients: Array<{
              __typename?: 'Ingredient'
              calories: number
              createdAt: any
              description: string
              id: string
              servingAmount: number
              servingUnits: string
            }>
            glucose: {
              __typename?: 'EntryGlucose'
              range: Array<number>
              healthyRange: Array<number>
              values: Array<{ __typename?: 'Measurement'; timestamp: any; value?: number | null }>
            }
            nutrition: Array<{ __typename?: 'KeyValuePairFloat'; key: string; value: number }>
          }
        | {
            __typename: 'Measurement'
            createdAt: any
            cumulative: boolean
            description: string
            id: string
            occurredAt: any
            timestamp: any
            title: string
            type: string
            units: string
            value?: number | null
            scan?: { __typename?: 'Scan'; createdAt: any; id: string } | null
            values?:
              | { __typename?: 'BloodPressureMeasurement'; diastolic: number; systolic: number }
              | { __typename?: 'SingleValueMeasurement'; value: number }
              | null
          }
        | {
            __typename: 'Note'
            body: string
            createdAt: any
            creatorId: string
            id: string
            occurredAt: any
            title: string
            createdBy: string
          }
      >
    }
  }
}

export type IAllFilesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IFileSort> | IFileSort>
  dynamicFilters?: InputMaybe<Array<IFileDynamicFilterItem> | IFileDynamicFilterItem>
}>

export type IAllFilesQuery = {
  __typename?: 'QueryType'
  allFiles: {
    __typename?: 'ListFiles'
    files: Array<{
      __typename?: 'File'
      tag?: UserFileTag | null
      id: string
      downloadFileUrl: string
      permanentUrl: string
      fileUrl: string
      fileName: string
      fileType: string
      createdAt: any
    }>
  }
}

export type IAllHistoryQueryVariables = Exact<{
  filter?: InputMaybe<IDateFilter>
  pagination?: InputMaybe<IPagination>
}>

export type IAllHistoryQuery = {
  __typename?: 'QueryType'
  allEvents: {
    __typename?: 'EventsData'
    events: {
      __typename?: 'EventCollection'
      events: Array<
        | {
            __typename: 'Activity'
            createdAt: any
            description: string
            endedAt: any
            favorite: boolean
            id: string
            length: number
            occurredAt: any
            startedAt: any
            status?: string | null
            title: string
            type: string
            statistics: {
              __typename?: 'EntryStatistics'
              aucScore?: number | null
              glucose2hrDelta?: number | null
              glucoseDelta?: number | null
              glucoseMax?: number | null
              glucoseMin?: number | null
              glucoseScore?: number | null
              glucoseScore2hrDelta?: number | null
              glucoseScoreAuc?: number | null
              glucoseScoreDelta?: number | null
              glucoseScorePeak?: number | null
            }
          }
        | {
            __typename?: 'JournalEntry'
            id: string
            title: string
            body: string
            occurredAt: any
            createdAt: any
            creatorId: string
            score?: number | null
            measurements?: Array<{
              __typename: 'Measurement'
              createdAt: any
              cumulative: boolean
              description: string
              id: string
              occurredAt: any
              timestamp: any
              title: string
              type: string
              units: string
              value?: number | null
              values?:
                | { __typename?: 'BloodPressureMeasurement'; diastolic: number; systolic: number }
                | { __typename?: 'SingleValueMeasurement'; value: number }
                | null
              scan?: { __typename?: 'Scan'; createdAt: any; id: string } | null
            }> | null
          }
        | {
            __typename: 'Meal'
            avatar?: string | null
            occurredAt: any
            createdAt: any
            description: string
            experimentId?: string | null
            favorite: boolean
            source: MealSource
            id: string
            title: string
            kind?: MealKind | null
            updatedAt: any
            mealCreatedBy: { __typename?: 'User'; id: string }
            statistics: {
              __typename?: 'EntryStatistics'
              aucScore?: number | null
              glucose2hrDelta?: number | null
              glucoseDelta?: number | null
              glucoseMax?: number | null
              glucoseMin?: number | null
              glucoseScore?: number | null
              glucoseScore2hrDelta?: number | null
              glucoseScoreAuc?: number | null
              glucoseScoreDelta?: number | null
              glucoseScorePeak?: number | null
            }
            ingredients: Array<{
              __typename?: 'Ingredient'
              calories: number
              createdAt: any
              description: string
              id: string
              servingAmount: number
              servingUnits: string
            }>
            glucose: {
              __typename?: 'EntryGlucose'
              range: Array<number>
              healthyRange: Array<number>
              values: Array<{ __typename?: 'Measurement'; timestamp: any; value?: number | null }>
            }
            nutrition: Array<{ __typename?: 'KeyValuePairFloat'; key: string; value: number }>
          }
        | {
            __typename: 'Measurement'
            createdAt: any
            cumulative: boolean
            description: string
            id: string
            occurredAt: any
            timestamp: any
            title: string
            type: string
            units: string
            value?: number | null
            scan?: { __typename?: 'Scan'; createdAt: any; id: string } | null
            values?:
              | { __typename?: 'BloodPressureMeasurement'; diastolic: number; systolic: number }
              | { __typename?: 'SingleValueMeasurement'; value: number }
              | null
          }
        | {
            __typename: 'Note'
            body: string
            createdAt: any
            creatorId: string
            id: string
            occurredAt: any
            title: string
            createdBy: string
          }
      >
    }
  }
}

export type IAllInvoiceOrdersQueryVariables = Exact<{
  page: Scalars['Int']
  perPage: Scalars['Int']
  sorts?: InputMaybe<Array<IInvoiceOrderSort> | IInvoiceOrderSort>
  dynamicFilters?: InputMaybe<
    Array<IInvoiceOrderDynamicFilterItem> | IInvoiceOrderDynamicFilterItem
  >
}>

export type IAllInvoiceOrdersQuery = {
  __typename?: 'QueryType'
  allInvoiceOrders: {
    __typename?: 'ListInvoiceOrders'
    totalCount: number
    invoiceOrders: Array<{
      __typename?: 'InvoiceOrder'
      id: string
      quantity: number
      product: string
      productType: string
      processedAt?: any | null
      orderId?: number | null
      lastProcessingAttemptAt?: any | null
      createdAt: any
      updatedAt: any
      state: InvoiceOrderState
      stateReason?: string | null
      invoice: { __typename?: 'Invoice'; stripeId: string; paidAt?: any | null }
      user: {
        __typename?: 'User'
        id: string
        email: string
        firstName: string
        lastName: string
        fulfillmentConfiguration: {
          __typename?: 'FulfillmentConfiguration'
          sensorKind: UserSensorKind
        }
        address?: {
          __typename?: 'Address'
          id: string
          name: string
          street: string
          street2: string
          city: string
          state?: AddressState | null
          zipCode: string
          country?: string | null
        } | null
      }
    }>
  }
}

export type IAllKnowledgeAnswersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeAnswerSort> | IKnowledgeAnswerSort>
  dynamicFilters?: InputMaybe<
    Array<IKnowledgeAnswerDynamicFilterItem> | IKnowledgeAnswerDynamicFilterItem
  >
}>

export type IAllKnowledgeAnswersQuery = {
  __typename?: 'QueryType'
  allKnowledgeAnswers: {
    __typename?: 'KnowledgeAnswerCollection'
    totalCount: number
    pageCount?: number | null
    knowledgeAnswers: Array<{
      __typename?: 'KnowledgeAnswer'
      id: string
      title: string
      value: string
      knowledgeQuestions: Array<{ __typename?: 'KnowledgeQuestion'; id: string; value: string }>
      knowledgeSources: Array<{
        __typename?: 'KnowledgeSource'
        id: string
        title: string
        url: string
      }>
    }>
  }
}

export type IAllKnowledgeQuestionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeQuestionSort> | IKnowledgeQuestionSort>
  dynamicFilters?: InputMaybe<
    Array<IKnowledgeQuestionDynamicFilterItem> | IKnowledgeQuestionDynamicFilterItem
  >
}>

export type IAllKnowledgeQuestionsQuery = {
  __typename?: 'QueryType'
  allKnowledgeQuestions: {
    __typename?: 'KnowledgeQuestionCollection'
    totalCount: number
    pageCount?: number | null
    knowledgeQuestions: Array<{
      __typename?: 'KnowledgeQuestion'
      id: string
      value: string
      knowledgeAnswers: Array<{
        __typename?: 'KnowledgeAnswer'
        id: string
        title: string
        value: string
        knowledgeSources: Array<{
          __typename?: 'KnowledgeSource'
          id: string
          title: string
          url: string
        }>
      }>
      knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
    }>
  }
}

export type IAllKnowledgeSourcesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeSourceSort> | IKnowledgeSourceSort>
  dynamicFilters?: InputMaybe<
    Array<IKnowledgeSourceDynamicFilterItem> | IKnowledgeSourceDynamicFilterItem
  >
}>

export type IAllKnowledgeSourcesQuery = {
  __typename?: 'QueryType'
  allKnowledgeSources: {
    __typename?: 'KnowledgeSourceCollection'
    totalCount: number
    pageCount?: number | null
    knowledgeSources: Array<{
      __typename?: 'KnowledgeSource'
      id: string
      title: string
      url: string
      knowledgeAnswers: Array<{
        __typename?: 'KnowledgeAnswer'
        id: string
        title: string
        value: string
        knowledgeQuestions: Array<{ __typename?: 'KnowledgeQuestion'; id: string; value: string }>
      }>
    }>
  }
}

export type IAllKnowledgeTagsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IKnowledgeTagSort> | IKnowledgeTagSort>
  dynamicFilters?: InputMaybe<
    Array<IKnowledgeTagDynamicFilterItem> | IKnowledgeTagDynamicFilterItem
  >
}>

export type IAllKnowledgeTagsQuery = {
  __typename?: 'QueryType'
  allKnowledgeTags: {
    __typename?: 'KnowledgeTagCollection'
    totalCount: number
    pageCount?: number | null
    knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
  }
}

export type IAllMeasurementsQueryVariables = Exact<{
  filter?: InputMaybe<IMeasurementFilter>
  pagination?: InputMaybe<IPagination>
}>

export type IAllMeasurementsQuery = {
  __typename?: 'QueryType'
  allMeasurements: {
    __typename?: 'MeasurementCollection'
    measurements: Array<{
      __typename?: 'Measurement'
      id: string
      occurredAt: any
      timestamp: any
      type: string
      units: string
      value?: number | null
    }>
  }
}

export type IAllNotesQueryVariables = Exact<{
  userId: Scalars['ID']
  sorts?: InputMaybe<Array<INoteSort> | INoteSort>
  dynamicFilters?: InputMaybe<Array<INoteDynamicFilterItem> | INoteDynamicFilterItem>
}>

export type IAllNotesQuery = {
  __typename?: 'QueryType'
  allNotes: {
    __typename?: 'ListNotes'
    notes: Array<{
      __typename?: 'Note'
      id: string
      body: string
      kind: NoteKind
      occurredAt: any
      pinnedAt?: any | null
      creator: { __typename?: 'User'; id: string; fullName: string }
    }>
  }
}

export type IAllOrdersQueryVariables = Exact<{
  page: Scalars['Int']
  perPage: Scalars['Int']
  sorts?: InputMaybe<Array<IOrderSort> | IOrderSort>
  dynamicFilters?: InputMaybe<Array<IOrderDynamicFilterItem> | IOrderDynamicFilterItem>
}>

export type IAllOrdersQuery = {
  __typename?: 'QueryType'
  allOrders: {
    __typename?: 'ListOrders'
    totalRecords: number
    orders: Array<{
      __typename?: 'Order'
      id: number
      userId: number
      product: string
      productType: ShippingOrderProductType
      sensorKind?: UserSensorKind | null
      provider?: ShippingOrderProvider | null
      quantity: number
      notes?: string | null
      createdAt: any
      updatedAt: any
      includable: ShippingOrderIncludable
      user?: {
        __typename?: 'User'
        id: string
        email: string
        firstName: string
        lastName: string
        phoneNumber?: string | null
        dateOfBirth?: string | null
        sex?: string | null
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      invoice?: { __typename?: 'Invoice'; stripeId: string } | null
      replacementRequest?: {
        __typename?: 'ReplacementRequest'
        replacementReason: ReplacementRequestReplacementReason
      } | null
    }>
  }
}

export type IAllOrganizationsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>
}>

export type IAllOrganizationsQuery = {
  __typename?: 'QueryType'
  allOrganizations: {
    __typename?: 'OrganizationsCollection'
    organizations: Array<{
      __typename?: 'Organization'
      id: number
      name: string
      isNutrisense: boolean
    }>
  }
}

export type IAllReplacementRequestsQueryVariables = Exact<{
  page: Scalars['Int']
  perPage: Scalars['Int']
  sorts?: InputMaybe<Array<IReplacementRequestSort> | IReplacementRequestSort>
  dynamicFilters?: InputMaybe<
    Array<IReplacementRequestDynamicFilterItem> | IReplacementRequestDynamicFilterItem
  >
}>

export type IAllReplacementRequestsQuery = {
  __typename?: 'QueryType'
  allReplacementRequests: {
    __typename?: 'ReplacementRequestCollection'
    totalCount: number
    replacementRequests: Array<{
      __typename?: 'ReplacementRequest'
      id: string
      userId: string
      createdById?: string | null
      chargeAmount: ReplacementRequestChargeAmount
      createdAt: any
      daysWorn?: string | null
      dateFellOff?: string | null
      dateNotified?: string | null
      errorType: ReplacementRequestErrorType
      howItFellOff: string
      notes: string
      replacementReason: ReplacementRequestReplacementReason
      testedWithLibreApp?: ReplacementRequestTestedWithLibreApp | null
      wearingPatch: ReplacementRequestWearingPatch
      state: ReplacementRequestState
      sensor?: { __typename?: 'Sensor'; id: string; serialNumber: string; model: string } | null
      createdBy?: { __typename?: 'User'; email: string } | null
      user: { __typename?: 'User'; email: string }
      invoice?: { __typename?: 'Invoice'; stripeId: string } | null
    }>
  }
}

export type IAllScansQueryVariables = Exact<{
  pagination?: InputMaybe<IPagination>
  filter?: InputMaybe<IMeasurementFilter>
}>

export type IAllScansQuery = {
  __typename?: 'QueryType'
  allScans: {
    __typename?: 'MeasurementCollection'
    measurements: Array<{
      __typename?: 'Measurement'
      id: string
      type: string
      title: string
      description: string
      value?: number | null
      units: string
      occurredAt: any
      createdAt: any
      scan?: { __typename?: 'Scan'; id: string; createdAt: any } | null
    }>
  }
}

export type IAllSensorsQueryVariables = Exact<{ [key: string]: never }>

export type IAllSensorsQuery = {
  __typename?: 'QueryType'
  allSensors: {
    __typename?: 'SensorCollection'
    sensors: Array<{
      __typename?: 'Sensor'
      activationDate: any
      baselineAdjustment: number
      daysRemaining: number
      expirationDate: any
      expirationTime: any
      expired: boolean
      id: string
      serialNumber: string
      status?: string | null
      lastStatusChange?: {
        __typename?: 'SensorStatusChange'
        status: SensorStatus
        createdAt: any
        failure: boolean
      } | null
    }>
  }
}

export type IAllShipmentsQueryVariables = Exact<{
  page: Scalars['Int']
  perPage: Scalars['Int']
  sorts?: InputMaybe<Array<IShipmentSort> | IShipmentSort>
  dynamicFilters?: InputMaybe<Array<IShipmentDynamicFilterItem> | IShipmentDynamicFilterItem>
}>

export type IAllShipmentsQuery = {
  __typename?: 'QueryType'
  allShipments: {
    __typename?: 'ListShipments'
    totalRecords: number
    shipments: Array<{
      __typename?: 'Shipment'
      eta?: any | null
      location?: string | null
      id: number
      orderId: number
      addressId: number
      status?: ShippingShipmentStatus | null
      trackingNumber?: string | null
      trackingUrl?: string | null
      createdAt: any
      updatedAt: any
      shippedAt?: any | null
      deliveredAt?: any | null
      canceledAt?: any | null
      providerShipmentId?: string | null
      providerError?: string | null
      providerReceivedAt?: any | null
      provider?: ShippingOrderProvider | null
      quantity: number
      order: {
        __typename?: 'Order'
        id: number
        createdAt: any
        product: string
        productType: ShippingOrderProductType
        sensorKind?: UserSensorKind | null
        quantity: number
        invoice?: { __typename?: 'Invoice'; stripeId: string } | null
        replacementRequest?: {
          __typename?: 'ReplacementRequest'
          providerCompensation: ReplacementRequestProviderCompensation
        } | null
      }
      address: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      }
      user: {
        __typename?: 'User'
        id: string
        email: string
        firstName: string
        lastName: string
        phoneNumber?: string | null
        dateOfBirth?: string | null
        sex?: string | null
        truepillPatientToken?: string | null
        activeRefillConsent?: { __typename?: 'UserRefillConsent'; givenAt: any } | null
      }
    }>
  }
}

export type IAllStatsQueryVariables = Exact<{
  filter?: InputMaybe<IDateFilter>
}>

export type IAllStatsQuery = {
  __typename?: 'QueryType'
  allStats: {
    __typename?: 'Stats'
    statistics: Array<{
      __typename?: 'Statistic'
      type: string
      title: string
      description: string
      value: number
      valuePrev: number
      change: number
      range: {
        __typename?: 'StatRange'
        min?: number | null
        max?: number | null
        warning?: number | null
        danger?: number | null
        reverse?: boolean | null
      }
      meta: { __typename?: 'StatMeta'; key: string; section: string }
    }>
  }
}

export type IAllSurveyLinksQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ISurveyLinkSort> | ISurveyLinkSort>
  dynamicFilters?: InputMaybe<Array<ISurveyLinkDynamicFilterItem> | ISurveyLinkDynamicFilterItem>
}>

export type IAllSurveyLinksQuery = {
  __typename?: 'QueryType'
  allSurveyLinks: {
    __typename?: 'PrivateSurveyLinkCollection'
    totalCount: number
    pageCount?: number | null
    surveyLinks: Array<{
      __typename?: 'SurveyLink'
      id: string
      state: SurveysUserLinkState
      finished: boolean
      startedAt: any
      finishedAt?: any | null
      survey: { __typename?: 'Survey'; kind: SurveysConfigKind; version: string }
      user?: {
        __typename?: 'User'
        id: string
        fullName: string
        email: string
        lastPrescriptionApprovalRequest?: {
          __typename?: 'ApprovalRequest'
          state: UserApprovalRequestState
        } | null
      } | null
    }>
  }
}

export type IAllUserPropertiesQueryVariables = Exact<{ [key: string]: never }>

export type IAllUserPropertiesQuery = {
  __typename?: 'QueryType'
  allUserProperties: {
    __typename?: 'UserPropertiesCollection'
    userProperties: Array<{
      __typename?: 'UserProperty'
      id: number
      kind: UserPropertyKind
      value: string
      userId: string
    }>
  }
}

export type IAllUsersAutocompleteQueryVariables = Exact<{
  query: Scalars['String']
}>

export type IAllUsersAutocompleteQuery = {
  __typename?: 'QueryType'
  allUsersAutocomplete: {
    __typename?: 'UserCollection'
    users: Array<{
      __typename?: 'UserMin'
      email: string
      fullName: string
      id: string
      timeZone: string
      phoneNumber: string
    }>
  }
}

export type IAllAppointmentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IAppointmentSort> | IAppointmentSort>
  dynamicFilters?: InputMaybe<Array<IAppointmentDynamicFilterItem> | IAppointmentDynamicFilterItem>
}>

export type IAllAppointmentsQuery = {
  __typename?: 'QueryType'
  allAppointments?: {
    __typename?: 'AppointmentCollection'
    totalCount: number
    appointments: Array<{
      __typename?: 'HealthPortalAppointment'
      id: string
      meetingAt?: any | null
      meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
      actualDuration?: number | null
      title?: string | null
      appointmentType?: InsuranceBerryStreetAppointmentType | null
      chargeType?: InsuranceBerryStreetAppointmentChargeType | null
      externalVideochatUrl?: string | null
      startVideochatUrl?: string | null
      agenda?: string | null
      user: { __typename?: 'User'; id: string; fullName: string; timeZone: string }
      insurancePolicy?: {
        __typename?: 'InsurancePolicy'
        unitsLeft?: number | null
        unitsUsed?: number | null
        unitsAuthorized?: number | null
        lastState?: {
          __typename?: 'InsurancePolicyState'
          kind: UserInsurancePolicyStateKind
          reason?: string | null
        } | null
      } | null
    }>
  } | null
}

export type IAppointmentQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type IAppointmentQuery = {
  __typename?: 'QueryType'
  appointment: {
    __typename?: 'HealthPortalAppointment'
    id: string
    meetingAt?: any | null
    meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
    actualDuration?: number | null
    title?: string | null
    appointmentType?: InsuranceBerryStreetAppointmentType | null
    chargeType?: InsuranceBerryStreetAppointmentChargeType | null
    externalVideochatUrl?: string | null
    startVideochatUrl?: string | null
    agenda?: string | null
    user: { __typename?: 'User'; id: string; fullName: string; timeZone: string }
    insurancePolicy?: {
      __typename?: 'InsurancePolicy'
      unitsLeft?: number | null
      unitsUsed?: number | null
      unitsAuthorized?: number | null
      lastState?: {
        __typename?: 'InsurancePolicyState'
        kind: UserInsurancePolicyStateKind
        reason?: string | null
      } | null
    } | null
  }
}

export type INextNutritionistAppointmentQueryVariables = Exact<{ [key: string]: never }>

export type INextNutritionistAppointmentQuery = {
  __typename?: 'QueryType'
  nextNutritionistAppointment?: { __typename?: 'Appointment'; meetingAt?: any | null } | null
}

export type INextUserAppointmentQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type INextUserAppointmentQuery = {
  __typename?: 'QueryType'
  nextUserAppointment?: { __typename?: 'Appointment'; meetingAt?: any | null } | null
}

export type IAllApprovalRequestsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IApprovalRequestSort> | IApprovalRequestSort>
  dynamicFilters?: InputMaybe<
    Array<IApprovalRequestDynamicFilterItem> | IApprovalRequestDynamicFilterItem
  >
}>

export type IAllApprovalRequestsQuery = {
  __typename?: 'QueryType'
  allApprovalRequests: {
    __typename?: 'ApprovalRequestCollection'
    totalCount: number
    approvalRequests: Array<{
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
      kind: UserApprovalRequestKind
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        fullName: string
        email: string
        dateOfBirth?: string | null
        age?: string | null
        phoneNumber?: string | null
        dexcomSensorPromptUiState?: boolean | null
        governmentIdCard?: string | null
        healthReject?: string | null
        lastAnsweredHealthSurveyQuestionKey?: SurveysQuestionKey | null
        lastAnsweredMedicalHistorySurveyQuestionKey?: SurveysQuestionKey | null
        address?: {
          __typename?: 'Address'
          id: string
          street: string
          street2: string
          city: string
          state?: AddressState | null
          zipCode: string
        } | null
        fulfillmentConfiguration: {
          __typename?: 'FulfillmentConfiguration'
          sensorKind: UserSensorKind
        }
        lastCoreSubscription?: {
          __typename?: 'Subscription'
          primaryProduct: { __typename?: 'ProductMin'; title: string }
          latestInvoice?: {
            __typename?: 'Invoice'
            latestCharge?: { __typename?: 'Charge'; created: any } | null
          } | null
        } | null
      }
    }>
  }
}

export type IAuthorizedFeaturesQueryVariables = Exact<{ [key: string]: never }>

export type IAuthorizedFeaturesQuery = {
  __typename?: 'QueryType'
  authorizedFeatures: {
    __typename?: 'HealthPortalFeatureCollection'
    features: Array<HealthPortalFeature>
  }
}

export type IBillingDashboardAllAccountRequestsQueryVariables = Exact<{
  sorts?: InputMaybe<Array<IAccountRequestSort> | IAccountRequestSort>
  dynamicFilters?: InputMaybe<
    Array<IAccountRequestDynamicFilterItem> | IAccountRequestDynamicFilterItem
  >
}>

export type IBillingDashboardAllAccountRequestsQuery = {
  __typename?: 'QueryType'
  allAccountRequests: {
    __typename?: 'AccountRequestCollection'
    accountRequests: Array<{
      __typename?: 'AccountRequest'
      id: string
      escalatedAt?: any | null
      processedAt?: any | null
      reviewedAt?: any | null
      rejectedAt?: any | null
      failedAt?: any | null
      priority: OpsAccountRequestPriority
      kind: OpsAccountRequestKind
      errorMessage?: string | null
      notes?: string | null
      status: OpsAccountRequestStatus
      user: { __typename?: 'User'; id: string; fullName: string; email: string }
      escalatedBy?: { __typename?: 'User'; fullName: string } | null
      processedBy?: { __typename?: 'User'; fullName: string } | null
      reviewedBy?: { __typename?: 'User'; fullName: string } | null
      rejectedBy?: { __typename?: 'User'; fullName: string } | null
      payload:
        | {
            __typename?: 'CancelSubscriptionResponsePayload'
            subscriptionId: string
            immediateCancellation: boolean
            waiveEarlyCancellationFee: boolean
            waiveEarlyCancellationSurcharge: boolean
            cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
          }
        | {
            __typename?: 'ChangeSubscriptionBillingCycleResponsePayload'
            subscriptionId: string
            billingCycleAnchor: any
          }
        | { __typename?: 'CreditResponsePayload'; centsAmount: number }
        | {
            __typename?: 'DeleteDuplicateAccountResponsePayload'
            duplicateUserId: string
            creditAmountInCents: number
            replaceWithDuplicateUserEmail?: boolean | null
          }
        | { __typename?: 'MiscRequestResponsePayload'; requestDetails: string }
        | {
            __typename?: 'OneOffAddonResponsePayload'
            product: BillingProduct
            paid: boolean
            discountCentsAmount?: number | null
          }
        | {
            __typename?: 'PauseSubscriptionResponsePayload'
            subscriptionId: string
            cycleCount: number
            reason: OpsAccountRequestKindsPauseSubscriptionReason
          }
        | {
            __typename?: 'RefundResponsePayload'
            chargeId: string
            amountInCents: number
            subscriptionCancellationRequired: boolean
            subscriptionCancellationReason?: OpsAccountRequestKindsCancelSubscriptionReason | null
            refundReason?: {
              __typename?: 'OpsAccountRequestKindsRefundReasonDef'
              journey: string
              reasonOne: string
              reasonTwo: string
            } | null
          }
        | { __typename?: 'UncancelSubscriptionResponsePayload'; subscriptionId: string }
        | {
            __typename?: 'UnpauseSubscriptionResponsePayload'
            subscriptionId: string
            resumeDate?: any | null
          }
        | {
            __typename?: 'UpdateSubscriptionResponsePayload'
            oldProduct?: BillingProduct | null
            nextProduct: BillingProduct
          }
        | {
            __typename?: 'UpsertLimitedSupportResponsePayload'
            limitedSupportSubscriptionId?: string | null
            limitedSupportCancelAt: any
          }
    }>
  }
}

export type IBillingDashboardAllChargesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IChargeSort> | IChargeSort>
  dynamicFilters?: InputMaybe<Array<IChargeDynamicFilterItem> | IChargeDynamicFilterItem>
}>

export type IBillingDashboardAllChargesQuery = {
  __typename?: 'QueryType'
  allCharges: {
    __typename?: 'ChargeCollection'
    totalCount: number
    pageCount?: number | null
    charges: Array<{
      __typename?: 'Charge'
      id: string
      stripeId: string
      amount: number
      amountRefunded: number
      paid: boolean
      status: string
      disputeStripeId?: string | null
      failureMessage?: string | null
      disputed: boolean
      refunded: boolean
      created: any
      refundable: boolean
      invoice?: {
        __typename?: 'Invoice'
        stripeId: string
        subscription?: { __typename?: 'Subscription'; stripeId: string } | null
      } | null
    }>
  }
}

export type IBillingDashboardAllInvoicesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IInvoiceSort> | IInvoiceSort>
  dynamicFilters?: InputMaybe<Array<IInvoiceDynamicFilterItem> | IInvoiceDynamicFilterItem>
}>

export type IBillingDashboardAllInvoicesQuery = {
  __typename?: 'QueryType'
  allInvoices: {
    __typename?: 'InvoiceCollection'
    totalCount: number
    pageCount?: number | null
    invoices: Array<{
      __typename?: 'Invoice'
      id: string
      stripeId: string
      paidAt?: any | null
      receiptNumber?: string | null
      downloadUrl?: string | null
      created: any
      updatedAt: any
      total?: number | null
      subscription?: { __typename?: 'Subscription'; stripeId: string } | null
    }>
  }
}

export type IBillingDashboardAllSubscriptionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ISubscriptionSort> | ISubscriptionSort>
  dynamicFilters?: InputMaybe<
    Array<ISubscriptionDynamicFilterItem> | ISubscriptionDynamicFilterItem
  >
}>

export type IBillingDashboardAllSubscriptionsQuery = {
  __typename?: 'QueryType'
  allSubscriptions: {
    __typename?: 'SubscriptionCollection'
    totalCount: number
    pageCount?: number | null
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      stripeId: string
      stripeStatus: string
      paidMonthsCount: number
      pausedMonthsCount: number
      cancelAt?: any | null
      canceledAt?: any | null
      createdAt: any
      currentPeriodEndAt?: any | null
      endAt?: any | null
      resumesAt?: any | null
      startAt: any
      subscribedAt: any
      trialEndAt?: any | null
      updatedAt: any
      paused: boolean
      plans: Array<{ __typename?: 'Plan'; nickname: string }>
    }>
  }
}

export type ICreateSyncRequestMutationVariables = Exact<{
  userId: Scalars['ID']
}>

export type ICreateSyncRequestMutation = {
  __typename?: 'MutationType'
  createSyncRequest: { __typename?: 'Success'; success: boolean }
}

export type ICalendarQueryVariables = Exact<{
  filter?: InputMaybe<IDateFilter>
}>

export type ICalendarQuery = {
  __typename?: 'QueryType'
  calendar: {
    __typename?: 'UserCalendar'
    dates: Array<{ __typename?: 'CalendarDate'; date: any; score?: number | null }>
  }
}

export type IAllChatConversationEventsQueryVariables = Exact<{
  conversationId: Scalars['ID']
  sorts?: InputMaybe<Array<IChatConversationEventSort> | IChatConversationEventSort>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}>

export type IAllChatConversationEventsQuery = {
  __typename?: 'QueryType'
  allChatConversationEvents: {
    __typename?: 'ChatConversationEventsCollection'
    totalCount: number
    pageCount?: number | null
    chatConversationEvents: Array<{
      __typename?: 'ChatConversationEvent'
      id: string
      kind: ChatConversationEventKind
      occurredAt: any
      snoozedUntil?: any | null
      message?: {
        __typename?: 'ChatConversationMessage'
        id: string
        value: string
        sentAt: any
        senderKind: ChatConversationMessageSenderKind
        sender?: {
          __typename?: 'User'
          id: string
          fullName: string
          avatar?: string | null
        } | null
      } | null
      note?: {
        __typename?: 'ChatConversationNote'
        id: string
        value: string
        createdAt: any
        createdBy: { __typename?: 'User'; id: string; fullName: string; avatar?: string | null }
      } | null
      performedBy?: { __typename?: 'User'; id: string; fullName: string } | null
      assignedTo?: { __typename?: 'User'; id: string; fullName: string } | null
    }>
  }
}

export type IAllChatConversationsQueryVariables = Exact<{
  kinds?: InputMaybe<Array<ChatConversationKind> | ChatConversationKind>
  states?: InputMaybe<Array<ChatConversationState> | ChatConversationState>
  sorts?: InputMaybe<Array<IChatConversationSort> | IChatConversationSort>
  forCoach?: InputMaybe<Scalars['Boolean']>
  active?: InputMaybe<Scalars['Boolean']>
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}>

export type IAllChatConversationsQuery = {
  __typename?: 'QueryType'
  allChatConversations: {
    __typename?: 'ChatConversationsCollection'
    totalCount: number
    chatConversations: Array<{
      __typename?: 'ChatConversation'
      id: string
      kind: ChatConversationKind
      state: ChatConversationState
      read: boolean
      intercomCreatedAt: any
      lastMessage?: {
        __typename?: 'ChatConversationMessage'
        id: string
        value: string
        sentAt: any
      } | null
      lastNote?: {
        __typename?: 'ChatConversationNote'
        id: string
        value: string
        createdAt: any
      } | null
      user: { __typename?: 'User'; id: string; fullName: string }
    }>
  }
}

export type IChatConversationQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type IChatConversationQuery = {
  __typename?: 'QueryType'
  chatConversation: {
    __typename?: 'ChatConversation'
    id: string
    kind: ChatConversationKind
    state: ChatConversationState
    read: boolean
    candidateRead: boolean
    intercomCreatedAt: any
    intercomBackfillState?: ChatConversationIntercomBackfillState | null
    lastMessage?: {
      __typename?: 'ChatConversationMessage'
      id: string
      value: string
      sender?: { __typename?: 'User'; id: string; fullName: string } | null
    } | null
    user: { __typename?: 'User'; id: string; fullName: string }
  }
}

export type IChatbotMessageQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type IChatbotMessageQuery = {
  __typename?: 'QueryType'
  chatbotMessage: {
    __typename?: 'Message'
    id: string
    content: string
    userMessage?: {
      __typename?: 'Message'
      id: string
      content: string
      question?: string | null
      knowledgeQuestions: Array<{
        __typename?: 'KnowledgeQuestion'
        id: string
        value: string
        knowledgeAnswers: Array<{
          __typename?: 'KnowledgeAnswer'
          id: string
          title: string
          value: string
          knowledgeSources: Array<{
            __typename?: 'KnowledgeSource'
            id: string
            title: string
            url: string
          }>
        }>
        knowledgeTags: Array<{ __typename?: 'KnowledgeTag'; id: string; value: string }>
      }>
    } | null
  }
}

export type IClientConfigQueryVariables = Exact<{ [key: string]: never }>

export type IClientConfigQuery = {
  __typename?: 'QueryType'
  clientConfig: {
    __typename?: 'ClientConfig'
    sensorSettings: {
      __typename?: 'SensorSettings'
      baselineAdjustmentOptions: Array<{
        __typename?: 'NumericalSelect'
        text: string
        value: number
      }>
    }
    score: { __typename?: 'Score'; warning: number; danger: number; max: number }
    insights: {
      __typename?: 'Config'
      statistics: Array<{
        __typename?: 'Statistics'
        name: string
        metric: {
          __typename?: 'ConfigUnit'
          precision: number
          units: string
          ranges: Array<{
            __typename?: 'RangeUnit'
            from?: number | null
            to?: number | null
            type: string
            rating: string
          }>
        }
        imperial: {
          __typename?: 'ConfigUnit'
          precision: number
          units: string
          ranges: Array<{
            __typename?: 'RangeUnit'
            from?: number | null
            to?: number | null
            type: string
            rating: string
          }>
        }
      }>
    }
    healthSettings: {
      __typename?: 'HealthSettings'
      glucoseLowOptions: Array<{ __typename?: 'NumericalSelect'; text: string; value: number }>
      glucoseHighOptions: Array<{ __typename?: 'NumericalSelect'; text: string; value: number }>
      ketoneLowOptions: Array<{ __typename?: 'NumericalSelect'; text: string; value: number }>
      ketoneHighOptions: Array<{ __typename?: 'NumericalSelect'; text: string; value: number }>
      macroGoalSettings: {
        __typename?: 'MacroGoalSettings'
        caloriesMax: number
        proteinMax: number
        carbsMax: number
        fatMax: number
      }
      unitSystemOptions: Array<{ __typename?: 'StringSelect'; text: string; value: string }>
      scanReminderOptions: Array<{ __typename?: 'NumericalSelect'; text: string; value: number }>
      dashboardGraphOptions: Array<{ __typename?: 'StringSelect'; text: string; value: string }>
    }
    journal: {
      __typename?: 'JournalConfig'
      range: { __typename?: 'JournalRange'; min: number; max: number }
      measurementTypes: Array<{
        __typename?: 'MeasurementType'
        type: string
        reverse?: boolean | null
      }>
    }
    chatbot: { __typename?: 'Chatbot'; responseTimeoutInSeconds: number }
    goalOptions: Array<{ __typename?: 'GoalOption'; text: string; value: UserGoalKind }>
    goalMetricOptions: Array<{
      __typename?: 'GoalMetricOption'
      text: string
      value: UserGoalMetricKind
    }>
    healthMetrics: Array<{
      __typename?: 'HealthMetricType'
      key: HealthMetricsParam
      label: string
      unit?: string | null
      kind: HealthMetricsKind
    }>
    tickets: {
      __typename?: 'Tickets'
      reasonDefs: Array<{
        __typename?: 'TicketReasonDef'
        key: TicketReason
        kind: TicketKind
        title: string
      }>
    }
    dailyActions: Array<{
      __typename?: 'DailyActionKind'
      key: string
      title: string
      description: string
      category: UserDailyActionCategory
    }>
  }
}

export type IAllCoursesQueryVariables = Exact<{ [key: string]: never }>

export type IAllCoursesQuery = {
  __typename?: 'QueryType'
  allCourses: {
    __typename?: 'ProgramCollection'
    programs: Array<{
      __typename?: 'ExpandableProgram'
      id: string
      contentId: string
      position?: number | null
      progress?: { __typename?: 'Progress'; complete: boolean } | null
      modules?: Array<{
        __typename?: 'ExpandableModule'
        id: string
        contentId: string
        position?: number | null
        progress?: { __typename?: 'Progress'; complete: boolean } | null
        lessons?: Array<{
          __typename?: 'Lesson'
          id: string
          contentId: string
          position?: number | null
          progress?: { __typename?: 'Progress'; complete: boolean } | null
        }> | null
      }> | null
    }>
  }
}

export type IAllCourseCohortSessionMembersQueryVariables = Exact<{
  sessionId: Scalars['ID']
}>

export type IAllCourseCohortSessionMembersQuery = {
  __typename?: 'QueryType'
  allCourseCohortSessionMembers: {
    __typename?: 'CohortSessionMemberCollection'
    totalCount: number
    members: Array<{
      __typename?: 'CohortSessionMember'
      id: string
      user: { __typename?: 'UserMin'; id: string; fullName: string }
      attendance?: {
        __typename?: 'CohortSessionAttendance'
        id: string
        state: CoursesCohortSessionAttendanceState
      } | null
      dppHealthDataRecordsChecklist: {
        __typename?: 'DppHealthDataRecordsChecklist'
        hbA1cChecklist: {
          __typename?: 'DppHealthDataRecordChecklist'
          state: DppHealthDataRecordChecklistState
        }
        weightChecklist: {
          __typename?: 'DppHealthDataRecordChecklist'
          state: DppHealthDataRecordChecklistState
        }
        physicalActivityChecklist: {
          __typename?: 'DppHealthDataRecordChecklist'
          state: DppHealthDataRecordChecklistState
        }
      }
    }>
  }
}

export type IAllCourseCohortSessionsQueryVariables = Exact<{
  cohortId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ICohortSessionSort> | ICohortSessionSort>
  dynamicFilters?: InputMaybe<
    Array<ICohortSessionDynamicFilterItem> | ICohortSessionDynamicFilterItem
  >
}>

export type IAllCourseCohortSessionsQuery = {
  __typename?: 'QueryType'
  allCourseCohortSessions: {
    __typename?: 'CohortSessionCollection'
    totalCount: number
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      kind: CoursesCohortSessionKind
      name: string
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
    }>
  }
}

export type IAllCourseCohortsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ICohortSort> | ICohortSort>
  dynamicFilters?: InputMaybe<Array<ICohortDynamicFilterItem> | ICohortDynamicFilterItem>
}>

export type IAllCourseCohortsQuery = {
  __typename?: 'QueryType'
  allCourseCohorts: {
    __typename?: 'CohortCollection'
    totalCount: number
    cohorts: Array<{
      __typename?: 'Cohort'
      id: string
      name: string
      kind: CoursesCohortKind
      state: CoursesCohortState
      instructor?: {
        __typename?: 'User'
        createdAt: any
        email: string
        timeZone: string
        avatar?: string | null
        firstName: string
        fullName: string
        id: string
        lastName: string
        role: UserRole
        sex?: string | null
        dateOfBirth?: string | null
        phoneNumber?: string | null
        lastAppVersion?: string | null
        lastAppPlatform?: string | null
        stripeId?: string | null
        healthieId?: number | null
        stripeCreditBalance: number
        kind: UserKind
        age?: string | null
        isCoach: boolean
        lastPrescriptionApprovalRequest?: {
          __typename?: 'ApprovalRequest'
          state: UserApprovalRequestState
          createdAt: any
        } | null
        address?: {
          __typename?: 'Address'
          id: string
          name: string
          street: string
          street2: string
          city: string
          state?: AddressState | null
          zipCode: string
          country?: string | null
        } | null
        sensorInfo?: {
          __typename?: 'UserSensorInfo'
          totalCount: number
          replacementRequestsCount: number
        } | null
        settings: {
          __typename?: 'UserSettings'
          id: string
          appVersionReminder: boolean
          dashboardGraph: string
          dexcomSync: boolean
          displayActivityScore: boolean
          displayDailyScore: boolean
          displayMealScore: boolean
          fastingEnd: number
          fastingStart: number
          glucoseHighThreshold: number
          glucoseLowThreshold: number
          googleFitSync: boolean
          healthKitSync: boolean
          ketoMojoSync: boolean
          ketoneHighThreshold: number
          ketoneLowThreshold: number
          reminders: boolean
          scanReminder: number
          showNetCarbs: boolean
          unitSystem: string
          integrationsSyncSettings: {
            __typename?: 'IntegrationsSync'
            lastSyncedHealth?: any | null
            lastSyncedLibreView?: any | null
            lastSyncedDexcom?: any | null
            lastSyncedKetoMojo?: any | null
          }
          macroGoalsDaily: {
            __typename?: 'MacroGoals'
            calories: number
            carbs: number
            fat: number
            netCarbs?: number | null
            protein: number
          }
        }
        sensor?: {
          __typename?: 'Sensor'
          activationDate: any
          baselineAdjustment: number
          daysRemaining: number
          expirationDate: any
          expirationTime: any
          expired: boolean
          model: string
          serialNumber: string
          status?: string | null
        } | null
        organization: { __typename?: 'Organization'; id: number; name: string }
        ongoingDietitianSubscription?: {
          __typename?: 'Subscription'
          id: string
          dietitianAddOn: boolean
          stripeStatus: string
          status: SubscriptionStatus
          paidMonthsCount: number
          cancelAt?: any | null
          startAt: any
          paused: boolean
          resumesAt?: any | null
          commitmentEndAt?: any | null
          primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
        } | null
        ongoingCoreSubscription?: {
          __typename?: 'Subscription'
          id: string
          paidMonthsCount: number
          paused: boolean
          cancelAt?: any | null
          commitmentEndAt?: any | null
          plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
          primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
        } | null
        lastDietitianSubscription?: {
          __typename?: 'Subscription'
          stripeStatus: string
          status: SubscriptionStatus
          paused: boolean
          resumesAt?: any | null
        } | null
        lastCoreSubscription?: {
          __typename?: 'Subscription'
          id: string
          stripeStatus: string
          status: SubscriptionStatus
          latestInvoice?: {
            __typename?: 'Invoice'
            latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
          } | null
        } | null
        lastAsyncChatAssignment?: {
          __typename?: 'CoachAssignment'
          id: string
          state: CoachAssignmentState
          last: boolean
          createdAt: any
          assignedAt?: any | null
          deactivatedAt?: any | null
          kind?: CoachAssignmentKind | null
          supportType: CoachAssignmentSupportType
          coach?: { __typename?: 'User'; id: string; fullName: string } | null
          appointment?: { __typename?: 'Appointment'; date?: string | null } | null
        } | null
        lastVideoChatAssignment?: {
          __typename?: 'CoachAssignment'
          id: string
          state: CoachAssignmentState
          last: boolean
          createdAt: any
          assignedAt?: any | null
          deactivatedAt?: any | null
          kind?: CoachAssignmentKind | null
          supportType: CoachAssignmentSupportType
          coach?: { __typename?: 'User'; id: string; fullName: string } | null
          appointment?: { __typename?: 'Appointment'; date?: string | null } | null
        } | null
        activeRefillConsent?: {
          __typename?: 'UserRefillConsent'
          id: string
          givenAt: any
          invalidatedAt?: any | null
        } | null
        fulfillmentConfiguration: {
          __typename?: 'FulfillmentConfiguration'
          sensorKind: UserSensorKind
        }
        lastDexcomResearchState?: {
          __typename?: 'ResearchState'
          status: DexcomResearchStatus
        } | null
        coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
        primaryGoal?: {
          __typename?: 'Goal'
          title: string
          kind: UserGoalKind
          description?: string | null
        } | null
        goalMetrics: Array<{
          __typename?: 'GoalMetric'
          primary: boolean
          kind: UserGoalMetricKind
          lowThresholdValue?: number | null
          highThresholdValue?: number | null
          section: string
          statKey: string
        }>
        coachProfile?: {
          __typename?: 'CoachProfile'
          stateLicences: Array<string>
          outOfOffice: boolean
        } | null
        referralPartner: {
          __typename?: 'UserReferralPartnerDef'
          key: UserReferralPartner
          logoUrl?: string | null
        }
      } | null
      members: Array<{
        __typename?: 'DppUser'
        id: string
        fullName: string
        email: string
        preferredCallDays: Array<string>
        preferredCallTimes: Array<string>
        lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
      }>
      sessions: Array<{
        __typename?: 'CohortSession'
        id: string
        kind: CoursesCohortSessionKind
        name: string
        position: number
        scheduledAt: any
        scheduledEndAt: any
        cancelledAt?: any | null
        joinUrl?: string | null
      }>
    }>
  }
}

export type IAllDppInsuranceParticipantsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IDppInsuranceParticipantSort> | IDppInsuranceParticipantSort>
  dynamicFilters?: InputMaybe<
    Array<IDppInsuranceParticipantDynamicFilterItem> | IDppInsuranceParticipantDynamicFilterItem
  >
}>

export type IAllDppInsuranceParticipantsQuery = {
  __typename?: 'QueryType'
  allDppInsuranceParticipants: {
    __typename?: 'DppInsuranceParticipantCollection'
    totalCount: number
    participants: Array<{
      __typename?: 'DppInsuranceParticipant'
      id: string
      state: InsuranceHabitNuParticipantState
      action?: string | null
      partnerId: string
      orgCode: string
      userId: string
      prefixedCohortId?: string | null
      firstName: string
      middleName?: string | null
      lastName: string
      dateOfBirth: string
      addressStreet: string
      addressStreet2: string
      addressCity: string
      addressState: string
      addressZipCode: string
      preferredPhoneNumber?: string | null
      homeNumber?: string | null
      workPhone?: string | null
      cellPhone: string
      email: string
      notifications: string
      insuranceType: string
      insurancePlan?: string | null
      memberId: string
      groupNumber?: string | null
      enrollMot: string
      enrollHc: string
      payerSource: string
      aian: string
      asian: string
      black: string
      hispanic: string
      mena: string
      nhpi: string
      white: string
      ethRaceInfo?: string | null
      sex: string
      gender: string
      height: string
      initialWeight: string
      edu: string
      diffHear: string
      diffSee: string
      diffMem: string
      diffWalk: string
      diffDress: string
      diffErrand: string
      a1c?: string | null
      glucTest: string
      gdm: string
      riskTest: string
    }>
  }
}

export type IAllDppInsuranceSessionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IDppInsuranceSessionSort> | IDppInsuranceSessionSort>
  dynamicFilters?: InputMaybe<
    Array<IDppInsuranceSessionDynamicFilterItem> | IDppInsuranceSessionDynamicFilterItem
  >
}>

export type IAllDppInsuranceSessionsQuery = {
  __typename?: 'QueryType'
  allDppInsuranceSessions: {
    __typename?: 'DppInsuranceSessionCollection'
    totalCount: number
    sessions: Array<{
      __typename?: 'DppInsuranceSession'
      id: string
      state: InsuranceHabitNuSessionState
      email?: string | null
      name: string
      deliveryMode: string
      sessionType: string
      date: string
      weight: string
      physicalActivity: string
      instructorEmail: string
      payerSource: InsuranceHabitNuParticipantPayerSource
    }>
  }
}

export type IAllDppUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IDppUserSort> | IDppUserSort>
  dynamicFilters?: InputMaybe<Array<IDppUserDynamicFilterItem> | IDppUserDynamicFilterItem>
}>

export type IAllDppUsersQuery = {
  __typename?: 'QueryType'
  allDppUsers: {
    __typename?: 'DppUserCollection'
    totalCount: number
    users: Array<{
      __typename?: 'DppUser'
      id: string
      fullName: string
      email: string
      preferredCallDays: Array<string>
      preferredCallTimes: Array<string>
      lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
    }>
  }
}

export type ICohortSuggestedSessionsQueryVariables = Exact<{
  kind: CoursesCohortKind
  startTime: Scalars['ISO8601DateTime']
}>

export type ICohortSuggestedSessionsQuery = {
  __typename?: 'QueryType'
  cohortSuggestedSessions: {
    __typename?: 'CohortSessionSuggestionCollection'
    suggestions: Array<{
      __typename?: 'CohortSessionSuggestion'
      name: string
      scheduledAt: any
      kind: CoursesCohortSessionKind
    }>
  }
}

export type ICourseCohortQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ICourseCohortQuery = {
  __typename?: 'QueryType'
  courseCohort: {
    __typename?: 'Cohort'
    id: string
    name: string
    kind: CoursesCohortKind
    state: CoursesCohortState
    instructor?: {
      __typename?: 'User'
      createdAt: any
      email: string
      timeZone: string
      avatar?: string | null
      firstName: string
      fullName: string
      id: string
      lastName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      lastAppVersion?: string | null
      lastAppPlatform?: string | null
      stripeId?: string | null
      healthieId?: number | null
      stripeCreditBalance: number
      kind: UserKind
      age?: string | null
      isCoach: boolean
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        state: UserApprovalRequestState
        createdAt: any
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      sensorInfo?: {
        __typename?: 'UserSensorInfo'
        totalCount: number
        replacementRequestsCount: number
      } | null
      settings: {
        __typename?: 'UserSettings'
        id: string
        appVersionReminder: boolean
        dashboardGraph: string
        dexcomSync: boolean
        displayActivityScore: boolean
        displayDailyScore: boolean
        displayMealScore: boolean
        fastingEnd: number
        fastingStart: number
        glucoseHighThreshold: number
        glucoseLowThreshold: number
        googleFitSync: boolean
        healthKitSync: boolean
        ketoMojoSync: boolean
        ketoneHighThreshold: number
        ketoneLowThreshold: number
        reminders: boolean
        scanReminder: number
        showNetCarbs: boolean
        unitSystem: string
        integrationsSyncSettings: {
          __typename?: 'IntegrationsSync'
          lastSyncedHealth?: any | null
          lastSyncedLibreView?: any | null
          lastSyncedDexcom?: any | null
          lastSyncedKetoMojo?: any | null
        }
        macroGoalsDaily: {
          __typename?: 'MacroGoals'
          calories: number
          carbs: number
          fat: number
          netCarbs?: number | null
          protein: number
        }
      }
      sensor?: {
        __typename?: 'Sensor'
        activationDate: any
        baselineAdjustment: number
        daysRemaining: number
        expirationDate: any
        expirationTime: any
        expired: boolean
        model: string
        serialNumber: string
        status?: string | null
      } | null
      organization: { __typename?: 'Organization'; id: number; name: string }
      ongoingDietitianSubscription?: {
        __typename?: 'Subscription'
        id: string
        dietitianAddOn: boolean
        stripeStatus: string
        status: SubscriptionStatus
        paidMonthsCount: number
        cancelAt?: any | null
        startAt: any
        paused: boolean
        resumesAt?: any | null
        commitmentEndAt?: any | null
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        paidMonthsCount: number
        paused: boolean
        cancelAt?: any | null
        commitmentEndAt?: any | null
        plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
        primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
      } | null
      lastDietitianSubscription?: {
        __typename?: 'Subscription'
        stripeStatus: string
        status: SubscriptionStatus
        paused: boolean
        resumesAt?: any | null
      } | null
      lastCoreSubscription?: {
        __typename?: 'Subscription'
        id: string
        stripeStatus: string
        status: SubscriptionStatus
        latestInvoice?: {
          __typename?: 'Invoice'
          latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
        } | null
      } | null
      lastAsyncChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        state: CoachAssignmentState
        last: boolean
        createdAt: any
        assignedAt?: any | null
        deactivatedAt?: any | null
        kind?: CoachAssignmentKind | null
        supportType: CoachAssignmentSupportType
        coach?: { __typename?: 'User'; id: string; fullName: string } | null
        appointment?: { __typename?: 'Appointment'; date?: string | null } | null
      } | null
      activeRefillConsent?: {
        __typename?: 'UserRefillConsent'
        id: string
        givenAt: any
        invalidatedAt?: any | null
      } | null
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      lastDexcomResearchState?: {
        __typename?: 'ResearchState'
        status: DexcomResearchStatus
      } | null
      coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
      primaryGoal?: {
        __typename?: 'Goal'
        title: string
        kind: UserGoalKind
        description?: string | null
      } | null
      goalMetrics: Array<{
        __typename?: 'GoalMetric'
        primary: boolean
        kind: UserGoalMetricKind
        lowThresholdValue?: number | null
        highThresholdValue?: number | null
        section: string
        statKey: string
      }>
      coachProfile?: {
        __typename?: 'CoachProfile'
        stateLicences: Array<string>
        outOfOffice: boolean
      } | null
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        logoUrl?: string | null
      }
    } | null
    members: Array<{
      __typename?: 'DppUser'
      id: string
      fullName: string
      email: string
      preferredCallDays: Array<string>
      preferredCallTimes: Array<string>
      lastDppApprovalRequest: { __typename?: 'ApprovalRequest'; createdAt: any }
    }>
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      kind: CoursesCohortSessionKind
      name: string
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
    }>
  }
}

export type ICourseCohortInstructorsQueryVariables = Exact<{
  sorts?: InputMaybe<Array<IUserSort> | IUserSort>
  dynamicFilters?: InputMaybe<Array<IUserDynamicFilterItem> | IUserDynamicFilterItem>
}>

export type ICourseCohortInstructorsQuery = {
  __typename?: 'QueryType'
  allUsers: {
    __typename?: 'PrivateUserCollection'
    users: Array<{ __typename?: 'User'; id: string; fullName: string }>
  }
}

export type ICourseCohortSessionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ICourseCohortSessionQuery = {
  __typename?: 'QueryType'
  courseCohortSession: {
    __typename?: 'CohortSession'
    id: string
    kind: CoursesCohortSessionKind
    name: string
    position: number
    scheduledAt: any
    scheduledEndAt: any
    cancelledAt?: any | null
    joinUrl?: string | null
  }
}

export type ICourseCohortsAutocompleteQueryVariables = Exact<{
  dynamicFilters?: InputMaybe<Array<ICohortDynamicFilterItem> | ICohortDynamicFilterItem>
}>

export type ICourseCohortsAutocompleteQuery = {
  __typename?: 'QueryType'
  allCourseCohorts: {
    __typename?: 'CohortCollection'
    totalCount: number
    cohorts: Array<{
      __typename?: 'Cohort'
      id: string
      name: string
      state: CoursesCohortState
      members: Array<{ __typename?: 'DppUser'; id: string }>
    }>
  }
}

export type ICurrentAdminQueryVariables = Exact<{ [key: string]: never }>

export type ICurrentAdminQuery = {
  __typename?: 'QueryType'
  currentAdmin?: {
    __typename?: 'User'
    id: string
    firstName: string
    lastName: string
    fullName: string
    email: string
    nutrisenseAdmin: boolean
    avatar?: string | null
    isSupport: boolean
    isCoach: boolean
    role: UserRole
  } | null
}

export type ICurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type ICurrentUserQuery = {
  __typename?: 'QueryType'
  currentUser?: {
    __typename?: 'User'
    createdAt: any
    email: string
    timeZone: string
    avatar?: string | null
    firstName: string
    fullName: string
    id: string
    lastName: string
    role: UserRole
    sex?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    lastAppVersion?: string | null
    lastAppPlatform?: string | null
    stripeId?: string | null
    healthieId?: number | null
    stripeCreditBalance: number
    kind: UserKind
    age?: string | null
    isCoach: boolean
    lastPrescriptionApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      state: UserApprovalRequestState
      createdAt: any
    } | null
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
    sensorInfo?: {
      __typename?: 'UserSensorInfo'
      totalCount: number
      replacementRequestsCount: number
    } | null
    settings: {
      __typename?: 'UserSettings'
      id: string
      appVersionReminder: boolean
      dashboardGraph: string
      dexcomSync: boolean
      displayActivityScore: boolean
      displayDailyScore: boolean
      displayMealScore: boolean
      fastingEnd: number
      fastingStart: number
      glucoseHighThreshold: number
      glucoseLowThreshold: number
      googleFitSync: boolean
      healthKitSync: boolean
      ketoMojoSync: boolean
      ketoneHighThreshold: number
      ketoneLowThreshold: number
      reminders: boolean
      scanReminder: number
      showNetCarbs: boolean
      unitSystem: string
      integrationsSyncSettings: {
        __typename?: 'IntegrationsSync'
        lastSyncedHealth?: any | null
        lastSyncedLibreView?: any | null
        lastSyncedDexcom?: any | null
        lastSyncedKetoMojo?: any | null
      }
      macroGoalsDaily: {
        __typename?: 'MacroGoals'
        calories: number
        carbs: number
        fat: number
        netCarbs?: number | null
        protein: number
      }
    }
    sensor?: {
      __typename?: 'Sensor'
      activationDate: any
      baselineAdjustment: number
      daysRemaining: number
      expirationDate: any
      expirationTime: any
      expired: boolean
      model: string
      serialNumber: string
      status?: string | null
    } | null
    organization: { __typename?: 'Organization'; id: number; name: string }
    ongoingDietitianSubscription?: {
      __typename?: 'Subscription'
      id: string
      dietitianAddOn: boolean
      stripeStatus: string
      status: SubscriptionStatus
      paidMonthsCount: number
      cancelAt?: any | null
      startAt: any
      paused: boolean
      resumesAt?: any | null
      commitmentEndAt?: any | null
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    ongoingCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      paidMonthsCount: number
      paused: boolean
      cancelAt?: any | null
      commitmentEndAt?: any | null
      plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    lastDietitianSubscription?: {
      __typename?: 'Subscription'
      stripeStatus: string
      status: SubscriptionStatus
      paused: boolean
      resumesAt?: any | null
    } | null
    lastCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      stripeStatus: string
      status: SubscriptionStatus
      latestInvoice?: {
        __typename?: 'Invoice'
        latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
      } | null
    } | null
    lastAsyncChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    lastVideoChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    activeRefillConsent?: {
      __typename?: 'UserRefillConsent'
      id: string
      givenAt: any
      invalidatedAt?: any | null
    } | null
    fulfillmentConfiguration: {
      __typename?: 'FulfillmentConfiguration'
      sensorKind: UserSensorKind
    }
    lastDexcomResearchState?: { __typename?: 'ResearchState'; status: DexcomResearchStatus } | null
    coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
    primaryGoal?: {
      __typename?: 'Goal'
      title: string
      kind: UserGoalKind
      description?: string | null
    } | null
    goalMetrics: Array<{
      __typename?: 'GoalMetric'
      primary: boolean
      kind: UserGoalMetricKind
      lowThresholdValue?: number | null
      highThresholdValue?: number | null
      section: string
      statKey: string
    }>
    coachProfile?: {
      __typename?: 'CoachProfile'
      stateLicences: Array<string>
      outOfOffice: boolean
    } | null
    referralPartner: {
      __typename?: 'UserReferralPartnerDef'
      key: UserReferralPartner
      logoUrl?: string | null
    }
  } | null
}

export type IListUserDailyActionsQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  endDate: Scalars['ISO8601Date']
  status?: InputMaybe<UserDailyActionStatus>
}>

export type IListUserDailyActionsQuery = {
  __typename?: 'QueryType'
  listUserDailyActions: {
    __typename?: 'DailyActionCollection'
    dailyActions: Array<{
      __typename?: 'DailyAction'
      id: string
      title: string
      description: string
      status: UserDailyActionStatus
      kind: UserDailyActionKind
      icon: UiIcon
      statKey?: string | null
      autocomplete: boolean
      category: UserDailyActionCategory
      createdAt: any
      updatedAt: any
    }>
    completionCounts: Array<{
      __typename?: 'DailyActionCompletionsCount'
      dailyActionId: number
      completionCount: number
    }>
  }
}

export type IDistinctUserPropertyValuesQueryVariables = Exact<{
  value: Scalars['String']
  kind: Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
}>

export type IDistinctUserPropertyValuesQuery = {
  __typename?: 'QueryType'
  distinctUserPropertyValues: Array<string>
}

export type IEligibleProductsQueryVariables = Exact<{
  userId: Scalars['ID']
  products: Array<BillingProduct> | BillingProduct
}>

export type IEligibleProductsQuery = {
  __typename?: 'QueryType'
  eligibleProducts: {
    __typename?: 'ProductCollection'
    products: Array<{
      __typename?: 'Product'
      id: string
      key: string
      title: string
      price: number
    }>
  }
}

export type IEligibleUpdateProductsQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type IEligibleUpdateProductsQuery = {
  __typename?: 'QueryType'
  eligibleUpdateProducts: {
    __typename?: 'EligibleUpdateProducts'
    eligibleUpdateProducts: Array<{
      __typename?: 'EligibleUpdateProduct'
      product: { __typename?: 'ProductMin'; key: string; title: string }
      feeRules?: Array<{ __typename?: 'FeeRule'; fee: number; paidCyclesCount: number }> | null
    }>
  }
}

export type IFinancialsAllChargesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IChargeSort> | IChargeSort>
  dynamicFilters?: InputMaybe<Array<IChargeDynamicFilterItem> | IChargeDynamicFilterItem>
}>

export type IFinancialsAllChargesQuery = {
  __typename?: 'QueryType'
  allCharges: {
    __typename?: 'ChargeCollection'
    totalCount: number
    pageCount?: number | null
    charges: Array<{
      __typename?: 'Charge'
      id: string
      stripeId: string
      amount: number
      amountRefunded: number
      paid: boolean
      status: string
      disputeStripeId?: string | null
      failureMessage?: string | null
      disputed: boolean
      refunded: boolean
      created: any
      refundable: boolean
      invoice?: {
        __typename?: 'Invoice'
        stripeId: string
        subscription?: { __typename?: 'Subscription'; stripeId: string } | null
      } | null
      user: {
        __typename?: 'User'
        id: string
        fullName: string
        email: string
        phoneNumber?: string | null
      }
    }>
  }
}

export type IFinancialsAllInvoicesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IInvoiceSort> | IInvoiceSort>
  dynamicFilters?: InputMaybe<Array<IInvoiceDynamicFilterItem> | IInvoiceDynamicFilterItem>
}>

export type IFinancialsAllInvoicesQuery = {
  __typename?: 'QueryType'
  allInvoices: {
    __typename?: 'InvoiceCollection'
    totalCount: number
    pageCount?: number | null
    invoices: Array<{
      __typename?: 'Invoice'
      id: string
      stripeId: string
      paidAt?: any | null
      receiptNumber?: string | null
      downloadUrl?: string | null
      created: any
      total?: number | null
      subscription?: { __typename?: 'Subscription'; stripeId: string } | null
      user: {
        __typename?: 'User'
        id: string
        fullName: string
        email: string
        stripeId?: string | null
      }
    }>
  }
}

export type IFinancialsAllSubscriptionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ISubscriptionSort> | ISubscriptionSort>
  dynamicFilters?: InputMaybe<
    Array<ISubscriptionDynamicFilterItem> | ISubscriptionDynamicFilterItem
  >
}>

export type IFinancialsAllSubscriptionsQuery = {
  __typename?: 'QueryType'
  allSubscriptions: {
    __typename?: 'SubscriptionCollection'
    totalCount: number
    pageCount?: number | null
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      stripeId: string
      stripeStatus: string
      paidMonthsCount: number
      pausedMonthsCount: number
      cancelAt?: any | null
      canceledAt?: any | null
      createdAt: any
      currentPeriodEndAt?: any | null
      endAt?: any | null
      resumesAt?: any | null
      startAt: any
      subscribedAt: any
      trialEndAt?: any | null
      updatedAt: any
      paused: boolean
      plans: Array<{ __typename?: 'Plan'; nickname: string }>
      user: { __typename?: 'User'; id: string; fullName: string; email: string }
    }>
  }
}

export type ICreateHealthMetricMutationVariables = Exact<{
  date: Scalars['ISO8601Date']
  value: Scalars['Float']
  kind: HealthDataMetricBiomarker
}>

export type ICreateHealthMetricMutation = {
  __typename?: 'MutationType'
  createHealthMetric: {
    __typename?: 'HealthMetric'
    id: string
    date: any
    value: number
    kind: HealthDataMetricBiomarker
  }
}

export type IDeleteHealthMetricMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type IDeleteHealthMetricMutation = {
  __typename?: 'MutationType'
  deleteHealthMetric: { __typename?: 'Success'; success: boolean }
}

export type IHealthMetricsQueryVariables = Exact<{ [key: string]: never }>

export type IHealthMetricsQuery = {
  __typename?: 'QueryType'
  healthMetrics: {
    __typename?: 'HealthMetrics'
    healthMetrics: Array<{
      __typename?: 'HealthMetricValues'
      kind: HealthDataMetricBiomarker
      title: string
      unit?: string | null
      data: Array<{ __typename?: 'HealthMetricValue'; id: string; value: number; date: any }>
    }>
  }
}

export type IUpdateHealthMetricMutationVariables = Exact<{
  id: Scalars['ID']
  date: Scalars['ISO8601Date']
  value: Scalars['Float']
}>

export type IUpdateHealthMetricMutation = {
  __typename?: 'MutationType'
  updateHealthMetric: {
    __typename?: 'HealthMetric'
    id: string
    date: any
    value: number
    kind: HealthDataMetricBiomarker
  }
}

export type IHealthQuestionnaireApprovalsAllUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IUserSort> | IUserSort>
  dynamicFilters?: InputMaybe<Array<IUserDynamicFilterItem> | IUserDynamicFilterItem>
  forHqApproval?: InputMaybe<Scalars['Boolean']>
}>

export type IHealthQuestionnaireApprovalsAllUsersQuery = {
  __typename?: 'QueryType'
  allUsers: {
    __typename?: 'PrivateUserCollection'
    totalCount: number
    users: Array<{
      __typename?: 'User'
      id: string
      fullName: string
      email: string
      dateOfBirth?: string | null
      age?: string | null
      phoneNumber?: string | null
      dexcomSensorPromptUiState?: boolean | null
      governmentIdCard?: string | null
      healthReject?: string | null
      lastAnsweredHealthSurveyQuestionKey?: SurveysQuestionKey | null
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        state: UserApprovalRequestState
        createdAt: any
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
      } | null
      fulfillmentConfiguration: {
        __typename?: 'FulfillmentConfiguration'
        sensorKind: UserSensorKind
      }
      lastCoreSubscription?: {
        __typename?: 'Subscription'
        primaryProduct: { __typename?: 'ProductMin'; title: string }
        latestInvoice?: {
          __typename?: 'Invoice'
          latestCharge?: { __typename?: 'Charge'; created: any } | null
        } | null
      } | null
    }>
  }
}

export type IAllNutritionistsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IUserSort> | IUserSort>
  dynamicFilters?: InputMaybe<Array<IUserDynamicFilterItem> | IUserDynamicFilterItem>
}>

export type IAllNutritionistsQuery = {
  __typename?: 'QueryType'
  allUsers: {
    __typename?: 'PrivateUserCollection'
    totalCount: number
    users: Array<{
      __typename?: 'User'
      id: string
      fullName: string
      role: UserRole
      email: string
      coachProfile?: {
        __typename?: 'CoachProfile'
        bio?: string | null
        getHealthieId?: string | null
        startDate?: any | null
        endDate?: any | null
        schedulingPriority?: number | null
        stateLicences: Array<string>
        active: boolean
        outOfOffice: boolean
        updatedAt: any
        priorityChangedAt?: any | null
        credentials?: string | null
        manager?: { __typename?: 'User'; id: string; fullName: string } | null
      } | null
    }>
  }
}

export type IOrderQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type IOrderQuery = {
  __typename?: 'QueryType'
  order: {
    __typename?: 'Order'
    id: number
    quantity: number
    createdAt: any
    invoice?: { __typename?: 'Invoice'; stripeId: string } | null
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
  }
}

export type IPreviewCoachConversationMessageQueryVariables = Exact<{
  userId: Scalars['ID']
  messageTemplateId: Scalars['ID']
}>

export type IPreviewCoachConversationMessageQuery = {
  __typename?: 'QueryType'
  previewCoachConversationMessage: { __typename?: 'CoachConversationMessagePreview'; value: string }
}

export type IResetPasswordRequestQueryVariables = Exact<{
  email: Scalars['String']
}>

export type IResetPasswordRequestQuery = {
  __typename?: 'QueryType'
  resetPasswordRequest: { __typename?: 'Success'; success: boolean }
}

export type ISupportAllUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<IUserSort> | IUserSort>
  dynamicFilters?: InputMaybe<Array<IUserDynamicFilterItem> | IUserDynamicFilterItem>
}>

export type ISupportAllUsersQuery = {
  __typename?: 'QueryType'
  allUsers: {
    __typename?: 'PrivateUserCollection'
    totalCount: number
    users: Array<{ __typename?: 'User'; id: string; fullName: string; email: string }>
  }
}

export type IAllTicketCommentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  ticketId: Scalars['ID']
  sorts?: InputMaybe<Array<ITicketCommentSort> | ITicketCommentSort>
  dynamicFilters?: InputMaybe<
    Array<ITicketCommentDynamicFilterItem> | ITicketCommentDynamicFilterItem
  >
}>

export type IAllTicketCommentsQuery = {
  __typename?: 'QueryType'
  allTicketComments: {
    __typename?: 'TicketCommentCollection'
    totalCount: number
    pageCount?: number | null
    ticketComments: Array<{
      __typename?: 'TicketComment'
      ticketId: string
      id: string
      body: string
      createdAt: any
      createdBy: {
        __typename?: 'UserMin'
        id: string
        fullName: string
        email: string
        phoneNumber: string
        timeZone: string
      }
    }>
  }
}

export type IAllTicketsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<ITicketSort> | ITicketSort>
  dynamicFilters?: InputMaybe<Array<ITicketDynamicFilterItem> | ITicketDynamicFilterItem>
}>

export type IAllTicketsQuery = {
  __typename?: 'QueryType'
  allTickets: {
    __typename?: 'TicketCollection'
    totalCount: number
    pageCount?: number | null
    tickets: Array<{
      __typename?: 'Ticket'
      id: string
      description: string
      externalLink?: string | null
      kind: TicketKind
      reason?: TicketReason | null
      status: TicketStatus
      priority: TicketPriority
      createdAt: any
      createdBy: { __typename?: 'User'; id: string; email: string; fullName: string }
      user: { __typename?: 'User'; id: string; email: string; fullName: string }
      assignedTo?: { __typename?: 'User'; id: string; email: string; fullName: string } | null
    }>
  }
}

export type IUserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type IUserQuery = {
  __typename?: 'QueryType'
  user: {
    __typename?: 'User'
    createdAt: any
    email: string
    timeZone: string
    avatar?: string | null
    firstName: string
    fullName: string
    id: string
    lastName: string
    role: UserRole
    sex?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    lastAppVersion?: string | null
    lastAppPlatform?: string | null
    stripeId?: string | null
    healthieId?: number | null
    stripeCreditBalance: number
    kind: UserKind
    age?: string | null
    isCoach: boolean
    address?: {
      __typename?: 'Address'
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      id: string
      country?: string | null
    } | null
    lastPrescriptionApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      state: UserApprovalRequestState
      createdAt: any
    } | null
    sensorInfo?: {
      __typename?: 'UserSensorInfo'
      totalCount: number
      replacementRequestsCount: number
    } | null
    settings: {
      __typename?: 'UserSettings'
      id: string
      appVersionReminder: boolean
      dashboardGraph: string
      dexcomSync: boolean
      displayActivityScore: boolean
      displayDailyScore: boolean
      displayMealScore: boolean
      fastingEnd: number
      fastingStart: number
      glucoseHighThreshold: number
      glucoseLowThreshold: number
      googleFitSync: boolean
      healthKitSync: boolean
      ketoMojoSync: boolean
      ketoneHighThreshold: number
      ketoneLowThreshold: number
      reminders: boolean
      scanReminder: number
      showNetCarbs: boolean
      unitSystem: string
      integrationsSyncSettings: {
        __typename?: 'IntegrationsSync'
        lastSyncedHealth?: any | null
        lastSyncedLibreView?: any | null
        lastSyncedDexcom?: any | null
        lastSyncedKetoMojo?: any | null
      }
      macroGoalsDaily: {
        __typename?: 'MacroGoals'
        calories: number
        carbs: number
        fat: number
        netCarbs?: number | null
        protein: number
      }
    }
    sensor?: {
      __typename?: 'Sensor'
      activationDate: any
      baselineAdjustment: number
      daysRemaining: number
      expirationDate: any
      expirationTime: any
      expired: boolean
      model: string
      serialNumber: string
      status?: string | null
    } | null
    organization: { __typename?: 'Organization'; id: number; name: string }
    ongoingDietitianSubscription?: {
      __typename?: 'Subscription'
      id: string
      dietitianAddOn: boolean
      stripeStatus: string
      status: SubscriptionStatus
      paidMonthsCount: number
      cancelAt?: any | null
      startAt: any
      paused: boolean
      resumesAt?: any | null
      commitmentEndAt?: any | null
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    ongoingCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      paidMonthsCount: number
      paused: boolean
      cancelAt?: any | null
      commitmentEndAt?: any | null
      plans: Array<{ __typename?: 'Plan'; id: string; nickname: string }>
      primaryProduct: { __typename?: 'ProductMin'; key: string; title: string }
    } | null
    lastDietitianSubscription?: {
      __typename?: 'Subscription'
      stripeStatus: string
      status: SubscriptionStatus
      paused: boolean
      resumesAt?: any | null
    } | null
    lastCoreSubscription?: {
      __typename?: 'Subscription'
      id: string
      stripeStatus: string
      status: SubscriptionStatus
      latestInvoice?: {
        __typename?: 'Invoice'
        latestCharge?: { __typename?: 'Charge'; status: string; created: any } | null
      } | null
    } | null
    lastAsyncChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    lastVideoChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      state: CoachAssignmentState
      last: boolean
      createdAt: any
      assignedAt?: any | null
      deactivatedAt?: any | null
      kind?: CoachAssignmentKind | null
      supportType: CoachAssignmentSupportType
      coach?: { __typename?: 'User'; id: string; fullName: string } | null
      appointment?: { __typename?: 'Appointment'; date?: string | null } | null
    } | null
    activeRefillConsent?: {
      __typename?: 'UserRefillConsent'
      id: string
      givenAt: any
      invalidatedAt?: any | null
    } | null
    fulfillmentConfiguration: {
      __typename?: 'FulfillmentConfiguration'
      sensorKind: UserSensorKind
    }
    lastDexcomResearchState?: { __typename?: 'ResearchState'; status: DexcomResearchStatus } | null
    coachChatConversation?: { __typename?: 'ChatConversation'; id: string } | null
    primaryGoal?: {
      __typename?: 'Goal'
      title: string
      kind: UserGoalKind
      description?: string | null
    } | null
    goalMetrics: Array<{
      __typename?: 'GoalMetric'
      primary: boolean
      kind: UserGoalMetricKind
      lowThresholdValue?: number | null
      highThresholdValue?: number | null
      section: string
      statKey: string
    }>
    coachProfile?: {
      __typename?: 'CoachProfile'
      stateLicences: Array<string>
      outOfOffice: boolean
    } | null
    referralPartner: {
      __typename?: 'UserReferralPartnerDef'
      key: UserReferralPartner
      logoUrl?: string | null
    }
  }
}

export type IUserInsurancePolicyQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type IUserInsurancePolicyQuery = {
  __typename?: 'QueryType'
  userInsurancePolicy: {
    __typename?: 'InsurancePolicy'
    id: string
    unitsLeft?: number | null
    unitsUsed?: number | null
    unitsOccurred?: number | null
    unitsAuthorized?: number | null
    lastState?: {
      __typename?: 'InsurancePolicyState'
      id: string
      kind: UserInsurancePolicyStateKind
      reason?: string | null
      createdAt: any
    } | null
    states?: Array<{
      __typename?: 'InsurancePolicyState'
      id: string
      kind: UserInsurancePolicyStateKind
      reason?: string | null
      createdAt: any
    }> | null
  }
}

export type IUserSurveyLinksQueryVariables = Exact<{ [key: string]: never }>

export type IUserSurveyLinksQuery = {
  __typename?: 'QueryType'
  userSurveyLinks: {
    __typename?: 'SurveyLinkCollection'
    surveyLinks: Array<{
      __typename?: 'SurveyLink'
      id: string
      state: SurveysUserLinkState
      finished: boolean
      responses: Array<{
        __typename?: 'SurveyResponse'
        id: string
        questionKey: SurveysQuestionKey
        answer: any
      }>
      survey: {
        __typename?: 'Survey'
        kind: SurveysConfigKind
        version: string
        header?: string | null
        completedScreen?: {
          __typename?: 'CompletedScreen'
          header: string
          intro: string
          icon: UiIcon
        } | null
        questionsGroups: Array<{
          __typename?: 'QuestionsGroup'
          introScreen?: {
            __typename?: 'IntroScreen'
            header: string
            intro?: string | null
            icon?: UiIcon | null
          } | null
          questions: Array<{
            __typename?: 'Question'
            key: SurveysQuestionKey
            label?: string | null
            note?: string | null
            required: boolean
            response: {
              __typename?: 'Response'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
              kindMetadata?: any | null
              answersGroups?: Array<{
                __typename?: 'AnswersGroup'
                answers: Array<{
                  __typename?: 'Answer'
                  label: string
                  value: string
                  followup?: {
                    __typename?: 'Followup'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                    question: {
                      __typename?: 'Question'
                      key: SurveysQuestionKey
                      label?: string | null
                      note?: string | null
                      required: boolean
                      response: {
                        __typename?: 'Response'
                        kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                        kindMetadata?: any | null
                        answersGroups?: Array<{
                          __typename?: 'AnswersGroup'
                          answers: Array<{ __typename?: 'Answer'; label: string; value: string }>
                        }> | null
                      }
                    }
                  } | null
                }>
              }> | null
            }
          }>
        }>
      }
    }>
  }
}

export type INewNoteUpdateFragment = { __typename?: 'Note'; id: string; body: string }

export const UpdateSubscriptionResponsePayloadFragmentDoc = gql`
  fragment UpdateSubscriptionResponsePayload on UpdateSubscriptionResponsePayload {
    oldProduct
    nextProduct
  }
`
export const CancelSubscriptionResponsePayloadFragmentDoc = gql`
  fragment CancelSubscriptionResponsePayload on CancelSubscriptionResponsePayload {
    subscriptionId
    immediateCancellation
    waiveEarlyCancellationFee
    waiveEarlyCancellationSurcharge
    cancellationReason
  }
`
export const RefundResponsePayloadFragmentDoc = gql`
  fragment RefundResponsePayload on RefundResponsePayload {
    chargeId
    amountInCents
    refundReason {
      journey
      reasonOne
      reasonTwo
    }
    subscriptionCancellationRequired
    subscriptionCancellationReason
  }
`
export const PauseSubscriptionResponsePayloadFragmentDoc = gql`
  fragment PauseSubscriptionResponsePayload on PauseSubscriptionResponsePayload {
    subscriptionId
    cycleCount
    reason
  }
`
export const CreditResponsePayloadFragmentDoc = gql`
  fragment CreditResponsePayload on CreditResponsePayload {
    centsAmount
  }
`
export const OneOffAddonResponsePayloadFragmentDoc = gql`
  fragment OneOffAddonResponsePayload on OneOffAddonResponsePayload {
    product
    paid
    discountCentsAmount
  }
`
export const ChangeSubscriptionBillingCycleResponsePayloadFragmentDoc = gql`
  fragment ChangeSubscriptionBillingCycleResponsePayload on ChangeSubscriptionBillingCycleResponsePayload {
    subscriptionId
    billingCycleAnchor
  }
`
export const MiscRequestResponsePayloadFragmentDoc = gql`
  fragment MiscRequestResponsePayload on MiscRequestResponsePayload {
    requestDetails
  }
`
export const DeleteDuplicateAccountResponsePayloadFragmentDoc = gql`
  fragment DeleteDuplicateAccountResponsePayload on DeleteDuplicateAccountResponsePayload {
    duplicateUserId
    creditAmountInCents
    replaceWithDuplicateUserEmail
  }
`
export const UnpauseSubscriptionResponsePayloadFragmentDoc = gql`
  fragment UnpauseSubscriptionResponsePayload on UnpauseSubscriptionResponsePayload {
    subscriptionId
    resumeDate
  }
`
export const UncancelSubscriptionResponsePayloadFragmentDoc = gql`
  fragment UncancelSubscriptionResponsePayload on UncancelSubscriptionResponsePayload {
    subscriptionId
  }
`
export const UpsertLimitedSupportResponsePayloadFragmentDoc = gql`
  fragment UpsertLimitedSupportResponsePayload on UpsertLimitedSupportResponsePayload {
    limitedSupportSubscriptionId
    limitedSupportCancelAt
  }
`
export const CoreAccountRequestFieldsFragmentDoc = gql`
  fragment CoreAccountRequestFields on AccountRequest {
    id
    user {
      id
      fullName
      email
    }
    escalatedBy {
      fullName
    }
    processedBy {
      fullName
    }
    reviewedBy {
      fullName
    }
    rejectedBy {
      fullName
    }
    escalatedAt
    processedAt
    reviewedAt
    rejectedAt
    failedAt
    priority
    kind
    errorMessage
    payload {
      ...UpdateSubscriptionResponsePayload
      ...CancelSubscriptionResponsePayload
      ...RefundResponsePayload
      ...PauseSubscriptionResponsePayload
      ...CreditResponsePayload
      ...OneOffAddonResponsePayload
      ...ChangeSubscriptionBillingCycleResponsePayload
      ...MiscRequestResponsePayload
      ...DeleteDuplicateAccountResponsePayload
      ...UnpauseSubscriptionResponsePayload
      ...UncancelSubscriptionResponsePayload
      ...UpsertLimitedSupportResponsePayload
    }
    notes
    status
  }
  ${UpdateSubscriptionResponsePayloadFragmentDoc}
  ${CancelSubscriptionResponsePayloadFragmentDoc}
  ${RefundResponsePayloadFragmentDoc}
  ${PauseSubscriptionResponsePayloadFragmentDoc}
  ${CreditResponsePayloadFragmentDoc}
  ${OneOffAddonResponsePayloadFragmentDoc}
  ${ChangeSubscriptionBillingCycleResponsePayloadFragmentDoc}
  ${MiscRequestResponsePayloadFragmentDoc}
  ${DeleteDuplicateAccountResponsePayloadFragmentDoc}
  ${UnpauseSubscriptionResponsePayloadFragmentDoc}
  ${UncancelSubscriptionResponsePayloadFragmentDoc}
  ${UpsertLimitedSupportResponsePayloadFragmentDoc}
`
export const AllEventsActivityFragmentDoc = gql`
  fragment AllEventsActivity on Activity {
    __typename
    createdAt
    description
    endedAt
    favorite
    id
    length
    occurredAt
    startedAt
    statistics {
      aucScore
      glucose2hrDelta
      glucoseDelta
      glucoseMax
      glucoseMin
      glucoseScore
      glucoseScore2hrDelta
      glucoseScoreAuc
      glucoseScoreDelta
      glucoseScorePeak
    }
    status
    title
    type
  }
`
export const AllEventsMeasurementFragmentDoc = gql`
  fragment AllEventsMeasurement on Measurement {
    __typename
    createdAt
    cumulative
    description
    id
    occurredAt
    scan {
      createdAt
      id
    }
    timestamp
    title
    type
    units
    value
    values {
      ... on BloodPressureMeasurement {
        diastolic
        systolic
      }
      ... on SingleValueMeasurement {
        value
      }
    }
  }
`
export const AllEventsJournalEntryFragmentDoc = gql`
  fragment AllEventsJournalEntry on JournalEntry {
    id
    title
    body
    occurredAt
    createdAt
    creatorId
    score
    measurements {
      ...AllEventsMeasurement
      values {
        ... on SingleValueMeasurement {
          value
        }
      }
    }
  }
  ${AllEventsMeasurementFragmentDoc}
`
export const AllEventsMealFragmentDoc = gql`
  fragment AllEventsMeal on Meal {
    __typename
    avatar
    occurredAt
    createdAt
    description
    experimentId
    favorite
    source
    id
    mealCreatedBy: createdBy {
      id
    }
    statistics {
      aucScore
      glucose2hrDelta
      glucoseDelta
      glucoseMax
      glucoseMin
      glucoseScore
      glucoseScore2hrDelta
      glucoseScoreAuc
      glucoseScoreDelta
      glucoseScorePeak
    }
    ingredients {
      calories
      createdAt
      description
      id
      servingAmount
      servingUnits
    }
    glucose {
      range
      healthyRange
      values {
        timestamp
        value
      }
    }
    nutrition {
      key
      value
    }
    occurredAt
    title
    kind
    updatedAt
  }
`
export const AllEventsNoteFragmentDoc = gql`
  fragment AllEventsNote on Note {
    body
    __typename
    createdAt
    creatorId
    id
    occurredAt
    title
    createdBy
  }
`
export const AllMeasurementsMeasurementFragmentDoc = gql`
  fragment AllMeasurementsMeasurement on Measurement {
    id
    occurredAt
    timestamp
    type
    units
    value
  }
`
export const CoreAppointmentFieldsFragmentDoc = gql`
  fragment CoreAppointmentFields on HealthPortalAppointment {
    id
    meetingAt
    meetingStatus
    actualDuration
    title
    appointmentType
    chargeType
    externalVideochatUrl
    startVideochatUrl
    agenda
    user {
      id
      fullName
      timeZone
    }
    insurancePolicy {
      unitsLeft
      unitsUsed
      unitsAuthorized
      lastState {
        kind
        reason
      }
    }
  }
`
export const CoreChatConversationFieldsFragmentDoc = gql`
  fragment CoreChatConversationFields on ChatConversation {
    id
    kind
    state
    read
    candidateRead
    intercomCreatedAt
    intercomBackfillState
    lastMessage {
      id
      value
      sender {
        id
        fullName
      }
    }
    user {
      id
      fullName
    }
  }
`
export const CoreAddressFieldsFragmentDoc = gql`
  fragment CoreAddressFields on Address {
    id
    name
    street
    street2
    city
    state
    zipCode
    country
  }
`
export const CoreCoachAssignmentFieldsFragmentDoc = gql`
  fragment CoreCoachAssignmentFields on CoachAssignment {
    id
    coach {
      id
      fullName
    }
    appointment {
      date
    }
    state
    last
    createdAt
    assignedAt
    deactivatedAt
    kind
    supportType
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    createdAt
    email
    timeZone
    avatar
    firstName
    fullName
    id
    lastName
    role
    sex
    dateOfBirth
    phoneNumber
    lastAppVersion
    lastAppPlatform
    stripeId
    healthieId
    stripeCreditBalance
    kind
    age
    lastPrescriptionApprovalRequest {
      state
      createdAt
    }
    address {
      ...CoreAddressFields
    }
    sensorInfo {
      totalCount
      replacementRequestsCount
    }
    settings {
      id
      appVersionReminder
      dashboardGraph
      dexcomSync
      displayActivityScore
      displayDailyScore
      displayMealScore
      fastingEnd
      fastingStart
      glucoseHighThreshold
      glucoseLowThreshold
      googleFitSync
      healthKitSync
      ketoMojoSync
      ketoneHighThreshold
      ketoneLowThreshold
      integrationsSyncSettings {
        lastSyncedHealth
        lastSyncedLibreView
        lastSyncedDexcom
        lastSyncedKetoMojo
      }
      macroGoalsDaily {
        calories
        carbs
        fat
        netCarbs
        protein
      }
      reminders
      scanReminder
      showNetCarbs
      unitSystem
    }
    sensor {
      activationDate
      baselineAdjustment
      daysRemaining
      expirationDate
      expirationTime
      expired
      model
      serialNumber
      status
    }
    organization {
      id
      name
    }
    ongoingDietitianSubscription {
      id
      dietitianAddOn
      stripeStatus
      status
      paidMonthsCount
      cancelAt
      startAt
      paused
      resumesAt
      commitmentEndAt
      primaryProduct {
        key
        title
      }
    }
    ongoingCoreSubscription {
      id
      paidMonthsCount
      paused
      cancelAt
      commitmentEndAt
      plans {
        id
        nickname
      }
      primaryProduct {
        key
        title
      }
    }
    lastDietitianSubscription {
      stripeStatus
      status
      paused
      resumesAt
    }
    lastCoreSubscription {
      id
      stripeStatus
      status
      latestInvoice {
        latestCharge {
          status
          created
        }
      }
    }
    lastAsyncChatAssignment {
      ...CoreCoachAssignmentFields
    }
    lastVideoChatAssignment {
      ...CoreCoachAssignmentFields
    }
    activeRefillConsent {
      id
      givenAt
      invalidatedAt
    }
    fulfillmentConfiguration {
      sensorKind
    }
    lastDexcomResearchState {
      status
    }
    coachChatConversation {
      id
    }
    primaryGoal {
      title
      kind
      description
    }
    goalMetrics {
      primary
      kind
      lowThresholdValue
      highThresholdValue
      section
      statKey
    }
    coachProfile {
      stateLicences
      outOfOffice
    }
    isCoach
    referralPartner {
      key
      logoUrl
    }
  }
  ${CoreAddressFieldsFragmentDoc}
  ${CoreCoachAssignmentFieldsFragmentDoc}
`
export const CoreDppUserFieldsFragmentDoc = gql`
  fragment CoreDppUserFields on DppUser {
    id
    fullName
    email
    lastDppApprovalRequest {
      createdAt
    }
    preferredCallDays
    preferredCallTimes
  }
`
export const CoreCohortSessionFieldsFragmentDoc = gql`
  fragment CoreCohortSessionFields on CohortSession {
    id
    kind
    name
    position
    scheduledAt
    scheduledEndAt
    cancelledAt
    joinUrl
  }
`
export const CoreCohortFieldsFragmentDoc = gql`
  fragment CoreCohortFields on Cohort {
    id
    name
    kind
    state
    instructor {
      ...User
    }
    members {
      ...CoreDppUserFields
    }
    sessions {
      ...CoreCohortSessionFields
    }
  }
  ${UserFragmentDoc}
  ${CoreDppUserFieldsFragmentDoc}
  ${CoreCohortSessionFieldsFragmentDoc}
`
export const CoreCohortSessionAttendanceFieldsFragmentDoc = gql`
  fragment CoreCohortSessionAttendanceFields on CohortSessionAttendance {
    id
    state
  }
`
export const CoreDirectUploadFieldsFragmentDoc = gql`
  fragment CoreDirectUploadFields on DirectUpload {
    url
    signedId
    headers {
      key
      value
    }
  }
`
export const CoreFileFieldsFragmentDoc = gql`
  fragment CoreFileFields on File {
    id
    downloadFileUrl
    permanentUrl
    fileUrl
    fileName
    fileType
    createdAt
  }
`
export const CoreTicketFieldsFragmentDoc = gql`
  fragment CoreTicketFields on Ticket {
    id
    description
    externalLink
    kind
    reason
    status
    priority
    createdAt
    createdBy {
      id
      email
      fullName
    }
    user {
      id
      email
      fullName
    }
    assignedTo {
      id
      email
      fullName
    }
  }
`
export const CoreHealthPortalNotificationFieldsFragmentDoc = gql`
  fragment CoreHealthPortalNotificationFields on HealthPortalNotification {
    id
    coachAssignment {
      kind
      user {
        id
        fullName
        ongoingDietitianSubscription {
          cancelAt
          resumesAt
        }
        coachAssignments {
          id
          kind
        }
      }
      appointment {
        date
      }
      supportType
    }
    chatConversation {
      id
    }
    supportTicket {
      ...CoreTicketFields
    }
    completedAt
    createdAt
    kind
  }
  ${CoreTicketFieldsFragmentDoc}
`
export const CoreInvoiceOrderFieldsFragmentDoc = gql`
  fragment CoreInvoiceOrderFields on InvoiceOrder {
    id
    invoice {
      stripeId
      paidAt
    }
    user {
      id
      email
      firstName
      lastName
      fulfillmentConfiguration {
        sensorKind
      }
      address {
        ...CoreAddressFields
      }
    }
    quantity
    product
    productType
    processedAt
    orderId
    lastProcessingAttemptAt
    createdAt
    updatedAt
    state
    stateReason
  }
  ${CoreAddressFieldsFragmentDoc}
`
export const CoreKnowledgeAnswerFieldsFragmentDoc = gql`
  fragment CoreKnowledgeAnswerFields on KnowledgeAnswer {
    id
    title
    value
    knowledgeQuestions {
      id
      value
    }
    knowledgeSources {
      id
      title
      url
    }
  }
`
export const CoreKnowledgeQuestionFieldsFragmentDoc = gql`
  fragment CoreKnowledgeQuestionFields on KnowledgeQuestion {
    id
    value
    knowledgeAnswers {
      id
      title
      value
      knowledgeSources {
        id
        title
        url
      }
    }
    knowledgeTags {
      id
      value
    }
  }
`
export const CoreMeasurementFieldsFragmentDoc = gql`
  fragment CoreMeasurementFields on Measurement {
    id
    type
    title
    description
    value
    units
    occurredAt
    createdAt
  }
`
export const CoreOrderFieldsFragmentDoc = gql`
  fragment CoreOrderFields on Order {
    id
    userId
    product
    productType
    sensorKind
    provider
    quantity
    notes
    createdAt
    updatedAt
    invoice {
      stripeId
    }
    replacementRequest {
      replacementReason
    }
    includable
  }
`
export const CoreReplacementRequestFieldsFragmentDoc = gql`
  fragment CoreReplacementRequestFields on ReplacementRequest {
    id
    userId
    createdById
    chargeAmount
    createdAt
    daysWorn
    dateFellOff
    dateNotified
    errorType
    howItFellOff
    notes
    replacementReason
    sensor {
      id
      serialNumber
      model
    }
    testedWithLibreApp
    wearingPatch
    state
    createdBy {
      email
    }
    user {
      email
    }
    invoice {
      stripeId
    }
  }
`
export const CoreScanFieldsFragmentDoc = gql`
  fragment CoreScanFields on Scan {
    id
    createdAt
  }
`
export const CoreShipmentFieldsFragmentDoc = gql`
  fragment CoreShipmentFields on Shipment {
    id
    orderId
    addressId
    status
    trackingNumber
    trackingUrl
    createdAt
    updatedAt
    shippedAt
    deliveredAt
    canceledAt
    providerShipmentId
    providerError
    providerReceivedAt
    provider
    quantity
  }
`
export const NewNoteUpdateFragmentDoc = gql`
  fragment NewNoteUpdate on Note {
    id
    body
  }
`
export const CreateAccountRequestDocument = gql`
  mutation createAccountRequest(
    $userId: ID
    $kind: OpsAccountRequestKind
    $priority: OpsAccountRequestPriority
    $notes: String
    $payload: AccountRequestPayloadUnionSubmissionType
  ) {
    createAccountRequest(
      userId: $userId
      kind: $kind
      priority: $priority
      notes: $notes
      payload: $payload
    ) {
      ...CoreAccountRequestFields
    }
  }
  ${CoreAccountRequestFieldsFragmentDoc}
`
export type ICreateAccountRequestMutationFn = Apollo.MutationFunction<
  ICreateAccountRequestMutation,
  ICreateAccountRequestMutationVariables
>

/**
 * __useCreateAccountRequestMutation__
 *
 * To run a mutation, you first call `useCreateAccountRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountRequestMutation, { data, loading, error }] = useCreateAccountRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      kind: // value for 'kind'
 *      priority: // value for 'priority'
 *      notes: // value for 'notes'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateAccountRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateAccountRequestMutation,
    ICreateAccountRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateAccountRequestMutation, ICreateAccountRequestMutationVariables>(
    CreateAccountRequestDocument,
    options
  )
}
export type CreateAccountRequestMutationHookResult = ReturnType<
  typeof useCreateAccountRequestMutation
>
export type CreateAccountRequestMutationResult =
  Apollo.MutationResult<ICreateAccountRequestMutation>
export type CreateAccountRequestMutationOptions = Apollo.BaseMutationOptions<
  ICreateAccountRequestMutation,
  ICreateAccountRequestMutationVariables
>
export const ExportAccountRequestsDataDocument = gql`
  mutation exportAccountRequestsData($status: OpsAccountRequestStatus) {
    exportAccountRequestsData(status: $status) {
      success
    }
  }
`
export type IExportAccountRequestsDataMutationFn = Apollo.MutationFunction<
  IExportAccountRequestsDataMutation,
  IExportAccountRequestsDataMutationVariables
>

/**
 * __useExportAccountRequestsDataMutation__
 *
 * To run a mutation, you first call `useExportAccountRequestsDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAccountRequestsDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAccountRequestsDataMutation, { data, loading, error }] = useExportAccountRequestsDataMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useExportAccountRequestsDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IExportAccountRequestsDataMutation,
    IExportAccountRequestsDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IExportAccountRequestsDataMutation,
    IExportAccountRequestsDataMutationVariables
  >(ExportAccountRequestsDataDocument, options)
}
export type ExportAccountRequestsDataMutationHookResult = ReturnType<
  typeof useExportAccountRequestsDataMutation
>
export type ExportAccountRequestsDataMutationResult =
  Apollo.MutationResult<IExportAccountRequestsDataMutation>
export type ExportAccountRequestsDataMutationOptions = Apollo.BaseMutationOptions<
  IExportAccountRequestsDataMutation,
  IExportAccountRequestsDataMutationVariables
>
export const SubmitAccountRequestDocument = gql`
  mutation submitAccountRequest(
    $userId: ID
    $kind: OpsAccountRequestKind
    $priority: OpsAccountRequestPriority
    $notes: String
    $payload: AccountRequestPayloadUnionSubmissionType
  ) {
    submitAccountRequest(
      userId: $userId
      kind: $kind
      priority: $priority
      notes: $notes
      payload: $payload
    ) {
      ...CoreAccountRequestFields
    }
  }
  ${CoreAccountRequestFieldsFragmentDoc}
`
export type ISubmitAccountRequestMutationFn = Apollo.MutationFunction<
  ISubmitAccountRequestMutation,
  ISubmitAccountRequestMutationVariables
>

/**
 * __useSubmitAccountRequestMutation__
 *
 * To run a mutation, you first call `useSubmitAccountRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAccountRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAccountRequestMutation, { data, loading, error }] = useSubmitAccountRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      kind: // value for 'kind'
 *      priority: // value for 'priority'
 *      notes: // value for 'notes'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSubmitAccountRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISubmitAccountRequestMutation,
    ISubmitAccountRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISubmitAccountRequestMutation, ISubmitAccountRequestMutationVariables>(
    SubmitAccountRequestDocument,
    options
  )
}
export type SubmitAccountRequestMutationHookResult = ReturnType<
  typeof useSubmitAccountRequestMutation
>
export type SubmitAccountRequestMutationResult =
  Apollo.MutationResult<ISubmitAccountRequestMutation>
export type SubmitAccountRequestMutationOptions = Apollo.BaseMutationOptions<
  ISubmitAccountRequestMutation,
  ISubmitAccountRequestMutationVariables
>
export const UpdateAccountRequestDocument = gql`
  mutation updateAccountRequest(
    $accountRequestId: ID!
    $status: OpsAccountRequestStatus
    $notes: String
  ) {
    updateAccountRequest(accountRequestId: $accountRequestId, status: $status, notes: $notes) {
      ...CoreAccountRequestFields
    }
  }
  ${CoreAccountRequestFieldsFragmentDoc}
`
export type IUpdateAccountRequestMutationFn = Apollo.MutationFunction<
  IUpdateAccountRequestMutation,
  IUpdateAccountRequestMutationVariables
>

/**
 * __useUpdateAccountRequestMutation__
 *
 * To run a mutation, you first call `useUpdateAccountRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountRequestMutation, { data, loading, error }] = useUpdateAccountRequestMutation({
 *   variables: {
 *      accountRequestId: // value for 'accountRequestId'
 *      status: // value for 'status'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateAccountRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateAccountRequestMutation,
    IUpdateAccountRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateAccountRequestMutation, IUpdateAccountRequestMutationVariables>(
    UpdateAccountRequestDocument,
    options
  )
}
export type UpdateAccountRequestMutationHookResult = ReturnType<
  typeof useUpdateAccountRequestMutation
>
export type UpdateAccountRequestMutationResult =
  Apollo.MutationResult<IUpdateAccountRequestMutation>
export type UpdateAccountRequestMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAccountRequestMutation,
  IUpdateAccountRequestMutationVariables
>
export const AddKnowledgeQuestionTagDocument = gql`
  mutation addKnowledgeQuestionTag($id: ID!, $knowledgeTagId: ID!) {
    addKnowledgeQuestionTag(id: $id, knowledgeTagId: $knowledgeTagId) {
      ...CoreKnowledgeQuestionFields
    }
  }
  ${CoreKnowledgeQuestionFieldsFragmentDoc}
`
export type IAddKnowledgeQuestionTagMutationFn = Apollo.MutationFunction<
  IAddKnowledgeQuestionTagMutation,
  IAddKnowledgeQuestionTagMutationVariables
>

/**
 * __useAddKnowledgeQuestionTagMutation__
 *
 * To run a mutation, you first call `useAddKnowledgeQuestionTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKnowledgeQuestionTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKnowledgeQuestionTagMutation, { data, loading, error }] = useAddKnowledgeQuestionTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      knowledgeTagId: // value for 'knowledgeTagId'
 *   },
 * });
 */
export function useAddKnowledgeQuestionTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAddKnowledgeQuestionTagMutation,
    IAddKnowledgeQuestionTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IAddKnowledgeQuestionTagMutation,
    IAddKnowledgeQuestionTagMutationVariables
  >(AddKnowledgeQuestionTagDocument, options)
}
export type AddKnowledgeQuestionTagMutationHookResult = ReturnType<
  typeof useAddKnowledgeQuestionTagMutation
>
export type AddKnowledgeQuestionTagMutationResult =
  Apollo.MutationResult<IAddKnowledgeQuestionTagMutation>
export type AddKnowledgeQuestionTagMutationOptions = Apollo.BaseMutationOptions<
  IAddKnowledgeQuestionTagMutation,
  IAddKnowledgeQuestionTagMutationVariables
>
export const SendAppointmentSmsReminderDocument = gql`
  mutation sendAppointmentSmsReminder($id: ID!) {
    sendAppointmentSmsReminder(id: $id) {
      success
    }
  }
`
export type ISendAppointmentSmsReminderMutationFn = Apollo.MutationFunction<
  ISendAppointmentSmsReminderMutation,
  ISendAppointmentSmsReminderMutationVariables
>

/**
 * __useSendAppointmentSmsReminderMutation__
 *
 * To run a mutation, you first call `useSendAppointmentSmsReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAppointmentSmsReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAppointmentSmsReminderMutation, { data, loading, error }] = useSendAppointmentSmsReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendAppointmentSmsReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISendAppointmentSmsReminderMutation,
    ISendAppointmentSmsReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISendAppointmentSmsReminderMutation,
    ISendAppointmentSmsReminderMutationVariables
  >(SendAppointmentSmsReminderDocument, options)
}
export type SendAppointmentSmsReminderMutationHookResult = ReturnType<
  typeof useSendAppointmentSmsReminderMutation
>
export type SendAppointmentSmsReminderMutationResult =
  Apollo.MutationResult<ISendAppointmentSmsReminderMutation>
export type SendAppointmentSmsReminderMutationOptions = Apollo.BaseMutationOptions<
  ISendAppointmentSmsReminderMutation,
  ISendAppointmentSmsReminderMutationVariables
>
export const UpdateAppointmentDocument = gql`
  mutation updateAppointment(
    $id: ID!
    $actualDuration: Int
    $meetingStatus: InsuranceBerryStreetAppointmentMeetingStatus
  ) {
    updateAppointment(id: $id, actualDuration: $actualDuration, meetingStatus: $meetingStatus) {
      success
    }
  }
`
export type IUpdateAppointmentMutationFn = Apollo.MutationFunction<
  IUpdateAppointmentMutation,
  IUpdateAppointmentMutationVariables
>

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      actualDuration: // value for 'actualDuration'
 *      meetingStatus: // value for 'meetingStatus'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateAppointmentMutation,
    IUpdateAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateAppointmentMutation, IUpdateAppointmentMutationVariables>(
    UpdateAppointmentDocument,
    options
  )
}
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>
export type UpdateAppointmentMutationResult = Apollo.MutationResult<IUpdateAppointmentMutation>
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAppointmentMutation,
  IUpdateAppointmentMutationVariables
>
export const BackfillChatConversationDocument = gql`
  mutation backfillChatConversation($id: ID!) {
    backfillChatConversation(id: $id) {
      ...CoreChatConversationFields
    }
  }
  ${CoreChatConversationFieldsFragmentDoc}
`
export type IBackfillChatConversationMutationFn = Apollo.MutationFunction<
  IBackfillChatConversationMutation,
  IBackfillChatConversationMutationVariables
>

/**
 * __useBackfillChatConversationMutation__
 *
 * To run a mutation, you first call `useBackfillChatConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackfillChatConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backfillChatConversationMutation, { data, loading, error }] = useBackfillChatConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBackfillChatConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBackfillChatConversationMutation,
    IBackfillChatConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IBackfillChatConversationMutation,
    IBackfillChatConversationMutationVariables
  >(BackfillChatConversationDocument, options)
}
export type BackfillChatConversationMutationHookResult = ReturnType<
  typeof useBackfillChatConversationMutation
>
export type BackfillChatConversationMutationResult =
  Apollo.MutationResult<IBackfillChatConversationMutation>
export type BackfillChatConversationMutationOptions = Apollo.BaseMutationOptions<
  IBackfillChatConversationMutation,
  IBackfillChatConversationMutationVariables
>
export const BulkCoachAssignDocument = gql`
  mutation bulkCoachAssign($coachAssignments: [CoachAssign!]) {
    bulkCoachAssign(coachAssignments: $coachAssignments) {
      coachAssignments {
        ...CoreCoachAssignmentFields
      }
    }
  }
  ${CoreCoachAssignmentFieldsFragmentDoc}
`
export type IBulkCoachAssignMutationFn = Apollo.MutationFunction<
  IBulkCoachAssignMutation,
  IBulkCoachAssignMutationVariables
>

/**
 * __useBulkCoachAssignMutation__
 *
 * To run a mutation, you first call `useBulkCoachAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCoachAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCoachAssignMutation, { data, loading, error }] = useBulkCoachAssignMutation({
 *   variables: {
 *      coachAssignments: // value for 'coachAssignments'
 *   },
 * });
 */
export function useBulkCoachAssignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkCoachAssignMutation,
    IBulkCoachAssignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IBulkCoachAssignMutation, IBulkCoachAssignMutationVariables>(
    BulkCoachAssignDocument,
    options
  )
}
export type BulkCoachAssignMutationHookResult = ReturnType<typeof useBulkCoachAssignMutation>
export type BulkCoachAssignMutationResult = Apollo.MutationResult<IBulkCoachAssignMutation>
export type BulkCoachAssignMutationOptions = Apollo.BaseMutationOptions<
  IBulkCoachAssignMutation,
  IBulkCoachAssignMutationVariables
>
export const BulkCreateFilesDocument = gql`
  mutation BulkCreateFiles($userId: ID, $urls: [String!]) {
    bulkCreateFiles(userId: $userId, urls: $urls) {
      files {
        ...CoreFileFields
        tag
      }
    }
  }
  ${CoreFileFieldsFragmentDoc}
`
export type IBulkCreateFilesMutationFn = Apollo.MutationFunction<
  IBulkCreateFilesMutation,
  IBulkCreateFilesMutationVariables
>

/**
 * __useBulkCreateFilesMutation__
 *
 * To run a mutation, you first call `useBulkCreateFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateFilesMutation, { data, loading, error }] = useBulkCreateFilesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      urls: // value for 'urls'
 *   },
 * });
 */
export function useBulkCreateFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkCreateFilesMutation,
    IBulkCreateFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IBulkCreateFilesMutation, IBulkCreateFilesMutationVariables>(
    BulkCreateFilesDocument,
    options
  )
}
export type BulkCreateFilesMutationHookResult = ReturnType<typeof useBulkCreateFilesMutation>
export type BulkCreateFilesMutationResult = Apollo.MutationResult<IBulkCreateFilesMutation>
export type BulkCreateFilesMutationOptions = Apollo.BaseMutationOptions<
  IBulkCreateFilesMutation,
  IBulkCreateFilesMutationVariables
>
export const BulkCreateShipmentsDocument = gql`
  mutation bulkCreateShipments(
    $provider: ShippingOrderProvider!
    $shippedAt: String!
    $shipmentsAttributes: [BulkCreateShipment!]
  ) {
    bulkCreateShipments(
      provider: $provider
      shippedAt: $shippedAt
      shipmentsAttributes: $shipmentsAttributes
    ) {
      shipments {
        ...CoreShipmentFields
        order {
          id
          invoice {
            stripeId
          }
          createdAt
          product
          productType
          sensorKind
          quantity
        }
        address {
          ...CoreAddressFields
        }
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          dateOfBirth
          sex
          truepillPatientToken
          activeRefillConsent {
            givenAt
          }
        }
      }
      totalRecords
    }
  }
  ${CoreShipmentFieldsFragmentDoc}
  ${CoreAddressFieldsFragmentDoc}
`
export type IBulkCreateShipmentsMutationFn = Apollo.MutationFunction<
  IBulkCreateShipmentsMutation,
  IBulkCreateShipmentsMutationVariables
>

/**
 * __useBulkCreateShipmentsMutation__
 *
 * To run a mutation, you first call `useBulkCreateShipmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateShipmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateShipmentsMutation, { data, loading, error }] = useBulkCreateShipmentsMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      shippedAt: // value for 'shippedAt'
 *      shipmentsAttributes: // value for 'shipmentsAttributes'
 *   },
 * });
 */
export function useBulkCreateShipmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkCreateShipmentsMutation,
    IBulkCreateShipmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IBulkCreateShipmentsMutation, IBulkCreateShipmentsMutationVariables>(
    BulkCreateShipmentsDocument,
    options
  )
}
export type BulkCreateShipmentsMutationHookResult = ReturnType<
  typeof useBulkCreateShipmentsMutation
>
export type BulkCreateShipmentsMutationResult = Apollo.MutationResult<IBulkCreateShipmentsMutation>
export type BulkCreateShipmentsMutationOptions = Apollo.BaseMutationOptions<
  IBulkCreateShipmentsMutation,
  IBulkCreateShipmentsMutationVariables
>
export const BulkFollowCoachAssignmentsDocument = gql`
  mutation bulkFollowCoachAssignments($coachId: ID!) {
    bulkFollowCoachAssignments(coachId: $coachId) {
      success
    }
  }
`
export type IBulkFollowCoachAssignmentsMutationFn = Apollo.MutationFunction<
  IBulkFollowCoachAssignmentsMutation,
  IBulkFollowCoachAssignmentsMutationVariables
>

/**
 * __useBulkFollowCoachAssignmentsMutation__
 *
 * To run a mutation, you first call `useBulkFollowCoachAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkFollowCoachAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkFollowCoachAssignmentsMutation, { data, loading, error }] = useBulkFollowCoachAssignmentsMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *   },
 * });
 */
export function useBulkFollowCoachAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkFollowCoachAssignmentsMutation,
    IBulkFollowCoachAssignmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IBulkFollowCoachAssignmentsMutation,
    IBulkFollowCoachAssignmentsMutationVariables
  >(BulkFollowCoachAssignmentsDocument, options)
}
export type BulkFollowCoachAssignmentsMutationHookResult = ReturnType<
  typeof useBulkFollowCoachAssignmentsMutation
>
export type BulkFollowCoachAssignmentsMutationResult =
  Apollo.MutationResult<IBulkFollowCoachAssignmentsMutation>
export type BulkFollowCoachAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  IBulkFollowCoachAssignmentsMutation,
  IBulkFollowCoachAssignmentsMutationVariables
>
export const BulkShipOrdersDocument = gql`
  mutation bulkShipOrders($ids: [ID!], $provider: ShippingOrderProvider!) {
    bulkShipOrders(ids: $ids, provider: $provider) {
      orders {
        ...CoreOrderFields
      }
      totalRecords
    }
  }
  ${CoreOrderFieldsFragmentDoc}
`
export type IBulkShipOrdersMutationFn = Apollo.MutationFunction<
  IBulkShipOrdersMutation,
  IBulkShipOrdersMutationVariables
>

/**
 * __useBulkShipOrdersMutation__
 *
 * To run a mutation, you first call `useBulkShipOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkShipOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkShipOrdersMutation, { data, loading, error }] = useBulkShipOrdersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useBulkShipOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkShipOrdersMutation,
    IBulkShipOrdersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IBulkShipOrdersMutation, IBulkShipOrdersMutationVariables>(
    BulkShipOrdersDocument,
    options
  )
}
export type BulkShipOrdersMutationHookResult = ReturnType<typeof useBulkShipOrdersMutation>
export type BulkShipOrdersMutationResult = Apollo.MutationResult<IBulkShipOrdersMutation>
export type BulkShipOrdersMutationOptions = Apollo.BaseMutationOptions<
  IBulkShipOrdersMutation,
  IBulkShipOrdersMutationVariables
>
export const BulkUnfollowCoachAssignmentsDocument = gql`
  mutation bulkUnfollowCoachAssignments($coachId: ID!) {
    bulkUnfollowCoachAssignments(coachId: $coachId) {
      success
    }
  }
`
export type IBulkUnfollowCoachAssignmentsMutationFn = Apollo.MutationFunction<
  IBulkUnfollowCoachAssignmentsMutation,
  IBulkUnfollowCoachAssignmentsMutationVariables
>

/**
 * __useBulkUnfollowCoachAssignmentsMutation__
 *
 * To run a mutation, you first call `useBulkUnfollowCoachAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUnfollowCoachAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUnfollowCoachAssignmentsMutation, { data, loading, error }] = useBulkUnfollowCoachAssignmentsMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *   },
 * });
 */
export function useBulkUnfollowCoachAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkUnfollowCoachAssignmentsMutation,
    IBulkUnfollowCoachAssignmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IBulkUnfollowCoachAssignmentsMutation,
    IBulkUnfollowCoachAssignmentsMutationVariables
  >(BulkUnfollowCoachAssignmentsDocument, options)
}
export type BulkUnfollowCoachAssignmentsMutationHookResult = ReturnType<
  typeof useBulkUnfollowCoachAssignmentsMutation
>
export type BulkUnfollowCoachAssignmentsMutationResult =
  Apollo.MutationResult<IBulkUnfollowCoachAssignmentsMutation>
export type BulkUnfollowCoachAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  IBulkUnfollowCoachAssignmentsMutation,
  IBulkUnfollowCoachAssignmentsMutationVariables
>
export const BulkUpdateHealthPortalNotificationsDocument = gql`
  mutation BulkUpdateHealthPortalNotifications(
    $healthPortalNotifications: [UpdateHealthPortalNotification!]
  ) {
    bulkUpdateHealthPortalNotifications(healthPortalNotifications: $healthPortalNotifications) {
      healthPortalNotifications {
        ...CoreHealthPortalNotificationFields
      }
    }
  }
  ${CoreHealthPortalNotificationFieldsFragmentDoc}
`
export type IBulkUpdateHealthPortalNotificationsMutationFn = Apollo.MutationFunction<
  IBulkUpdateHealthPortalNotificationsMutation,
  IBulkUpdateHealthPortalNotificationsMutationVariables
>

/**
 * __useBulkUpdateHealthPortalNotificationsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateHealthPortalNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateHealthPortalNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateHealthPortalNotificationsMutation, { data, loading, error }] = useBulkUpdateHealthPortalNotificationsMutation({
 *   variables: {
 *      healthPortalNotifications: // value for 'healthPortalNotifications'
 *   },
 * });
 */
export function useBulkUpdateHealthPortalNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkUpdateHealthPortalNotificationsMutation,
    IBulkUpdateHealthPortalNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IBulkUpdateHealthPortalNotificationsMutation,
    IBulkUpdateHealthPortalNotificationsMutationVariables
  >(BulkUpdateHealthPortalNotificationsDocument, options)
}
export type BulkUpdateHealthPortalNotificationsMutationHookResult = ReturnType<
  typeof useBulkUpdateHealthPortalNotificationsMutation
>
export type BulkUpdateHealthPortalNotificationsMutationResult =
  Apollo.MutationResult<IBulkUpdateHealthPortalNotificationsMutation>
export type BulkUpdateHealthPortalNotificationsMutationOptions = Apollo.BaseMutationOptions<
  IBulkUpdateHealthPortalNotificationsMutation,
  IBulkUpdateHealthPortalNotificationsMutationVariables
>
export const BulkUpdateCoachProfilesDocument = gql`
  mutation bulkUpdateCoachProfiles($coachProfiles: [UpdateCoachProfile!]) {
    bulkUpdateCoachProfiles(coachProfiles: $coachProfiles) {
      coachProfiles {
        fullName
        startDate
        endDate
      }
    }
  }
`
export type IBulkUpdateCoachProfilesMutationFn = Apollo.MutationFunction<
  IBulkUpdateCoachProfilesMutation,
  IBulkUpdateCoachProfilesMutationVariables
>

/**
 * __useBulkUpdateCoachProfilesMutation__
 *
 * To run a mutation, you first call `useBulkUpdateCoachProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateCoachProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateCoachProfilesMutation, { data, loading, error }] = useBulkUpdateCoachProfilesMutation({
 *   variables: {
 *      coachProfiles: // value for 'coachProfiles'
 *   },
 * });
 */
export function useBulkUpdateCoachProfilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkUpdateCoachProfilesMutation,
    IBulkUpdateCoachProfilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IBulkUpdateCoachProfilesMutation,
    IBulkUpdateCoachProfilesMutationVariables
  >(BulkUpdateCoachProfilesDocument, options)
}
export type BulkUpdateCoachProfilesMutationHookResult = ReturnType<
  typeof useBulkUpdateCoachProfilesMutation
>
export type BulkUpdateCoachProfilesMutationResult =
  Apollo.MutationResult<IBulkUpdateCoachProfilesMutation>
export type BulkUpdateCoachProfilesMutationOptions = Apollo.BaseMutationOptions<
  IBulkUpdateCoachProfilesMutation,
  IBulkUpdateCoachProfilesMutationVariables
>
export const CreateChatConversationNoteDocument = gql`
  mutation createChatConversationNote($conversationId: ID!, $body: String!) {
    createChatConversationNote(conversationId: $conversationId, body: $body) {
      id
      value
      createdAt
      createdBy {
        id
        fullName
      }
    }
  }
`
export type ICreateChatConversationNoteMutationFn = Apollo.MutationFunction<
  ICreateChatConversationNoteMutation,
  ICreateChatConversationNoteMutationVariables
>

/**
 * __useCreateChatConversationNoteMutation__
 *
 * To run a mutation, you first call `useCreateChatConversationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatConversationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatConversationNoteMutation, { data, loading, error }] = useCreateChatConversationNoteMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateChatConversationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateChatConversationNoteMutation,
    ICreateChatConversationNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateChatConversationNoteMutation,
    ICreateChatConversationNoteMutationVariables
  >(CreateChatConversationNoteDocument, options)
}
export type CreateChatConversationNoteMutationHookResult = ReturnType<
  typeof useCreateChatConversationNoteMutation
>
export type CreateChatConversationNoteMutationResult =
  Apollo.MutationResult<ICreateChatConversationNoteMutation>
export type CreateChatConversationNoteMutationOptions = Apollo.BaseMutationOptions<
  ICreateChatConversationNoteMutation,
  ICreateChatConversationNoteMutationVariables
>
export const UpdateChatConversationNoteDocument = gql`
  mutation updateChatConversationNote($id: ID!, $body: String!) {
    updateChatConversationNote(id: $id, body: $body) {
      id
      value
      createdAt
      createdBy {
        id
        fullName
      }
    }
  }
`
export type IUpdateChatConversationNoteMutationFn = Apollo.MutationFunction<
  IUpdateChatConversationNoteMutation,
  IUpdateChatConversationNoteMutationVariables
>

/**
 * __useUpdateChatConversationNoteMutation__
 *
 * To run a mutation, you first call `useUpdateChatConversationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatConversationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatConversationNoteMutation, { data, loading, error }] = useUpdateChatConversationNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateChatConversationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateChatConversationNoteMutation,
    IUpdateChatConversationNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateChatConversationNoteMutation,
    IUpdateChatConversationNoteMutationVariables
  >(UpdateChatConversationNoteDocument, options)
}
export type UpdateChatConversationNoteMutationHookResult = ReturnType<
  typeof useUpdateChatConversationNoteMutation
>
export type UpdateChatConversationNoteMutationResult =
  Apollo.MutationResult<IUpdateChatConversationNoteMutation>
export type UpdateChatConversationNoteMutationOptions = Apollo.BaseMutationOptions<
  IUpdateChatConversationNoteMutation,
  IUpdateChatConversationNoteMutationVariables
>
export const UpdateChatConversationStateDocument = gql`
  mutation updateChatConversationState(
    $id: ID!
    $state: ChatConversationState!
    $snoozedUntil: ISO8601DateTime
  ) {
    updateChatConversationState(id: $id, state: $state, snoozedUntil: $snoozedUntil) {
      ...CoreChatConversationFields
    }
  }
  ${CoreChatConversationFieldsFragmentDoc}
`
export type IUpdateChatConversationStateMutationFn = Apollo.MutationFunction<
  IUpdateChatConversationStateMutation,
  IUpdateChatConversationStateMutationVariables
>

/**
 * __useUpdateChatConversationStateMutation__
 *
 * To run a mutation, you first call `useUpdateChatConversationStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatConversationStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatConversationStateMutation, { data, loading, error }] = useUpdateChatConversationStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      snoozedUntil: // value for 'snoozedUntil'
 *   },
 * });
 */
export function useUpdateChatConversationStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateChatConversationStateMutation,
    IUpdateChatConversationStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateChatConversationStateMutation,
    IUpdateChatConversationStateMutationVariables
  >(UpdateChatConversationStateDocument, options)
}
export type UpdateChatConversationStateMutationHookResult = ReturnType<
  typeof useUpdateChatConversationStateMutation
>
export type UpdateChatConversationStateMutationResult =
  Apollo.MutationResult<IUpdateChatConversationStateMutation>
export type UpdateChatConversationStateMutationOptions = Apollo.BaseMutationOptions<
  IUpdateChatConversationStateMutation,
  IUpdateChatConversationStateMutationVariables
>
export const BulkCreateCourseCohortMemberEnrollmentsDocument = gql`
  mutation bulkCreateCourseCohortMemberEnrollments(
    $cohortId: ID!
    $userIds: [ID!]!
    $state: CoursesCohortMemberEnrollmentState!
  ) {
    bulkCreateCourseCohortMemberEnrollments(cohortId: $cohortId, userIds: $userIds, state: $state) {
      success
    }
  }
`
export type IBulkCreateCourseCohortMemberEnrollmentsMutationFn = Apollo.MutationFunction<
  IBulkCreateCourseCohortMemberEnrollmentsMutation,
  IBulkCreateCourseCohortMemberEnrollmentsMutationVariables
>

/**
 * __useBulkCreateCourseCohortMemberEnrollmentsMutation__
 *
 * To run a mutation, you first call `useBulkCreateCourseCohortMemberEnrollmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateCourseCohortMemberEnrollmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateCourseCohortMemberEnrollmentsMutation, { data, loading, error }] = useBulkCreateCourseCohortMemberEnrollmentsMutation({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      userIds: // value for 'userIds'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useBulkCreateCourseCohortMemberEnrollmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkCreateCourseCohortMemberEnrollmentsMutation,
    IBulkCreateCourseCohortMemberEnrollmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IBulkCreateCourseCohortMemberEnrollmentsMutation,
    IBulkCreateCourseCohortMemberEnrollmentsMutationVariables
  >(BulkCreateCourseCohortMemberEnrollmentsDocument, options)
}
export type BulkCreateCourseCohortMemberEnrollmentsMutationHookResult = ReturnType<
  typeof useBulkCreateCourseCohortMemberEnrollmentsMutation
>
export type BulkCreateCourseCohortMemberEnrollmentsMutationResult =
  Apollo.MutationResult<IBulkCreateCourseCohortMemberEnrollmentsMutation>
export type BulkCreateCourseCohortMemberEnrollmentsMutationOptions = Apollo.BaseMutationOptions<
  IBulkCreateCourseCohortMemberEnrollmentsMutation,
  IBulkCreateCourseCohortMemberEnrollmentsMutationVariables
>
export const BulkUpsertCourseCohortSessionsDocument = gql`
  mutation bulkUpsertCourseCohortSessions($id: ID!, $sessionsData: [CohortSessionData!]!) {
    bulkUpsertCourseCohortSessions(id: $id, sessionsData: $sessionsData) {
      ...CoreCohortFields
    }
  }
  ${CoreCohortFieldsFragmentDoc}
`
export type IBulkUpsertCourseCohortSessionsMutationFn = Apollo.MutationFunction<
  IBulkUpsertCourseCohortSessionsMutation,
  IBulkUpsertCourseCohortSessionsMutationVariables
>

/**
 * __useBulkUpsertCourseCohortSessionsMutation__
 *
 * To run a mutation, you first call `useBulkUpsertCourseCohortSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpsertCourseCohortSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpsertCourseCohortSessionsMutation, { data, loading, error }] = useBulkUpsertCourseCohortSessionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sessionsData: // value for 'sessionsData'
 *   },
 * });
 */
export function useBulkUpsertCourseCohortSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IBulkUpsertCourseCohortSessionsMutation,
    IBulkUpsertCourseCohortSessionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IBulkUpsertCourseCohortSessionsMutation,
    IBulkUpsertCourseCohortSessionsMutationVariables
  >(BulkUpsertCourseCohortSessionsDocument, options)
}
export type BulkUpsertCourseCohortSessionsMutationHookResult = ReturnType<
  typeof useBulkUpsertCourseCohortSessionsMutation
>
export type BulkUpsertCourseCohortSessionsMutationResult =
  Apollo.MutationResult<IBulkUpsertCourseCohortSessionsMutation>
export type BulkUpsertCourseCohortSessionsMutationOptions = Apollo.BaseMutationOptions<
  IBulkUpsertCourseCohortSessionsMutation,
  IBulkUpsertCourseCohortSessionsMutationVariables
>
export const CreateCourseCohortDocument = gql`
  mutation createCourseCohort(
    $kind: CoursesCohortKind!
    $name: String!
    $instructorId: ID!
    $sessionsData: [CohortSessionData!]
  ) {
    createCourseCohort(
      kind: $kind
      name: $name
      instructorId: $instructorId
      sessionsData: $sessionsData
    ) {
      ...CoreCohortFields
    }
  }
  ${CoreCohortFieldsFragmentDoc}
`
export type ICreateCourseCohortMutationFn = Apollo.MutationFunction<
  ICreateCourseCohortMutation,
  ICreateCourseCohortMutationVariables
>

/**
 * __useCreateCourseCohortMutation__
 *
 * To run a mutation, you first call `useCreateCourseCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseCohortMutation, { data, loading, error }] = useCreateCourseCohortMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      name: // value for 'name'
 *      instructorId: // value for 'instructorId'
 *      sessionsData: // value for 'sessionsData'
 *   },
 * });
 */
export function useCreateCourseCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateCourseCohortMutation,
    ICreateCourseCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateCourseCohortMutation, ICreateCourseCohortMutationVariables>(
    CreateCourseCohortDocument,
    options
  )
}
export type CreateCourseCohortMutationHookResult = ReturnType<typeof useCreateCourseCohortMutation>
export type CreateCourseCohortMutationResult = Apollo.MutationResult<ICreateCourseCohortMutation>
export type CreateCourseCohortMutationOptions = Apollo.BaseMutationOptions<
  ICreateCourseCohortMutation,
  ICreateCourseCohortMutationVariables
>
export const ProcessDppInsuranceParticipantsDocument = gql`
  mutation processDppInsuranceParticipants {
    processDppInsuranceParticipants {
      success
    }
  }
`
export type IProcessDppInsuranceParticipantsMutationFn = Apollo.MutationFunction<
  IProcessDppInsuranceParticipantsMutation,
  IProcessDppInsuranceParticipantsMutationVariables
>

/**
 * __useProcessDppInsuranceParticipantsMutation__
 *
 * To run a mutation, you first call `useProcessDppInsuranceParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessDppInsuranceParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processDppInsuranceParticipantsMutation, { data, loading, error }] = useProcessDppInsuranceParticipantsMutation({
 *   variables: {
 *   },
 * });
 */
export function useProcessDppInsuranceParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IProcessDppInsuranceParticipantsMutation,
    IProcessDppInsuranceParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IProcessDppInsuranceParticipantsMutation,
    IProcessDppInsuranceParticipantsMutationVariables
  >(ProcessDppInsuranceParticipantsDocument, options)
}
export type ProcessDppInsuranceParticipantsMutationHookResult = ReturnType<
  typeof useProcessDppInsuranceParticipantsMutation
>
export type ProcessDppInsuranceParticipantsMutationResult =
  Apollo.MutationResult<IProcessDppInsuranceParticipantsMutation>
export type ProcessDppInsuranceParticipantsMutationOptions = Apollo.BaseMutationOptions<
  IProcessDppInsuranceParticipantsMutation,
  IProcessDppInsuranceParticipantsMutationVariables
>
export const ProcessDppInsuranceSessionsDocument = gql`
  mutation processDppInsuranceSessions {
    processDppInsuranceSessions {
      success
    }
  }
`
export type IProcessDppInsuranceSessionsMutationFn = Apollo.MutationFunction<
  IProcessDppInsuranceSessionsMutation,
  IProcessDppInsuranceSessionsMutationVariables
>

/**
 * __useProcessDppInsuranceSessionsMutation__
 *
 * To run a mutation, you first call `useProcessDppInsuranceSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessDppInsuranceSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processDppInsuranceSessionsMutation, { data, loading, error }] = useProcessDppInsuranceSessionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useProcessDppInsuranceSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IProcessDppInsuranceSessionsMutation,
    IProcessDppInsuranceSessionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IProcessDppInsuranceSessionsMutation,
    IProcessDppInsuranceSessionsMutationVariables
  >(ProcessDppInsuranceSessionsDocument, options)
}
export type ProcessDppInsuranceSessionsMutationHookResult = ReturnType<
  typeof useProcessDppInsuranceSessionsMutation
>
export type ProcessDppInsuranceSessionsMutationResult =
  Apollo.MutationResult<IProcessDppInsuranceSessionsMutation>
export type ProcessDppInsuranceSessionsMutationOptions = Apollo.BaseMutationOptions<
  IProcessDppInsuranceSessionsMutation,
  IProcessDppInsuranceSessionsMutationVariables
>
export const UpdateCourseCohortDocument = gql`
  mutation updateCourseCohort($id: ID!, $name: String!) {
    updateCourseCohort(id: $id, name: $name) {
      ...CoreCohortFields
    }
  }
  ${CoreCohortFieldsFragmentDoc}
`
export type IUpdateCourseCohortMutationFn = Apollo.MutationFunction<
  IUpdateCourseCohortMutation,
  IUpdateCourseCohortMutationVariables
>

/**
 * __useUpdateCourseCohortMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCohortMutation, { data, loading, error }] = useUpdateCourseCohortMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCourseCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateCourseCohortMutation,
    IUpdateCourseCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateCourseCohortMutation, IUpdateCourseCohortMutationVariables>(
    UpdateCourseCohortDocument,
    options
  )
}
export type UpdateCourseCohortMutationHookResult = ReturnType<typeof useUpdateCourseCohortMutation>
export type UpdateCourseCohortMutationResult = Apollo.MutationResult<IUpdateCourseCohortMutation>
export type UpdateCourseCohortMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCourseCohortMutation,
  IUpdateCourseCohortMutationVariables
>
export const UpdateCourseCohortInstructorDocument = gql`
  mutation updateCourseCohortInstructor($id: ID!, $instructorId: ID!) {
    updateCourseCohortInstructor(id: $id, instructorId: $instructorId) {
      ...CoreCohortFields
    }
  }
  ${CoreCohortFieldsFragmentDoc}
`
export type IUpdateCourseCohortInstructorMutationFn = Apollo.MutationFunction<
  IUpdateCourseCohortInstructorMutation,
  IUpdateCourseCohortInstructorMutationVariables
>

/**
 * __useUpdateCourseCohortInstructorMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCohortInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCohortInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCohortInstructorMutation, { data, loading, error }] = useUpdateCourseCohortInstructorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      instructorId: // value for 'instructorId'
 *   },
 * });
 */
export function useUpdateCourseCohortInstructorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateCourseCohortInstructorMutation,
    IUpdateCourseCohortInstructorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateCourseCohortInstructorMutation,
    IUpdateCourseCohortInstructorMutationVariables
  >(UpdateCourseCohortInstructorDocument, options)
}
export type UpdateCourseCohortInstructorMutationHookResult = ReturnType<
  typeof useUpdateCourseCohortInstructorMutation
>
export type UpdateCourseCohortInstructorMutationResult =
  Apollo.MutationResult<IUpdateCourseCohortInstructorMutation>
export type UpdateCourseCohortInstructorMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCourseCohortInstructorMutation,
  IUpdateCourseCohortInstructorMutationVariables
>
export const UpdateCourseCohortStateDocument = gql`
  mutation updateCourseCohortState($id: ID!, $state: CoursesCohortState!) {
    updateCourseCohortState(id: $id, state: $state) {
      ...CoreCohortFields
    }
  }
  ${CoreCohortFieldsFragmentDoc}
`
export type IUpdateCourseCohortStateMutationFn = Apollo.MutationFunction<
  IUpdateCourseCohortStateMutation,
  IUpdateCourseCohortStateMutationVariables
>

/**
 * __useUpdateCourseCohortStateMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCohortStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCohortStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCohortStateMutation, { data, loading, error }] = useUpdateCourseCohortStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateCourseCohortStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateCourseCohortStateMutation,
    IUpdateCourseCohortStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateCourseCohortStateMutation,
    IUpdateCourseCohortStateMutationVariables
  >(UpdateCourseCohortStateDocument, options)
}
export type UpdateCourseCohortStateMutationHookResult = ReturnType<
  typeof useUpdateCourseCohortStateMutation
>
export type UpdateCourseCohortStateMutationResult =
  Apollo.MutationResult<IUpdateCourseCohortStateMutation>
export type UpdateCourseCohortStateMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCourseCohortStateMutation,
  IUpdateCourseCohortStateMutationVariables
>
export const UpsertCourseCohortSessionAttendanceDocument = gql`
  mutation upsertCourseCohortSessionAttendance(
    $sessionId: ID!
    $userId: ID!
    $state: CoursesCohortSessionAttendanceState!
  ) {
    upsertCourseCohortSessionAttendance(sessionId: $sessionId, userId: $userId, state: $state) {
      ...CoreCohortSessionAttendanceFields
    }
  }
  ${CoreCohortSessionAttendanceFieldsFragmentDoc}
`
export type IUpsertCourseCohortSessionAttendanceMutationFn = Apollo.MutationFunction<
  IUpsertCourseCohortSessionAttendanceMutation,
  IUpsertCourseCohortSessionAttendanceMutationVariables
>

/**
 * __useUpsertCourseCohortSessionAttendanceMutation__
 *
 * To run a mutation, you first call `useUpsertCourseCohortSessionAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCourseCohortSessionAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCourseCohortSessionAttendanceMutation, { data, loading, error }] = useUpsertCourseCohortSessionAttendanceMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      userId: // value for 'userId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpsertCourseCohortSessionAttendanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpsertCourseCohortSessionAttendanceMutation,
    IUpsertCourseCohortSessionAttendanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpsertCourseCohortSessionAttendanceMutation,
    IUpsertCourseCohortSessionAttendanceMutationVariables
  >(UpsertCourseCohortSessionAttendanceDocument, options)
}
export type UpsertCourseCohortSessionAttendanceMutationHookResult = ReturnType<
  typeof useUpsertCourseCohortSessionAttendanceMutation
>
export type UpsertCourseCohortSessionAttendanceMutationResult =
  Apollo.MutationResult<IUpsertCourseCohortSessionAttendanceMutation>
export type UpsertCourseCohortSessionAttendanceMutationOptions = Apollo.BaseMutationOptions<
  IUpsertCourseCohortSessionAttendanceMutation,
  IUpsertCourseCohortSessionAttendanceMutationVariables
>
export const CreateChatConversationMessageTemplateDocument = gql`
  mutation createChatConversationMessageTemplate(
    $name: String!
    $value: String!
    $private: Boolean!
  ) {
    createChatConversationMessageTemplate(name: $name, value: $value, private: $private) {
      id
      name
      value
      private
      kind
    }
  }
`
export type ICreateChatConversationMessageTemplateMutationFn = Apollo.MutationFunction<
  ICreateChatConversationMessageTemplateMutation,
  ICreateChatConversationMessageTemplateMutationVariables
>

/**
 * __useCreateChatConversationMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateChatConversationMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatConversationMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatConversationMessageTemplateMutation, { data, loading, error }] = useCreateChatConversationMessageTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useCreateChatConversationMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateChatConversationMessageTemplateMutation,
    ICreateChatConversationMessageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateChatConversationMessageTemplateMutation,
    ICreateChatConversationMessageTemplateMutationVariables
  >(CreateChatConversationMessageTemplateDocument, options)
}
export type CreateChatConversationMessageTemplateMutationHookResult = ReturnType<
  typeof useCreateChatConversationMessageTemplateMutation
>
export type CreateChatConversationMessageTemplateMutationResult =
  Apollo.MutationResult<ICreateChatConversationMessageTemplateMutation>
export type CreateChatConversationMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  ICreateChatConversationMessageTemplateMutation,
  ICreateChatConversationMessageTemplateMutationVariables
>
export const CreateChatTokenDocument = gql`
  mutation createChatToken($userId: ID!) {
    createChatToken(userId: $userId) {
      value
    }
  }
`
export type ICreateChatTokenMutationFn = Apollo.MutationFunction<
  ICreateChatTokenMutation,
  ICreateChatTokenMutationVariables
>

/**
 * __useCreateChatTokenMutation__
 *
 * To run a mutation, you first call `useCreateChatTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatTokenMutation, { data, loading, error }] = useCreateChatTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateChatTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateChatTokenMutation,
    ICreateChatTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateChatTokenMutation, ICreateChatTokenMutationVariables>(
    CreateChatTokenDocument,
    options
  )
}
export type CreateChatTokenMutationHookResult = ReturnType<typeof useCreateChatTokenMutation>
export type CreateChatTokenMutationResult = Apollo.MutationResult<ICreateChatTokenMutation>
export type CreateChatTokenMutationOptions = Apollo.BaseMutationOptions<
  ICreateChatTokenMutation,
  ICreateChatTokenMutationVariables
>
export const CreateChatbotDelimiterDocument = gql`
  mutation createChatbotDelimiter($providerConversationId: ID!) {
    createChatbotDelimiter(providerConversationId: $providerConversationId) {
      id
      createdAt
    }
  }
`
export type ICreateChatbotDelimiterMutationFn = Apollo.MutationFunction<
  ICreateChatbotDelimiterMutation,
  ICreateChatbotDelimiterMutationVariables
>

/**
 * __useCreateChatbotDelimiterMutation__
 *
 * To run a mutation, you first call `useCreateChatbotDelimiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatbotDelimiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatbotDelimiterMutation, { data, loading, error }] = useCreateChatbotDelimiterMutation({
 *   variables: {
 *      providerConversationId: // value for 'providerConversationId'
 *   },
 * });
 */
export function useCreateChatbotDelimiterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateChatbotDelimiterMutation,
    ICreateChatbotDelimiterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateChatbotDelimiterMutation,
    ICreateChatbotDelimiterMutationVariables
  >(CreateChatbotDelimiterDocument, options)
}
export type CreateChatbotDelimiterMutationHookResult = ReturnType<
  typeof useCreateChatbotDelimiterMutation
>
export type CreateChatbotDelimiterMutationResult =
  Apollo.MutationResult<ICreateChatbotDelimiterMutation>
export type CreateChatbotDelimiterMutationOptions = Apollo.BaseMutationOptions<
  ICreateChatbotDelimiterMutation,
  ICreateChatbotDelimiterMutationVariables
>
export const CreateDietitianSessionDocument = gql`
  mutation createDietitianSession(
    $userId: ID
    $startedAt: ISO8601DateTime!
    $endedAt: ISO8601DateTime!
  ) {
    createDietitianSession(userId: $userId, startedAt: $startedAt, endedAt: $endedAt) {
      id
      dietitianId
      userId
      startedAt
      endedAt
      durationInSeconds
    }
  }
`
export type ICreateDietitianSessionMutationFn = Apollo.MutationFunction<
  ICreateDietitianSessionMutation,
  ICreateDietitianSessionMutationVariables
>

/**
 * __useCreateDietitianSessionMutation__
 *
 * To run a mutation, you first call `useCreateDietitianSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDietitianSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDietitianSessionMutation, { data, loading, error }] = useCreateDietitianSessionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      startedAt: // value for 'startedAt'
 *      endedAt: // value for 'endedAt'
 *   },
 * });
 */
export function useCreateDietitianSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateDietitianSessionMutation,
    ICreateDietitianSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateDietitianSessionMutation,
    ICreateDietitianSessionMutationVariables
  >(CreateDietitianSessionDocument, options)
}
export type CreateDietitianSessionMutationHookResult = ReturnType<
  typeof useCreateDietitianSessionMutation
>
export type CreateDietitianSessionMutationResult =
  Apollo.MutationResult<ICreateDietitianSessionMutation>
export type CreateDietitianSessionMutationOptions = Apollo.BaseMutationOptions<
  ICreateDietitianSessionMutation,
  ICreateDietitianSessionMutationVariables
>
export const CreateDirectUploadsDocument = gql`
  mutation CreateDirectUploads($files: [FileMetadata!]) {
    createDirectUploads(files: $files) {
      directUploads {
        ...CoreDirectUploadFields
      }
    }
  }
  ${CoreDirectUploadFieldsFragmentDoc}
`
export type ICreateDirectUploadsMutationFn = Apollo.MutationFunction<
  ICreateDirectUploadsMutation,
  ICreateDirectUploadsMutationVariables
>

/**
 * __useCreateDirectUploadsMutation__
 *
 * To run a mutation, you first call `useCreateDirectUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectUploadsMutation, { data, loading, error }] = useCreateDirectUploadsMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateDirectUploadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateDirectUploadsMutation,
    ICreateDirectUploadsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateDirectUploadsMutation, ICreateDirectUploadsMutationVariables>(
    CreateDirectUploadsDocument,
    options
  )
}
export type CreateDirectUploadsMutationHookResult = ReturnType<
  typeof useCreateDirectUploadsMutation
>
export type CreateDirectUploadsMutationResult = Apollo.MutationResult<ICreateDirectUploadsMutation>
export type CreateDirectUploadsMutationOptions = Apollo.BaseMutationOptions<
  ICreateDirectUploadsMutation,
  ICreateDirectUploadsMutationVariables
>
export const CreateFilesFromDirectUploadsDocument = gql`
  mutation CreateFilesFromDirectUploads($userId: ID!, $signedIds: [String!]) {
    createFilesFromDirectUploads(userId: $userId, signedIds: $signedIds) {
      files {
        ...CoreFileFields
        tag
      }
    }
  }
  ${CoreFileFieldsFragmentDoc}
`
export type ICreateFilesFromDirectUploadsMutationFn = Apollo.MutationFunction<
  ICreateFilesFromDirectUploadsMutation,
  ICreateFilesFromDirectUploadsMutationVariables
>

/**
 * __useCreateFilesFromDirectUploadsMutation__
 *
 * To run a mutation, you first call `useCreateFilesFromDirectUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilesFromDirectUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilesFromDirectUploadsMutation, { data, loading, error }] = useCreateFilesFromDirectUploadsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      signedIds: // value for 'signedIds'
 *   },
 * });
 */
export function useCreateFilesFromDirectUploadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateFilesFromDirectUploadsMutation,
    ICreateFilesFromDirectUploadsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateFilesFromDirectUploadsMutation,
    ICreateFilesFromDirectUploadsMutationVariables
  >(CreateFilesFromDirectUploadsDocument, options)
}
export type CreateFilesFromDirectUploadsMutationHookResult = ReturnType<
  typeof useCreateFilesFromDirectUploadsMutation
>
export type CreateFilesFromDirectUploadsMutationResult =
  Apollo.MutationResult<ICreateFilesFromDirectUploadsMutation>
export type CreateFilesFromDirectUploadsMutationOptions = Apollo.BaseMutationOptions<
  ICreateFilesFromDirectUploadsMutation,
  ICreateFilesFromDirectUploadsMutationVariables
>
export const CreateKnowledgeAnswerDocument = gql`
  mutation createKnowledgeAnswer($title: String!, $value: String!) {
    createKnowledgeAnswer(title: $title, value: $value) {
      ...CoreKnowledgeAnswerFields
    }
  }
  ${CoreKnowledgeAnswerFieldsFragmentDoc}
`
export type ICreateKnowledgeAnswerMutationFn = Apollo.MutationFunction<
  ICreateKnowledgeAnswerMutation,
  ICreateKnowledgeAnswerMutationVariables
>

/**
 * __useCreateKnowledgeAnswerMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeAnswerMutation, { data, loading, error }] = useCreateKnowledgeAnswerMutation({
 *   variables: {
 *      title: // value for 'title'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateKnowledgeAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateKnowledgeAnswerMutation,
    ICreateKnowledgeAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateKnowledgeAnswerMutation,
    ICreateKnowledgeAnswerMutationVariables
  >(CreateKnowledgeAnswerDocument, options)
}
export type CreateKnowledgeAnswerMutationHookResult = ReturnType<
  typeof useCreateKnowledgeAnswerMutation
>
export type CreateKnowledgeAnswerMutationResult =
  Apollo.MutationResult<ICreateKnowledgeAnswerMutation>
export type CreateKnowledgeAnswerMutationOptions = Apollo.BaseMutationOptions<
  ICreateKnowledgeAnswerMutation,
  ICreateKnowledgeAnswerMutationVariables
>
export const CreateKnowledgeQuestionDocument = gql`
  mutation createKnowledgeQuestion($value: String!) {
    createKnowledgeQuestion(value: $value) {
      id
      value
    }
  }
`
export type ICreateKnowledgeQuestionMutationFn = Apollo.MutationFunction<
  ICreateKnowledgeQuestionMutation,
  ICreateKnowledgeQuestionMutationVariables
>

/**
 * __useCreateKnowledgeQuestionMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeQuestionMutation, { data, loading, error }] = useCreateKnowledgeQuestionMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateKnowledgeQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateKnowledgeQuestionMutation,
    ICreateKnowledgeQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateKnowledgeQuestionMutation,
    ICreateKnowledgeQuestionMutationVariables
  >(CreateKnowledgeQuestionDocument, options)
}
export type CreateKnowledgeQuestionMutationHookResult = ReturnType<
  typeof useCreateKnowledgeQuestionMutation
>
export type CreateKnowledgeQuestionMutationResult =
  Apollo.MutationResult<ICreateKnowledgeQuestionMutation>
export type CreateKnowledgeQuestionMutationOptions = Apollo.BaseMutationOptions<
  ICreateKnowledgeQuestionMutation,
  ICreateKnowledgeQuestionMutationVariables
>
export const CreateKnowledgeSourceDocument = gql`
  mutation createKnowledgeSource($title: String!, $url: String!) {
    createKnowledgeSource(title: $title, url: $url) {
      id
      title
      url
    }
  }
`
export type ICreateKnowledgeSourceMutationFn = Apollo.MutationFunction<
  ICreateKnowledgeSourceMutation,
  ICreateKnowledgeSourceMutationVariables
>

/**
 * __useCreateKnowledgeSourceMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeSourceMutation, { data, loading, error }] = useCreateKnowledgeSourceMutation({
 *   variables: {
 *      title: // value for 'title'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateKnowledgeSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateKnowledgeSourceMutation,
    ICreateKnowledgeSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateKnowledgeSourceMutation,
    ICreateKnowledgeSourceMutationVariables
  >(CreateKnowledgeSourceDocument, options)
}
export type CreateKnowledgeSourceMutationHookResult = ReturnType<
  typeof useCreateKnowledgeSourceMutation
>
export type CreateKnowledgeSourceMutationResult =
  Apollo.MutationResult<ICreateKnowledgeSourceMutation>
export type CreateKnowledgeSourceMutationOptions = Apollo.BaseMutationOptions<
  ICreateKnowledgeSourceMutation,
  ICreateKnowledgeSourceMutationVariables
>
export const CreateKnowledgeTagDocument = gql`
  mutation createKnowledgeTag($value: String!) {
    createKnowledgeTag(value: $value) {
      id
      value
    }
  }
`
export type ICreateKnowledgeTagMutationFn = Apollo.MutationFunction<
  ICreateKnowledgeTagMutation,
  ICreateKnowledgeTagMutationVariables
>

/**
 * __useCreateKnowledgeTagMutation__
 *
 * To run a mutation, you first call `useCreateKnowledgeTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKnowledgeTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKnowledgeTagMutation, { data, loading, error }] = useCreateKnowledgeTagMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateKnowledgeTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateKnowledgeTagMutation,
    ICreateKnowledgeTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateKnowledgeTagMutation, ICreateKnowledgeTagMutationVariables>(
    CreateKnowledgeTagDocument,
    options
  )
}
export type CreateKnowledgeTagMutationHookResult = ReturnType<typeof useCreateKnowledgeTagMutation>
export type CreateKnowledgeTagMutationResult = Apollo.MutationResult<ICreateKnowledgeTagMutation>
export type CreateKnowledgeTagMutationOptions = Apollo.BaseMutationOptions<
  ICreateKnowledgeTagMutation,
  ICreateKnowledgeTagMutationVariables
>
export const CreateOrderDocument = gql`
  mutation createOrder(
    $userId: ID!
    $product: String!
    $productType: String!
    $quantity: Int!
    $sensorKind: UserSensorKind
    $notes: String
    $invoiceOrderId: ID
  ) {
    createOrder(
      userId: $userId
      product: $product
      productType: $productType
      sensorKind: $sensorKind
      quantity: $quantity
      notes: $notes
      invoiceOrderId: $invoiceOrderId
    ) {
      ...CoreOrderFields
    }
  }
  ${CoreOrderFieldsFragmentDoc}
`
export type ICreateOrderMutationFn = Apollo.MutationFunction<
  ICreateOrderMutation,
  ICreateOrderMutationVariables
>

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      product: // value for 'product'
 *      productType: // value for 'productType'
 *      quantity: // value for 'quantity'
 *      sensorKind: // value for 'sensorKind'
 *      notes: // value for 'notes'
 *      invoiceOrderId: // value for 'invoiceOrderId'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateOrderMutation, ICreateOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateOrderMutation, ICreateOrderMutationVariables>(
    CreateOrderDocument,
    options
  )
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>
export type CreateOrderMutationResult = Apollo.MutationResult<ICreateOrderMutation>
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
  ICreateOrderMutation,
  ICreateOrderMutationVariables
>
export const CreateReplacementRequestDocument = gql`
  mutation createReplacementRequest(
    $chargeAmount: ReplacementRequestChargeAmount!
    $daysWorn: Int
    $dateFellOff: String!
    $dateNotified: String!
    $errorType: ReplacementRequestErrorType!
    $howItFellOff: String
    $notes: String
    $replacementReason: ReplacementRequestReplacementReason!
    $sensorId: ID
    $testedWithLibreApp: ReplacementRequestTestedWithLibreApp
    $wearingPatch: ReplacementRequestWearingPatch!
  ) {
    createReplacementRequest(
      chargeAmount: $chargeAmount
      daysWorn: $daysWorn
      dateFellOff: $dateFellOff
      dateNotified: $dateNotified
      errorType: $errorType
      howItFellOff: $howItFellOff
      notes: $notes
      replacementReason: $replacementReason
      sensorId: $sensorId
      testedWithLibreApp: $testedWithLibreApp
      wearingPatch: $wearingPatch
    ) {
      ...CoreReplacementRequestFields
    }
  }
  ${CoreReplacementRequestFieldsFragmentDoc}
`
export type ICreateReplacementRequestMutationFn = Apollo.MutationFunction<
  ICreateReplacementRequestMutation,
  ICreateReplacementRequestMutationVariables
>

/**
 * __useCreateReplacementRequestMutation__
 *
 * To run a mutation, you first call `useCreateReplacementRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReplacementRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReplacementRequestMutation, { data, loading, error }] = useCreateReplacementRequestMutation({
 *   variables: {
 *      chargeAmount: // value for 'chargeAmount'
 *      daysWorn: // value for 'daysWorn'
 *      dateFellOff: // value for 'dateFellOff'
 *      dateNotified: // value for 'dateNotified'
 *      errorType: // value for 'errorType'
 *      howItFellOff: // value for 'howItFellOff'
 *      notes: // value for 'notes'
 *      replacementReason: // value for 'replacementReason'
 *      sensorId: // value for 'sensorId'
 *      testedWithLibreApp: // value for 'testedWithLibreApp'
 *      wearingPatch: // value for 'wearingPatch'
 *   },
 * });
 */
export function useCreateReplacementRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateReplacementRequestMutation,
    ICreateReplacementRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateReplacementRequestMutation,
    ICreateReplacementRequestMutationVariables
  >(CreateReplacementRequestDocument, options)
}
export type CreateReplacementRequestMutationHookResult = ReturnType<
  typeof useCreateReplacementRequestMutation
>
export type CreateReplacementRequestMutationResult =
  Apollo.MutationResult<ICreateReplacementRequestMutation>
export type CreateReplacementRequestMutationOptions = Apollo.BaseMutationOptions<
  ICreateReplacementRequestMutation,
  ICreateReplacementRequestMutationVariables
>
export const CreateReplacementRequestInvoiceDocument = gql`
  mutation createReplacementRequestInvoice($id: ID) {
    createReplacementRequestInvoice(id: $id) {
      ...CoreReplacementRequestFields
    }
  }
  ${CoreReplacementRequestFieldsFragmentDoc}
`
export type ICreateReplacementRequestInvoiceMutationFn = Apollo.MutationFunction<
  ICreateReplacementRequestInvoiceMutation,
  ICreateReplacementRequestInvoiceMutationVariables
>

/**
 * __useCreateReplacementRequestInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateReplacementRequestInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReplacementRequestInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReplacementRequestInvoiceMutation, { data, loading, error }] = useCreateReplacementRequestInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateReplacementRequestInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateReplacementRequestInvoiceMutation,
    ICreateReplacementRequestInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateReplacementRequestInvoiceMutation,
    ICreateReplacementRequestInvoiceMutationVariables
  >(CreateReplacementRequestInvoiceDocument, options)
}
export type CreateReplacementRequestInvoiceMutationHookResult = ReturnType<
  typeof useCreateReplacementRequestInvoiceMutation
>
export type CreateReplacementRequestInvoiceMutationResult =
  Apollo.MutationResult<ICreateReplacementRequestInvoiceMutation>
export type CreateReplacementRequestInvoiceMutationOptions = Apollo.BaseMutationOptions<
  ICreateReplacementRequestInvoiceMutation,
  ICreateReplacementRequestInvoiceMutationVariables
>
export const CreateShipmentDocument = gql`
  mutation createShipment(
    $orderId: Int!
    $status: String
    $quantity: Int!
    $trackingNumber: String
    $trackingUrl: String
    $provider: String
    $providerShipmentId: ID
    $providerReceivedAt: ISO8601DateTime
    $shippedAt: ISO8601DateTime
    $deliveredAt: ISO8601DateTime
    $firstName: String
    $lastName: String
    $street: String
    $street2: String
    $city: String
    $zipCode: String
    $state: String
    $country: String
  ) {
    createShipment(
      orderId: $orderId
      status: $status
      quantity: $quantity
      trackingNumber: $trackingNumber
      trackingUrl: $trackingUrl
      provider: $provider
      providerShipmentId: $providerShipmentId
      providerReceivedAt: $providerReceivedAt
      shippedAt: $shippedAt
      deliveredAt: $deliveredAt
      firstName: $firstName
      lastName: $lastName
      street: $street
      street2: $street2
      city: $city
      zipCode: $zipCode
      state: $state
      country: $country
    ) {
      ...CoreShipmentFields
      order {
        id
        invoice {
          stripeId
        }
        createdAt
        product
        productType
        sensorKind
        quantity
      }
      address {
        ...CoreAddressFields
      }
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        dateOfBirth
        sex
        truepillPatientToken
        activeRefillConsent {
          givenAt
        }
      }
    }
  }
  ${CoreShipmentFieldsFragmentDoc}
  ${CoreAddressFieldsFragmentDoc}
`
export type ICreateShipmentMutationFn = Apollo.MutationFunction<
  ICreateShipmentMutation,
  ICreateShipmentMutationVariables
>

/**
 * __useCreateShipmentMutation__
 *
 * To run a mutation, you first call `useCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentMutation, { data, loading, error }] = useCreateShipmentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      status: // value for 'status'
 *      quantity: // value for 'quantity'
 *      trackingNumber: // value for 'trackingNumber'
 *      trackingUrl: // value for 'trackingUrl'
 *      provider: // value for 'provider'
 *      providerShipmentId: // value for 'providerShipmentId'
 *      providerReceivedAt: // value for 'providerReceivedAt'
 *      shippedAt: // value for 'shippedAt'
 *      deliveredAt: // value for 'deliveredAt'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      street: // value for 'street'
 *      street2: // value for 'street2'
 *      city: // value for 'city'
 *      zipCode: // value for 'zipCode'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCreateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateShipmentMutation,
    ICreateShipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateShipmentMutation, ICreateShipmentMutationVariables>(
    CreateShipmentDocument,
    options
  )
}
export type CreateShipmentMutationHookResult = ReturnType<typeof useCreateShipmentMutation>
export type CreateShipmentMutationResult = Apollo.MutationResult<ICreateShipmentMutation>
export type CreateShipmentMutationOptions = Apollo.BaseMutationOptions<
  ICreateShipmentMutation,
  ICreateShipmentMutationVariables
>
export const UpsertDailyActionDocument = gql`
  mutation upsertDailyAction($kind: UserDailyActionKind!, $status: UserDailyActionStatus!) {
    upsertDailyAction(kind: $kind, status: $status) {
      id
      title
      description
      status
      kind
      autocomplete
      category
      createdAt
      updatedAt
    }
  }
`
export type IUpsertDailyActionMutationFn = Apollo.MutationFunction<
  IUpsertDailyActionMutation,
  IUpsertDailyActionMutationVariables
>

/**
 * __useUpsertDailyActionMutation__
 *
 * To run a mutation, you first call `useUpsertDailyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDailyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDailyActionMutation, { data, loading, error }] = useUpsertDailyActionMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpsertDailyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpsertDailyActionMutation,
    IUpsertDailyActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpsertDailyActionMutation, IUpsertDailyActionMutationVariables>(
    UpsertDailyActionDocument,
    options
  )
}
export type UpsertDailyActionMutationHookResult = ReturnType<typeof useUpsertDailyActionMutation>
export type UpsertDailyActionMutationResult = Apollo.MutationResult<IUpsertDailyActionMutation>
export type UpsertDailyActionMutationOptions = Apollo.BaseMutationOptions<
  IUpsertDailyActionMutation,
  IUpsertDailyActionMutationVariables
>
export const DeleteChatConversationMessageTemplateDocument = gql`
  mutation deleteChatConversationMessageTemplate($id: ID!) {
    deleteChatConversationMessageTemplate(id: $id) {
      success
    }
  }
`
export type IDeleteChatConversationMessageTemplateMutationFn = Apollo.MutationFunction<
  IDeleteChatConversationMessageTemplateMutation,
  IDeleteChatConversationMessageTemplateMutationVariables
>

/**
 * __useDeleteChatConversationMessageTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteChatConversationMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatConversationMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatConversationMessageTemplateMutation, { data, loading, error }] = useDeleteChatConversationMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChatConversationMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteChatConversationMessageTemplateMutation,
    IDeleteChatConversationMessageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteChatConversationMessageTemplateMutation,
    IDeleteChatConversationMessageTemplateMutationVariables
  >(DeleteChatConversationMessageTemplateDocument, options)
}
export type DeleteChatConversationMessageTemplateMutationHookResult = ReturnType<
  typeof useDeleteChatConversationMessageTemplateMutation
>
export type DeleteChatConversationMessageTemplateMutationResult =
  Apollo.MutationResult<IDeleteChatConversationMessageTemplateMutation>
export type DeleteChatConversationMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  IDeleteChatConversationMessageTemplateMutation,
  IDeleteChatConversationMessageTemplateMutationVariables
>
export const DeleteFileDocument = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      ...CoreFileFields
    }
  }
  ${CoreFileFieldsFragmentDoc}
`
export type IDeleteFileMutationFn = Apollo.MutationFunction<
  IDeleteFileMutation,
  IDeleteFileMutationVariables
>

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteFileMutation, IDeleteFileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteFileMutation, IDeleteFileMutationVariables>(
    DeleteFileDocument,
    options
  )
}
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>
export type DeleteFileMutationResult = Apollo.MutationResult<IDeleteFileMutation>
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
  IDeleteFileMutation,
  IDeleteFileMutationVariables
>
export const DeleteKnowledgeAnswerDocument = gql`
  mutation deleteKnowledgeAnswer($id: ID!) {
    deleteKnowledgeAnswer(id: $id) {
      id
      title
      value
    }
  }
`
export type IDeleteKnowledgeAnswerMutationFn = Apollo.MutationFunction<
  IDeleteKnowledgeAnswerMutation,
  IDeleteKnowledgeAnswerMutationVariables
>

/**
 * __useDeleteKnowledgeAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeAnswerMutation, { data, loading, error }] = useDeleteKnowledgeAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteKnowledgeAnswerMutation,
    IDeleteKnowledgeAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteKnowledgeAnswerMutation,
    IDeleteKnowledgeAnswerMutationVariables
  >(DeleteKnowledgeAnswerDocument, options)
}
export type DeleteKnowledgeAnswerMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeAnswerMutation
>
export type DeleteKnowledgeAnswerMutationResult =
  Apollo.MutationResult<IDeleteKnowledgeAnswerMutation>
export type DeleteKnowledgeAnswerMutationOptions = Apollo.BaseMutationOptions<
  IDeleteKnowledgeAnswerMutation,
  IDeleteKnowledgeAnswerMutationVariables
>
export const DeleteKnowledgeQuestionDocument = gql`
  mutation deleteKnowledgeQuestion($id: ID!) {
    deleteKnowledgeQuestion(id: $id) {
      id
      value
    }
  }
`
export type IDeleteKnowledgeQuestionMutationFn = Apollo.MutationFunction<
  IDeleteKnowledgeQuestionMutation,
  IDeleteKnowledgeQuestionMutationVariables
>

/**
 * __useDeleteKnowledgeQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeQuestionMutation, { data, loading, error }] = useDeleteKnowledgeQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteKnowledgeQuestionMutation,
    IDeleteKnowledgeQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteKnowledgeQuestionMutation,
    IDeleteKnowledgeQuestionMutationVariables
  >(DeleteKnowledgeQuestionDocument, options)
}
export type DeleteKnowledgeQuestionMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeQuestionMutation
>
export type DeleteKnowledgeQuestionMutationResult =
  Apollo.MutationResult<IDeleteKnowledgeQuestionMutation>
export type DeleteKnowledgeQuestionMutationOptions = Apollo.BaseMutationOptions<
  IDeleteKnowledgeQuestionMutation,
  IDeleteKnowledgeQuestionMutationVariables
>
export const DeleteKnowledgeSourceDocument = gql`
  mutation deleteKnowledgeSource($id: ID!) {
    deleteKnowledgeSource(id: $id) {
      id
      url
    }
  }
`
export type IDeleteKnowledgeSourceMutationFn = Apollo.MutationFunction<
  IDeleteKnowledgeSourceMutation,
  IDeleteKnowledgeSourceMutationVariables
>

/**
 * __useDeleteKnowledgeSourceMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeSourceMutation, { data, loading, error }] = useDeleteKnowledgeSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteKnowledgeSourceMutation,
    IDeleteKnowledgeSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteKnowledgeSourceMutation,
    IDeleteKnowledgeSourceMutationVariables
  >(DeleteKnowledgeSourceDocument, options)
}
export type DeleteKnowledgeSourceMutationHookResult = ReturnType<
  typeof useDeleteKnowledgeSourceMutation
>
export type DeleteKnowledgeSourceMutationResult =
  Apollo.MutationResult<IDeleteKnowledgeSourceMutation>
export type DeleteKnowledgeSourceMutationOptions = Apollo.BaseMutationOptions<
  IDeleteKnowledgeSourceMutation,
  IDeleteKnowledgeSourceMutationVariables
>
export const DeleteKnowledgeTagDocument = gql`
  mutation deleteKnowledgeTag($id: ID!) {
    deleteKnowledgeTag(id: $id) {
      id
      value
    }
  }
`
export type IDeleteKnowledgeTagMutationFn = Apollo.MutationFunction<
  IDeleteKnowledgeTagMutation,
  IDeleteKnowledgeTagMutationVariables
>

/**
 * __useDeleteKnowledgeTagMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeTagMutation, { data, loading, error }] = useDeleteKnowledgeTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteKnowledgeTagMutation,
    IDeleteKnowledgeTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteKnowledgeTagMutation, IDeleteKnowledgeTagMutationVariables>(
    DeleteKnowledgeTagDocument,
    options
  )
}
export type DeleteKnowledgeTagMutationHookResult = ReturnType<typeof useDeleteKnowledgeTagMutation>
export type DeleteKnowledgeTagMutationResult = Apollo.MutationResult<IDeleteKnowledgeTagMutation>
export type DeleteKnowledgeTagMutationOptions = Apollo.BaseMutationOptions<
  IDeleteKnowledgeTagMutation,
  IDeleteKnowledgeTagMutationVariables
>
export const DeleteMealDocument = gql`
  mutation deleteMeal($id: ID) {
    deleteMeal(id: $id) {
      id
    }
  }
`
export type IDeleteMealMutationFn = Apollo.MutationFunction<
  IDeleteMealMutation,
  IDeleteMealMutationVariables
>

/**
 * __useDeleteMealMutation__
 *
 * To run a mutation, you first call `useDeleteMealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMealMutation, { data, loading, error }] = useDeleteMealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMealMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteMealMutation, IDeleteMealMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteMealMutation, IDeleteMealMutationVariables>(
    DeleteMealDocument,
    options
  )
}
export type DeleteMealMutationHookResult = ReturnType<typeof useDeleteMealMutation>
export type DeleteMealMutationResult = Apollo.MutationResult<IDeleteMealMutation>
export type DeleteMealMutationOptions = Apollo.BaseMutationOptions<
  IDeleteMealMutation,
  IDeleteMealMutationVariables
>
export const DeleteNoteDocument = gql`
  mutation deleteNote($id: ID) {
    deleteNote(id: $id) {
      body
      createdAt
      creatorId
      id
      occurredAt
      title
    }
  }
`
export type IDeleteNoteMutationFn = Apollo.MutationFunction<
  IDeleteNoteMutation,
  IDeleteNoteMutationVariables
>

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteNoteMutation, IDeleteNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteNoteMutation, IDeleteNoteMutationVariables>(
    DeleteNoteDocument,
    options
  )
}
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>
export type DeleteNoteMutationResult = Apollo.MutationResult<IDeleteNoteMutation>
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<
  IDeleteNoteMutation,
  IDeleteNoteMutationVariables
>
export const DeleteOrderDocument = gql`
  mutation deleteOrder($id: Int!, $deletionReason: String!) {
    deleteOrder(id: $id, deletionReason: $deletionReason) {
      ...CoreOrderFields
    }
  }
  ${CoreOrderFieldsFragmentDoc}
`
export type IDeleteOrderMutationFn = Apollo.MutationFunction<
  IDeleteOrderMutation,
  IDeleteOrderMutationVariables
>

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletionReason: // value for 'deletionReason'
 *   },
 * });
 */
export function useDeleteOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteOrderMutation, IDeleteOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteOrderMutation, IDeleteOrderMutationVariables>(
    DeleteOrderDocument,
    options
  )
}
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>
export type DeleteOrderMutationResult = Apollo.MutationResult<IDeleteOrderMutation>
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<
  IDeleteOrderMutation,
  IDeleteOrderMutationVariables
>
export const DeleteReplacementRequestDocument = gql`
  mutation deleteReplacementRequest($id: ID) {
    deleteReplacementRequest(id: $id) {
      ...CoreReplacementRequestFields
    }
  }
  ${CoreReplacementRequestFieldsFragmentDoc}
`
export type IDeleteReplacementRequestMutationFn = Apollo.MutationFunction<
  IDeleteReplacementRequestMutation,
  IDeleteReplacementRequestMutationVariables
>

/**
 * __useDeleteReplacementRequestMutation__
 *
 * To run a mutation, you first call `useDeleteReplacementRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReplacementRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReplacementRequestMutation, { data, loading, error }] = useDeleteReplacementRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReplacementRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteReplacementRequestMutation,
    IDeleteReplacementRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteReplacementRequestMutation,
    IDeleteReplacementRequestMutationVariables
  >(DeleteReplacementRequestDocument, options)
}
export type DeleteReplacementRequestMutationHookResult = ReturnType<
  typeof useDeleteReplacementRequestMutation
>
export type DeleteReplacementRequestMutationResult =
  Apollo.MutationResult<IDeleteReplacementRequestMutation>
export type DeleteReplacementRequestMutationOptions = Apollo.BaseMutationOptions<
  IDeleteReplacementRequestMutation,
  IDeleteReplacementRequestMutationVariables
>
export const DeleteShipmentDocument = gql`
  mutation deleteShipment($id: Int) {
    deleteShipment(id: $id) {
      ...CoreShipmentFields
    }
  }
  ${CoreShipmentFieldsFragmentDoc}
`
export type IDeleteShipmentMutationFn = Apollo.MutationFunction<
  IDeleteShipmentMutation,
  IDeleteShipmentMutationVariables
>

/**
 * __useDeleteShipmentMutation__
 *
 * To run a mutation, you first call `useDeleteShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShipmentMutation, { data, loading, error }] = useDeleteShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteShipmentMutation,
    IDeleteShipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteShipmentMutation, IDeleteShipmentMutationVariables>(
    DeleteShipmentDocument,
    options
  )
}
export type DeleteShipmentMutationHookResult = ReturnType<typeof useDeleteShipmentMutation>
export type DeleteShipmentMutationResult = Apollo.MutationResult<IDeleteShipmentMutation>
export type DeleteShipmentMutationOptions = Apollo.BaseMutationOptions<
  IDeleteShipmentMutation,
  IDeleteShipmentMutationVariables
>
export const DeleteUserPropertyDocument = gql`
  mutation deleteUserProperty($id: ID!) {
    deleteUserProperty(id: $id) {
      id
    }
  }
`
export type IDeleteUserPropertyMutationFn = Apollo.MutationFunction<
  IDeleteUserPropertyMutation,
  IDeleteUserPropertyMutationVariables
>

/**
 * __useDeleteUserPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteUserPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserPropertyMutation, { data, loading, error }] = useDeleteUserPropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteUserPropertyMutation,
    IDeleteUserPropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteUserPropertyMutation, IDeleteUserPropertyMutationVariables>(
    DeleteUserPropertyDocument,
    options
  )
}
export type DeleteUserPropertyMutationHookResult = ReturnType<typeof useDeleteUserPropertyMutation>
export type DeleteUserPropertyMutationResult = Apollo.MutationResult<IDeleteUserPropertyMutation>
export type DeleteUserPropertyMutationOptions = Apollo.BaseMutationOptions<
  IDeleteUserPropertyMutation,
  IDeleteUserPropertyMutationVariables
>
export const StopDexcomResearchParticipationDocument = gql`
  mutation stopDexcomResearchParticipation($userId: ID!) {
    stopDexcomResearchParticipation(userId: $userId) {
      success
    }
  }
`
export type IStopDexcomResearchParticipationMutationFn = Apollo.MutationFunction<
  IStopDexcomResearchParticipationMutation,
  IStopDexcomResearchParticipationMutationVariables
>

/**
 * __useStopDexcomResearchParticipationMutation__
 *
 * To run a mutation, you first call `useStopDexcomResearchParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopDexcomResearchParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopDexcomResearchParticipationMutation, { data, loading, error }] = useStopDexcomResearchParticipationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStopDexcomResearchParticipationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IStopDexcomResearchParticipationMutation,
    IStopDexcomResearchParticipationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IStopDexcomResearchParticipationMutation,
    IStopDexcomResearchParticipationMutationVariables
  >(StopDexcomResearchParticipationDocument, options)
}
export type StopDexcomResearchParticipationMutationHookResult = ReturnType<
  typeof useStopDexcomResearchParticipationMutation
>
export type StopDexcomResearchParticipationMutationResult =
  Apollo.MutationResult<IStopDexcomResearchParticipationMutation>
export type StopDexcomResearchParticipationMutationOptions = Apollo.BaseMutationOptions<
  IStopDexcomResearchParticipationMutation,
  IStopDexcomResearchParticipationMutationVariables
>
export const InvalidateRefillConsentDocument = gql`
  mutation invalidateRefillConsent {
    invalidateRefillConsent {
      id
      givenAt
      invalidatedAt
    }
  }
`
export type IInvalidateRefillConsentMutationFn = Apollo.MutationFunction<
  IInvalidateRefillConsentMutation,
  IInvalidateRefillConsentMutationVariables
>

/**
 * __useInvalidateRefillConsentMutation__
 *
 * To run a mutation, you first call `useInvalidateRefillConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateRefillConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateRefillConsentMutation, { data, loading, error }] = useInvalidateRefillConsentMutation({
 *   variables: {
 *   },
 * });
 */
export function useInvalidateRefillConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IInvalidateRefillConsentMutation,
    IInvalidateRefillConsentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IInvalidateRefillConsentMutation,
    IInvalidateRefillConsentMutationVariables
  >(InvalidateRefillConsentDocument, options)
}
export type InvalidateRefillConsentMutationHookResult = ReturnType<
  typeof useInvalidateRefillConsentMutation
>
export type InvalidateRefillConsentMutationResult =
  Apollo.MutationResult<IInvalidateRefillConsentMutation>
export type InvalidateRefillConsentMutationOptions = Apollo.BaseMutationOptions<
  IInvalidateRefillConsentMutation,
  IInvalidateRefillConsentMutationVariables
>
export const LinkKnowledgeAnswerDocument = gql`
  mutation linkKnowledgeAnswer($id: ID!, $knowledgeSourceId: ID!) {
    linkKnowledgeAnswer(id: $id, knowledgeSourceId: $knowledgeSourceId) {
      ...CoreKnowledgeAnswerFields
    }
  }
  ${CoreKnowledgeAnswerFieldsFragmentDoc}
`
export type ILinkKnowledgeAnswerMutationFn = Apollo.MutationFunction<
  ILinkKnowledgeAnswerMutation,
  ILinkKnowledgeAnswerMutationVariables
>

/**
 * __useLinkKnowledgeAnswerMutation__
 *
 * To run a mutation, you first call `useLinkKnowledgeAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkKnowledgeAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkKnowledgeAnswerMutation, { data, loading, error }] = useLinkKnowledgeAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      knowledgeSourceId: // value for 'knowledgeSourceId'
 *   },
 * });
 */
export function useLinkKnowledgeAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILinkKnowledgeAnswerMutation,
    ILinkKnowledgeAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ILinkKnowledgeAnswerMutation, ILinkKnowledgeAnswerMutationVariables>(
    LinkKnowledgeAnswerDocument,
    options
  )
}
export type LinkKnowledgeAnswerMutationHookResult = ReturnType<
  typeof useLinkKnowledgeAnswerMutation
>
export type LinkKnowledgeAnswerMutationResult = Apollo.MutationResult<ILinkKnowledgeAnswerMutation>
export type LinkKnowledgeAnswerMutationOptions = Apollo.BaseMutationOptions<
  ILinkKnowledgeAnswerMutation,
  ILinkKnowledgeAnswerMutationVariables
>
export const LinkKnowledgeQuestionDocument = gql`
  mutation linkKnowledgeQuestion($id: ID!, $knowledgeAnswerId: ID!) {
    linkKnowledgeQuestion(id: $id, knowledgeAnswerId: $knowledgeAnswerId) {
      ...CoreKnowledgeQuestionFields
    }
  }
  ${CoreKnowledgeQuestionFieldsFragmentDoc}
`
export type ILinkKnowledgeQuestionMutationFn = Apollo.MutationFunction<
  ILinkKnowledgeQuestionMutation,
  ILinkKnowledgeQuestionMutationVariables
>

/**
 * __useLinkKnowledgeQuestionMutation__
 *
 * To run a mutation, you first call `useLinkKnowledgeQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkKnowledgeQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkKnowledgeQuestionMutation, { data, loading, error }] = useLinkKnowledgeQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      knowledgeAnswerId: // value for 'knowledgeAnswerId'
 *   },
 * });
 */
export function useLinkKnowledgeQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILinkKnowledgeQuestionMutation,
    ILinkKnowledgeQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ILinkKnowledgeQuestionMutation,
    ILinkKnowledgeQuestionMutationVariables
  >(LinkKnowledgeQuestionDocument, options)
}
export type LinkKnowledgeQuestionMutationHookResult = ReturnType<
  typeof useLinkKnowledgeQuestionMutation
>
export type LinkKnowledgeQuestionMutationResult =
  Apollo.MutationResult<ILinkKnowledgeQuestionMutation>
export type LinkKnowledgeQuestionMutationOptions = Apollo.BaseMutationOptions<
  ILinkKnowledgeQuestionMutation,
  ILinkKnowledgeQuestionMutationVariables
>
export const PromoteToCoachChatConversationDocument = gql`
  mutation promoteToCoachChatConversation($id: ID!) {
    promoteToCoachChatConversation(id: $id) {
      success
    }
  }
`
export type IPromoteToCoachChatConversationMutationFn = Apollo.MutationFunction<
  IPromoteToCoachChatConversationMutation,
  IPromoteToCoachChatConversationMutationVariables
>

/**
 * __usePromoteToCoachChatConversationMutation__
 *
 * To run a mutation, you first call `usePromoteToCoachChatConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteToCoachChatConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteToCoachChatConversationMutation, { data, loading, error }] = usePromoteToCoachChatConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePromoteToCoachChatConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IPromoteToCoachChatConversationMutation,
    IPromoteToCoachChatConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IPromoteToCoachChatConversationMutation,
    IPromoteToCoachChatConversationMutationVariables
  >(PromoteToCoachChatConversationDocument, options)
}
export type PromoteToCoachChatConversationMutationHookResult = ReturnType<
  typeof usePromoteToCoachChatConversationMutation
>
export type PromoteToCoachChatConversationMutationResult =
  Apollo.MutationResult<IPromoteToCoachChatConversationMutation>
export type PromoteToCoachChatConversationMutationOptions = Apollo.BaseMutationOptions<
  IPromoteToCoachChatConversationMutation,
  IPromoteToCoachChatConversationMutationVariables
>
export const RemoveKnowledgeQuestionTagDocument = gql`
  mutation removeKnowledgeQuestionTag($id: ID!, $knowledgeTagId: ID!) {
    removeKnowledgeQuestionTag(id: $id, knowledgeTagId: $knowledgeTagId) {
      ...CoreKnowledgeQuestionFields
    }
  }
  ${CoreKnowledgeQuestionFieldsFragmentDoc}
`
export type IRemoveKnowledgeQuestionTagMutationFn = Apollo.MutationFunction<
  IRemoveKnowledgeQuestionTagMutation,
  IRemoveKnowledgeQuestionTagMutationVariables
>

/**
 * __useRemoveKnowledgeQuestionTagMutation__
 *
 * To run a mutation, you first call `useRemoveKnowledgeQuestionTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKnowledgeQuestionTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKnowledgeQuestionTagMutation, { data, loading, error }] = useRemoveKnowledgeQuestionTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      knowledgeTagId: // value for 'knowledgeTagId'
 *   },
 * });
 */
export function useRemoveKnowledgeQuestionTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRemoveKnowledgeQuestionTagMutation,
    IRemoveKnowledgeQuestionTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IRemoveKnowledgeQuestionTagMutation,
    IRemoveKnowledgeQuestionTagMutationVariables
  >(RemoveKnowledgeQuestionTagDocument, options)
}
export type RemoveKnowledgeQuestionTagMutationHookResult = ReturnType<
  typeof useRemoveKnowledgeQuestionTagMutation
>
export type RemoveKnowledgeQuestionTagMutationResult =
  Apollo.MutationResult<IRemoveKnowledgeQuestionTagMutation>
export type RemoveKnowledgeQuestionTagMutationOptions = Apollo.BaseMutationOptions<
  IRemoveKnowledgeQuestionTagMutation,
  IRemoveKnowledgeQuestionTagMutationVariables
>
export const ResendMissingPrescriptionsShipmentsDocument = gql`
  mutation resendMissingPrescriptionsShipments {
    resendMissingPrescriptionsShipments {
      shipments {
        ...CoreShipmentFields
      }
      totalRecords
    }
  }
  ${CoreShipmentFieldsFragmentDoc}
`
export type IResendMissingPrescriptionsShipmentsMutationFn = Apollo.MutationFunction<
  IResendMissingPrescriptionsShipmentsMutation,
  IResendMissingPrescriptionsShipmentsMutationVariables
>

/**
 * __useResendMissingPrescriptionsShipmentsMutation__
 *
 * To run a mutation, you first call `useResendMissingPrescriptionsShipmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMissingPrescriptionsShipmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMissingPrescriptionsShipmentsMutation, { data, loading, error }] = useResendMissingPrescriptionsShipmentsMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendMissingPrescriptionsShipmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IResendMissingPrescriptionsShipmentsMutation,
    IResendMissingPrescriptionsShipmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IResendMissingPrescriptionsShipmentsMutation,
    IResendMissingPrescriptionsShipmentsMutationVariables
  >(ResendMissingPrescriptionsShipmentsDocument, options)
}
export type ResendMissingPrescriptionsShipmentsMutationHookResult = ReturnType<
  typeof useResendMissingPrescriptionsShipmentsMutation
>
export type ResendMissingPrescriptionsShipmentsMutationResult =
  Apollo.MutationResult<IResendMissingPrescriptionsShipmentsMutation>
export type ResendMissingPrescriptionsShipmentsMutationOptions = Apollo.BaseMutationOptions<
  IResendMissingPrescriptionsShipmentsMutation,
  IResendMissingPrescriptionsShipmentsMutationVariables
>
export const ResendShipmentDocument = gql`
  mutation resendShipment($id: Int) {
    resendShipment(id: $id) {
      ...CoreShipmentFields
    }
  }
  ${CoreShipmentFieldsFragmentDoc}
`
export type IResendShipmentMutationFn = Apollo.MutationFunction<
  IResendShipmentMutation,
  IResendShipmentMutationVariables
>

/**
 * __useResendShipmentMutation__
 *
 * To run a mutation, you first call `useResendShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendShipmentMutation, { data, loading, error }] = useResendShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IResendShipmentMutation,
    IResendShipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IResendShipmentMutation, IResendShipmentMutationVariables>(
    ResendShipmentDocument,
    options
  )
}
export type ResendShipmentMutationHookResult = ReturnType<typeof useResendShipmentMutation>
export type ResendShipmentMutationResult = Apollo.MutationResult<IResendShipmentMutation>
export type ResendShipmentMutationOptions = Apollo.BaseMutationOptions<
  IResendShipmentMutation,
  IResendShipmentMutationVariables
>
export const RestartSurveyLinkDocument = gql`
  mutation restartSurveyLink($id: ID!, $questionKeys: [RestartSurveyLinkQuestionKeys!]) {
    restartSurveyLink(id: $id, questionKeys: $questionKeys) {
      id
      state
    }
  }
`
export type IRestartSurveyLinkMutationFn = Apollo.MutationFunction<
  IRestartSurveyLinkMutation,
  IRestartSurveyLinkMutationVariables
>

/**
 * __useRestartSurveyLinkMutation__
 *
 * To run a mutation, you first call `useRestartSurveyLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartSurveyLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartSurveyLinkMutation, { data, loading, error }] = useRestartSurveyLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionKeys: // value for 'questionKeys'
 *   },
 * });
 */
export function useRestartSurveyLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRestartSurveyLinkMutation,
    IRestartSurveyLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IRestartSurveyLinkMutation, IRestartSurveyLinkMutationVariables>(
    RestartSurveyLinkDocument,
    options
  )
}
export type RestartSurveyLinkMutationHookResult = ReturnType<typeof useRestartSurveyLinkMutation>
export type RestartSurveyLinkMutationResult = Apollo.MutationResult<IRestartSurveyLinkMutation>
export type RestartSurveyLinkMutationOptions = Apollo.BaseMutationOptions<
  IRestartSurveyLinkMutation,
  IRestartSurveyLinkMutationVariables
>
export const SendChatConversationMessageDocument = gql`
  mutation sendChatConversationMessage($userId: ID!, $body: String!, $conversationId: ID) {
    sendChatConversationMessage(userId: $userId, body: $body, conversationId: $conversationId) {
      success
    }
  }
`
export type ISendChatConversationMessageMutationFn = Apollo.MutationFunction<
  ISendChatConversationMessageMutation,
  ISendChatConversationMessageMutationVariables
>

/**
 * __useSendChatConversationMessageMutation__
 *
 * To run a mutation, you first call `useSendChatConversationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatConversationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatConversationMessageMutation, { data, loading, error }] = useSendChatConversationMessageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      body: // value for 'body'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useSendChatConversationMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISendChatConversationMessageMutation,
    ISendChatConversationMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISendChatConversationMessageMutation,
    ISendChatConversationMessageMutationVariables
  >(SendChatConversationMessageDocument, options)
}
export type SendChatConversationMessageMutationHookResult = ReturnType<
  typeof useSendChatConversationMessageMutation
>
export type SendChatConversationMessageMutationResult =
  Apollo.MutationResult<ISendChatConversationMessageMutation>
export type SendChatConversationMessageMutationOptions = Apollo.BaseMutationOptions<
  ISendChatConversationMessageMutation,
  ISendChatConversationMessageMutationVariables
>
export const SetPrimaryGoalMetricsDocument = gql`
  mutation setPrimaryGoalMetrics($kinds: [UserGoalMetricKind!]) {
    setPrimaryGoalMetrics(kinds: $kinds) {
      primary
      kind
      lowThresholdValue
      highThresholdValue
      section
      statKey
    }
  }
`
export type ISetPrimaryGoalMetricsMutationFn = Apollo.MutationFunction<
  ISetPrimaryGoalMetricsMutation,
  ISetPrimaryGoalMetricsMutationVariables
>

/**
 * __useSetPrimaryGoalMetricsMutation__
 *
 * To run a mutation, you first call `useSetPrimaryGoalMetricsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryGoalMetricsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryGoalMetricsMutation, { data, loading, error }] = useSetPrimaryGoalMetricsMutation({
 *   variables: {
 *      kinds: // value for 'kinds'
 *   },
 * });
 */
export function useSetPrimaryGoalMetricsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISetPrimaryGoalMetricsMutation,
    ISetPrimaryGoalMetricsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISetPrimaryGoalMetricsMutation,
    ISetPrimaryGoalMetricsMutationVariables
  >(SetPrimaryGoalMetricsDocument, options)
}
export type SetPrimaryGoalMetricsMutationHookResult = ReturnType<
  typeof useSetPrimaryGoalMetricsMutation
>
export type SetPrimaryGoalMetricsMutationResult =
  Apollo.MutationResult<ISetPrimaryGoalMetricsMutation>
export type SetPrimaryGoalMetricsMutationOptions = Apollo.BaseMutationOptions<
  ISetPrimaryGoalMetricsMutation,
  ISetPrimaryGoalMetricsMutationVariables
>
export const SignInThirdPartyUserDocument = gql`
  mutation signInThirdPartyUser($provider: ThirdPartySignInProvider!, $providerToken: String!) {
    signInThirdPartyUser(provider: $provider, providerToken: $providerToken) {
      role
      token
      user {
        id
      }
    }
  }
`
export type ISignInThirdPartyUserMutationFn = Apollo.MutationFunction<
  ISignInThirdPartyUserMutation,
  ISignInThirdPartyUserMutationVariables
>

/**
 * __useSignInThirdPartyUserMutation__
 *
 * To run a mutation, you first call `useSignInThirdPartyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInThirdPartyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInThirdPartyUserMutation, { data, loading, error }] = useSignInThirdPartyUserMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      providerToken: // value for 'providerToken'
 *   },
 * });
 */
export function useSignInThirdPartyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISignInThirdPartyUserMutation,
    ISignInThirdPartyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISignInThirdPartyUserMutation, ISignInThirdPartyUserMutationVariables>(
    SignInThirdPartyUserDocument,
    options
  )
}
export type SignInThirdPartyUserMutationHookResult = ReturnType<
  typeof useSignInThirdPartyUserMutation
>
export type SignInThirdPartyUserMutationResult =
  Apollo.MutationResult<ISignInThirdPartyUserMutation>
export type SignInThirdPartyUserMutationOptions = Apollo.BaseMutationOptions<
  ISignInThirdPartyUserMutation,
  ISignInThirdPartyUserMutationVariables
>
export const SignInUserDocument = gql`
  mutation signInUser($email: String, $password: String) {
    signInUser(email: $email, password: $password) {
      role
      token
      user {
        id
      }
    }
  }
`
export type ISignInUserMutationFn = Apollo.MutationFunction<
  ISignInUserMutation,
  ISignInUserMutationVariables
>

/**
 * __useSignInUserMutation__
 *
 * To run a mutation, you first call `useSignInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserMutation, { data, loading, error }] = useSignInUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ISignInUserMutation, ISignInUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISignInUserMutation, ISignInUserMutationVariables>(
    SignInUserDocument,
    options
  )
}
export type SignInUserMutationHookResult = ReturnType<typeof useSignInUserMutation>
export type SignInUserMutationResult = Apollo.MutationResult<ISignInUserMutation>
export type SignInUserMutationOptions = Apollo.BaseMutationOptions<
  ISignInUserMutation,
  ISignInUserMutationVariables
>
export const SignOutUserDocument = gql`
  mutation signOutUser {
    signOutUser {
      success
    }
  }
`
export type ISignOutUserMutationFn = Apollo.MutationFunction<
  ISignOutUserMutation,
  ISignOutUserMutationVariables
>

/**
 * __useSignOutUserMutation__
 *
 * To run a mutation, you first call `useSignOutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutUserMutation, { data, loading, error }] = useSignOutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ISignOutUserMutation, ISignOutUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISignOutUserMutation, ISignOutUserMutationVariables>(
    SignOutUserDocument,
    options
  )
}
export type SignOutUserMutationHookResult = ReturnType<typeof useSignOutUserMutation>
export type SignOutUserMutationResult = Apollo.MutationResult<ISignOutUserMutation>
export type SignOutUserMutationOptions = Apollo.BaseMutationOptions<
  ISignOutUserMutation,
  ISignOutUserMutationVariables
>
export const CreateTicketCommentDocument = gql`
  mutation createTicketComment($ticketId: ID!, $body: String!, $mentionedAdminIds: [ID!]) {
    createTicketComment(ticketId: $ticketId, body: $body, mentionedAdminIds: $mentionedAdminIds) {
      body
      createdBy {
        id
        fullName
        email
        phoneNumber
        timeZone
      }
      createdAt
    }
  }
`
export type ICreateTicketCommentMutationFn = Apollo.MutationFunction<
  ICreateTicketCommentMutation,
  ICreateTicketCommentMutationVariables
>

/**
 * __useCreateTicketCommentMutation__
 *
 * To run a mutation, you first call `useCreateTicketCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketCommentMutation, { data, loading, error }] = useCreateTicketCommentMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      body: // value for 'body'
 *      mentionedAdminIds: // value for 'mentionedAdminIds'
 *   },
 * });
 */
export function useCreateTicketCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateTicketCommentMutation,
    ICreateTicketCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateTicketCommentMutation, ICreateTicketCommentMutationVariables>(
    CreateTicketCommentDocument,
    options
  )
}
export type CreateTicketCommentMutationHookResult = ReturnType<
  typeof useCreateTicketCommentMutation
>
export type CreateTicketCommentMutationResult = Apollo.MutationResult<ICreateTicketCommentMutation>
export type CreateTicketCommentMutationOptions = Apollo.BaseMutationOptions<
  ICreateTicketCommentMutation,
  ICreateTicketCommentMutationVariables
>
export const CreateTicketDocument = gql`
  mutation createTicket(
    $userId: ID!
    $description: String!
    $kind: TicketKind!
    $reason: TicketReason!
    $priority: TicketPriority!
  ) {
    createTicket(
      userId: $userId
      description: $description
      kind: $kind
      reason: $reason
      priority: $priority
    ) {
      ...CoreTicketFields
    }
  }
  ${CoreTicketFieldsFragmentDoc}
`
export type ICreateTicketMutationFn = Apollo.MutationFunction<
  ICreateTicketMutation,
  ICreateTicketMutationVariables
>

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      description: // value for 'description'
 *      kind: // value for 'kind'
 *      reason: // value for 'reason'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useCreateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateTicketMutation, ICreateTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateTicketMutation, ICreateTicketMutationVariables>(
    CreateTicketDocument,
    options
  )
}
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>
export type CreateTicketMutationResult = Apollo.MutationResult<ICreateTicketMutation>
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<
  ICreateTicketMutation,
  ICreateTicketMutationVariables
>
export const DeleteTicketDocument = gql`
  mutation deleteTicket($id: ID!) {
    deleteTicket(id: $id) {
      ...CoreTicketFields
    }
  }
  ${CoreTicketFieldsFragmentDoc}
`
export type IDeleteTicketMutationFn = Apollo.MutationFunction<
  IDeleteTicketMutation,
  IDeleteTicketMutationVariables
>

/**
 * __useDeleteTicketMutation__
 *
 * To run a mutation, you first call `useDeleteTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketMutation, { data, loading, error }] = useDeleteTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteTicketMutation, IDeleteTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteTicketMutation, IDeleteTicketMutationVariables>(
    DeleteTicketDocument,
    options
  )
}
export type DeleteTicketMutationHookResult = ReturnType<typeof useDeleteTicketMutation>
export type DeleteTicketMutationResult = Apollo.MutationResult<IDeleteTicketMutation>
export type DeleteTicketMutationOptions = Apollo.BaseMutationOptions<
  IDeleteTicketMutation,
  IDeleteTicketMutationVariables
>
export const UpdateTicketDocument = gql`
  mutation updateTicket(
    $id: ID!
    $assignedToId: ID
    $description: String
    $status: TicketStatus
    $priority: TicketPriority
    $externalLink: String
  ) {
    updateTicket(
      id: $id
      assignedToId: $assignedToId
      description: $description
      status: $status
      priority: $priority
      externalLink: $externalLink
    ) {
      ...CoreTicketFields
    }
  }
  ${CoreTicketFieldsFragmentDoc}
`
export type IUpdateTicketMutationFn = Apollo.MutationFunction<
  IUpdateTicketMutation,
  IUpdateTicketMutationVariables
>

/**
 * __useUpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketMutation, { data, loading, error }] = useUpdateTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assignedToId: // value for 'assignedToId'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      priority: // value for 'priority'
 *      externalLink: // value for 'externalLink'
 *   },
 * });
 */
export function useUpdateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateTicketMutation, IUpdateTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateTicketMutation, IUpdateTicketMutationVariables>(
    UpdateTicketDocument,
    options
  )
}
export type UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicketMutation>
export type UpdateTicketMutationResult = Apollo.MutationResult<IUpdateTicketMutation>
export type UpdateTicketMutationOptions = Apollo.BaseMutationOptions<
  IUpdateTicketMutation,
  IUpdateTicketMutationVariables
>
export const UnlinkKnowledgeAnswerDocument = gql`
  mutation unlinkKnowledgeAnswer($id: ID!, $knowledgeSourceId: ID!) {
    unlinkKnowledgeAnswer(id: $id, knowledgeSourceId: $knowledgeSourceId) {
      ...CoreKnowledgeAnswerFields
    }
  }
  ${CoreKnowledgeAnswerFieldsFragmentDoc}
`
export type IUnlinkKnowledgeAnswerMutationFn = Apollo.MutationFunction<
  IUnlinkKnowledgeAnswerMutation,
  IUnlinkKnowledgeAnswerMutationVariables
>

/**
 * __useUnlinkKnowledgeAnswerMutation__
 *
 * To run a mutation, you first call `useUnlinkKnowledgeAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkKnowledgeAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkKnowledgeAnswerMutation, { data, loading, error }] = useUnlinkKnowledgeAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      knowledgeSourceId: // value for 'knowledgeSourceId'
 *   },
 * });
 */
export function useUnlinkKnowledgeAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUnlinkKnowledgeAnswerMutation,
    IUnlinkKnowledgeAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUnlinkKnowledgeAnswerMutation,
    IUnlinkKnowledgeAnswerMutationVariables
  >(UnlinkKnowledgeAnswerDocument, options)
}
export type UnlinkKnowledgeAnswerMutationHookResult = ReturnType<
  typeof useUnlinkKnowledgeAnswerMutation
>
export type UnlinkKnowledgeAnswerMutationResult =
  Apollo.MutationResult<IUnlinkKnowledgeAnswerMutation>
export type UnlinkKnowledgeAnswerMutationOptions = Apollo.BaseMutationOptions<
  IUnlinkKnowledgeAnswerMutation,
  IUnlinkKnowledgeAnswerMutationVariables
>
export const UnlinkKnowledgeQuestionDocument = gql`
  mutation unlinkKnowledgeQuestion($id: ID!, $knowledgeAnswerId: ID!) {
    unlinkKnowledgeQuestion(id: $id, knowledgeAnswerId: $knowledgeAnswerId) {
      ...CoreKnowledgeQuestionFields
    }
  }
  ${CoreKnowledgeQuestionFieldsFragmentDoc}
`
export type IUnlinkKnowledgeQuestionMutationFn = Apollo.MutationFunction<
  IUnlinkKnowledgeQuestionMutation,
  IUnlinkKnowledgeQuestionMutationVariables
>

/**
 * __useUnlinkKnowledgeQuestionMutation__
 *
 * To run a mutation, you first call `useUnlinkKnowledgeQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkKnowledgeQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkKnowledgeQuestionMutation, { data, loading, error }] = useUnlinkKnowledgeQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      knowledgeAnswerId: // value for 'knowledgeAnswerId'
 *   },
 * });
 */
export function useUnlinkKnowledgeQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUnlinkKnowledgeQuestionMutation,
    IUnlinkKnowledgeQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUnlinkKnowledgeQuestionMutation,
    IUnlinkKnowledgeQuestionMutationVariables
  >(UnlinkKnowledgeQuestionDocument, options)
}
export type UnlinkKnowledgeQuestionMutationHookResult = ReturnType<
  typeof useUnlinkKnowledgeQuestionMutation
>
export type UnlinkKnowledgeQuestionMutationResult =
  Apollo.MutationResult<IUnlinkKnowledgeQuestionMutation>
export type UnlinkKnowledgeQuestionMutationOptions = Apollo.BaseMutationOptions<
  IUnlinkKnowledgeQuestionMutation,
  IUnlinkKnowledgeQuestionMutationVariables
>
export const UpdateAddressDocument = gql`
  mutation updateAddress(
    $id: ID
    $street: String
    $street2: String
    $city: String
    $name: String
    $state: AddressState
    $country: AddressCountry
    $zipCode: String
  ) {
    updateAddress(
      id: $id
      street: $street
      street2: $street2
      city: $city
      name: $name
      state: $state
      country: $country
      zipCode: $zipCode
    ) {
      ...CoreAddressFields
    }
  }
  ${CoreAddressFieldsFragmentDoc}
`
export type IUpdateAddressMutationFn = Apollo.MutationFunction<
  IUpdateAddressMutation,
  IUpdateAddressMutationVariables
>

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      street: // value for 'street'
 *      street2: // value for 'street2'
 *      city: // value for 'city'
 *      name: // value for 'name'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateAddressMutation, IUpdateAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateAddressMutation, IUpdateAddressMutationVariables>(
    UpdateAddressDocument,
    options
  )
}
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>
export type UpdateAddressMutationResult = Apollo.MutationResult<IUpdateAddressMutation>
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAddressMutation,
  IUpdateAddressMutationVariables
>
export const UpdateChatConversationDocument = gql`
  mutation updateChatConversation($id: ID, $read: Boolean) {
    updateChatConversation(id: $id, read: $read) {
      ...CoreChatConversationFields
    }
  }
  ${CoreChatConversationFieldsFragmentDoc}
`
export type IUpdateChatConversationMutationFn = Apollo.MutationFunction<
  IUpdateChatConversationMutation,
  IUpdateChatConversationMutationVariables
>

/**
 * __useUpdateChatConversationMutation__
 *
 * To run a mutation, you first call `useUpdateChatConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatConversationMutation, { data, loading, error }] = useUpdateChatConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useUpdateChatConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateChatConversationMutation,
    IUpdateChatConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateChatConversationMutation,
    IUpdateChatConversationMutationVariables
  >(UpdateChatConversationDocument, options)
}
export type UpdateChatConversationMutationHookResult = ReturnType<
  typeof useUpdateChatConversationMutation
>
export type UpdateChatConversationMutationResult =
  Apollo.MutationResult<IUpdateChatConversationMutation>
export type UpdateChatConversationMutationOptions = Apollo.BaseMutationOptions<
  IUpdateChatConversationMutation,
  IUpdateChatConversationMutationVariables
>
export const UpdateChatConversationMessageTemplateDocument = gql`
  mutation updateChatConversationMessageTemplate(
    $id: ID
    $name: String
    $value: String
    $private: Boolean
  ) {
    updateChatConversationMessageTemplate(id: $id, name: $name, value: $value, private: $private) {
      id
      name
      value
      private
      kind
    }
  }
`
export type IUpdateChatConversationMessageTemplateMutationFn = Apollo.MutationFunction<
  IUpdateChatConversationMessageTemplateMutation,
  IUpdateChatConversationMessageTemplateMutationVariables
>

/**
 * __useUpdateChatConversationMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateChatConversationMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatConversationMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatConversationMessageTemplateMutation, { data, loading, error }] = useUpdateChatConversationMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useUpdateChatConversationMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateChatConversationMessageTemplateMutation,
    IUpdateChatConversationMessageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateChatConversationMessageTemplateMutation,
    IUpdateChatConversationMessageTemplateMutationVariables
  >(UpdateChatConversationMessageTemplateDocument, options)
}
export type UpdateChatConversationMessageTemplateMutationHookResult = ReturnType<
  typeof useUpdateChatConversationMessageTemplateMutation
>
export type UpdateChatConversationMessageTemplateMutationResult =
  Apollo.MutationResult<IUpdateChatConversationMessageTemplateMutation>
export type UpdateChatConversationMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  IUpdateChatConversationMessageTemplateMutation,
  IUpdateChatConversationMessageTemplateMutationVariables
>
export const UpdateCoachConversationScheduledMessageDocument = gql`
  mutation updateCoachConversationScheduledMessage(
    $scheduledMessageId: ID!
    $state: CoachesConversationScheduledMessageState
    $sendAt: ISO8601DateTime
    $value: String
  ) {
    updateCoachConversationScheduledMessage(
      scheduledMessageId: $scheduledMessageId
      state: $state
      sendAt: $sendAt
      value: $value
    ) {
      id
      kind
      state
      sendAt
      sentAt
    }
  }
`
export type IUpdateCoachConversationScheduledMessageMutationFn = Apollo.MutationFunction<
  IUpdateCoachConversationScheduledMessageMutation,
  IUpdateCoachConversationScheduledMessageMutationVariables
>

/**
 * __useUpdateCoachConversationScheduledMessageMutation__
 *
 * To run a mutation, you first call `useUpdateCoachConversationScheduledMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachConversationScheduledMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachConversationScheduledMessageMutation, { data, loading, error }] = useUpdateCoachConversationScheduledMessageMutation({
 *   variables: {
 *      scheduledMessageId: // value for 'scheduledMessageId'
 *      state: // value for 'state'
 *      sendAt: // value for 'sendAt'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateCoachConversationScheduledMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateCoachConversationScheduledMessageMutation,
    IUpdateCoachConversationScheduledMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateCoachConversationScheduledMessageMutation,
    IUpdateCoachConversationScheduledMessageMutationVariables
  >(UpdateCoachConversationScheduledMessageDocument, options)
}
export type UpdateCoachConversationScheduledMessageMutationHookResult = ReturnType<
  typeof useUpdateCoachConversationScheduledMessageMutation
>
export type UpdateCoachConversationScheduledMessageMutationResult =
  Apollo.MutationResult<IUpdateCoachConversationScheduledMessageMutation>
export type UpdateCoachConversationScheduledMessageMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCoachConversationScheduledMessageMutation,
  IUpdateCoachConversationScheduledMessageMutationVariables
>
export const UpdateFulfillmentConfigurationDocument = gql`
  mutation UpdateFulfillmentConfiguration($userId: ID!, $sensorKind: String!) {
    updateFulfillmentConfiguration(userId: $userId, sensorKind: $sensorKind) {
      userId
      sensorKind
    }
  }
`
export type IUpdateFulfillmentConfigurationMutationFn = Apollo.MutationFunction<
  IUpdateFulfillmentConfigurationMutation,
  IUpdateFulfillmentConfigurationMutationVariables
>

/**
 * __useUpdateFulfillmentConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateFulfillmentConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFulfillmentConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFulfillmentConfigurationMutation, { data, loading, error }] = useUpdateFulfillmentConfigurationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      sensorKind: // value for 'sensorKind'
 *   },
 * });
 */
export function useUpdateFulfillmentConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateFulfillmentConfigurationMutation,
    IUpdateFulfillmentConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateFulfillmentConfigurationMutation,
    IUpdateFulfillmentConfigurationMutationVariables
  >(UpdateFulfillmentConfigurationDocument, options)
}
export type UpdateFulfillmentConfigurationMutationHookResult = ReturnType<
  typeof useUpdateFulfillmentConfigurationMutation
>
export type UpdateFulfillmentConfigurationMutationResult =
  Apollo.MutationResult<IUpdateFulfillmentConfigurationMutation>
export type UpdateFulfillmentConfigurationMutationOptions = Apollo.BaseMutationOptions<
  IUpdateFulfillmentConfigurationMutation,
  IUpdateFulfillmentConfigurationMutationVariables
>
export const UpdateGoalMetricDocument = gql`
  mutation updateGoalMetric(
    $kind: UserGoalMetricKind!
    $lowThresholdValue: Float
    $highThresholdValue: Float
  ) {
    updateGoalMetric(
      kind: $kind
      lowThresholdValue: $lowThresholdValue
      highThresholdValue: $highThresholdValue
    ) {
      primary
      kind
      lowThresholdValue
      highThresholdValue
      section
      statKey
    }
  }
`
export type IUpdateGoalMetricMutationFn = Apollo.MutationFunction<
  IUpdateGoalMetricMutation,
  IUpdateGoalMetricMutationVariables
>

/**
 * __useUpdateGoalMetricMutation__
 *
 * To run a mutation, you first call `useUpdateGoalMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMetricMutation, { data, loading, error }] = useUpdateGoalMetricMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      lowThresholdValue: // value for 'lowThresholdValue'
 *      highThresholdValue: // value for 'highThresholdValue'
 *   },
 * });
 */
export function useUpdateGoalMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateGoalMetricMutation,
    IUpdateGoalMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateGoalMetricMutation, IUpdateGoalMetricMutationVariables>(
    UpdateGoalMetricDocument,
    options
  )
}
export type UpdateGoalMetricMutationHookResult = ReturnType<typeof useUpdateGoalMetricMutation>
export type UpdateGoalMetricMutationResult = Apollo.MutationResult<IUpdateGoalMetricMutation>
export type UpdateGoalMetricMutationOptions = Apollo.BaseMutationOptions<
  IUpdateGoalMetricMutation,
  IUpdateGoalMetricMutationVariables
>
export const UpdateInvoiceOrderDocument = gql`
  mutation updateInvoiceOrder($id: ID!, $state: InvoiceOrderState, $stateReason: String) {
    updateInvoiceOrder(id: $id, state: $state, stateReason: $stateReason) {
      ...CoreInvoiceOrderFields
    }
  }
  ${CoreInvoiceOrderFieldsFragmentDoc}
`
export type IUpdateInvoiceOrderMutationFn = Apollo.MutationFunction<
  IUpdateInvoiceOrderMutation,
  IUpdateInvoiceOrderMutationVariables
>

/**
 * __useUpdateInvoiceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceOrderMutation, { data, loading, error }] = useUpdateInvoiceOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      stateReason: // value for 'stateReason'
 *   },
 * });
 */
export function useUpdateInvoiceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateInvoiceOrderMutation,
    IUpdateInvoiceOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateInvoiceOrderMutation, IUpdateInvoiceOrderMutationVariables>(
    UpdateInvoiceOrderDocument,
    options
  )
}
export type UpdateInvoiceOrderMutationHookResult = ReturnType<typeof useUpdateInvoiceOrderMutation>
export type UpdateInvoiceOrderMutationResult = Apollo.MutationResult<IUpdateInvoiceOrderMutation>
export type UpdateInvoiceOrderMutationOptions = Apollo.BaseMutationOptions<
  IUpdateInvoiceOrderMutation,
  IUpdateInvoiceOrderMutationVariables
>
export const UpdateKnowledgeAnswerDocument = gql`
  mutation updateKnowledgeAnswer($id: ID!, $title: String, $value: String) {
    updateKnowledgeAnswer(id: $id, title: $title, value: $value) {
      ...CoreKnowledgeAnswerFields
    }
  }
  ${CoreKnowledgeAnswerFieldsFragmentDoc}
`
export type IUpdateKnowledgeAnswerMutationFn = Apollo.MutationFunction<
  IUpdateKnowledgeAnswerMutation,
  IUpdateKnowledgeAnswerMutationVariables
>

/**
 * __useUpdateKnowledgeAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeAnswerMutation, { data, loading, error }] = useUpdateKnowledgeAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateKnowledgeAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateKnowledgeAnswerMutation,
    IUpdateKnowledgeAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateKnowledgeAnswerMutation,
    IUpdateKnowledgeAnswerMutationVariables
  >(UpdateKnowledgeAnswerDocument, options)
}
export type UpdateKnowledgeAnswerMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeAnswerMutation
>
export type UpdateKnowledgeAnswerMutationResult =
  Apollo.MutationResult<IUpdateKnowledgeAnswerMutation>
export type UpdateKnowledgeAnswerMutationOptions = Apollo.BaseMutationOptions<
  IUpdateKnowledgeAnswerMutation,
  IUpdateKnowledgeAnswerMutationVariables
>
export const UpdateKnowledgeQuestionDocument = gql`
  mutation updateKnowledgeQuestion($id: ID!, $value: String!) {
    updateKnowledgeQuestion(id: $id, value: $value) {
      ...CoreKnowledgeQuestionFields
    }
  }
  ${CoreKnowledgeQuestionFieldsFragmentDoc}
`
export type IUpdateKnowledgeQuestionMutationFn = Apollo.MutationFunction<
  IUpdateKnowledgeQuestionMutation,
  IUpdateKnowledgeQuestionMutationVariables
>

/**
 * __useUpdateKnowledgeQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeQuestionMutation, { data, loading, error }] = useUpdateKnowledgeQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateKnowledgeQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateKnowledgeQuestionMutation,
    IUpdateKnowledgeQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateKnowledgeQuestionMutation,
    IUpdateKnowledgeQuestionMutationVariables
  >(UpdateKnowledgeQuestionDocument, options)
}
export type UpdateKnowledgeQuestionMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeQuestionMutation
>
export type UpdateKnowledgeQuestionMutationResult =
  Apollo.MutationResult<IUpdateKnowledgeQuestionMutation>
export type UpdateKnowledgeQuestionMutationOptions = Apollo.BaseMutationOptions<
  IUpdateKnowledgeQuestionMutation,
  IUpdateKnowledgeQuestionMutationVariables
>
export const UpdateKnowledgeSourceDocument = gql`
  mutation updateKnowledgeSource($id: ID!, $title: String, $url: String) {
    updateKnowledgeSource(id: $id, title: $title, url: $url) {
      id
      title
      url
    }
  }
`
export type IUpdateKnowledgeSourceMutationFn = Apollo.MutationFunction<
  IUpdateKnowledgeSourceMutation,
  IUpdateKnowledgeSourceMutationVariables
>

/**
 * __useUpdateKnowledgeSourceMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeSourceMutation, { data, loading, error }] = useUpdateKnowledgeSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUpdateKnowledgeSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateKnowledgeSourceMutation,
    IUpdateKnowledgeSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateKnowledgeSourceMutation,
    IUpdateKnowledgeSourceMutationVariables
  >(UpdateKnowledgeSourceDocument, options)
}
export type UpdateKnowledgeSourceMutationHookResult = ReturnType<
  typeof useUpdateKnowledgeSourceMutation
>
export type UpdateKnowledgeSourceMutationResult =
  Apollo.MutationResult<IUpdateKnowledgeSourceMutation>
export type UpdateKnowledgeSourceMutationOptions = Apollo.BaseMutationOptions<
  IUpdateKnowledgeSourceMutation,
  IUpdateKnowledgeSourceMutationVariables
>
export const UpdateKnowledgeTagDocument = gql`
  mutation updateKnowledgeTag($id: ID!, $value: String!) {
    updateKnowledgeTag(id: $id, value: $value) {
      id
      value
    }
  }
`
export type IUpdateKnowledgeTagMutationFn = Apollo.MutationFunction<
  IUpdateKnowledgeTagMutation,
  IUpdateKnowledgeTagMutationVariables
>

/**
 * __useUpdateKnowledgeTagMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeTagMutation, { data, loading, error }] = useUpdateKnowledgeTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateKnowledgeTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateKnowledgeTagMutation,
    IUpdateKnowledgeTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateKnowledgeTagMutation, IUpdateKnowledgeTagMutationVariables>(
    UpdateKnowledgeTagDocument,
    options
  )
}
export type UpdateKnowledgeTagMutationHookResult = ReturnType<typeof useUpdateKnowledgeTagMutation>
export type UpdateKnowledgeTagMutationResult = Apollo.MutationResult<IUpdateKnowledgeTagMutation>
export type UpdateKnowledgeTagMutationOptions = Apollo.BaseMutationOptions<
  IUpdateKnowledgeTagMutation,
  IUpdateKnowledgeTagMutationVariables
>
export const UpdateOrderDocument = gql`
  mutation updateOrder(
    $id: Int!
    $notes: String
    $email: String
    $product: String
    $productType: String
    $sensorKind: UserSensorKind
    $quantity: Int
  ) {
    updateOrder(
      id: $id
      notes: $notes
      email: $email
      product: $product
      productType: $productType
      sensorKind: $sensorKind
      quantity: $quantity
    ) {
      ...CoreOrderFields
    }
  }
  ${CoreOrderFieldsFragmentDoc}
`
export type IUpdateOrderMutationFn = Apollo.MutationFunction<
  IUpdateOrderMutation,
  IUpdateOrderMutationVariables
>

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *      email: // value for 'email'
 *      product: // value for 'product'
 *      productType: // value for 'productType'
 *      sensorKind: // value for 'sensorKind'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateOrderMutation, IUpdateOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateOrderMutation, IUpdateOrderMutationVariables>(
    UpdateOrderDocument,
    options
  )
}
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>
export type UpdateOrderMutationResult = Apollo.MutationResult<IUpdateOrderMutation>
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  IUpdateOrderMutation,
  IUpdateOrderMutationVariables
>
export const UpdateReplacementRequestDocument = gql`
  mutation updateReplacementRequest(
    $id: ID!
    $chargeAmount: ReplacementRequestChargeAmount
    $daysWorn: Int
    $dateFellOff: String
    $dateNotified: String
    $errorType: ReplacementRequestErrorType
    $howItFellOff: String
    $notes: String
    $replacementReason: ReplacementRequestReplacementReason
    $testedWithLibreApp: ReplacementRequestTestedWithLibreApp
    $wearingPatch: ReplacementRequestWearingPatch
  ) {
    updateReplacementRequest(
      id: $id
      chargeAmount: $chargeAmount
      daysWorn: $daysWorn
      dateFellOff: $dateFellOff
      dateNotified: $dateNotified
      errorType: $errorType
      howItFellOff: $howItFellOff
      notes: $notes
      replacementReason: $replacementReason
      testedWithLibreApp: $testedWithLibreApp
      wearingPatch: $wearingPatch
    ) {
      ...CoreReplacementRequestFields
    }
  }
  ${CoreReplacementRequestFieldsFragmentDoc}
`
export type IUpdateReplacementRequestMutationFn = Apollo.MutationFunction<
  IUpdateReplacementRequestMutation,
  IUpdateReplacementRequestMutationVariables
>

/**
 * __useUpdateReplacementRequestMutation__
 *
 * To run a mutation, you first call `useUpdateReplacementRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReplacementRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReplacementRequestMutation, { data, loading, error }] = useUpdateReplacementRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chargeAmount: // value for 'chargeAmount'
 *      daysWorn: // value for 'daysWorn'
 *      dateFellOff: // value for 'dateFellOff'
 *      dateNotified: // value for 'dateNotified'
 *      errorType: // value for 'errorType'
 *      howItFellOff: // value for 'howItFellOff'
 *      notes: // value for 'notes'
 *      replacementReason: // value for 'replacementReason'
 *      testedWithLibreApp: // value for 'testedWithLibreApp'
 *      wearingPatch: // value for 'wearingPatch'
 *   },
 * });
 */
export function useUpdateReplacementRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateReplacementRequestMutation,
    IUpdateReplacementRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateReplacementRequestMutation,
    IUpdateReplacementRequestMutationVariables
  >(UpdateReplacementRequestDocument, options)
}
export type UpdateReplacementRequestMutationHookResult = ReturnType<
  typeof useUpdateReplacementRequestMutation
>
export type UpdateReplacementRequestMutationResult =
  Apollo.MutationResult<IUpdateReplacementRequestMutation>
export type UpdateReplacementRequestMutationOptions = Apollo.BaseMutationOptions<
  IUpdateReplacementRequestMutation,
  IUpdateReplacementRequestMutationVariables
>
export const UpdateSensorDocument = gql`
  mutation updateSensor($baselineAdjustment: Int!) {
    updateSensor(baselineAdjustment: $baselineAdjustment) {
      serialNumber
      activationDate
      expirationDate
      expirationTime
      daysRemaining
      baselineAdjustment
    }
  }
`
export type IUpdateSensorMutationFn = Apollo.MutationFunction<
  IUpdateSensorMutation,
  IUpdateSensorMutationVariables
>

/**
 * __useUpdateSensorMutation__
 *
 * To run a mutation, you first call `useUpdateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorMutation, { data, loading, error }] = useUpdateSensorMutation({
 *   variables: {
 *      baselineAdjustment: // value for 'baselineAdjustment'
 *   },
 * });
 */
export function useUpdateSensorMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateSensorMutation, IUpdateSensorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateSensorMutation, IUpdateSensorMutationVariables>(
    UpdateSensorDocument,
    options
  )
}
export type UpdateSensorMutationHookResult = ReturnType<typeof useUpdateSensorMutation>
export type UpdateSensorMutationResult = Apollo.MutationResult<IUpdateSensorMutation>
export type UpdateSensorMutationOptions = Apollo.BaseMutationOptions<
  IUpdateSensorMutation,
  IUpdateSensorMutationVariables
>
export const UpdateShipmentDocument = gql`
  mutation updateShipment(
    $id: Int!
    $status: String
    $trackingNumber: String
    $trackingUrl: String
    $providerShipmentId: ID
    $provider: String
    $providerReceivedAt: ISO8601DateTime
    $quantity: Int
    $shippedAt: ISO8601DateTime
    $deliveredAt: ISO8601DateTime
  ) {
    updateShipment(
      id: $id
      status: $status
      trackingNumber: $trackingNumber
      trackingUrl: $trackingUrl
      providerShipmentId: $providerShipmentId
      provider: $provider
      providerReceivedAt: $providerReceivedAt
      quantity: $quantity
      shippedAt: $shippedAt
      deliveredAt: $deliveredAt
    ) {
      ...CoreShipmentFields
    }
  }
  ${CoreShipmentFieldsFragmentDoc}
`
export type IUpdateShipmentMutationFn = Apollo.MutationFunction<
  IUpdateShipmentMutation,
  IUpdateShipmentMutationVariables
>

/**
 * __useUpdateShipmentMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentMutation, { data, loading, error }] = useUpdateShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      trackingNumber: // value for 'trackingNumber'
 *      trackingUrl: // value for 'trackingUrl'
 *      providerShipmentId: // value for 'providerShipmentId'
 *      provider: // value for 'provider'
 *      providerReceivedAt: // value for 'providerReceivedAt'
 *      quantity: // value for 'quantity'
 *      shippedAt: // value for 'shippedAt'
 *      deliveredAt: // value for 'deliveredAt'
 *   },
 * });
 */
export function useUpdateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateShipmentMutation,
    IUpdateShipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateShipmentMutation, IUpdateShipmentMutationVariables>(
    UpdateShipmentDocument,
    options
  )
}
export type UpdateShipmentMutationHookResult = ReturnType<typeof useUpdateShipmentMutation>
export type UpdateShipmentMutationResult = Apollo.MutationResult<IUpdateShipmentMutation>
export type UpdateShipmentMutationOptions = Apollo.BaseMutationOptions<
  IUpdateShipmentMutation,
  IUpdateShipmentMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser(
    $id: ID
    $firstName: String
    $lastName: String
    $kind: String
    $sex: String
    $phoneNumber: String
    $dateOfBirth: String
    $email: String
    $truepillPatientToken: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      kind: $kind
      sex: $sex
      phoneNumber: $phoneNumber
      dateOfBirth: $dateOfBirth
      email: $email
      truepillPatientToken: $truepillPatientToken
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type IUpdateUserMutationFn = Apollo.MutationFunction<
  IUpdateUserMutation,
  IUpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      kind: // value for 'kind'
 *      sex: // value for 'sex'
 *      phoneNumber: // value for 'phoneNumber'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      truepillPatientToken: // value for 'truepillPatientToken'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateUserMutation, IUpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateUserMutation, IUpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<IUpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  IUpdateUserMutation,
  IUpdateUserMutationVariables
>
export const UpdateUserApprovalStatusDocument = gql`
  mutation updateUserApprovalStatus(
    $id: ID!
    $approvalStatus: UpdateUserApprovalStatuses!
    $kind: UserApprovalRequestKind
  ) {
    updateUserApprovalStatus(id: $id, approvalStatus: $approvalStatus, kind: $kind) {
      id
    }
  }
`
export type IUpdateUserApprovalStatusMutationFn = Apollo.MutationFunction<
  IUpdateUserApprovalStatusMutation,
  IUpdateUserApprovalStatusMutationVariables
>

/**
 * __useUpdateUserApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserApprovalStatusMutation, { data, loading, error }] = useUpdateUserApprovalStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approvalStatus: // value for 'approvalStatus'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useUpdateUserApprovalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateUserApprovalStatusMutation,
    IUpdateUserApprovalStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateUserApprovalStatusMutation,
    IUpdateUserApprovalStatusMutationVariables
  >(UpdateUserApprovalStatusDocument, options)
}
export type UpdateUserApprovalStatusMutationHookResult = ReturnType<
  typeof useUpdateUserApprovalStatusMutation
>
export type UpdateUserApprovalStatusMutationResult =
  Apollo.MutationResult<IUpdateUserApprovalStatusMutation>
export type UpdateUserApprovalStatusMutationOptions = Apollo.BaseMutationOptions<
  IUpdateUserApprovalStatusMutation,
  IUpdateUserApprovalStatusMutationVariables
>
export const UpdateUserOrganizationDocument = gql`
  mutation updateUserOrganization($id: ID!, $organizationId: Int!) {
    updateUserOrganization(id: $id, organizationId: $organizationId) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type IUpdateUserOrganizationMutationFn = Apollo.MutationFunction<
  IUpdateUserOrganizationMutation,
  IUpdateUserOrganizationMutationVariables
>

/**
 * __useUpdateUserOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateUserOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOrganizationMutation, { data, loading, error }] = useUpdateUserOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateUserOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateUserOrganizationMutation,
    IUpdateUserOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateUserOrganizationMutation,
    IUpdateUserOrganizationMutationVariables
  >(UpdateUserOrganizationDocument, options)
}
export type UpdateUserOrganizationMutationHookResult = ReturnType<
  typeof useUpdateUserOrganizationMutation
>
export type UpdateUserOrganizationMutationResult =
  Apollo.MutationResult<IUpdateUserOrganizationMutation>
export type UpdateUserOrganizationMutationOptions = Apollo.BaseMutationOptions<
  IUpdateUserOrganizationMutation,
  IUpdateUserOrganizationMutationVariables
>
export const UpdateUserPropertyDocument = gql`
  mutation updateUserProperty($userId: ID, $kind: String!, $value: String!, $id: ID) {
    updateUserProperty(userId: $userId, kind: $kind, value: $value, id: $id) {
      id
      kind
      value
      userId
    }
  }
`
export type IUpdateUserPropertyMutationFn = Apollo.MutationFunction<
  IUpdateUserPropertyMutation,
  IUpdateUserPropertyMutationVariables
>

/**
 * __useUpdateUserPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateUserPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPropertyMutation, { data, loading, error }] = useUpdateUserPropertyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      kind: // value for 'kind'
 *      value: // value for 'value'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateUserPropertyMutation,
    IUpdateUserPropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateUserPropertyMutation, IUpdateUserPropertyMutationVariables>(
    UpdateUserPropertyDocument,
    options
  )
}
export type UpdateUserPropertyMutationHookResult = ReturnType<typeof useUpdateUserPropertyMutation>
export type UpdateUserPropertyMutationResult = Apollo.MutationResult<IUpdateUserPropertyMutation>
export type UpdateUserPropertyMutationOptions = Apollo.BaseMutationOptions<
  IUpdateUserPropertyMutation,
  IUpdateUserPropertyMutationVariables
>
export const UpdateSettingsDocument = gql`
  mutation updateSettings(
    $appVersionReminder: Boolean
    $dashboardGraph: String
    $dexcomSync: Boolean
    $displayActivityScore: Boolean
    $displayDailyScore: Boolean
    $displayMealScore: Boolean
    $fastingEnd: Int
    $fastingStart: Int
    $glucoseHighThreshold: Int
    $glucoseLowThreshold: Int
    $googleFitSync: Boolean
    $healthKitSync: Boolean
    $ketoMojoSync: Boolean
    $ketoneHighThreshold: Float
    $ketoneLowThreshold: Float
    $macroGoalsDaily: MacroGoalsDaily
    $reminders: Boolean
    $scanReminder: Int
    $showNetCarbs: Boolean
    $unitSystem: String
  ) {
    updateSettings(
      appVersionReminder: $appVersionReminder
      dashboardGraph: $dashboardGraph
      dexcomSync: $dexcomSync
      displayActivityScore: $displayActivityScore
      displayDailyScore: $displayDailyScore
      displayMealScore: $displayMealScore
      fastingEnd: $fastingEnd
      fastingStart: $fastingStart
      glucoseHighThreshold: $glucoseHighThreshold
      glucoseLowThreshold: $glucoseLowThreshold
      googleFitSync: $googleFitSync
      healthKitSync: $healthKitSync
      ketoMojoSync: $ketoMojoSync
      ketoneHighThreshold: $ketoneHighThreshold
      ketoneLowThreshold: $ketoneLowThreshold
      macroGoalsDaily: $macroGoalsDaily
      reminders: $reminders
      scanReminder: $scanReminder
      showNetCarbs: $showNetCarbs
      unitSystem: $unitSystem
    ) {
      id
      appVersionReminder
      dashboardGraph
      dexcomSync
      displayActivityScore
      displayDailyScore
      displayMealScore
      fastingEnd
      fastingStart
      glucoseHighThreshold
      glucoseLowThreshold
      googleFitSync
      healthKitSync
      ketoMojoSync
      ketoneHighThreshold
      ketoneLowThreshold
      macroGoalsDaily {
        calories
        carbs
        fat
        netCarbs
        protein
      }
      reminders
      scanReminder
      showNetCarbs
      unitSystem
    }
  }
`
export type IUpdateSettingsMutationFn = Apollo.MutationFunction<
  IUpdateSettingsMutation,
  IUpdateSettingsMutationVariables
>

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      appVersionReminder: // value for 'appVersionReminder'
 *      dashboardGraph: // value for 'dashboardGraph'
 *      dexcomSync: // value for 'dexcomSync'
 *      displayActivityScore: // value for 'displayActivityScore'
 *      displayDailyScore: // value for 'displayDailyScore'
 *      displayMealScore: // value for 'displayMealScore'
 *      fastingEnd: // value for 'fastingEnd'
 *      fastingStart: // value for 'fastingStart'
 *      glucoseHighThreshold: // value for 'glucoseHighThreshold'
 *      glucoseLowThreshold: // value for 'glucoseLowThreshold'
 *      googleFitSync: // value for 'googleFitSync'
 *      healthKitSync: // value for 'healthKitSync'
 *      ketoMojoSync: // value for 'ketoMojoSync'
 *      ketoneHighThreshold: // value for 'ketoneHighThreshold'
 *      ketoneLowThreshold: // value for 'ketoneLowThreshold'
 *      macroGoalsDaily: // value for 'macroGoalsDaily'
 *      reminders: // value for 'reminders'
 *      scanReminder: // value for 'scanReminder'
 *      showNetCarbs: // value for 'showNetCarbs'
 *      unitSystem: // value for 'unitSystem'
 *   },
 * });
 */
export function useUpdateSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateSettingsMutation,
    IUpdateSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateSettingsMutation, IUpdateSettingsMutationVariables>(
    UpdateSettingsDocument,
    options
  )
}
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>
export type UpdateSettingsMutationResult = Apollo.MutationResult<IUpdateSettingsMutation>
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateSettingsMutation,
  IUpdateSettingsMutationVariables
>
export const UpsertCoachProfileDocument = gql`
  mutation upsertCoachProfile($stateLicences: [String!], $outOfOffice: Boolean) {
    upsertCoachProfile(stateLicences: $stateLicences, outOfOffice: $outOfOffice) {
      stateLicences
      outOfOffice
    }
  }
`
export type IUpsertCoachProfileMutationFn = Apollo.MutationFunction<
  IUpsertCoachProfileMutation,
  IUpsertCoachProfileMutationVariables
>

/**
 * __useUpsertCoachProfileMutation__
 *
 * To run a mutation, you first call `useUpsertCoachProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCoachProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCoachProfileMutation, { data, loading, error }] = useUpsertCoachProfileMutation({
 *   variables: {
 *      stateLicences: // value for 'stateLicences'
 *      outOfOffice: // value for 'outOfOffice'
 *   },
 * });
 */
export function useUpsertCoachProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpsertCoachProfileMutation,
    IUpsertCoachProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpsertCoachProfileMutation, IUpsertCoachProfileMutationVariables>(
    UpsertCoachProfileDocument,
    options
  )
}
export type UpsertCoachProfileMutationHookResult = ReturnType<typeof useUpsertCoachProfileMutation>
export type UpsertCoachProfileMutationResult = Apollo.MutationResult<IUpsertCoachProfileMutation>
export type UpsertCoachProfileMutationOptions = Apollo.BaseMutationOptions<
  IUpsertCoachProfileMutation,
  IUpsertCoachProfileMutationVariables
>
export const UpsertMealDocument = gql`
  mutation upsertMeal(
    $id: ID
    $kind: MealKind
    $favorite: Boolean
    $description: String!
    $time: String!
    $ingredients: [IngredientData!]
  ) {
    upsertMeal(
      id: $id
      kind: $kind
      favorite: $favorite
      description: $description
      time: $time
      ingredients: $ingredients
    ) {
      ...AllEventsMeal
    }
  }
  ${AllEventsMealFragmentDoc}
`
export type IUpsertMealMutationFn = Apollo.MutationFunction<
  IUpsertMealMutation,
  IUpsertMealMutationVariables
>

/**
 * __useUpsertMealMutation__
 *
 * To run a mutation, you first call `useUpsertMealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMealMutation, { data, loading, error }] = useUpsertMealMutation({
 *   variables: {
 *      id: // value for 'id'
 *      kind: // value for 'kind'
 *      favorite: // value for 'favorite'
 *      description: // value for 'description'
 *      time: // value for 'time'
 *      ingredients: // value for 'ingredients'
 *   },
 * });
 */
export function useUpsertMealMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpsertMealMutation, IUpsertMealMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpsertMealMutation, IUpsertMealMutationVariables>(
    UpsertMealDocument,
    options
  )
}
export type UpsertMealMutationHookResult = ReturnType<typeof useUpsertMealMutation>
export type UpsertMealMutationResult = Apollo.MutationResult<IUpsertMealMutation>
export type UpsertMealMutationOptions = Apollo.BaseMutationOptions<
  IUpsertMealMutation,
  IUpsertMealMutationVariables
>
export const UpsertNoteDocument = gql`
  mutation upsertNote($id: ID, $body: String!, $time: String, $kind: String, $pinned: Boolean) {
    upsertNote(id: $id, body: $body, time: $time, kind: $kind, pinned: $pinned) {
      id
      body
      kind
      occurredAt
      pinnedAt
    }
  }
`
export type IUpsertNoteMutationFn = Apollo.MutationFunction<
  IUpsertNoteMutation,
  IUpsertNoteMutationVariables
>

/**
 * __useUpsertNoteMutation__
 *
 * To run a mutation, you first call `useUpsertNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNoteMutation, { data, loading, error }] = useUpsertNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *      time: // value for 'time'
 *      kind: // value for 'kind'
 *      pinned: // value for 'pinned'
 *   },
 * });
 */
export function useUpsertNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpsertNoteMutation, IUpsertNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpsertNoteMutation, IUpsertNoteMutationVariables>(
    UpsertNoteDocument,
    options
  )
}
export type UpsertNoteMutationHookResult = ReturnType<typeof useUpsertNoteMutation>
export type UpsertNoteMutationResult = Apollo.MutationResult<IUpsertNoteMutation>
export type UpsertNoteMutationOptions = Apollo.BaseMutationOptions<
  IUpsertNoteMutation,
  IUpsertNoteMutationVariables
>
export const UpsertPrimaryGoalDocument = gql`
  mutation upsertPrimaryGoal($kind: UserGoalKind!, $description: String) {
    upsertPrimaryGoal(kind: $kind, description: $description) {
      title
      kind
      description
    }
  }
`
export type IUpsertPrimaryGoalMutationFn = Apollo.MutationFunction<
  IUpsertPrimaryGoalMutation,
  IUpsertPrimaryGoalMutationVariables
>

/**
 * __useUpsertPrimaryGoalMutation__
 *
 * To run a mutation, you first call `useUpsertPrimaryGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPrimaryGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPrimaryGoalMutation, { data, loading, error }] = useUpsertPrimaryGoalMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpsertPrimaryGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpsertPrimaryGoalMutation,
    IUpsertPrimaryGoalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpsertPrimaryGoalMutation, IUpsertPrimaryGoalMutationVariables>(
    UpsertPrimaryGoalDocument,
    options
  )
}
export type UpsertPrimaryGoalMutationHookResult = ReturnType<typeof useUpsertPrimaryGoalMutation>
export type UpsertPrimaryGoalMutationResult = Apollo.MutationResult<IUpsertPrimaryGoalMutation>
export type UpsertPrimaryGoalMutationOptions = Apollo.BaseMutationOptions<
  IUpsertPrimaryGoalMutation,
  IUpsertPrimaryGoalMutationVariables
>
export const AllAccountRequestsDocument = gql`
  query AllAccountRequests(
    $page: Int
    $perPage: Int
    $sorts: [AccountRequestSort!]
    $dynamicFilters: [AccountRequestDynamicFilterItem!]
    $status: OpsAccountRequestStatus
  ) {
    allAccountRequests(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
      status: $status
    ) {
      totalCount
      accountRequests {
        ...CoreAccountRequestFields
      }
    }
  }
  ${CoreAccountRequestFieldsFragmentDoc}
`

/**
 * __useAllAccountRequestsQuery__
 *
 * To run a query within a React component, call `useAllAccountRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAccountRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAccountRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAllAccountRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllAccountRequestsQuery, IAllAccountRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllAccountRequestsQuery, IAllAccountRequestsQueryVariables>(
    AllAccountRequestsDocument,
    options
  )
}
export function useAllAccountRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllAccountRequestsQuery,
    IAllAccountRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllAccountRequestsQuery, IAllAccountRequestsQueryVariables>(
    AllAccountRequestsDocument,
    options
  )
}
export type AllAccountRequestsQueryHookResult = ReturnType<typeof useAllAccountRequestsQuery>
export type AllAccountRequestsLazyQueryHookResult = ReturnType<
  typeof useAllAccountRequestsLazyQuery
>
export type AllAccountRequestsQueryResult = Apollo.QueryResult<
  IAllAccountRequestsQuery,
  IAllAccountRequestsQueryVariables
>
export const CalculateDuplicateAccountCreditDocument = gql`
  query calculateDuplicateAccountCredit($userId: ID!) {
    calculateDuplicateAccountCredit(userId: $userId) {
      creditAmountInCents
    }
  }
`

/**
 * __useCalculateDuplicateAccountCreditQuery__
 *
 * To run a query within a React component, call `useCalculateDuplicateAccountCreditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateDuplicateAccountCreditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateDuplicateAccountCreditQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCalculateDuplicateAccountCreditQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICalculateDuplicateAccountCreditQuery,
    ICalculateDuplicateAccountCreditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICalculateDuplicateAccountCreditQuery,
    ICalculateDuplicateAccountCreditQueryVariables
  >(CalculateDuplicateAccountCreditDocument, options)
}
export function useCalculateDuplicateAccountCreditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICalculateDuplicateAccountCreditQuery,
    ICalculateDuplicateAccountCreditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICalculateDuplicateAccountCreditQuery,
    ICalculateDuplicateAccountCreditQueryVariables
  >(CalculateDuplicateAccountCreditDocument, options)
}
export type CalculateDuplicateAccountCreditQueryHookResult = ReturnType<
  typeof useCalculateDuplicateAccountCreditQuery
>
export type CalculateDuplicateAccountCreditLazyQueryHookResult = ReturnType<
  typeof useCalculateDuplicateAccountCreditLazyQuery
>
export type CalculateDuplicateAccountCreditQueryResult = Apollo.QueryResult<
  ICalculateDuplicateAccountCreditQuery,
  ICalculateDuplicateAccountCreditQueryVariables
>
export const ShowAccountRequestsConfigDocument = gql`
  query ShowAccountRequestsConfig {
    showAccountRequestsConfig {
      pauseReasons {
        key
        copy
      }
      refundReasons {
        key
        description
      }
    }
  }
`

/**
 * __useShowAccountRequestsConfigQuery__
 *
 * To run a query within a React component, call `useShowAccountRequestsConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowAccountRequestsConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowAccountRequestsConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowAccountRequestsConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IShowAccountRequestsConfigQuery,
    IShowAccountRequestsConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IShowAccountRequestsConfigQuery, IShowAccountRequestsConfigQueryVariables>(
    ShowAccountRequestsConfigDocument,
    options
  )
}
export function useShowAccountRequestsConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IShowAccountRequestsConfigQuery,
    IShowAccountRequestsConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IShowAccountRequestsConfigQuery,
    IShowAccountRequestsConfigQueryVariables
  >(ShowAccountRequestsConfigDocument, options)
}
export type ShowAccountRequestsConfigQueryHookResult = ReturnType<
  typeof useShowAccountRequestsConfigQuery
>
export type ShowAccountRequestsConfigLazyQueryHookResult = ReturnType<
  typeof useShowAccountRequestsConfigLazyQuery
>
export type ShowAccountRequestsConfigQueryResult = Apollo.QueryResult<
  IShowAccountRequestsConfigQuery,
  IShowAccountRequestsConfigQueryVariables
>
export const AdminPanelAllCoachAssignmentsDocument = gql`
  query AdminPanelAllCoachAssignments(
    $page: Int
    $perPage: Int
    $sorts: [CoachAssignmentSort!]
    $dynamicFilters: [CoachAssignmentDynamicFilterItem!]
    $last: Boolean
  ) {
    allCoachAssignments(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
      last: $last
    ) {
      totalCount
      coachAssignments {
        id
        coach {
          id
          fullName
        }
        user {
          id
          fullName
          email
          kind
          lastPrescriptionApprovalRequest {
            state
            createdAt
          }
          primaryGoal {
            title
            kind
            description
          }
          userProperties {
            testGroup {
              id
              kind
              value
            }
            oneMonthActiveFeedbackDietitian {
              id
              kind
              value
            }
            dietitianRequest {
              id
              kind
              value
            }
            scheduledVideoCallStatus {
              id
              kind
              value
            }
            healthGoal {
              id
              kind
              value
            }
            communicationFrequency {
              id
              kind
              value
            }
            dietitianSensorCountNotes {
              id
              kind
              value
            }
            nutritionistGroup {
              id
              kind
              value
            }
          }
          lastDietitianSubscription {
            stripeStatus
            status
            paused
            resumesAt
          }
          ongoingDietitianSubscription {
            id
            cancelAt
            stripeStatus
            status
            dietitianAddOn
            plans {
              nickname
            }
            primaryProduct {
              title
            }
          }
          lastCoreSubscription {
            id
            stripeStatus
            status
            latestInvoice {
              latestCharge {
                status
                created
              }
            }
          }
          sensorInfo {
            totalCount
            replacementRequestsCount
            firstStartTime
            lastSensor {
              expired
              expirationDate
            }
          }
          coachAssignments {
            ...CoreCoachAssignmentFields
          }
        }
        appointment {
          date
        }
        state
        last
        createdAt
        assignedAt
        deactivatedAt
        kind
        supportType
        dietitianMenu
      }
    }
  }
  ${CoreCoachAssignmentFieldsFragmentDoc}
`

/**
 * __useAdminPanelAllCoachAssignmentsQuery__
 *
 * To run a query within a React component, call `useAdminPanelAllCoachAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPanelAllCoachAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPanelAllCoachAssignmentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAdminPanelAllCoachAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAdminPanelAllCoachAssignmentsQuery,
    IAdminPanelAllCoachAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAdminPanelAllCoachAssignmentsQuery,
    IAdminPanelAllCoachAssignmentsQueryVariables
  >(AdminPanelAllCoachAssignmentsDocument, options)
}
export function useAdminPanelAllCoachAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAdminPanelAllCoachAssignmentsQuery,
    IAdminPanelAllCoachAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAdminPanelAllCoachAssignmentsQuery,
    IAdminPanelAllCoachAssignmentsQueryVariables
  >(AdminPanelAllCoachAssignmentsDocument, options)
}
export type AdminPanelAllCoachAssignmentsQueryHookResult = ReturnType<
  typeof useAdminPanelAllCoachAssignmentsQuery
>
export type AdminPanelAllCoachAssignmentsLazyQueryHookResult = ReturnType<
  typeof useAdminPanelAllCoachAssignmentsLazyQuery
>
export type AdminPanelAllCoachAssignmentsQueryResult = Apollo.QueryResult<
  IAdminPanelAllCoachAssignmentsQuery,
  IAdminPanelAllCoachAssignmentsQueryVariables
>
export const AdminPanelAllCoachesDocument = gql`
  query AdminPanelAllCoaches($sorts: [UserSort!], $dynamicFilters: [UserDynamicFilterItem!]) {
    allUsers(sorts: $sorts, dynamicFilters: $dynamicFilters) {
      users {
        id
        fullName
        coachAssignmentInfo {
          activeAssignmentsCount
        }
      }
    }
  }
`

/**
 * __useAdminPanelAllCoachesQuery__
 *
 * To run a query within a React component, call `useAdminPanelAllCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPanelAllCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPanelAllCoachesQuery({
 *   variables: {
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAdminPanelAllCoachesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAdminPanelAllCoachesQuery,
    IAdminPanelAllCoachesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAdminPanelAllCoachesQuery, IAdminPanelAllCoachesQueryVariables>(
    AdminPanelAllCoachesDocument,
    options
  )
}
export function useAdminPanelAllCoachesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAdminPanelAllCoachesQuery,
    IAdminPanelAllCoachesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAdminPanelAllCoachesQuery, IAdminPanelAllCoachesQueryVariables>(
    AdminPanelAllCoachesDocument,
    options
  )
}
export type AdminPanelAllCoachesQueryHookResult = ReturnType<typeof useAdminPanelAllCoachesQuery>
export type AdminPanelAllCoachesLazyQueryHookResult = ReturnType<
  typeof useAdminPanelAllCoachesLazyQuery
>
export type AdminPanelAllCoachesQueryResult = Apollo.QueryResult<
  IAdminPanelAllCoachesQuery,
  IAdminPanelAllCoachesQueryVariables
>
export const AllHealthPortalNotificationsDocument = gql`
  query AllHealthPortalNotifications(
    $page: Int
    $perPage: Int
    $sorts: [HealthPortalNotificationsSort!]
    $completed: Boolean
    $forReview: Boolean
    $kinds: [HealthPortalNotificationKind!]
    $userIds: [ID!]
  ) {
    allHealthPortalNotifications(
      page: $page
      perPage: $perPage
      sorts: $sorts
      completed: $completed
      forReview: $forReview
      kinds: $kinds
      userIds: $userIds
    ) {
      healthPortalNotifications {
        ...CoreHealthPortalNotificationFields
      }
    }
  }
  ${CoreHealthPortalNotificationFieldsFragmentDoc}
`

/**
 * __useAllHealthPortalNotificationsQuery__
 *
 * To run a query within a React component, call `useAllHealthPortalNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllHealthPortalNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllHealthPortalNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      completed: // value for 'completed'
 *      forReview: // value for 'forReview'
 *      kinds: // value for 'kinds'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAllHealthPortalNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllHealthPortalNotificationsQuery,
    IAllHealthPortalNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAllHealthPortalNotificationsQuery,
    IAllHealthPortalNotificationsQueryVariables
  >(AllHealthPortalNotificationsDocument, options)
}
export function useAllHealthPortalNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllHealthPortalNotificationsQuery,
    IAllHealthPortalNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAllHealthPortalNotificationsQuery,
    IAllHealthPortalNotificationsQueryVariables
  >(AllHealthPortalNotificationsDocument, options)
}
export type AllHealthPortalNotificationsQueryHookResult = ReturnType<
  typeof useAllHealthPortalNotificationsQuery
>
export type AllHealthPortalNotificationsLazyQueryHookResult = ReturnType<
  typeof useAllHealthPortalNotificationsLazyQuery
>
export type AllHealthPortalNotificationsQueryResult = Apollo.QueryResult<
  IAllHealthPortalNotificationsQuery,
  IAllHealthPortalNotificationsQueryVariables
>
export const AdminPanelAllUsersDocument = gql`
  query AdminPanelAllUsers(
    $page: Int
    $perPage: Int
    $sorts: [UserSort!]
    $dynamicFilters: [UserDynamicFilterItem!]
  ) {
    allUsers(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      users {
        id
        fullName
        email
        sensorInfo {
          totalCount
        }
        coachAssignments {
          kind
          last
        }
        ongoingDietitianSubscription {
          id
          cancelAt
          status
          plans {
            nickname
          }
        }
        lastDietitianSubscription {
          id
          stripeStatus
          status
        }
        primaryGoal {
          title
          kind
          description
        }
        userProperties {
          oneMonthActiveFeedbackDietitian {
            id
            kind
            value
          }
          dietitianRequest {
            id
            kind
            value
          }
          scheduledVideoCallStatus {
            id
            kind
            value
          }
          testGroup {
            id
            kind
            value
          }
          healthGoal {
            id
            kind
            value
          }
          videoCallRescheduleFreePassUsed {
            id
            kind
            value
          }
        }
      }
    }
  }
`

/**
 * __useAdminPanelAllUsersQuery__
 *
 * To run a query within a React component, call `useAdminPanelAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPanelAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPanelAllUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAdminPanelAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<IAdminPanelAllUsersQuery, IAdminPanelAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAdminPanelAllUsersQuery, IAdminPanelAllUsersQueryVariables>(
    AdminPanelAllUsersDocument,
    options
  )
}
export function useAdminPanelAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAdminPanelAllUsersQuery,
    IAdminPanelAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAdminPanelAllUsersQuery, IAdminPanelAllUsersQueryVariables>(
    AdminPanelAllUsersDocument,
    options
  )
}
export type AdminPanelAllUsersQueryHookResult = ReturnType<typeof useAdminPanelAllUsersQuery>
export type AdminPanelAllUsersLazyQueryHookResult = ReturnType<
  typeof useAdminPanelAllUsersLazyQuery
>
export type AdminPanelAllUsersQueryResult = Apollo.QueryResult<
  IAdminPanelAllUsersQuery,
  IAdminPanelAllUsersQueryVariables
>
export const AllChartsDocument = gql`
  query allCharts($filter: DateFilter) {
    allCharts(filter: $filter) {
      charts {
        type
        title
        description
        xAxis
        yAxis
        range {
          min
          max
          goal
          goalMin
          goalMax
        }
        meta {
          key
          tag
          section
        }
        values {
          ... on TimePair {
            xTimePair: x
            yTimePair: y
            interpolated
          }
          ... on NumericPair {
            xNumericPair: x
            yNumericPair: y
          }
          ... on StringPair {
            name
            xStringPair: x
            yStringPair: y
          }
          ... on RangePair {
            xRangePair: x {
              min
              max
            }
            yRangePair: y
          }
        }
      }
    }
  }
`

/**
 * __useAllChartsQuery__
 *
 * To run a query within a React component, call `useAllChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChartsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllChartsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllChartsQuery, IAllChartsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllChartsQuery, IAllChartsQueryVariables>(AllChartsDocument, options)
}
export function useAllChartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllChartsQuery, IAllChartsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllChartsQuery, IAllChartsQueryVariables>(AllChartsDocument, options)
}
export type AllChartsQueryHookResult = ReturnType<typeof useAllChartsQuery>
export type AllChartsLazyQueryHookResult = ReturnType<typeof useAllChartsLazyQuery>
export type AllChartsQueryResult = Apollo.QueryResult<IAllChartsQuery, IAllChartsQueryVariables>
export const AllChatConversationMessageTemplateVariablesDocument = gql`
  query allChatConversationMessageTemplateVariables {
    allChatConversationMessageTemplateVariables {
      chatConversationMessageTemplateVariables {
        id
        key
      }
    }
  }
`

/**
 * __useAllChatConversationMessageTemplateVariablesQuery__
 *
 * To run a query within a React component, call `useAllChatConversationMessageTemplateVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatConversationMessageTemplateVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatConversationMessageTemplateVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllChatConversationMessageTemplateVariablesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllChatConversationMessageTemplateVariablesQuery,
    IAllChatConversationMessageTemplateVariablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAllChatConversationMessageTemplateVariablesQuery,
    IAllChatConversationMessageTemplateVariablesQueryVariables
  >(AllChatConversationMessageTemplateVariablesDocument, options)
}
export function useAllChatConversationMessageTemplateVariablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllChatConversationMessageTemplateVariablesQuery,
    IAllChatConversationMessageTemplateVariablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAllChatConversationMessageTemplateVariablesQuery,
    IAllChatConversationMessageTemplateVariablesQueryVariables
  >(AllChatConversationMessageTemplateVariablesDocument, options)
}
export type AllChatConversationMessageTemplateVariablesQueryHookResult = ReturnType<
  typeof useAllChatConversationMessageTemplateVariablesQuery
>
export type AllChatConversationMessageTemplateVariablesLazyQueryHookResult = ReturnType<
  typeof useAllChatConversationMessageTemplateVariablesLazyQuery
>
export type AllChatConversationMessageTemplateVariablesQueryResult = Apollo.QueryResult<
  IAllChatConversationMessageTemplateVariablesQuery,
  IAllChatConversationMessageTemplateVariablesQueryVariables
>
export const AllChatConversationMessageTemplatesDocument = gql`
  query allChatConversationMessageTemplates(
    $kinds: [ChatConversationMessageTemplateKind!]
    $excludeAutomatic: Boolean
  ) {
    allChatConversationMessageTemplates(kinds: $kinds, excludeAutomatic: $excludeAutomatic) {
      chatConversationMessageTemplates {
        id
        value
        kind
        name
        private
        automatic
        createdBy {
          id
        }
      }
    }
  }
`

/**
 * __useAllChatConversationMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useAllChatConversationMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatConversationMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatConversationMessageTemplatesQuery({
 *   variables: {
 *      kinds: // value for 'kinds'
 *      excludeAutomatic: // value for 'excludeAutomatic'
 *   },
 * });
 */
export function useAllChatConversationMessageTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllChatConversationMessageTemplatesQuery,
    IAllChatConversationMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAllChatConversationMessageTemplatesQuery,
    IAllChatConversationMessageTemplatesQueryVariables
  >(AllChatConversationMessageTemplatesDocument, options)
}
export function useAllChatConversationMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllChatConversationMessageTemplatesQuery,
    IAllChatConversationMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAllChatConversationMessageTemplatesQuery,
    IAllChatConversationMessageTemplatesQueryVariables
  >(AllChatConversationMessageTemplatesDocument, options)
}
export type AllChatConversationMessageTemplatesQueryHookResult = ReturnType<
  typeof useAllChatConversationMessageTemplatesQuery
>
export type AllChatConversationMessageTemplatesLazyQueryHookResult = ReturnType<
  typeof useAllChatConversationMessageTemplatesLazyQuery
>
export type AllChatConversationMessageTemplatesQueryResult = Apollo.QueryResult<
  IAllChatConversationMessageTemplatesQuery,
  IAllChatConversationMessageTemplatesQueryVariables
>
export const AllChatbotConversationsDocument = gql`
  query allChatbotConversations {
    allChatbotConversations {
      conversations {
        id
        userId
        providerId
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useAllChatbotConversationsQuery__
 *
 * To run a query within a React component, call `useAllChatbotConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatbotConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatbotConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllChatbotConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllChatbotConversationsQuery,
    IAllChatbotConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllChatbotConversationsQuery, IAllChatbotConversationsQueryVariables>(
    AllChatbotConversationsDocument,
    options
  )
}
export function useAllChatbotConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllChatbotConversationsQuery,
    IAllChatbotConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllChatbotConversationsQuery, IAllChatbotConversationsQueryVariables>(
    AllChatbotConversationsDocument,
    options
  )
}
export type AllChatbotConversationsQueryHookResult = ReturnType<
  typeof useAllChatbotConversationsQuery
>
export type AllChatbotConversationsLazyQueryHookResult = ReturnType<
  typeof useAllChatbotConversationsLazyQuery
>
export type AllChatbotConversationsQueryResult = Apollo.QueryResult<
  IAllChatbotConversationsQuery,
  IAllChatbotConversationsQueryVariables
>
export const AllCoachConversationScheduledMessagesDocument = gql`
  query allCoachConversationScheduledMessages(
    $userId: ID
    $kinds: [ChatConversationMessageTemplateKind!]
    $states: [CoachesConversationScheduledMessageState!]
    $sorts: [CoachConversationScheduledMessageSort!]
  ) {
    allCoachConversationScheduledMessages(
      kinds: $kinds
      states: $states
      userId: $userId
      sorts: $sorts
    ) {
      coachConversationScheduledMessages {
        id
        kind
        state
        sendAt
        sentAt
        coachAssignment {
          id
          user {
            id
            fullName
          }
          appointment {
            id
          }
        }
      }
    }
  }
`

/**
 * __useAllCoachConversationScheduledMessagesQuery__
 *
 * To run a query within a React component, call `useAllCoachConversationScheduledMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCoachConversationScheduledMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCoachConversationScheduledMessagesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      kinds: // value for 'kinds'
 *      states: // value for 'states'
 *      sorts: // value for 'sorts'
 *   },
 * });
 */
export function useAllCoachConversationScheduledMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllCoachConversationScheduledMessagesQuery,
    IAllCoachConversationScheduledMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAllCoachConversationScheduledMessagesQuery,
    IAllCoachConversationScheduledMessagesQueryVariables
  >(AllCoachConversationScheduledMessagesDocument, options)
}
export function useAllCoachConversationScheduledMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllCoachConversationScheduledMessagesQuery,
    IAllCoachConversationScheduledMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAllCoachConversationScheduledMessagesQuery,
    IAllCoachConversationScheduledMessagesQueryVariables
  >(AllCoachConversationScheduledMessagesDocument, options)
}
export type AllCoachConversationScheduledMessagesQueryHookResult = ReturnType<
  typeof useAllCoachConversationScheduledMessagesQuery
>
export type AllCoachConversationScheduledMessagesLazyQueryHookResult = ReturnType<
  typeof useAllCoachConversationScheduledMessagesLazyQuery
>
export type AllCoachConversationScheduledMessagesQueryResult = Apollo.QueryResult<
  IAllCoachConversationScheduledMessagesQuery,
  IAllCoachConversationScheduledMessagesQueryVariables
>
export const AllEventsDocument = gql`
  query allEvents($filter: DateFilter, $pagination: Pagination) {
    allEvents(filter: $filter, pagination: $pagination) {
      events {
        events {
          ...AllEventsActivity
          ...AllEventsMeal
          ...AllEventsMeasurement
          ...AllEventsNote
          ...AllEventsJournalEntry
        }
      }
    }
  }
  ${AllEventsActivityFragmentDoc}
  ${AllEventsMealFragmentDoc}
  ${AllEventsMeasurementFragmentDoc}
  ${AllEventsNoteFragmentDoc}
  ${AllEventsJournalEntryFragmentDoc}
`

/**
 * __useAllEventsQuery__
 *
 * To run a query within a React component, call `useAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAllEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllEventsQuery, IAllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllEventsQuery, IAllEventsQueryVariables>(AllEventsDocument, options)
}
export function useAllEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllEventsQuery, IAllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllEventsQuery, IAllEventsQueryVariables>(AllEventsDocument, options)
}
export type AllEventsQueryHookResult = ReturnType<typeof useAllEventsQuery>
export type AllEventsLazyQueryHookResult = ReturnType<typeof useAllEventsLazyQuery>
export type AllEventsQueryResult = Apollo.QueryResult<IAllEventsQuery, IAllEventsQueryVariables>
export const AllFilesDocument = gql`
  query allFiles(
    $page: Int
    $perPage: Int
    $sorts: [FileSort!]
    $dynamicFilters: [FileDynamicFilterItem!]
  ) {
    allFiles(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      files {
        ...CoreFileFields
        tag
      }
    }
  }
  ${CoreFileFieldsFragmentDoc}
`

/**
 * __useAllFilesQuery__
 *
 * To run a query within a React component, call `useAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFilesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllFilesQuery, IAllFilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllFilesQuery, IAllFilesQueryVariables>(AllFilesDocument, options)
}
export function useAllFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllFilesQuery, IAllFilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllFilesQuery, IAllFilesQueryVariables>(AllFilesDocument, options)
}
export type AllFilesQueryHookResult = ReturnType<typeof useAllFilesQuery>
export type AllFilesLazyQueryHookResult = ReturnType<typeof useAllFilesLazyQuery>
export type AllFilesQueryResult = Apollo.QueryResult<IAllFilesQuery, IAllFilesQueryVariables>
export const AllHistoryDocument = gql`
  query allHistory($filter: DateFilter, $pagination: Pagination) {
    allEvents(filter: $filter, pagination: $pagination) {
      events {
        events {
          ...AllEventsActivity
          ...AllEventsMeal
          ...AllEventsMeasurement
          ...AllEventsNote
          ...AllEventsJournalEntry
        }
      }
    }
  }
  ${AllEventsActivityFragmentDoc}
  ${AllEventsMealFragmentDoc}
  ${AllEventsMeasurementFragmentDoc}
  ${AllEventsNoteFragmentDoc}
  ${AllEventsJournalEntryFragmentDoc}
`

/**
 * __useAllHistoryQuery__
 *
 * To run a query within a React component, call `useAllHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllHistoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAllHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllHistoryQuery, IAllHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllHistoryQuery, IAllHistoryQueryVariables>(AllHistoryDocument, options)
}
export function useAllHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllHistoryQuery, IAllHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllHistoryQuery, IAllHistoryQueryVariables>(
    AllHistoryDocument,
    options
  )
}
export type AllHistoryQueryHookResult = ReturnType<typeof useAllHistoryQuery>
export type AllHistoryLazyQueryHookResult = ReturnType<typeof useAllHistoryLazyQuery>
export type AllHistoryQueryResult = Apollo.QueryResult<IAllHistoryQuery, IAllHistoryQueryVariables>
export const AllInvoiceOrdersDocument = gql`
  query allInvoiceOrders(
    $page: Int!
    $perPage: Int!
    $sorts: [InvoiceOrderSort!]
    $dynamicFilters: [InvoiceOrderDynamicFilterItem!]
  ) {
    allInvoiceOrders(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      invoiceOrders {
        ...CoreInvoiceOrderFields
      }
      totalCount
    }
  }
  ${CoreInvoiceOrderFieldsFragmentDoc}
`

/**
 * __useAllInvoiceOrdersQuery__
 *
 * To run a query within a React component, call `useAllInvoiceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvoiceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvoiceOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllInvoiceOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<IAllInvoiceOrdersQuery, IAllInvoiceOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllInvoiceOrdersQuery, IAllInvoiceOrdersQueryVariables>(
    AllInvoiceOrdersDocument,
    options
  )
}
export function useAllInvoiceOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllInvoiceOrdersQuery, IAllInvoiceOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllInvoiceOrdersQuery, IAllInvoiceOrdersQueryVariables>(
    AllInvoiceOrdersDocument,
    options
  )
}
export type AllInvoiceOrdersQueryHookResult = ReturnType<typeof useAllInvoiceOrdersQuery>
export type AllInvoiceOrdersLazyQueryHookResult = ReturnType<typeof useAllInvoiceOrdersLazyQuery>
export type AllInvoiceOrdersQueryResult = Apollo.QueryResult<
  IAllInvoiceOrdersQuery,
  IAllInvoiceOrdersQueryVariables
>
export const AllKnowledgeAnswersDocument = gql`
  query allKnowledgeAnswers(
    $page: Int
    $perPage: Int
    $sorts: [KnowledgeAnswerSort!]
    $dynamicFilters: [KnowledgeAnswerDynamicFilterItem!]
  ) {
    allKnowledgeAnswers(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      pageCount
      knowledgeAnswers {
        ...CoreKnowledgeAnswerFields
      }
    }
  }
  ${CoreKnowledgeAnswerFieldsFragmentDoc}
`

/**
 * __useAllKnowledgeAnswersQuery__
 *
 * To run a query within a React component, call `useAllKnowledgeAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllKnowledgeAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllKnowledgeAnswersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllKnowledgeAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllKnowledgeAnswersQuery,
    IAllKnowledgeAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllKnowledgeAnswersQuery, IAllKnowledgeAnswersQueryVariables>(
    AllKnowledgeAnswersDocument,
    options
  )
}
export function useAllKnowledgeAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllKnowledgeAnswersQuery,
    IAllKnowledgeAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllKnowledgeAnswersQuery, IAllKnowledgeAnswersQueryVariables>(
    AllKnowledgeAnswersDocument,
    options
  )
}
export type AllKnowledgeAnswersQueryHookResult = ReturnType<typeof useAllKnowledgeAnswersQuery>
export type AllKnowledgeAnswersLazyQueryHookResult = ReturnType<
  typeof useAllKnowledgeAnswersLazyQuery
>
export type AllKnowledgeAnswersQueryResult = Apollo.QueryResult<
  IAllKnowledgeAnswersQuery,
  IAllKnowledgeAnswersQueryVariables
>
export const AllKnowledgeQuestionsDocument = gql`
  query allKnowledgeQuestions(
    $page: Int
    $perPage: Int
    $sorts: [KnowledgeQuestionSort!]
    $dynamicFilters: [KnowledgeQuestionDynamicFilterItem!]
  ) {
    allKnowledgeQuestions(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      pageCount
      knowledgeQuestions {
        ...CoreKnowledgeQuestionFields
      }
    }
  }
  ${CoreKnowledgeQuestionFieldsFragmentDoc}
`

/**
 * __useAllKnowledgeQuestionsQuery__
 *
 * To run a query within a React component, call `useAllKnowledgeQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllKnowledgeQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllKnowledgeQuestionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllKnowledgeQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllKnowledgeQuestionsQuery,
    IAllKnowledgeQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllKnowledgeQuestionsQuery, IAllKnowledgeQuestionsQueryVariables>(
    AllKnowledgeQuestionsDocument,
    options
  )
}
export function useAllKnowledgeQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllKnowledgeQuestionsQuery,
    IAllKnowledgeQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllKnowledgeQuestionsQuery, IAllKnowledgeQuestionsQueryVariables>(
    AllKnowledgeQuestionsDocument,
    options
  )
}
export type AllKnowledgeQuestionsQueryHookResult = ReturnType<typeof useAllKnowledgeQuestionsQuery>
export type AllKnowledgeQuestionsLazyQueryHookResult = ReturnType<
  typeof useAllKnowledgeQuestionsLazyQuery
>
export type AllKnowledgeQuestionsQueryResult = Apollo.QueryResult<
  IAllKnowledgeQuestionsQuery,
  IAllKnowledgeQuestionsQueryVariables
>
export const AllKnowledgeSourcesDocument = gql`
  query allKnowledgeSources(
    $page: Int
    $perPage: Int
    $sorts: [KnowledgeSourceSort!]
    $dynamicFilters: [KnowledgeSourceDynamicFilterItem!]
  ) {
    allKnowledgeSources(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      pageCount
      knowledgeSources {
        id
        title
        url
        knowledgeAnswers {
          id
          title
          value
          knowledgeQuestions {
            id
            value
          }
        }
      }
    }
  }
`

/**
 * __useAllKnowledgeSourcesQuery__
 *
 * To run a query within a React component, call `useAllKnowledgeSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllKnowledgeSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllKnowledgeSourcesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllKnowledgeSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllKnowledgeSourcesQuery,
    IAllKnowledgeSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllKnowledgeSourcesQuery, IAllKnowledgeSourcesQueryVariables>(
    AllKnowledgeSourcesDocument,
    options
  )
}
export function useAllKnowledgeSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllKnowledgeSourcesQuery,
    IAllKnowledgeSourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllKnowledgeSourcesQuery, IAllKnowledgeSourcesQueryVariables>(
    AllKnowledgeSourcesDocument,
    options
  )
}
export type AllKnowledgeSourcesQueryHookResult = ReturnType<typeof useAllKnowledgeSourcesQuery>
export type AllKnowledgeSourcesLazyQueryHookResult = ReturnType<
  typeof useAllKnowledgeSourcesLazyQuery
>
export type AllKnowledgeSourcesQueryResult = Apollo.QueryResult<
  IAllKnowledgeSourcesQuery,
  IAllKnowledgeSourcesQueryVariables
>
export const AllKnowledgeTagsDocument = gql`
  query allKnowledgeTags(
    $page: Int
    $perPage: Int
    $sorts: [KnowledgeTagSort!]
    $dynamicFilters: [KnowledgeTagDynamicFilterItem!]
  ) {
    allKnowledgeTags(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      pageCount
      knowledgeTags {
        id
        value
      }
    }
  }
`

/**
 * __useAllKnowledgeTagsQuery__
 *
 * To run a query within a React component, call `useAllKnowledgeTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllKnowledgeTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllKnowledgeTagsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllKnowledgeTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllKnowledgeTagsQuery, IAllKnowledgeTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllKnowledgeTagsQuery, IAllKnowledgeTagsQueryVariables>(
    AllKnowledgeTagsDocument,
    options
  )
}
export function useAllKnowledgeTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllKnowledgeTagsQuery, IAllKnowledgeTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllKnowledgeTagsQuery, IAllKnowledgeTagsQueryVariables>(
    AllKnowledgeTagsDocument,
    options
  )
}
export type AllKnowledgeTagsQueryHookResult = ReturnType<typeof useAllKnowledgeTagsQuery>
export type AllKnowledgeTagsLazyQueryHookResult = ReturnType<typeof useAllKnowledgeTagsLazyQuery>
export type AllKnowledgeTagsQueryResult = Apollo.QueryResult<
  IAllKnowledgeTagsQuery,
  IAllKnowledgeTagsQueryVariables
>
export const AllMeasurementsDocument = gql`
  query allMeasurements($filter: MeasurementFilter, $pagination: Pagination) {
    allMeasurements(filter: $filter, pagination: $pagination) {
      measurements {
        ...AllMeasurementsMeasurement
      }
    }
  }
  ${AllMeasurementsMeasurementFragmentDoc}
`

/**
 * __useAllMeasurementsQuery__
 *
 * To run a query within a React component, call `useAllMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMeasurementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAllMeasurementsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllMeasurementsQuery, IAllMeasurementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllMeasurementsQuery, IAllMeasurementsQueryVariables>(
    AllMeasurementsDocument,
    options
  )
}
export function useAllMeasurementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllMeasurementsQuery, IAllMeasurementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllMeasurementsQuery, IAllMeasurementsQueryVariables>(
    AllMeasurementsDocument,
    options
  )
}
export type AllMeasurementsQueryHookResult = ReturnType<typeof useAllMeasurementsQuery>
export type AllMeasurementsLazyQueryHookResult = ReturnType<typeof useAllMeasurementsLazyQuery>
export type AllMeasurementsQueryResult = Apollo.QueryResult<
  IAllMeasurementsQuery,
  IAllMeasurementsQueryVariables
>
export const AllNotesDocument = gql`
  query allNotes($userId: ID!, $sorts: [NoteSort!], $dynamicFilters: [NoteDynamicFilterItem!]) {
    allNotes(userId: $userId, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      notes {
        id
        body
        kind
        occurredAt
        pinnedAt
        creator {
          id
          fullName
        }
      }
    }
  }
`

/**
 * __useAllNotesQuery__
 *
 * To run a query within a React component, call `useAllNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllNotesQuery(
  baseOptions: Apollo.QueryHookOptions<IAllNotesQuery, IAllNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllNotesQuery, IAllNotesQueryVariables>(AllNotesDocument, options)
}
export function useAllNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllNotesQuery, IAllNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllNotesQuery, IAllNotesQueryVariables>(AllNotesDocument, options)
}
export type AllNotesQueryHookResult = ReturnType<typeof useAllNotesQuery>
export type AllNotesLazyQueryHookResult = ReturnType<typeof useAllNotesLazyQuery>
export type AllNotesQueryResult = Apollo.QueryResult<IAllNotesQuery, IAllNotesQueryVariables>
export const AllOrdersDocument = gql`
  query allOrders(
    $page: Int!
    $perPage: Int!
    $sorts: [OrderSort!]
    $dynamicFilters: [OrderDynamicFilterItem!]
  ) {
    allOrders(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      orders {
        ...CoreOrderFields
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          dateOfBirth
          sex
        }
        address {
          ...CoreAddressFields
        }
      }
      totalRecords
    }
  }
  ${CoreOrderFieldsFragmentDoc}
  ${CoreAddressFieldsFragmentDoc}
`

/**
 * __useAllOrdersQuery__
 *
 * To run a query within a React component, call `useAllOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<IAllOrdersQuery, IAllOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllOrdersQuery, IAllOrdersQueryVariables>(AllOrdersDocument, options)
}
export function useAllOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllOrdersQuery, IAllOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllOrdersQuery, IAllOrdersQueryVariables>(AllOrdersDocument, options)
}
export type AllOrdersQueryHookResult = ReturnType<typeof useAllOrdersQuery>
export type AllOrdersLazyQueryHookResult = ReturnType<typeof useAllOrdersLazyQuery>
export type AllOrdersQueryResult = Apollo.QueryResult<IAllOrdersQuery, IAllOrdersQueryVariables>
export const AllOrganizationsDocument = gql`
  query allOrganizations($name: String) {
    allOrganizations(name: $name) {
      organizations {
        id
        name
        isNutrisense
      }
    }
  }
`

/**
 * __useAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganizationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAllOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllOrganizationsQuery, IAllOrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllOrganizationsQuery, IAllOrganizationsQueryVariables>(
    AllOrganizationsDocument,
    options
  )
}
export function useAllOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllOrganizationsQuery, IAllOrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllOrganizationsQuery, IAllOrganizationsQueryVariables>(
    AllOrganizationsDocument,
    options
  )
}
export type AllOrganizationsQueryHookResult = ReturnType<typeof useAllOrganizationsQuery>
export type AllOrganizationsLazyQueryHookResult = ReturnType<typeof useAllOrganizationsLazyQuery>
export type AllOrganizationsQueryResult = Apollo.QueryResult<
  IAllOrganizationsQuery,
  IAllOrganizationsQueryVariables
>
export const AllReplacementRequestsDocument = gql`
  query allReplacementRequests(
    $page: Int!
    $perPage: Int!
    $sorts: [ReplacementRequestSort!]
    $dynamicFilters: [ReplacementRequestDynamicFilterItem!]
  ) {
    allReplacementRequests(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      replacementRequests {
        ...CoreReplacementRequestFields
      }
      totalCount
    }
  }
  ${CoreReplacementRequestFieldsFragmentDoc}
`

/**
 * __useAllReplacementRequestsQuery__
 *
 * To run a query within a React component, call `useAllReplacementRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReplacementRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReplacementRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllReplacementRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAllReplacementRequestsQuery,
    IAllReplacementRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllReplacementRequestsQuery, IAllReplacementRequestsQueryVariables>(
    AllReplacementRequestsDocument,
    options
  )
}
export function useAllReplacementRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllReplacementRequestsQuery,
    IAllReplacementRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllReplacementRequestsQuery, IAllReplacementRequestsQueryVariables>(
    AllReplacementRequestsDocument,
    options
  )
}
export type AllReplacementRequestsQueryHookResult = ReturnType<
  typeof useAllReplacementRequestsQuery
>
export type AllReplacementRequestsLazyQueryHookResult = ReturnType<
  typeof useAllReplacementRequestsLazyQuery
>
export type AllReplacementRequestsQueryResult = Apollo.QueryResult<
  IAllReplacementRequestsQuery,
  IAllReplacementRequestsQueryVariables
>
export const AllScansDocument = gql`
  query allScans($pagination: Pagination, $filter: MeasurementFilter) {
    allScans(pagination: $pagination, filter: $filter) {
      measurements {
        ...CoreMeasurementFields
        scan {
          ...CoreScanFields
        }
      }
    }
  }
  ${CoreMeasurementFieldsFragmentDoc}
  ${CoreScanFieldsFragmentDoc}
`

/**
 * __useAllScansQuery__
 *
 * To run a query within a React component, call `useAllScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllScansQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllScansQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllScansQuery, IAllScansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllScansQuery, IAllScansQueryVariables>(AllScansDocument, options)
}
export function useAllScansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllScansQuery, IAllScansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllScansQuery, IAllScansQueryVariables>(AllScansDocument, options)
}
export type AllScansQueryHookResult = ReturnType<typeof useAllScansQuery>
export type AllScansLazyQueryHookResult = ReturnType<typeof useAllScansLazyQuery>
export type AllScansQueryResult = Apollo.QueryResult<IAllScansQuery, IAllScansQueryVariables>
export const AllSensorsDocument = gql`
  query allSensors {
    allSensors {
      sensors {
        activationDate
        baselineAdjustment
        daysRemaining
        expirationDate
        expirationTime
        expired
        id
        serialNumber
        status
        lastStatusChange {
          status
          createdAt
          failure
        }
      }
    }
  }
`

/**
 * __useAllSensorsQuery__
 *
 * To run a query within a React component, call `useAllSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSensorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSensorsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllSensorsQuery, IAllSensorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllSensorsQuery, IAllSensorsQueryVariables>(AllSensorsDocument, options)
}
export function useAllSensorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllSensorsQuery, IAllSensorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllSensorsQuery, IAllSensorsQueryVariables>(
    AllSensorsDocument,
    options
  )
}
export type AllSensorsQueryHookResult = ReturnType<typeof useAllSensorsQuery>
export type AllSensorsLazyQueryHookResult = ReturnType<typeof useAllSensorsLazyQuery>
export type AllSensorsQueryResult = Apollo.QueryResult<IAllSensorsQuery, IAllSensorsQueryVariables>
export const AllShipmentsDocument = gql`
  query allShipments(
    $page: Int!
    $perPage: Int!
    $sorts: [ShipmentSort!]
    $dynamicFilters: [ShipmentDynamicFilterItem!]
  ) {
    allShipments(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      shipments {
        ...CoreShipmentFields
        eta
        location
        order {
          id
          invoice {
            stripeId
          }
          createdAt
          product
          productType
          sensorKind
          quantity
          replacementRequest {
            providerCompensation
          }
        }
        address {
          ...CoreAddressFields
        }
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          dateOfBirth
          sex
          truepillPatientToken
          activeRefillConsent {
            givenAt
          }
        }
      }
      totalRecords
    }
  }
  ${CoreShipmentFieldsFragmentDoc}
  ${CoreAddressFieldsFragmentDoc}
`

/**
 * __useAllShipmentsQuery__
 *
 * To run a query within a React component, call `useAllShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllShipmentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<IAllShipmentsQuery, IAllShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllShipmentsQuery, IAllShipmentsQueryVariables>(
    AllShipmentsDocument,
    options
  )
}
export function useAllShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllShipmentsQuery, IAllShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllShipmentsQuery, IAllShipmentsQueryVariables>(
    AllShipmentsDocument,
    options
  )
}
export type AllShipmentsQueryHookResult = ReturnType<typeof useAllShipmentsQuery>
export type AllShipmentsLazyQueryHookResult = ReturnType<typeof useAllShipmentsLazyQuery>
export type AllShipmentsQueryResult = Apollo.QueryResult<
  IAllShipmentsQuery,
  IAllShipmentsQueryVariables
>
export const AllStatsDocument = gql`
  query allStats($filter: DateFilter) {
    allStats(filter: $filter) {
      statistics {
        type
        title
        description
        value
        valuePrev
        change
        range {
          min
          max
          warning
          danger
          reverse
        }
        meta {
          key
          section
        }
      }
    }
  }
`

/**
 * __useAllStatsQuery__
 *
 * To run a query within a React component, call `useAllStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllStatsQuery, IAllStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllStatsQuery, IAllStatsQueryVariables>(AllStatsDocument, options)
}
export function useAllStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllStatsQuery, IAllStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllStatsQuery, IAllStatsQueryVariables>(AllStatsDocument, options)
}
export type AllStatsQueryHookResult = ReturnType<typeof useAllStatsQuery>
export type AllStatsLazyQueryHookResult = ReturnType<typeof useAllStatsLazyQuery>
export type AllStatsQueryResult = Apollo.QueryResult<IAllStatsQuery, IAllStatsQueryVariables>
export const AllSurveyLinksDocument = gql`
  query allSurveyLinks(
    $page: Int
    $perPage: Int
    $sorts: [SurveyLinkSort!]
    $dynamicFilters: [SurveyLinkDynamicFilterItem!]
  ) {
    allSurveyLinks(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      pageCount
      surveyLinks {
        id
        state
        finished
        startedAt
        finishedAt
        survey {
          kind
          version
        }
        user {
          id
          fullName
          email
          lastPrescriptionApprovalRequest {
            state
          }
        }
      }
    }
  }
`

/**
 * __useAllSurveyLinksQuery__
 *
 * To run a query within a React component, call `useAllSurveyLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSurveyLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSurveyLinksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllSurveyLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllSurveyLinksQuery, IAllSurveyLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllSurveyLinksQuery, IAllSurveyLinksQueryVariables>(
    AllSurveyLinksDocument,
    options
  )
}
export function useAllSurveyLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllSurveyLinksQuery, IAllSurveyLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllSurveyLinksQuery, IAllSurveyLinksQueryVariables>(
    AllSurveyLinksDocument,
    options
  )
}
export type AllSurveyLinksQueryHookResult = ReturnType<typeof useAllSurveyLinksQuery>
export type AllSurveyLinksLazyQueryHookResult = ReturnType<typeof useAllSurveyLinksLazyQuery>
export type AllSurveyLinksQueryResult = Apollo.QueryResult<
  IAllSurveyLinksQuery,
  IAllSurveyLinksQueryVariables
>
export const AllUserPropertiesDocument = gql`
  query allUserProperties {
    allUserProperties {
      userProperties {
        id
        kind
        value
        userId
      }
    }
  }
`

/**
 * __useAllUserPropertiesQuery__
 *
 * To run a query within a React component, call `useAllUserPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUserPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllUserPropertiesQuery, IAllUserPropertiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllUserPropertiesQuery, IAllUserPropertiesQueryVariables>(
    AllUserPropertiesDocument,
    options
  )
}
export function useAllUserPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllUserPropertiesQuery,
    IAllUserPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllUserPropertiesQuery, IAllUserPropertiesQueryVariables>(
    AllUserPropertiesDocument,
    options
  )
}
export type AllUserPropertiesQueryHookResult = ReturnType<typeof useAllUserPropertiesQuery>
export type AllUserPropertiesLazyQueryHookResult = ReturnType<typeof useAllUserPropertiesLazyQuery>
export type AllUserPropertiesQueryResult = Apollo.QueryResult<
  IAllUserPropertiesQuery,
  IAllUserPropertiesQueryVariables
>
export const AllUsersAutocompleteDocument = gql`
  query allUsersAutocomplete($query: String!) {
    allUsersAutocomplete(query: $query) {
      users {
        email
        fullName
        id
        timeZone
        phoneNumber
      }
    }
  }
`

/**
 * __useAllUsersAutocompleteQuery__
 *
 * To run a query within a React component, call `useAllUsersAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUsersAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAllUsersAutocompleteQuery,
    IAllUsersAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllUsersAutocompleteQuery, IAllUsersAutocompleteQueryVariables>(
    AllUsersAutocompleteDocument,
    options
  )
}
export function useAllUsersAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllUsersAutocompleteQuery,
    IAllUsersAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllUsersAutocompleteQuery, IAllUsersAutocompleteQueryVariables>(
    AllUsersAutocompleteDocument,
    options
  )
}
export type AllUsersAutocompleteQueryHookResult = ReturnType<typeof useAllUsersAutocompleteQuery>
export type AllUsersAutocompleteLazyQueryHookResult = ReturnType<
  typeof useAllUsersAutocompleteLazyQuery
>
export type AllUsersAutocompleteQueryResult = Apollo.QueryResult<
  IAllUsersAutocompleteQuery,
  IAllUsersAutocompleteQueryVariables
>
export const AllAppointmentsDocument = gql`
  query allAppointments(
    $page: Int
    $perPage: Int
    $sorts: [AppointmentSort!]
    $dynamicFilters: [AppointmentDynamicFilterItem!]
  ) {
    allAppointments(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      appointments {
        ...CoreAppointmentFields
      }
    }
  }
  ${CoreAppointmentFieldsFragmentDoc}
`

/**
 * __useAllAppointmentsQuery__
 *
 * To run a query within a React component, call `useAllAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppointmentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllAppointmentsQuery, IAllAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllAppointmentsQuery, IAllAppointmentsQueryVariables>(
    AllAppointmentsDocument,
    options
  )
}
export function useAllAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllAppointmentsQuery, IAllAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllAppointmentsQuery, IAllAppointmentsQueryVariables>(
    AllAppointmentsDocument,
    options
  )
}
export type AllAppointmentsQueryHookResult = ReturnType<typeof useAllAppointmentsQuery>
export type AllAppointmentsLazyQueryHookResult = ReturnType<typeof useAllAppointmentsLazyQuery>
export type AllAppointmentsQueryResult = Apollo.QueryResult<
  IAllAppointmentsQuery,
  IAllAppointmentsQueryVariables
>
export const AppointmentDocument = gql`
  query appointment($id: ID!) {
    appointment(id: $id) {
      ...CoreAppointmentFields
    }
  }
  ${CoreAppointmentFieldsFragmentDoc}
`

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<IAppointmentQuery, IAppointmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAppointmentQuery, IAppointmentQueryVariables>(
    AppointmentDocument,
    options
  )
}
export function useAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAppointmentQuery, IAppointmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAppointmentQuery, IAppointmentQueryVariables>(
    AppointmentDocument,
    options
  )
}
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>
export type AppointmentQueryResult = Apollo.QueryResult<
  IAppointmentQuery,
  IAppointmentQueryVariables
>
export const NextNutritionistAppointmentDocument = gql`
  query nextNutritionistAppointment {
    nextNutritionistAppointment {
      meetingAt
    }
  }
`

/**
 * __useNextNutritionistAppointmentQuery__
 *
 * To run a query within a React component, call `useNextNutritionistAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextNutritionistAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextNutritionistAppointmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useNextNutritionistAppointmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    INextNutritionistAppointmentQuery,
    INextNutritionistAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    INextNutritionistAppointmentQuery,
    INextNutritionistAppointmentQueryVariables
  >(NextNutritionistAppointmentDocument, options)
}
export function useNextNutritionistAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    INextNutritionistAppointmentQuery,
    INextNutritionistAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    INextNutritionistAppointmentQuery,
    INextNutritionistAppointmentQueryVariables
  >(NextNutritionistAppointmentDocument, options)
}
export type NextNutritionistAppointmentQueryHookResult = ReturnType<
  typeof useNextNutritionistAppointmentQuery
>
export type NextNutritionistAppointmentLazyQueryHookResult = ReturnType<
  typeof useNextNutritionistAppointmentLazyQuery
>
export type NextNutritionistAppointmentQueryResult = Apollo.QueryResult<
  INextNutritionistAppointmentQuery,
  INextNutritionistAppointmentQueryVariables
>
export const NextUserAppointmentDocument = gql`
  query nextUserAppointment($id: ID!) {
    nextUserAppointment(id: $id) {
      meetingAt
    }
  }
`

/**
 * __useNextUserAppointmentQuery__
 *
 * To run a query within a React component, call `useNextUserAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextUserAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextUserAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNextUserAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    INextUserAppointmentQuery,
    INextUserAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<INextUserAppointmentQuery, INextUserAppointmentQueryVariables>(
    NextUserAppointmentDocument,
    options
  )
}
export function useNextUserAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    INextUserAppointmentQuery,
    INextUserAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<INextUserAppointmentQuery, INextUserAppointmentQueryVariables>(
    NextUserAppointmentDocument,
    options
  )
}
export type NextUserAppointmentQueryHookResult = ReturnType<typeof useNextUserAppointmentQuery>
export type NextUserAppointmentLazyQueryHookResult = ReturnType<
  typeof useNextUserAppointmentLazyQuery
>
export type NextUserAppointmentQueryResult = Apollo.QueryResult<
  INextUserAppointmentQuery,
  INextUserAppointmentQueryVariables
>
export const AllApprovalRequestsDocument = gql`
  query AllApprovalRequests(
    $page: Int
    $perPage: Int
    $sorts: [ApprovalRequestSort!]
    $dynamicFilters: [ApprovalRequestDynamicFilterItem!]
  ) {
    allApprovalRequests(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      approvalRequests {
        id
        state
        kind
        createdAt
        user {
          id
          fullName
          email
          dateOfBirth
          age
          phoneNumber
          dexcomSensorPromptUiState
          address {
            id
            street
            street2
            city
            state
            zipCode
          }
          governmentIdCard
          healthReject
          lastAnsweredHealthSurveyQuestionKey
          lastAnsweredMedicalHistorySurveyQuestionKey
          fulfillmentConfiguration {
            sensorKind
          }
          lastCoreSubscription {
            primaryProduct {
              title
            }
            latestInvoice {
              latestCharge {
                created
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useAllApprovalRequestsQuery__
 *
 * To run a query within a React component, call `useAllApprovalRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllApprovalRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApprovalRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllApprovalRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllApprovalRequestsQuery,
    IAllApprovalRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllApprovalRequestsQuery, IAllApprovalRequestsQueryVariables>(
    AllApprovalRequestsDocument,
    options
  )
}
export function useAllApprovalRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllApprovalRequestsQuery,
    IAllApprovalRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllApprovalRequestsQuery, IAllApprovalRequestsQueryVariables>(
    AllApprovalRequestsDocument,
    options
  )
}
export type AllApprovalRequestsQueryHookResult = ReturnType<typeof useAllApprovalRequestsQuery>
export type AllApprovalRequestsLazyQueryHookResult = ReturnType<
  typeof useAllApprovalRequestsLazyQuery
>
export type AllApprovalRequestsQueryResult = Apollo.QueryResult<
  IAllApprovalRequestsQuery,
  IAllApprovalRequestsQueryVariables
>
export const AuthorizedFeaturesDocument = gql`
  query authorizedFeatures {
    authorizedFeatures {
      features
    }
  }
`

/**
 * __useAuthorizedFeaturesQuery__
 *
 * To run a query within a React component, call `useAuthorizedFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizedFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizedFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorizedFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<IAuthorizedFeaturesQuery, IAuthorizedFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAuthorizedFeaturesQuery, IAuthorizedFeaturesQueryVariables>(
    AuthorizedFeaturesDocument,
    options
  )
}
export function useAuthorizedFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAuthorizedFeaturesQuery,
    IAuthorizedFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAuthorizedFeaturesQuery, IAuthorizedFeaturesQueryVariables>(
    AuthorizedFeaturesDocument,
    options
  )
}
export type AuthorizedFeaturesQueryHookResult = ReturnType<typeof useAuthorizedFeaturesQuery>
export type AuthorizedFeaturesLazyQueryHookResult = ReturnType<
  typeof useAuthorizedFeaturesLazyQuery
>
export type AuthorizedFeaturesQueryResult = Apollo.QueryResult<
  IAuthorizedFeaturesQuery,
  IAuthorizedFeaturesQueryVariables
>
export const BillingDashboardAllAccountRequestsDocument = gql`
  query billingDashboardAllAccountRequests(
    $sorts: [AccountRequestSort!]
    $dynamicFilters: [AccountRequestDynamicFilterItem!]
  ) {
    allAccountRequests(sorts: $sorts, dynamicFilters: $dynamicFilters) {
      accountRequests {
        ...CoreAccountRequestFields
      }
    }
  }
  ${CoreAccountRequestFieldsFragmentDoc}
`

/**
 * __useBillingDashboardAllAccountRequestsQuery__
 *
 * To run a query within a React component, call `useBillingDashboardAllAccountRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDashboardAllAccountRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDashboardAllAccountRequestsQuery({
 *   variables: {
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useBillingDashboardAllAccountRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IBillingDashboardAllAccountRequestsQuery,
    IBillingDashboardAllAccountRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IBillingDashboardAllAccountRequestsQuery,
    IBillingDashboardAllAccountRequestsQueryVariables
  >(BillingDashboardAllAccountRequestsDocument, options)
}
export function useBillingDashboardAllAccountRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IBillingDashboardAllAccountRequestsQuery,
    IBillingDashboardAllAccountRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IBillingDashboardAllAccountRequestsQuery,
    IBillingDashboardAllAccountRequestsQueryVariables
  >(BillingDashboardAllAccountRequestsDocument, options)
}
export type BillingDashboardAllAccountRequestsQueryHookResult = ReturnType<
  typeof useBillingDashboardAllAccountRequestsQuery
>
export type BillingDashboardAllAccountRequestsLazyQueryHookResult = ReturnType<
  typeof useBillingDashboardAllAccountRequestsLazyQuery
>
export type BillingDashboardAllAccountRequestsQueryResult = Apollo.QueryResult<
  IBillingDashboardAllAccountRequestsQuery,
  IBillingDashboardAllAccountRequestsQueryVariables
>
export const BillingDashboardAllChargesDocument = gql`
  query billingDashboardAllCharges(
    $page: Int
    $perPage: Int
    $sorts: [ChargeSort!]
    $dynamicFilters: [ChargeDynamicFilterItem!]
  ) {
    allCharges(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      pageCount
      charges {
        id
        stripeId
        amount
        amountRefunded
        paid
        status
        disputeStripeId
        failureMessage
        disputed
        refunded
        created
        refundable
        invoice {
          stripeId
          subscription {
            stripeId
          }
        }
      }
    }
  }
`

/**
 * __useBillingDashboardAllChargesQuery__
 *
 * To run a query within a React component, call `useBillingDashboardAllChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDashboardAllChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDashboardAllChargesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useBillingDashboardAllChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IBillingDashboardAllChargesQuery,
    IBillingDashboardAllChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IBillingDashboardAllChargesQuery,
    IBillingDashboardAllChargesQueryVariables
  >(BillingDashboardAllChargesDocument, options)
}
export function useBillingDashboardAllChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IBillingDashboardAllChargesQuery,
    IBillingDashboardAllChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IBillingDashboardAllChargesQuery,
    IBillingDashboardAllChargesQueryVariables
  >(BillingDashboardAllChargesDocument, options)
}
export type BillingDashboardAllChargesQueryHookResult = ReturnType<
  typeof useBillingDashboardAllChargesQuery
>
export type BillingDashboardAllChargesLazyQueryHookResult = ReturnType<
  typeof useBillingDashboardAllChargesLazyQuery
>
export type BillingDashboardAllChargesQueryResult = Apollo.QueryResult<
  IBillingDashboardAllChargesQuery,
  IBillingDashboardAllChargesQueryVariables
>
export const BillingDashboardAllInvoicesDocument = gql`
  query billingDashboardAllInvoices(
    $page: Int
    $perPage: Int
    $sorts: [InvoiceSort!]
    $dynamicFilters: [InvoiceDynamicFilterItem!]
  ) {
    allInvoices(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      pageCount
      invoices {
        id
        stripeId
        paidAt
        receiptNumber
        downloadUrl
        created
        updatedAt
        subscription {
          stripeId
        }
        total
      }
    }
  }
`

/**
 * __useBillingDashboardAllInvoicesQuery__
 *
 * To run a query within a React component, call `useBillingDashboardAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDashboardAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDashboardAllInvoicesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useBillingDashboardAllInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IBillingDashboardAllInvoicesQuery,
    IBillingDashboardAllInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IBillingDashboardAllInvoicesQuery,
    IBillingDashboardAllInvoicesQueryVariables
  >(BillingDashboardAllInvoicesDocument, options)
}
export function useBillingDashboardAllInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IBillingDashboardAllInvoicesQuery,
    IBillingDashboardAllInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IBillingDashboardAllInvoicesQuery,
    IBillingDashboardAllInvoicesQueryVariables
  >(BillingDashboardAllInvoicesDocument, options)
}
export type BillingDashboardAllInvoicesQueryHookResult = ReturnType<
  typeof useBillingDashboardAllInvoicesQuery
>
export type BillingDashboardAllInvoicesLazyQueryHookResult = ReturnType<
  typeof useBillingDashboardAllInvoicesLazyQuery
>
export type BillingDashboardAllInvoicesQueryResult = Apollo.QueryResult<
  IBillingDashboardAllInvoicesQuery,
  IBillingDashboardAllInvoicesQueryVariables
>
export const BillingDashboardAllSubscriptionsDocument = gql`
  query billingDashboardAllSubscriptions(
    $page: Int
    $perPage: Int
    $sorts: [SubscriptionSort!]
    $dynamicFilters: [SubscriptionDynamicFilterItem!]
  ) {
    allSubscriptions(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      pageCount
      subscriptions {
        id
        stripeId
        stripeStatus
        paidMonthsCount
        pausedMonthsCount
        cancelAt
        canceledAt
        createdAt
        currentPeriodEndAt
        endAt
        resumesAt
        startAt
        subscribedAt
        trialEndAt
        updatedAt
        paused
        plans {
          nickname
        }
      }
    }
  }
`

/**
 * __useBillingDashboardAllSubscriptionsQuery__
 *
 * To run a query within a React component, call `useBillingDashboardAllSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDashboardAllSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDashboardAllSubscriptionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useBillingDashboardAllSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IBillingDashboardAllSubscriptionsQuery,
    IBillingDashboardAllSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IBillingDashboardAllSubscriptionsQuery,
    IBillingDashboardAllSubscriptionsQueryVariables
  >(BillingDashboardAllSubscriptionsDocument, options)
}
export function useBillingDashboardAllSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IBillingDashboardAllSubscriptionsQuery,
    IBillingDashboardAllSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IBillingDashboardAllSubscriptionsQuery,
    IBillingDashboardAllSubscriptionsQueryVariables
  >(BillingDashboardAllSubscriptionsDocument, options)
}
export type BillingDashboardAllSubscriptionsQueryHookResult = ReturnType<
  typeof useBillingDashboardAllSubscriptionsQuery
>
export type BillingDashboardAllSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useBillingDashboardAllSubscriptionsLazyQuery
>
export type BillingDashboardAllSubscriptionsQueryResult = Apollo.QueryResult<
  IBillingDashboardAllSubscriptionsQuery,
  IBillingDashboardAllSubscriptionsQueryVariables
>
export const CreateSyncRequestDocument = gql`
  mutation createSyncRequest($userId: ID!) {
    createSyncRequest(userId: $userId) {
      success
    }
  }
`
export type ICreateSyncRequestMutationFn = Apollo.MutationFunction<
  ICreateSyncRequestMutation,
  ICreateSyncRequestMutationVariables
>

/**
 * __useCreateSyncRequestMutation__
 *
 * To run a mutation, you first call `useCreateSyncRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSyncRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSyncRequestMutation, { data, loading, error }] = useCreateSyncRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateSyncRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateSyncRequestMutation,
    ICreateSyncRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateSyncRequestMutation, ICreateSyncRequestMutationVariables>(
    CreateSyncRequestDocument,
    options
  )
}
export type CreateSyncRequestMutationHookResult = ReturnType<typeof useCreateSyncRequestMutation>
export type CreateSyncRequestMutationResult = Apollo.MutationResult<ICreateSyncRequestMutation>
export type CreateSyncRequestMutationOptions = Apollo.BaseMutationOptions<
  ICreateSyncRequestMutation,
  ICreateSyncRequestMutationVariables
>
export const CalendarDocument = gql`
  query calendar($filter: DateFilter) {
    calendar(filter: $filter) {
      dates {
        date
        score
      }
    }
  }
`

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCalendarQuery(
  baseOptions?: Apollo.QueryHookOptions<ICalendarQuery, ICalendarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICalendarQuery, ICalendarQueryVariables>(CalendarDocument, options)
}
export function useCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICalendarQuery, ICalendarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICalendarQuery, ICalendarQueryVariables>(CalendarDocument, options)
}
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>
export type CalendarQueryResult = Apollo.QueryResult<ICalendarQuery, ICalendarQueryVariables>
export const AllChatConversationEventsDocument = gql`
  query allChatConversationEvents(
    $conversationId: ID!
    $sorts: [ChatConversationEventSort!]
    $page: Int
    $perPage: Int
  ) {
    allChatConversationEvents(
      conversationId: $conversationId
      sorts: $sorts
      page: $page
      perPage: $perPage
    ) {
      totalCount
      pageCount
      chatConversationEvents {
        id
        kind
        occurredAt
        snoozedUntil
        message {
          id
          value
          sentAt
          senderKind
          sender {
            id
            fullName
            avatar
          }
        }
        note {
          id
          value
          createdAt
          createdBy {
            id
            fullName
            avatar
          }
        }
        performedBy {
          id
          fullName
        }
        assignedTo {
          id
          fullName
        }
      }
    }
  }
`

/**
 * __useAllChatConversationEventsQuery__
 *
 * To run a query within a React component, call `useAllChatConversationEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatConversationEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatConversationEventsQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      sorts: // value for 'sorts'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useAllChatConversationEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAllChatConversationEventsQuery,
    IAllChatConversationEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllChatConversationEventsQuery, IAllChatConversationEventsQueryVariables>(
    AllChatConversationEventsDocument,
    options
  )
}
export function useAllChatConversationEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllChatConversationEventsQuery,
    IAllChatConversationEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAllChatConversationEventsQuery,
    IAllChatConversationEventsQueryVariables
  >(AllChatConversationEventsDocument, options)
}
export type AllChatConversationEventsQueryHookResult = ReturnType<
  typeof useAllChatConversationEventsQuery
>
export type AllChatConversationEventsLazyQueryHookResult = ReturnType<
  typeof useAllChatConversationEventsLazyQuery
>
export type AllChatConversationEventsQueryResult = Apollo.QueryResult<
  IAllChatConversationEventsQuery,
  IAllChatConversationEventsQueryVariables
>
export const AllChatConversationsDocument = gql`
  query allChatConversations(
    $kinds: [ChatConversationKind!]
    $states: [ChatConversationState!]
    $sorts: [ChatConversationSort!]
    $forCoach: Boolean
    $active: Boolean
    $userIds: [ID!]
    $page: Int
    $perPage: Int
  ) {
    allChatConversations(
      kinds: $kinds
      states: $states
      sorts: $sorts
      forCoach: $forCoach
      active: $active
      userIds: $userIds
      page: $page
      perPage: $perPage
    ) {
      totalCount
      chatConversations {
        id
        kind
        state
        read
        lastMessage {
          id
          value
          sentAt
        }
        lastNote {
          id
          value
          createdAt
        }
        user {
          id
          fullName
        }
        intercomCreatedAt
      }
    }
  }
`

/**
 * __useAllChatConversationsQuery__
 *
 * To run a query within a React component, call `useAllChatConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatConversationsQuery({
 *   variables: {
 *      kinds: // value for 'kinds'
 *      states: // value for 'states'
 *      sorts: // value for 'sorts'
 *      forCoach: // value for 'forCoach'
 *      active: // value for 'active'
 *      userIds: // value for 'userIds'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useAllChatConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllChatConversationsQuery,
    IAllChatConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllChatConversationsQuery, IAllChatConversationsQueryVariables>(
    AllChatConversationsDocument,
    options
  )
}
export function useAllChatConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllChatConversationsQuery,
    IAllChatConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllChatConversationsQuery, IAllChatConversationsQueryVariables>(
    AllChatConversationsDocument,
    options
  )
}
export type AllChatConversationsQueryHookResult = ReturnType<typeof useAllChatConversationsQuery>
export type AllChatConversationsLazyQueryHookResult = ReturnType<
  typeof useAllChatConversationsLazyQuery
>
export type AllChatConversationsQueryResult = Apollo.QueryResult<
  IAllChatConversationsQuery,
  IAllChatConversationsQueryVariables
>
export const ChatConversationDocument = gql`
  query chatConversation($id: ID!) {
    chatConversation(id: $id) {
      ...CoreChatConversationFields
    }
  }
  ${CoreChatConversationFieldsFragmentDoc}
`

/**
 * __useChatConversationQuery__
 *
 * To run a query within a React component, call `useChatConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatConversationQuery(
  baseOptions: Apollo.QueryHookOptions<IChatConversationQuery, IChatConversationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IChatConversationQuery, IChatConversationQueryVariables>(
    ChatConversationDocument,
    options
  )
}
export function useChatConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IChatConversationQuery, IChatConversationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IChatConversationQuery, IChatConversationQueryVariables>(
    ChatConversationDocument,
    options
  )
}
export type ChatConversationQueryHookResult = ReturnType<typeof useChatConversationQuery>
export type ChatConversationLazyQueryHookResult = ReturnType<typeof useChatConversationLazyQuery>
export type ChatConversationQueryResult = Apollo.QueryResult<
  IChatConversationQuery,
  IChatConversationQueryVariables
>
export const ChatbotMessageDocument = gql`
  query chatbotMessage($id: ID!) {
    chatbotMessage(id: $id) {
      id
      content
      userMessage {
        id
        content
        question
        knowledgeQuestions {
          ...CoreKnowledgeQuestionFields
        }
      }
    }
  }
  ${CoreKnowledgeQuestionFieldsFragmentDoc}
`

/**
 * __useChatbotMessageQuery__
 *
 * To run a query within a React component, call `useChatbotMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatbotMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatbotMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatbotMessageQuery(
  baseOptions: Apollo.QueryHookOptions<IChatbotMessageQuery, IChatbotMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IChatbotMessageQuery, IChatbotMessageQueryVariables>(
    ChatbotMessageDocument,
    options
  )
}
export function useChatbotMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IChatbotMessageQuery, IChatbotMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IChatbotMessageQuery, IChatbotMessageQueryVariables>(
    ChatbotMessageDocument,
    options
  )
}
export type ChatbotMessageQueryHookResult = ReturnType<typeof useChatbotMessageQuery>
export type ChatbotMessageLazyQueryHookResult = ReturnType<typeof useChatbotMessageLazyQuery>
export type ChatbotMessageQueryResult = Apollo.QueryResult<
  IChatbotMessageQuery,
  IChatbotMessageQueryVariables
>
export const ClientConfigDocument = gql`
  query clientConfig {
    clientConfig {
      sensorSettings {
        baselineAdjustmentOptions {
          text
          value
        }
      }
      score {
        warning
        danger
        max
      }
      insights {
        statistics {
          name
          metric {
            ranges {
              from
              to
              type
              rating
            }
            precision
            units
          }
          imperial {
            ranges {
              from
              to
              type
              rating
            }
            precision
            units
          }
        }
      }
      healthSettings {
        glucoseLowOptions {
          text
          value
        }
        glucoseHighOptions {
          text
          value
        }
        ketoneLowOptions {
          text
          value
        }
        ketoneHighOptions {
          text
          value
        }
        macroGoalSettings {
          caloriesMax
          proteinMax
          carbsMax
          fatMax
        }
        unitSystemOptions {
          text
          value
        }
        scanReminderOptions {
          text
          value
        }
        dashboardGraphOptions {
          text
          value
        }
      }
      journal {
        range {
          min
          max
        }
        measurementTypes {
          type
          reverse
        }
      }
      chatbot {
        responseTimeoutInSeconds
      }
      goalOptions {
        text
        value
      }
      goalMetricOptions {
        text
        value
      }
      healthMetrics {
        key
        label
        unit
        kind
      }
      tickets {
        reasonDefs {
          key
          kind
          title
        }
      }
      dailyActions {
        key
        title
        description
        category
      }
    }
  }
`

/**
 * __useClientConfigQuery__
 *
 * To run a query within a React component, call `useClientConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<IClientConfigQuery, IClientConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IClientConfigQuery, IClientConfigQueryVariables>(
    ClientConfigDocument,
    options
  )
}
export function useClientConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IClientConfigQuery, IClientConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IClientConfigQuery, IClientConfigQueryVariables>(
    ClientConfigDocument,
    options
  )
}
export type ClientConfigQueryHookResult = ReturnType<typeof useClientConfigQuery>
export type ClientConfigLazyQueryHookResult = ReturnType<typeof useClientConfigLazyQuery>
export type ClientConfigQueryResult = Apollo.QueryResult<
  IClientConfigQuery,
  IClientConfigQueryVariables
>
export const AllCoursesDocument = gql`
  query allCourses {
    allCourses {
      programs {
        id
        contentId
        position
        progress {
          complete
        }
        modules {
          id
          contentId
          position
          progress {
            complete
          }
          lessons {
            id
            contentId
            position
            progress {
              complete
            }
          }
        }
      }
    }
  }
`

/**
 * __useAllCoursesQuery__
 *
 * To run a query within a React component, call `useAllCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllCoursesQuery, IAllCoursesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllCoursesQuery, IAllCoursesQueryVariables>(AllCoursesDocument, options)
}
export function useAllCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllCoursesQuery, IAllCoursesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllCoursesQuery, IAllCoursesQueryVariables>(
    AllCoursesDocument,
    options
  )
}
export type AllCoursesQueryHookResult = ReturnType<typeof useAllCoursesQuery>
export type AllCoursesLazyQueryHookResult = ReturnType<typeof useAllCoursesLazyQuery>
export type AllCoursesQueryResult = Apollo.QueryResult<IAllCoursesQuery, IAllCoursesQueryVariables>
export const AllCourseCohortSessionMembersDocument = gql`
  query allCourseCohortSessionMembers($sessionId: ID!) {
    allCourseCohortSessionMembers(sessionId: $sessionId) {
      members {
        id
        user {
          id
          fullName
        }
        attendance {
          ...CoreCohortSessionAttendanceFields
        }
        dppHealthDataRecordsChecklist {
          hbA1cChecklist {
            state
          }
          weightChecklist {
            state
          }
          physicalActivityChecklist {
            state
          }
        }
      }
      totalCount
    }
  }
  ${CoreCohortSessionAttendanceFieldsFragmentDoc}
`

/**
 * __useAllCourseCohortSessionMembersQuery__
 *
 * To run a query within a React component, call `useAllCourseCohortSessionMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCourseCohortSessionMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCourseCohortSessionMembersQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAllCourseCohortSessionMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAllCourseCohortSessionMembersQuery,
    IAllCourseCohortSessionMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAllCourseCohortSessionMembersQuery,
    IAllCourseCohortSessionMembersQueryVariables
  >(AllCourseCohortSessionMembersDocument, options)
}
export function useAllCourseCohortSessionMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllCourseCohortSessionMembersQuery,
    IAllCourseCohortSessionMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAllCourseCohortSessionMembersQuery,
    IAllCourseCohortSessionMembersQueryVariables
  >(AllCourseCohortSessionMembersDocument, options)
}
export type AllCourseCohortSessionMembersQueryHookResult = ReturnType<
  typeof useAllCourseCohortSessionMembersQuery
>
export type AllCourseCohortSessionMembersLazyQueryHookResult = ReturnType<
  typeof useAllCourseCohortSessionMembersLazyQuery
>
export type AllCourseCohortSessionMembersQueryResult = Apollo.QueryResult<
  IAllCourseCohortSessionMembersQuery,
  IAllCourseCohortSessionMembersQueryVariables
>
export const AllCourseCohortSessionsDocument = gql`
  query allCourseCohortSessions(
    $cohortId: ID!
    $page: Int
    $perPage: Int
    $sorts: [CohortSessionSort!]
    $dynamicFilters: [CohortSessionDynamicFilterItem!]
  ) {
    allCourseCohortSessions(
      cohortId: $cohortId
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      sessions {
        ...CoreCohortSessionFields
      }
    }
  }
  ${CoreCohortSessionFieldsFragmentDoc}
`

/**
 * __useAllCourseCohortSessionsQuery__
 *
 * To run a query within a React component, call `useAllCourseCohortSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCourseCohortSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCourseCohortSessionsQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllCourseCohortSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAllCourseCohortSessionsQuery,
    IAllCourseCohortSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllCourseCohortSessionsQuery, IAllCourseCohortSessionsQueryVariables>(
    AllCourseCohortSessionsDocument,
    options
  )
}
export function useAllCourseCohortSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllCourseCohortSessionsQuery,
    IAllCourseCohortSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllCourseCohortSessionsQuery, IAllCourseCohortSessionsQueryVariables>(
    AllCourseCohortSessionsDocument,
    options
  )
}
export type AllCourseCohortSessionsQueryHookResult = ReturnType<
  typeof useAllCourseCohortSessionsQuery
>
export type AllCourseCohortSessionsLazyQueryHookResult = ReturnType<
  typeof useAllCourseCohortSessionsLazyQuery
>
export type AllCourseCohortSessionsQueryResult = Apollo.QueryResult<
  IAllCourseCohortSessionsQuery,
  IAllCourseCohortSessionsQueryVariables
>
export const AllCourseCohortsDocument = gql`
  query allCourseCohorts(
    $page: Int
    $perPage: Int
    $sorts: [CohortSort!]
    $dynamicFilters: [CohortDynamicFilterItem!]
  ) {
    allCourseCohorts(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      cohorts {
        ...CoreCohortFields
      }
    }
  }
  ${CoreCohortFieldsFragmentDoc}
`

/**
 * __useAllCourseCohortsQuery__
 *
 * To run a query within a React component, call `useAllCourseCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCourseCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCourseCohortsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllCourseCohortsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllCourseCohortsQuery, IAllCourseCohortsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllCourseCohortsQuery, IAllCourseCohortsQueryVariables>(
    AllCourseCohortsDocument,
    options
  )
}
export function useAllCourseCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllCourseCohortsQuery, IAllCourseCohortsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllCourseCohortsQuery, IAllCourseCohortsQueryVariables>(
    AllCourseCohortsDocument,
    options
  )
}
export type AllCourseCohortsQueryHookResult = ReturnType<typeof useAllCourseCohortsQuery>
export type AllCourseCohortsLazyQueryHookResult = ReturnType<typeof useAllCourseCohortsLazyQuery>
export type AllCourseCohortsQueryResult = Apollo.QueryResult<
  IAllCourseCohortsQuery,
  IAllCourseCohortsQueryVariables
>
export const AllDppInsuranceParticipantsDocument = gql`
  query allDppInsuranceParticipants(
    $page: Int
    $perPage: Int
    $sorts: [DppInsuranceParticipantSort!]
    $dynamicFilters: [DppInsuranceParticipantDynamicFilterItem!]
  ) {
    allDppInsuranceParticipants(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      participants {
        id
        state
        action
        partnerId
        orgCode
        userId
        prefixedCohortId
        firstName
        middleName
        lastName
        dateOfBirth
        addressStreet
        addressStreet2
        addressCity
        addressState
        addressZipCode
        preferredPhoneNumber
        homeNumber
        workPhone
        cellPhone
        email
        notifications
        insuranceType
        insurancePlan
        memberId
        groupNumber
        enrollMot
        enrollHc
        payerSource
        aian
        asian
        black
        hispanic
        mena
        nhpi
        white
        ethRaceInfo
        sex
        gender
        height
        initialWeight
        edu
        diffHear
        diffSee
        diffMem
        diffWalk
        diffDress
        diffErrand
        a1c
        glucTest
        gdm
        riskTest
      }
    }
  }
`

/**
 * __useAllDppInsuranceParticipantsQuery__
 *
 * To run a query within a React component, call `useAllDppInsuranceParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDppInsuranceParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDppInsuranceParticipantsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllDppInsuranceParticipantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllDppInsuranceParticipantsQuery,
    IAllDppInsuranceParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAllDppInsuranceParticipantsQuery,
    IAllDppInsuranceParticipantsQueryVariables
  >(AllDppInsuranceParticipantsDocument, options)
}
export function useAllDppInsuranceParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllDppInsuranceParticipantsQuery,
    IAllDppInsuranceParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAllDppInsuranceParticipantsQuery,
    IAllDppInsuranceParticipantsQueryVariables
  >(AllDppInsuranceParticipantsDocument, options)
}
export type AllDppInsuranceParticipantsQueryHookResult = ReturnType<
  typeof useAllDppInsuranceParticipantsQuery
>
export type AllDppInsuranceParticipantsLazyQueryHookResult = ReturnType<
  typeof useAllDppInsuranceParticipantsLazyQuery
>
export type AllDppInsuranceParticipantsQueryResult = Apollo.QueryResult<
  IAllDppInsuranceParticipantsQuery,
  IAllDppInsuranceParticipantsQueryVariables
>
export const AllDppInsuranceSessionsDocument = gql`
  query allDppInsuranceSessions(
    $page: Int
    $perPage: Int
    $sorts: [DppInsuranceSessionSort!]
    $dynamicFilters: [DppInsuranceSessionDynamicFilterItem!]
  ) {
    allDppInsuranceSessions(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      sessions {
        id
        state
        email
        name
        deliveryMode
        sessionType
        date
        weight
        physicalActivity
        instructorEmail
        payerSource
      }
    }
  }
`

/**
 * __useAllDppInsuranceSessionsQuery__
 *
 * To run a query within a React component, call `useAllDppInsuranceSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDppInsuranceSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDppInsuranceSessionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllDppInsuranceSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IAllDppInsuranceSessionsQuery,
    IAllDppInsuranceSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllDppInsuranceSessionsQuery, IAllDppInsuranceSessionsQueryVariables>(
    AllDppInsuranceSessionsDocument,
    options
  )
}
export function useAllDppInsuranceSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllDppInsuranceSessionsQuery,
    IAllDppInsuranceSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllDppInsuranceSessionsQuery, IAllDppInsuranceSessionsQueryVariables>(
    AllDppInsuranceSessionsDocument,
    options
  )
}
export type AllDppInsuranceSessionsQueryHookResult = ReturnType<
  typeof useAllDppInsuranceSessionsQuery
>
export type AllDppInsuranceSessionsLazyQueryHookResult = ReturnType<
  typeof useAllDppInsuranceSessionsLazyQuery
>
export type AllDppInsuranceSessionsQueryResult = Apollo.QueryResult<
  IAllDppInsuranceSessionsQuery,
  IAllDppInsuranceSessionsQueryVariables
>
export const AllDppUsersDocument = gql`
  query allDppUsers(
    $page: Int
    $perPage: Int
    $sorts: [DppUserSort!]
    $dynamicFilters: [DppUserDynamicFilterItem!]
  ) {
    allDppUsers(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      users {
        ...CoreDppUserFields
      }
    }
  }
  ${CoreDppUserFieldsFragmentDoc}
`

/**
 * __useAllDppUsersQuery__
 *
 * To run a query within a React component, call `useAllDppUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDppUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDppUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllDppUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllDppUsersQuery, IAllDppUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllDppUsersQuery, IAllDppUsersQueryVariables>(
    AllDppUsersDocument,
    options
  )
}
export function useAllDppUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllDppUsersQuery, IAllDppUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllDppUsersQuery, IAllDppUsersQueryVariables>(
    AllDppUsersDocument,
    options
  )
}
export type AllDppUsersQueryHookResult = ReturnType<typeof useAllDppUsersQuery>
export type AllDppUsersLazyQueryHookResult = ReturnType<typeof useAllDppUsersLazyQuery>
export type AllDppUsersQueryResult = Apollo.QueryResult<
  IAllDppUsersQuery,
  IAllDppUsersQueryVariables
>
export const CohortSuggestedSessionsDocument = gql`
  query cohortSuggestedSessions($kind: CoursesCohortKind!, $startTime: ISO8601DateTime!) {
    cohortSuggestedSessions(kind: $kind, startTime: $startTime) {
      suggestions {
        name
        scheduledAt
        kind
      }
    }
  }
`

/**
 * __useCohortSuggestedSessionsQuery__
 *
 * To run a query within a React component, call `useCohortSuggestedSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortSuggestedSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortSuggestedSessionsQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useCohortSuggestedSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICohortSuggestedSessionsQuery,
    ICohortSuggestedSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICohortSuggestedSessionsQuery, ICohortSuggestedSessionsQueryVariables>(
    CohortSuggestedSessionsDocument,
    options
  )
}
export function useCohortSuggestedSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICohortSuggestedSessionsQuery,
    ICohortSuggestedSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICohortSuggestedSessionsQuery, ICohortSuggestedSessionsQueryVariables>(
    CohortSuggestedSessionsDocument,
    options
  )
}
export type CohortSuggestedSessionsQueryHookResult = ReturnType<
  typeof useCohortSuggestedSessionsQuery
>
export type CohortSuggestedSessionsLazyQueryHookResult = ReturnType<
  typeof useCohortSuggestedSessionsLazyQuery
>
export type CohortSuggestedSessionsQueryResult = Apollo.QueryResult<
  ICohortSuggestedSessionsQuery,
  ICohortSuggestedSessionsQueryVariables
>
export const CourseCohortDocument = gql`
  query courseCohort($id: ID!) {
    courseCohort(id: $id) {
      ...CoreCohortFields
    }
  }
  ${CoreCohortFieldsFragmentDoc}
`

/**
 * __useCourseCohortQuery__
 *
 * To run a query within a React component, call `useCourseCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCohortQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseCohortQuery(
  baseOptions: Apollo.QueryHookOptions<ICourseCohortQuery, ICourseCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICourseCohortQuery, ICourseCohortQueryVariables>(
    CourseCohortDocument,
    options
  )
}
export function useCourseCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICourseCohortQuery, ICourseCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICourseCohortQuery, ICourseCohortQueryVariables>(
    CourseCohortDocument,
    options
  )
}
export type CourseCohortQueryHookResult = ReturnType<typeof useCourseCohortQuery>
export type CourseCohortLazyQueryHookResult = ReturnType<typeof useCourseCohortLazyQuery>
export type CourseCohortQueryResult = Apollo.QueryResult<
  ICourseCohortQuery,
  ICourseCohortQueryVariables
>
export const CourseCohortInstructorsDocument = gql`
  query courseCohortInstructors($sorts: [UserSort!], $dynamicFilters: [UserDynamicFilterItem!]) {
    allUsers(sorts: $sorts, dynamicFilters: $dynamicFilters) {
      users {
        id
        fullName
      }
    }
  }
`

/**
 * __useCourseCohortInstructorsQuery__
 *
 * To run a query within a React component, call `useCourseCohortInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCohortInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCohortInstructorsQuery({
 *   variables: {
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useCourseCohortInstructorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ICourseCohortInstructorsQuery,
    ICourseCohortInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICourseCohortInstructorsQuery, ICourseCohortInstructorsQueryVariables>(
    CourseCohortInstructorsDocument,
    options
  )
}
export function useCourseCohortInstructorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICourseCohortInstructorsQuery,
    ICourseCohortInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICourseCohortInstructorsQuery, ICourseCohortInstructorsQueryVariables>(
    CourseCohortInstructorsDocument,
    options
  )
}
export type CourseCohortInstructorsQueryHookResult = ReturnType<
  typeof useCourseCohortInstructorsQuery
>
export type CourseCohortInstructorsLazyQueryHookResult = ReturnType<
  typeof useCourseCohortInstructorsLazyQuery
>
export type CourseCohortInstructorsQueryResult = Apollo.QueryResult<
  ICourseCohortInstructorsQuery,
  ICourseCohortInstructorsQueryVariables
>
export const CourseCohortSessionDocument = gql`
  query courseCohortSession($id: ID!) {
    courseCohortSession(id: $id) {
      ...CoreCohortSessionFields
    }
  }
  ${CoreCohortSessionFieldsFragmentDoc}
`

/**
 * __useCourseCohortSessionQuery__
 *
 * To run a query within a React component, call `useCourseCohortSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCohortSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCohortSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseCohortSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICourseCohortSessionQuery,
    ICourseCohortSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICourseCohortSessionQuery, ICourseCohortSessionQueryVariables>(
    CourseCohortSessionDocument,
    options
  )
}
export function useCourseCohortSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICourseCohortSessionQuery,
    ICourseCohortSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICourseCohortSessionQuery, ICourseCohortSessionQueryVariables>(
    CourseCohortSessionDocument,
    options
  )
}
export type CourseCohortSessionQueryHookResult = ReturnType<typeof useCourseCohortSessionQuery>
export type CourseCohortSessionLazyQueryHookResult = ReturnType<
  typeof useCourseCohortSessionLazyQuery
>
export type CourseCohortSessionQueryResult = Apollo.QueryResult<
  ICourseCohortSessionQuery,
  ICourseCohortSessionQueryVariables
>
export const CourseCohortsAutocompleteDocument = gql`
  query courseCohortsAutocomplete($dynamicFilters: [CohortDynamicFilterItem!]) {
    allCourseCohorts(dynamicFilters: $dynamicFilters) {
      totalCount
      cohorts {
        id
        name
        state
        members {
          id
        }
      }
    }
  }
`

/**
 * __useCourseCohortsAutocompleteQuery__
 *
 * To run a query within a React component, call `useCourseCohortsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCohortsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCohortsAutocompleteQuery({
 *   variables: {
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useCourseCohortsAutocompleteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ICourseCohortsAutocompleteQuery,
    ICourseCohortsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICourseCohortsAutocompleteQuery, ICourseCohortsAutocompleteQueryVariables>(
    CourseCohortsAutocompleteDocument,
    options
  )
}
export function useCourseCohortsAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICourseCohortsAutocompleteQuery,
    ICourseCohortsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICourseCohortsAutocompleteQuery,
    ICourseCohortsAutocompleteQueryVariables
  >(CourseCohortsAutocompleteDocument, options)
}
export type CourseCohortsAutocompleteQueryHookResult = ReturnType<
  typeof useCourseCohortsAutocompleteQuery
>
export type CourseCohortsAutocompleteLazyQueryHookResult = ReturnType<
  typeof useCourseCohortsAutocompleteLazyQuery
>
export type CourseCohortsAutocompleteQueryResult = Apollo.QueryResult<
  ICourseCohortsAutocompleteQuery,
  ICourseCohortsAutocompleteQueryVariables
>
export const CurrentAdminDocument = gql`
  query currentAdmin {
    currentAdmin {
      id
      firstName
      lastName
      fullName
      email
      nutrisenseAdmin
      avatar
      fullName
      isSupport
      isCoach
      role
    }
  }
`

/**
 * __useCurrentAdminQuery__
 *
 * To run a query within a React component, call `useCurrentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<ICurrentAdminQuery, ICurrentAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICurrentAdminQuery, ICurrentAdminQueryVariables>(
    CurrentAdminDocument,
    options
  )
}
export function useCurrentAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICurrentAdminQuery, ICurrentAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICurrentAdminQuery, ICurrentAdminQueryVariables>(
    CurrentAdminDocument,
    options
  )
}
export type CurrentAdminQueryHookResult = ReturnType<typeof useCurrentAdminQuery>
export type CurrentAdminLazyQueryHookResult = ReturnType<typeof useCurrentAdminLazyQuery>
export type CurrentAdminQueryResult = Apollo.QueryResult<
  ICurrentAdminQuery,
  ICurrentAdminQueryVariables
>
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...User
    }
  }
  ${UserFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<ICurrentUserQuery, ICurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICurrentUserQuery, ICurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  )
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ICurrentUserQuery, ICurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICurrentUserQuery, ICurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<
  ICurrentUserQuery,
  ICurrentUserQueryVariables
>
export const ListUserDailyActionsDocument = gql`
  query listUserDailyActions(
    $startDate: ISO8601Date
    $endDate: ISO8601Date!
    $status: UserDailyActionStatus
  ) {
    listUserDailyActions(startDate: $startDate, endDate: $endDate, status: $status) {
      dailyActions {
        id
        title
        description
        status
        kind
        icon
        statKey
        autocomplete
        category
        createdAt
        updatedAt
      }
      completionCounts {
        dailyActionId
        completionCount
      }
    }
  }
`

/**
 * __useListUserDailyActionsQuery__
 *
 * To run a query within a React component, call `useListUserDailyActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserDailyActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserDailyActionsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListUserDailyActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IListUserDailyActionsQuery,
    IListUserDailyActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IListUserDailyActionsQuery, IListUserDailyActionsQueryVariables>(
    ListUserDailyActionsDocument,
    options
  )
}
export function useListUserDailyActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IListUserDailyActionsQuery,
    IListUserDailyActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IListUserDailyActionsQuery, IListUserDailyActionsQueryVariables>(
    ListUserDailyActionsDocument,
    options
  )
}
export type ListUserDailyActionsQueryHookResult = ReturnType<typeof useListUserDailyActionsQuery>
export type ListUserDailyActionsLazyQueryHookResult = ReturnType<
  typeof useListUserDailyActionsLazyQuery
>
export type ListUserDailyActionsQueryResult = Apollo.QueryResult<
  IListUserDailyActionsQuery,
  IListUserDailyActionsQueryVariables
>
export const DistinctUserPropertyValuesDocument = gql`
  query distinctUserPropertyValues($value: String!, $kind: String!, $limit: Int) {
    distinctUserPropertyValues(value: $value, kind: $kind, limit: $limit)
  }
`

/**
 * __useDistinctUserPropertyValuesQuery__
 *
 * To run a query within a React component, call `useDistinctUserPropertyValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistinctUserPropertyValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistinctUserPropertyValuesQuery({
 *   variables: {
 *      value: // value for 'value'
 *      kind: // value for 'kind'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDistinctUserPropertyValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IDistinctUserPropertyValuesQuery,
    IDistinctUserPropertyValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IDistinctUserPropertyValuesQuery,
    IDistinctUserPropertyValuesQueryVariables
  >(DistinctUserPropertyValuesDocument, options)
}
export function useDistinctUserPropertyValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IDistinctUserPropertyValuesQuery,
    IDistinctUserPropertyValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IDistinctUserPropertyValuesQuery,
    IDistinctUserPropertyValuesQueryVariables
  >(DistinctUserPropertyValuesDocument, options)
}
export type DistinctUserPropertyValuesQueryHookResult = ReturnType<
  typeof useDistinctUserPropertyValuesQuery
>
export type DistinctUserPropertyValuesLazyQueryHookResult = ReturnType<
  typeof useDistinctUserPropertyValuesLazyQuery
>
export type DistinctUserPropertyValuesQueryResult = Apollo.QueryResult<
  IDistinctUserPropertyValuesQuery,
  IDistinctUserPropertyValuesQueryVariables
>
export const EligibleProductsDocument = gql`
  query eligibleProducts($userId: ID!, $products: [BillingProduct!]!) {
    eligibleProducts(userId: $userId, products: $products) {
      products {
        id
        key
        title
        price
      }
    }
  }
`

/**
 * __useEligibleProductsQuery__
 *
 * To run a query within a React component, call `useEligibleProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibleProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibleProductsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useEligibleProductsQuery(
  baseOptions: Apollo.QueryHookOptions<IEligibleProductsQuery, IEligibleProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IEligibleProductsQuery, IEligibleProductsQueryVariables>(
    EligibleProductsDocument,
    options
  )
}
export function useEligibleProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEligibleProductsQuery, IEligibleProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IEligibleProductsQuery, IEligibleProductsQueryVariables>(
    EligibleProductsDocument,
    options
  )
}
export type EligibleProductsQueryHookResult = ReturnType<typeof useEligibleProductsQuery>
export type EligibleProductsLazyQueryHookResult = ReturnType<typeof useEligibleProductsLazyQuery>
export type EligibleProductsQueryResult = Apollo.QueryResult<
  IEligibleProductsQuery,
  IEligibleProductsQueryVariables
>
export const EligibleUpdateProductsDocument = gql`
  query eligibleUpdateProducts($userId: ID!) {
    eligibleUpdateProducts(userId: $userId) {
      eligibleUpdateProducts {
        product {
          key
          title
        }
        feeRules {
          fee
          paidCyclesCount
        }
      }
    }
  }
`

/**
 * __useEligibleUpdateProductsQuery__
 *
 * To run a query within a React component, call `useEligibleUpdateProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibleUpdateProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibleUpdateProductsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEligibleUpdateProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IEligibleUpdateProductsQuery,
    IEligibleUpdateProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IEligibleUpdateProductsQuery, IEligibleUpdateProductsQueryVariables>(
    EligibleUpdateProductsDocument,
    options
  )
}
export function useEligibleUpdateProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IEligibleUpdateProductsQuery,
    IEligibleUpdateProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IEligibleUpdateProductsQuery, IEligibleUpdateProductsQueryVariables>(
    EligibleUpdateProductsDocument,
    options
  )
}
export type EligibleUpdateProductsQueryHookResult = ReturnType<
  typeof useEligibleUpdateProductsQuery
>
export type EligibleUpdateProductsLazyQueryHookResult = ReturnType<
  typeof useEligibleUpdateProductsLazyQuery
>
export type EligibleUpdateProductsQueryResult = Apollo.QueryResult<
  IEligibleUpdateProductsQuery,
  IEligibleUpdateProductsQueryVariables
>
export const FinancialsAllChargesDocument = gql`
  query financialsAllCharges(
    $page: Int
    $perPage: Int
    $sorts: [ChargeSort!]
    $dynamicFilters: [ChargeDynamicFilterItem!]
  ) {
    allCharges(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      pageCount
      charges {
        id
        stripeId
        amount
        amountRefunded
        paid
        status
        disputeStripeId
        failureMessage
        disputed
        refunded
        created
        refundable
        invoice {
          stripeId
          subscription {
            stripeId
          }
        }
        user {
          id
          fullName
          email
          phoneNumber
        }
      }
    }
  }
`

/**
 * __useFinancialsAllChargesQuery__
 *
 * To run a query within a React component, call `useFinancialsAllChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialsAllChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialsAllChargesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useFinancialsAllChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IFinancialsAllChargesQuery,
    IFinancialsAllChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IFinancialsAllChargesQuery, IFinancialsAllChargesQueryVariables>(
    FinancialsAllChargesDocument,
    options
  )
}
export function useFinancialsAllChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IFinancialsAllChargesQuery,
    IFinancialsAllChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IFinancialsAllChargesQuery, IFinancialsAllChargesQueryVariables>(
    FinancialsAllChargesDocument,
    options
  )
}
export type FinancialsAllChargesQueryHookResult = ReturnType<typeof useFinancialsAllChargesQuery>
export type FinancialsAllChargesLazyQueryHookResult = ReturnType<
  typeof useFinancialsAllChargesLazyQuery
>
export type FinancialsAllChargesQueryResult = Apollo.QueryResult<
  IFinancialsAllChargesQuery,
  IFinancialsAllChargesQueryVariables
>
export const FinancialsAllInvoicesDocument = gql`
  query financialsAllInvoices(
    $page: Int
    $perPage: Int
    $sorts: [InvoiceSort!]
    $dynamicFilters: [InvoiceDynamicFilterItem!]
  ) {
    allInvoices(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      pageCount
      invoices {
        id
        stripeId
        paidAt
        receiptNumber
        downloadUrl
        created
        subscription {
          stripeId
        }
        total
        user {
          id
          fullName
          email
          stripeId
        }
      }
    }
  }
`

/**
 * __useFinancialsAllInvoicesQuery__
 *
 * To run a query within a React component, call `useFinancialsAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialsAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialsAllInvoicesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useFinancialsAllInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IFinancialsAllInvoicesQuery,
    IFinancialsAllInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IFinancialsAllInvoicesQuery, IFinancialsAllInvoicesQueryVariables>(
    FinancialsAllInvoicesDocument,
    options
  )
}
export function useFinancialsAllInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IFinancialsAllInvoicesQuery,
    IFinancialsAllInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IFinancialsAllInvoicesQuery, IFinancialsAllInvoicesQueryVariables>(
    FinancialsAllInvoicesDocument,
    options
  )
}
export type FinancialsAllInvoicesQueryHookResult = ReturnType<typeof useFinancialsAllInvoicesQuery>
export type FinancialsAllInvoicesLazyQueryHookResult = ReturnType<
  typeof useFinancialsAllInvoicesLazyQuery
>
export type FinancialsAllInvoicesQueryResult = Apollo.QueryResult<
  IFinancialsAllInvoicesQuery,
  IFinancialsAllInvoicesQueryVariables
>
export const FinancialsAllSubscriptionsDocument = gql`
  query financialsAllSubscriptions(
    $page: Int
    $perPage: Int
    $sorts: [SubscriptionSort!]
    $dynamicFilters: [SubscriptionDynamicFilterItem!]
  ) {
    allSubscriptions(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      pageCount
      subscriptions {
        id
        stripeId
        stripeStatus
        paidMonthsCount
        pausedMonthsCount
        cancelAt
        canceledAt
        createdAt
        currentPeriodEndAt
        endAt
        resumesAt
        startAt
        subscribedAt
        trialEndAt
        updatedAt
        paused
        plans {
          nickname
        }
        user {
          id
          fullName
          email
        }
      }
    }
  }
`

/**
 * __useFinancialsAllSubscriptionsQuery__
 *
 * To run a query within a React component, call `useFinancialsAllSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialsAllSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialsAllSubscriptionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useFinancialsAllSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IFinancialsAllSubscriptionsQuery,
    IFinancialsAllSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IFinancialsAllSubscriptionsQuery,
    IFinancialsAllSubscriptionsQueryVariables
  >(FinancialsAllSubscriptionsDocument, options)
}
export function useFinancialsAllSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IFinancialsAllSubscriptionsQuery,
    IFinancialsAllSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IFinancialsAllSubscriptionsQuery,
    IFinancialsAllSubscriptionsQueryVariables
  >(FinancialsAllSubscriptionsDocument, options)
}
export type FinancialsAllSubscriptionsQueryHookResult = ReturnType<
  typeof useFinancialsAllSubscriptionsQuery
>
export type FinancialsAllSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useFinancialsAllSubscriptionsLazyQuery
>
export type FinancialsAllSubscriptionsQueryResult = Apollo.QueryResult<
  IFinancialsAllSubscriptionsQuery,
  IFinancialsAllSubscriptionsQueryVariables
>
export const CreateHealthMetricDocument = gql`
  mutation createHealthMetric(
    $date: ISO8601Date!
    $value: Float!
    $kind: HealthDataMetricBiomarker!
  ) {
    createHealthMetric(date: $date, value: $value, kind: $kind) {
      id
      date
      value
      kind
    }
  }
`
export type ICreateHealthMetricMutationFn = Apollo.MutationFunction<
  ICreateHealthMetricMutation,
  ICreateHealthMetricMutationVariables
>

/**
 * __useCreateHealthMetricMutation__
 *
 * To run a mutation, you first call `useCreateHealthMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHealthMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHealthMetricMutation, { data, loading, error }] = useCreateHealthMetricMutation({
 *   variables: {
 *      date: // value for 'date'
 *      value: // value for 'value'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useCreateHealthMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateHealthMetricMutation,
    ICreateHealthMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateHealthMetricMutation, ICreateHealthMetricMutationVariables>(
    CreateHealthMetricDocument,
    options
  )
}
export type CreateHealthMetricMutationHookResult = ReturnType<typeof useCreateHealthMetricMutation>
export type CreateHealthMetricMutationResult = Apollo.MutationResult<ICreateHealthMetricMutation>
export type CreateHealthMetricMutationOptions = Apollo.BaseMutationOptions<
  ICreateHealthMetricMutation,
  ICreateHealthMetricMutationVariables
>
export const DeleteHealthMetricDocument = gql`
  mutation deleteHealthMetric($id: ID!) {
    deleteHealthMetric(id: $id) {
      success
    }
  }
`
export type IDeleteHealthMetricMutationFn = Apollo.MutationFunction<
  IDeleteHealthMetricMutation,
  IDeleteHealthMetricMutationVariables
>

/**
 * __useDeleteHealthMetricMutation__
 *
 * To run a mutation, you first call `useDeleteHealthMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHealthMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHealthMetricMutation, { data, loading, error }] = useDeleteHealthMetricMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHealthMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteHealthMetricMutation,
    IDeleteHealthMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteHealthMetricMutation, IDeleteHealthMetricMutationVariables>(
    DeleteHealthMetricDocument,
    options
  )
}
export type DeleteHealthMetricMutationHookResult = ReturnType<typeof useDeleteHealthMetricMutation>
export type DeleteHealthMetricMutationResult = Apollo.MutationResult<IDeleteHealthMetricMutation>
export type DeleteHealthMetricMutationOptions = Apollo.BaseMutationOptions<
  IDeleteHealthMetricMutation,
  IDeleteHealthMetricMutationVariables
>
export const HealthMetricsDocument = gql`
  query healthMetrics {
    healthMetrics {
      healthMetrics {
        kind
        title
        unit
        data {
          id
          value
          date
        }
      }
    }
  }
`

/**
 * __useHealthMetricsQuery__
 *
 * To run a query within a React component, call `useHealthMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<IHealthMetricsQuery, IHealthMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IHealthMetricsQuery, IHealthMetricsQueryVariables>(
    HealthMetricsDocument,
    options
  )
}
export function useHealthMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IHealthMetricsQuery, IHealthMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IHealthMetricsQuery, IHealthMetricsQueryVariables>(
    HealthMetricsDocument,
    options
  )
}
export type HealthMetricsQueryHookResult = ReturnType<typeof useHealthMetricsQuery>
export type HealthMetricsLazyQueryHookResult = ReturnType<typeof useHealthMetricsLazyQuery>
export type HealthMetricsQueryResult = Apollo.QueryResult<
  IHealthMetricsQuery,
  IHealthMetricsQueryVariables
>
export const UpdateHealthMetricDocument = gql`
  mutation updateHealthMetric($id: ID!, $date: ISO8601Date!, $value: Float!) {
    updateHealthMetric(id: $id, date: $date, value: $value) {
      id
      date
      value
      kind
    }
  }
`
export type IUpdateHealthMetricMutationFn = Apollo.MutationFunction<
  IUpdateHealthMetricMutation,
  IUpdateHealthMetricMutationVariables
>

/**
 * __useUpdateHealthMetricMutation__
 *
 * To run a mutation, you first call `useUpdateHealthMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthMetricMutation, { data, loading, error }] = useUpdateHealthMetricMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateHealthMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateHealthMetricMutation,
    IUpdateHealthMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateHealthMetricMutation, IUpdateHealthMetricMutationVariables>(
    UpdateHealthMetricDocument,
    options
  )
}
export type UpdateHealthMetricMutationHookResult = ReturnType<typeof useUpdateHealthMetricMutation>
export type UpdateHealthMetricMutationResult = Apollo.MutationResult<IUpdateHealthMetricMutation>
export type UpdateHealthMetricMutationOptions = Apollo.BaseMutationOptions<
  IUpdateHealthMetricMutation,
  IUpdateHealthMetricMutationVariables
>
export const HealthQuestionnaireApprovalsAllUsersDocument = gql`
  query HealthQuestionnaireApprovalsAllUsers(
    $page: Int
    $perPage: Int
    $sorts: [UserSort!]
    $dynamicFilters: [UserDynamicFilterItem!]
    $forHqApproval: Boolean
  ) {
    allUsers(
      page: $page
      perPage: $perPage
      sorts: $sorts
      dynamicFilters: $dynamicFilters
      forHqApproval: $forHqApproval
    ) {
      totalCount
      users {
        id
        fullName
        email
        dateOfBirth
        age
        phoneNumber
        lastPrescriptionApprovalRequest {
          state
          createdAt
        }
        dexcomSensorPromptUiState
        address {
          id
          street
          street2
          city
          state
          zipCode
        }
        governmentIdCard
        healthReject
        lastAnsweredHealthSurveyQuestionKey
        fulfillmentConfiguration {
          sensorKind
        }
        lastCoreSubscription {
          primaryProduct {
            title
          }
          latestInvoice {
            latestCharge {
              created
            }
          }
        }
      }
    }
  }
`

/**
 * __useHealthQuestionnaireApprovalsAllUsersQuery__
 *
 * To run a query within a React component, call `useHealthQuestionnaireApprovalsAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthQuestionnaireApprovalsAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthQuestionnaireApprovalsAllUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *      forHqApproval: // value for 'forHqApproval'
 *   },
 * });
 */
export function useHealthQuestionnaireApprovalsAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IHealthQuestionnaireApprovalsAllUsersQuery,
    IHealthQuestionnaireApprovalsAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IHealthQuestionnaireApprovalsAllUsersQuery,
    IHealthQuestionnaireApprovalsAllUsersQueryVariables
  >(HealthQuestionnaireApprovalsAllUsersDocument, options)
}
export function useHealthQuestionnaireApprovalsAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IHealthQuestionnaireApprovalsAllUsersQuery,
    IHealthQuestionnaireApprovalsAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IHealthQuestionnaireApprovalsAllUsersQuery,
    IHealthQuestionnaireApprovalsAllUsersQueryVariables
  >(HealthQuestionnaireApprovalsAllUsersDocument, options)
}
export type HealthQuestionnaireApprovalsAllUsersQueryHookResult = ReturnType<
  typeof useHealthQuestionnaireApprovalsAllUsersQuery
>
export type HealthQuestionnaireApprovalsAllUsersLazyQueryHookResult = ReturnType<
  typeof useHealthQuestionnaireApprovalsAllUsersLazyQuery
>
export type HealthQuestionnaireApprovalsAllUsersQueryResult = Apollo.QueryResult<
  IHealthQuestionnaireApprovalsAllUsersQuery,
  IHealthQuestionnaireApprovalsAllUsersQueryVariables
>
export const AllNutritionistsDocument = gql`
  query AllNutritionists(
    $page: Int
    $perPage: Int
    $sorts: [UserSort!]
    $dynamicFilters: [UserDynamicFilterItem!]
  ) {
    allUsers(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      users {
        id
        fullName
        role
        email
        coachProfile {
          bio
          getHealthieId
          manager {
            id
            fullName
          }
          startDate
          endDate
          schedulingPriority
          stateLicences
          active
          outOfOffice
          updatedAt
          priorityChangedAt
          credentials
        }
      }
    }
  }
`

/**
 * __useAllNutritionistsQuery__
 *
 * To run a query within a React component, call `useAllNutritionistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNutritionistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNutritionistsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllNutritionistsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllNutritionistsQuery, IAllNutritionistsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllNutritionistsQuery, IAllNutritionistsQueryVariables>(
    AllNutritionistsDocument,
    options
  )
}
export function useAllNutritionistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllNutritionistsQuery, IAllNutritionistsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllNutritionistsQuery, IAllNutritionistsQueryVariables>(
    AllNutritionistsDocument,
    options
  )
}
export type AllNutritionistsQueryHookResult = ReturnType<typeof useAllNutritionistsQuery>
export type AllNutritionistsLazyQueryHookResult = ReturnType<typeof useAllNutritionistsLazyQuery>
export type AllNutritionistsQueryResult = Apollo.QueryResult<
  IAllNutritionistsQuery,
  IAllNutritionistsQueryVariables
>
export const OrderDocument = gql`
  query order($id: Int!) {
    order(id: $id) {
      id
      quantity
      createdAt
      invoice {
        stripeId
      }
      address {
        ...CoreAddressFields
      }
    }
  }
  ${CoreAddressFieldsFragmentDoc}
`

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<IOrderQuery, IOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IOrderQuery, IOrderQueryVariables>(OrderDocument, options)
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IOrderQuery, IOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IOrderQuery, IOrderQueryVariables>(OrderDocument, options)
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>
export type OrderQueryResult = Apollo.QueryResult<IOrderQuery, IOrderQueryVariables>
export const PreviewCoachConversationMessageDocument = gql`
  query previewCoachConversationMessage($userId: ID!, $messageTemplateId: ID!) {
    previewCoachConversationMessage(userId: $userId, messageTemplateId: $messageTemplateId) {
      value
    }
  }
`

/**
 * __usePreviewCoachConversationMessageQuery__
 *
 * To run a query within a React component, call `usePreviewCoachConversationMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewCoachConversationMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewCoachConversationMessageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      messageTemplateId: // value for 'messageTemplateId'
 *   },
 * });
 */
export function usePreviewCoachConversationMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    IPreviewCoachConversationMessageQuery,
    IPreviewCoachConversationMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IPreviewCoachConversationMessageQuery,
    IPreviewCoachConversationMessageQueryVariables
  >(PreviewCoachConversationMessageDocument, options)
}
export function usePreviewCoachConversationMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPreviewCoachConversationMessageQuery,
    IPreviewCoachConversationMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IPreviewCoachConversationMessageQuery,
    IPreviewCoachConversationMessageQueryVariables
  >(PreviewCoachConversationMessageDocument, options)
}
export type PreviewCoachConversationMessageQueryHookResult = ReturnType<
  typeof usePreviewCoachConversationMessageQuery
>
export type PreviewCoachConversationMessageLazyQueryHookResult = ReturnType<
  typeof usePreviewCoachConversationMessageLazyQuery
>
export type PreviewCoachConversationMessageQueryResult = Apollo.QueryResult<
  IPreviewCoachConversationMessageQuery,
  IPreviewCoachConversationMessageQueryVariables
>
export const ResetPasswordRequestDocument = gql`
  query resetPasswordRequest($email: String!) {
    resetPasswordRequest(email: $email) {
      success
    }
  }
`

/**
 * __useResetPasswordRequestQuery__
 *
 * To run a query within a React component, call `useResetPasswordRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetPasswordRequestQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    IResetPasswordRequestQuery,
    IResetPasswordRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IResetPasswordRequestQuery, IResetPasswordRequestQueryVariables>(
    ResetPasswordRequestDocument,
    options
  )
}
export function useResetPasswordRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IResetPasswordRequestQuery,
    IResetPasswordRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IResetPasswordRequestQuery, IResetPasswordRequestQueryVariables>(
    ResetPasswordRequestDocument,
    options
  )
}
export type ResetPasswordRequestQueryHookResult = ReturnType<typeof useResetPasswordRequestQuery>
export type ResetPasswordRequestLazyQueryHookResult = ReturnType<
  typeof useResetPasswordRequestLazyQuery
>
export type ResetPasswordRequestQueryResult = Apollo.QueryResult<
  IResetPasswordRequestQuery,
  IResetPasswordRequestQueryVariables
>
export const SupportAllUsersDocument = gql`
  query SupportAllUsers(
    $page: Int
    $perPage: Int
    $sorts: [UserSort!]
    $dynamicFilters: [UserDynamicFilterItem!]
  ) {
    allUsers(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      users {
        id
        fullName
        email
      }
    }
  }
`

/**
 * __useSupportAllUsersQuery__
 *
 * To run a query within a React component, call `useSupportAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportAllUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useSupportAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ISupportAllUsersQuery, ISupportAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISupportAllUsersQuery, ISupportAllUsersQueryVariables>(
    SupportAllUsersDocument,
    options
  )
}
export function useSupportAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISupportAllUsersQuery, ISupportAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISupportAllUsersQuery, ISupportAllUsersQueryVariables>(
    SupportAllUsersDocument,
    options
  )
}
export type SupportAllUsersQueryHookResult = ReturnType<typeof useSupportAllUsersQuery>
export type SupportAllUsersLazyQueryHookResult = ReturnType<typeof useSupportAllUsersLazyQuery>
export type SupportAllUsersQueryResult = Apollo.QueryResult<
  ISupportAllUsersQuery,
  ISupportAllUsersQueryVariables
>
export const AllTicketCommentsDocument = gql`
  query allTicketComments(
    $page: Int
    $perPage: Int
    $ticketId: ID!
    $sorts: [TicketCommentSort!]
    $dynamicFilters: [TicketCommentDynamicFilterItem!]
  ) {
    allTicketComments(
      page: $page
      perPage: $perPage
      ticketId: $ticketId
      sorts: $sorts
      dynamicFilters: $dynamicFilters
    ) {
      totalCount
      pageCount
      ticketComments {
        ticketId
        id
        body
        createdBy {
          id
          fullName
          email
          phoneNumber
          timeZone
        }
        createdAt
      }
    }
  }
`

/**
 * __useAllTicketCommentsQuery__
 *
 * To run a query within a React component, call `useAllTicketCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTicketCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTicketCommentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      ticketId: // value for 'ticketId'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllTicketCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<IAllTicketCommentsQuery, IAllTicketCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllTicketCommentsQuery, IAllTicketCommentsQueryVariables>(
    AllTicketCommentsDocument,
    options
  )
}
export function useAllTicketCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAllTicketCommentsQuery,
    IAllTicketCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllTicketCommentsQuery, IAllTicketCommentsQueryVariables>(
    AllTicketCommentsDocument,
    options
  )
}
export type AllTicketCommentsQueryHookResult = ReturnType<typeof useAllTicketCommentsQuery>
export type AllTicketCommentsLazyQueryHookResult = ReturnType<typeof useAllTicketCommentsLazyQuery>
export type AllTicketCommentsQueryResult = Apollo.QueryResult<
  IAllTicketCommentsQuery,
  IAllTicketCommentsQueryVariables
>
export const AllTicketsDocument = gql`
  query allTickets(
    $page: Int
    $perPage: Int
    $sorts: [TicketSort!]
    $dynamicFilters: [TicketDynamicFilterItem!]
  ) {
    allTickets(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      totalCount
      pageCount
      tickets {
        ...CoreTicketFields
      }
    }
  }
  ${CoreTicketFieldsFragmentDoc}
`

/**
 * __useAllTicketsQuery__
 *
 * To run a query within a React component, call `useAllTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTicketsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllTicketsQuery, IAllTicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllTicketsQuery, IAllTicketsQueryVariables>(AllTicketsDocument, options)
}
export function useAllTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllTicketsQuery, IAllTicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllTicketsQuery, IAllTicketsQueryVariables>(
    AllTicketsDocument,
    options
  )
}
export type AllTicketsQueryHookResult = ReturnType<typeof useAllTicketsQuery>
export type AllTicketsLazyQueryHookResult = ReturnType<typeof useAllTicketsLazyQuery>
export type AllTicketsQueryResult = Apollo.QueryResult<IAllTicketsQuery, IAllTicketsQueryVariables>
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...User
      address {
        name
        street
        street2
        city
        state
        zipCode
      }
    }
  }
  ${UserFragmentDoc}
`

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<IUserQuery, IUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IUserQuery, IUserQueryVariables>(UserDocument, options)
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUserQuery, IUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IUserQuery, IUserQueryVariables>(UserDocument, options)
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserQueryResult = Apollo.QueryResult<IUserQuery, IUserQueryVariables>
export const UserInsurancePolicyDocument = gql`
  query userInsurancePolicy($userId: ID!) {
    userInsurancePolicy(userId: $userId) {
      id
      unitsLeft
      unitsUsed
      unitsOccurred
      unitsAuthorized
      lastState {
        id
        kind
        reason
        createdAt
      }
      states {
        id
        kind
        reason
        createdAt
      }
    }
  }
`

/**
 * __useUserInsurancePolicyQuery__
 *
 * To run a query within a React component, call `useUserInsurancePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInsurancePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInsurancePolicyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserInsurancePolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    IUserInsurancePolicyQuery,
    IUserInsurancePolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IUserInsurancePolicyQuery, IUserInsurancePolicyQueryVariables>(
    UserInsurancePolicyDocument,
    options
  )
}
export function useUserInsurancePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUserInsurancePolicyQuery,
    IUserInsurancePolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IUserInsurancePolicyQuery, IUserInsurancePolicyQueryVariables>(
    UserInsurancePolicyDocument,
    options
  )
}
export type UserInsurancePolicyQueryHookResult = ReturnType<typeof useUserInsurancePolicyQuery>
export type UserInsurancePolicyLazyQueryHookResult = ReturnType<
  typeof useUserInsurancePolicyLazyQuery
>
export type UserInsurancePolicyQueryResult = Apollo.QueryResult<
  IUserInsurancePolicyQuery,
  IUserInsurancePolicyQueryVariables
>
export const UserSurveyLinksDocument = gql`
  query userSurveyLinks {
    userSurveyLinks {
      surveyLinks {
        id
        state
        finished
        responses {
          id
          questionKey
          answer
        }
        survey {
          kind
          version
          header
          completedScreen {
            header
            intro
            icon
          }
          questionsGroups {
            introScreen {
              header
              intro
              icon
            }
            questions {
              key
              label
              note
              required
              response {
                kind
                kindMetadata
                answersGroups {
                  answers {
                    label
                    value
                    followup {
                      kind
                      question {
                        key
                        label
                        note
                        required
                        response {
                          kind
                          kindMetadata
                          answersGroups {
                            answers {
                              label
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useUserSurveyLinksQuery__
 *
 * To run a query within a React component, call `useUserSurveyLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSurveyLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSurveyLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSurveyLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<IUserSurveyLinksQuery, IUserSurveyLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IUserSurveyLinksQuery, IUserSurveyLinksQueryVariables>(
    UserSurveyLinksDocument,
    options
  )
}
export function useUserSurveyLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUserSurveyLinksQuery, IUserSurveyLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IUserSurveyLinksQuery, IUserSurveyLinksQueryVariables>(
    UserSurveyLinksDocument,
    options
  )
}
export type UserSurveyLinksQueryHookResult = ReturnType<typeof useUserSurveyLinksQuery>
export type UserSurveyLinksLazyQueryHookResult = ReturnType<typeof useUserSurveyLinksLazyQuery>
export type UserSurveyLinksQueryResult = Apollo.QueryResult<
  IUserSurveyLinksQuery,
  IUserSurveyLinksQueryVariables
>
